import React, {useEffect, useRef, useState} from 'react';
import {formatAddress} from "../../../../utils/publicJs";
import {useConnect} from "../../../../api/contracts";
import {
    Button,
    message,
    Form,
    Input, Modal,
    Empty

} from 'antd';
import {getContractByName, getContractByContract} from "../../../../api/connectContract";
import {dealMethod, dealPayMethod, viewMethod} from "../../../../utils/contractUtil"
import develop from "../../../../env";
import AddThreeWhiteListStyle from "./OgAdminLevelStyle";
import judgeStatus from "../../../../utils/judgeStatus";
import sc from "../../../../imgs/sc.png"
import xz from "../../../../imgs/xz.png"
import wxz from "../../../../imgs/wxz.png"
import add from "../../../../imgs/add.png"
import cut from "../../../../imgs/remove.png"
import {useTranslation} from "react-i18next";

const AddThreeWhiteList = ({
                               allRecords,
                               isLevel2,
                               isThreeAdmin,

                           }) => {
    let {state, dispatch} = useConnect();
    const [addWhiteArr, setAddWArr] = useState([{}])
    const [curWhiteUser, setCurWhiteUser] = useState("")

    const [form] = Form.useForm();
    const [form2] = Form.useForm();
    const [isDelMolOpen, setDelOpen] = useState(false)
    const [isAddMolOpen, setAddOpen] = useState(false)
    const [adminWhiteList, setAdminWhiteList] = useState([])
    const [refRecords, setREFRecords] = useState([])
    const [maxSet, setMax] = useState(0)
    const [canSetLevel, setCanSetLevel] = useState(0)
    const { t } = useTranslation();


    const handleDealMethod = async (name, params) => {
        let contractTemp = await getContractByName("spbd", state.api,)
        if (!contractTemp) {
            message.warn("Please connect", 5)
        }
        await dealMethod(contractTemp, state.account, name, params)
    }
    const addOneWhite = async () => {
        let addWhiteArrT = JSON.parse(JSON.stringify(addWhiteArr))
        addWhiteArrT.push({})
        setAddWArr(addWhiteArrT)
    }
    const removeOneWhite = async () => {
        let addWhiteArrT = JSON.parse(JSON.stringify(addWhiteArr))
        addWhiteArrT.shift()
        setAddWArr(addWhiteArrT)
    }

    const handleViewMethod = async (name, params) => {
        let contractTemp = await getContractByName("spbd", state.api,)
        if (!contractTemp) {
            message.warn("Please connect", 5)
        }
        return await viewMethod(contractTemp, state.account, name, params)
    }

    const getUserSetAdminsLevelThree = async () => {
        try {
            let length = await handleViewMethod("getAdminsLevelThreeLength", [state.account])
            let adminWhiteList = []
            for (let i = 0; i < length; i++) {
                let res = await handleViewMethod("setAdminLevelThree_", [state.account, i])
                adminWhiteList.push(res)
            }
            setAdminWhiteList(adminWhiteList)

            let refArr = []
            allRecords.forEach(item => {
                adminWhiteList.forEach(adminItem => {
                    if (adminItem.user.toLowerCase() == item.addr.toLowerCase().toString()) {
                        refArr.push(item)
                    }
                })
            })
            let tAmount = 0, tETH = 0, tUSDT = 0
            refArr.forEach(item => {
                tAmount += parseFloat(item.fdtAmount)
                tETH += parseFloat(item.ethAmount)
                tUSDT += parseFloat(item.usdtAmount)
            })
            refArr.push({
                name: "Total",
                fdtAmount: tAmount,
                ethAmount: tETH,
                usdtAmount: tUSDT
            })
            setREFRecords(refArr)

        } catch (e) {

        }
    }
    const getUserSetAdminsLevelNine = async () => {
        try {
            let length = await handleViewMethod("getAdminsLevelNineLength", [state.account])
            let adminWhiteList = []
            for (let i = 0; i < length; i++) {
                let res = await handleViewMethod("setAdminLevelNine_", [state.account, i])
                adminWhiteList.push(res)
            }
            setAdminWhiteList(adminWhiteList)

            let refArr = []
            allRecords.forEach(item => {
                adminWhiteList.forEach(adminItem => {
                    if (adminItem.user.toLowerCase() == item.addr.toLowerCase().toString()) {
                        refArr.push(item)
                    }
                })
            })
            let tAmount = 0, tETH = 0, tUSDT = 0
            refArr.forEach(item => {
                tAmount += parseFloat(item.fdtAmount)
                tETH += parseFloat(item.ethAmount)
                tUSDT += parseFloat(item.usdtAmount)
            })
            refArr.push({
                name: "Total",
                fdtAmount: tAmount,
                ethAmount: tETH,
                usdtAmount: tUSDT
            })
            setREFRecords(refArr)

        } catch (e) {
            console.log(e)
        }
    }

    const getUserSetAdminsLevelEight = async () => {
        try {
            let length = await handleViewMethod("getAdminsLevelEightLength", [state.account])
            let adminWhiteList = []
            for (let i = 0; i < length; i++) {
                let res = await handleViewMethod("setAdminLevelEight_", [state.account, i])
                adminWhiteList.push(res)
            }
            setAdminWhiteList(adminWhiteList)

            let refArr = []
            allRecords.forEach(item => {
                adminWhiteList.forEach(adminItem => {
                    if (adminItem.user.toLowerCase() == item.addr.toLowerCase().toString()) {
                        refArr.push(item)
                    }
                })
            })
            let tAmount = 0, tETH = 0, tUSDT = 0
            refArr.forEach(item => {
                tAmount += parseFloat(item.fdtAmount)
                tETH += parseFloat(item.ethAmount)
                tUSDT += parseFloat(item.usdtAmount)
            })
            refArr.push({
                name: "Total",
                fdtAmount: tAmount,
                ethAmount: tETH,
                usdtAmount: tUSDT
            })
            setREFRecords(refArr)

        } catch (e) {
            console.log(e)
        }
    }
    const getUserSetAdminsLevelSeven = async () => {
        try {
            let length = await handleViewMethod("getAdminsLevelSevenLength", [state.account])
            let adminWhiteList = []
            for (let i = 0; i < length; i++) {
                let res = await handleViewMethod("setAdminLevelSeven_", [state.account, i])
                adminWhiteList.push(res)
            }
            setAdminWhiteList(adminWhiteList)

            let refArr = []
            allRecords.forEach(item => {
                adminWhiteList.forEach(adminItem => {
                    if (adminItem.user.toLowerCase() == item.addr.toLowerCase().toString()) {
                        refArr.push(item)
                    }
                })
            })
            let tAmount = 0, tETH = 0, tUSDT = 0
            refArr.forEach(item => {
                tAmount += parseFloat(item.fdtAmount)
                tETH += parseFloat(item.ethAmount)
                tUSDT += parseFloat(item.usdtAmount)
            })
            refArr.push({
                name: "Total",
                fdtAmount: tAmount,
                ethAmount: tETH,
                usdtAmount: tUSDT
            })
            setREFRecords(refArr)

        } catch (e) {
            console.log(e)
        }
    }
    const getUserSetAdminsLevelSix = async () => {
        try {
            let length = await handleViewMethod("getAdminsLevelSixLength", [state.account])
            let adminWhiteList = []
            for (let i = 0; i < length; i++) {
                let res = await handleViewMethod("setAdminLevelSix_", [state.account, i])
                adminWhiteList.push(res)
            }
            setAdminWhiteList(adminWhiteList)

            let refArr = []
            allRecords.forEach(item => {
                adminWhiteList.forEach(adminItem => {
                    if (adminItem.user.toLowerCase() == item.addr.toLowerCase().toString()) {
                        refArr.push(item)
                    }
                })
            })
            let tAmount = 0, tETH = 0, tUSDT = 0
            refArr.forEach(item => {
                tAmount += parseFloat(item.fdtAmount)
                tETH += parseFloat(item.ethAmount)
                tUSDT += parseFloat(item.usdtAmount)
            })
            refArr.push({
                name: "Total",
                fdtAmount: tAmount,
                ethAmount: tETH,
                usdtAmount: tUSDT
            })
            setREFRecords(refArr)

        } catch (e) {
            console.log(e)
        }
    }
    const getUserSetAdminsLevelFive = async () => {
        try {
            let length = await handleViewMethod("getAdminsLevelFiveLength", [state.account])
            let adminWhiteList = []
            for (let i = 0; i < length; i++) {
                let res = await handleViewMethod("setAdminLevelFive_", [state.account, i])
                adminWhiteList.push(res)
            }
            setAdminWhiteList(adminWhiteList)

            let refArr = []
            allRecords.forEach(item => {
                adminWhiteList.forEach(adminItem => {
                    if (adminItem.user.toLowerCase() == item.addr.toLowerCase().toString()) {
                        refArr.push(item)
                    }
                })
            })
            let tAmount = 0, tETH = 0, tUSDT = 0
            refArr.forEach(item => {
                tAmount += parseFloat(item.fdtAmount)
                tETH += parseFloat(item.ethAmount)
                tUSDT += parseFloat(item.usdtAmount)
            })
            refArr.push({
                name: "Total",
                fdtAmount: tAmount,
                ethAmount: tETH,
                usdtAmount: tUSDT
            })
            setREFRecords(refArr)

        } catch (e) {
            console.log(e)
        }
    }
    const getUserSetAdminsLevelFour = async () => {
        try {
            let length = await handleViewMethod("getAdminsLevelFourLength", [state.account])
            let adminWhiteList = []
            for (let i = 0; i < length; i++) {
                let res = await handleViewMethod("setAdminLevelFour_", [state.account, i])
                adminWhiteList.push(res)
            }
            setAdminWhiteList(adminWhiteList)

            let refArr = []
            allRecords.forEach(item => {
                adminWhiteList.forEach(adminItem => {
                    if (adminItem.user.toLowerCase() == item.addr.toLowerCase().toString()) {
                        refArr.push(item)
                    }
                })
            })
            let tAmount = 0, tETH = 0, tUSDT = 0
            refArr.forEach(item => {
                tAmount += parseFloat(item.fdtAmount)
                tETH += parseFloat(item.ethAmount)
                tUSDT += parseFloat(item.usdtAmount)
            })
            refArr.push({
                name: "Total",
                fdtAmount: tAmount,
                ethAmount: tETH,
                usdtAmount: tUSDT
            })
            setREFRecords(refArr)

        } catch (e) {
            console.log(e)
        }
    }

    const handleSetAdminLevelFour = async () => {
        let arr = []
        for (let i = 0; i < addWhiteArr.length; i++) {
            arr.push(form2.getFieldValue()["address" + i])
        }
        await handleDealMethod("setAdminLevelFour", [arr])
        getUserSetAdminsLevelFour()
    }
    const handleSetAdminLevelFive = async () => {
        let arr = []
        for (let i = 0; i < addWhiteArr.length; i++) {
            arr.push(form2.getFieldValue()["address" + i])
        }
        await handleDealMethod("setAdminLevelFive", [arr])
        getUserSetAdminsLevelFive()
    }

    const handleSetAdminLevelSix = async () => {
        let arr = []
        for (let i = 0; i < addWhiteArr.length; i++) {
            arr.push(form2.getFieldValue()["address" + i])
        }
        await handleDealMethod("setAdminLevelSix", [arr])
        getUserSetAdminsLevelSix()
    }
    const handleSetAdminLevelSeven = async () => {
        let arr = []
        for (let i = 0; i < addWhiteArr.length; i++) {
            arr.push(form2.getFieldValue()["address" + i])
        }
        await handleDealMethod("setAdminLevelSeven", [arr])
        getUserSetAdminsLevelSeven()
    }
    const handleSetAdminLevelEight = async () => {
        let arr = []
        for (let i = 0; i < addWhiteArr.length; i++) {
            arr.push(form2.getFieldValue()["address" + i])
        }
        await handleDealMethod("setAdminLevelEight", [arr])
        getUserSetAdminsLevelEight()
    }
    const handleSetAdminLevelNine = async () => {
        let arr = []
        for (let i = 0; i < addWhiteArr.length; i++) {
            arr.push(form2.getFieldValue()["address" + i])
        }
        await handleDealMethod("setAdminLevelNine", [arr])
        getUserSetAdminsLevelNine()
    }
    const handleSetAdminLevelThree = async () => {
        let arr = []
        for (let i = 0; i < addWhiteArr.length; i++) {
            arr.push(form2.getFieldValue()["address" + i])
        }
        await handleDealMethod("setAdminLevelThree", [arr])
        getUserSetAdminsLevelThree()
    }

    const getMaxThree = async () => {
        let res
        if (isLevel2) {
            res = await handleViewMethod("maxTwo", [])
        } else if (isThreeAdmin) {
            res = await handleViewMethod("maxThree", [])
        }
        setMax(res.toString())
    }

    const removeWhiteListUser = async () => {
        if (isLevel2) {
            await handleDealMethod("removeAdminLevelThree", [curWhiteUser])
            getUserSetAdminsLevelThree()
        } else {
            await handleDealMethod("removeAdminLevelFour", [curWhiteUser])
            getUserSetAdminsLevelFour()

        }
        setDelOpen(false)

    }

    const AddLevel = async () => {
        if (isLevel2) {
            handleSetAdminLevelThree()
        } else if (isThreeAdmin) {
            handleSetAdminLevelFour()
        }

    }

    const deleteWhite = async (user) => {
        setCurWhiteUser(user)
        setDelOpen(true)
    }

    useEffect(async () => {
        let judgeRes = await judgeStatus(state)
        if (!judgeRes) {
            return
        }
        if (isLevel2) {
            getUserSetAdminsLevelThree()
            setCanSetLevel(3)
        } else if (isThreeAdmin) {
            getUserSetAdminsLevelFour()
            setCanSetLevel(4)
        }
        getMaxThree()
    }, [state.account]);


    return (
        <AddThreeWhiteListStyle>

            <div className="part3">
                <Modal className="model-dialog" title="Delete " open={isDelMolOpen} onOk={removeWhiteListUser}
                       onCancel={() => {
                           setDelOpen(false)
                       }}>
                    <p>
                        Wallet Address
                    </p>
                    <div className="value">
                        {curWhiteUser}
                    </div>
                </Modal>
                <div className="panel-box">
                    <div className="panel-container">
                        {/* <div className="tip">
                                I can have <strong>{maxSet}</strong> level{canSetLevel} admin, I've
                                got <strong>{adminWhiteList.length}</strong> level{canSetLevel} admin, I can
                                set up <strong>{maxSet - adminWhiteList.length}</strong> level{canSetLevel} admin.
                            </div> */}
                        <Modal className="model-dialogadd" title="Add" open={isAddMolOpen} onOk={
                            AddLevel
                        }
                               onCancel={() => {
                                   setAddOpen(false)
                               }}>
                            <Form form={form2} name="control-hooks" className="form">

                                {addWhiteArr.map((item, index) => {
                                    return (
                                        <Form.Item
                                            name={"address" + index}
                                            validateTrigger="onBlur"
                                            label="Address"
                                            validateFirst={true}
                                        >
                                            <div className="input-box">
                                                {/*<Input type="text" value={addressValue} onChange={handleInputChange}*/}
                                                {/*       onPaste={handlePaste}/>*/}
                                                <Input type="text"></Input>
                                            </div>
                                        </Form.Item>
                                    )
                                })}
                                <div className="icon-box">
                                    <img src={add} onClick={() => {
                                        addOneWhite()
                                    }}/>
                                    <img src={cut} onClick={() => {
                                        removeOneWhite()
                                    }}/>

                                </div>
                            </Form>
                        </Modal>
                        <div className='panel-title'>
                            Set Lv-{isLevel2 ? 3 : ""}

                            &nbsp; Administrator
                        </div>
                        <div className="superdao-list-box admin3-list">
                            <div className="list-header3 list-header">
                                <div className="col">
                                    {t("No.")}
                                </div>

                                <div className="col ">
                                    Address
                                </div>
                                <div className="col">
                                    Delete
                                </div>
                            </div>

                            {
                                adminWhiteList.length == 0 ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/> :
                                    adminWhiteList.map((item, index) => (
                                            <div className="list-item "
                                                 key={index}
                                            >
                                                <div className="col no">
                                                    {index + 1}
                                                </div>

                                                {state.isMobile &&
                                                    <div className="col address">

                                                        <a>{formatAddress(item)}</a>
                                                    </div>
                                                }
                                                {!state.isMobile &&
                                                    <div className="col address">
                                                        <a>{item}</a>
                                                    </div>
                                                }
                                                <div className="col sc1">
                                                    <img src={sc} className="sc" id='scc' onClick={(e) => {
                                                        deleteWhite(item)
                                                    }}/>
                                                </div>
                                            </div>
                                        )
                                    )
                            }
                            <div className='addsbt' onClick={() => {
                                setAddOpen(true)

                            }}>Add
                            </div>

                            {/* { && <div className='addsbt' onClick={() => {
                                setAddOpen(true)
                            }}>Add</div>}

                            { && <div className='addsbt' onClick={() => {
                                setAddOpen(true)
                            }}>Add</div>} */}
                        </div>


                    </div>
                </div>
            </div>


        </AddThreeWhiteListStyle>
    )
}
export default AddThreeWhiteList
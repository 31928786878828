import styled from "styled-components";

export default styled.div`

  .ant-pagination .ant-pagination-item-link {
    border-radius: 5px;
  }

  .ant-pagination-disabled {
    border-radius: 5px;

  }

  .receive-nft {
    text-transform: uppercase;
  }

  .panel-container .search-container .search-box .ant-input-affix-wrapper {
    border: none;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {

    background: #1C1C1C !important;
    border: 1px solid rgba(255, 255, 255, 0.15);
    color: #FFFFFF;

    .ant-select-selection-item {
      color: #C3C3C3;
      font-weight: bold;
    }
  }

  .ant-pagination-item-link {
    background: #1C1C1C !important;

    border: 1px solid rgba(255, 255, 255, 0.15);
    color: #FFFFFF;
  }

  .ant-pagination-item {
    background: #1C1C1C !important;
    color: #FFFFFF;
    border: 1px solid rgba(255, 255, 255, 0.15);
  }

  .anticon-right {
    color: #FFFFFF !important;

  }

  .anticon-left {
    color: #FFFFFF !important;

  }

  .filter-box .ant-select:not(.ant-select-customize-input) .ant-select-selector {

    border-radius: 10px;
    height: 45px;

    .ant-select-selection-item {
      line-height: 45px;
    }
  }

  .signup-dialog {
    .input-title {
      font-size: 20px;
      font-family: Roboto-Bold, Roboto;
      font-weight: bold;
      color: #8A8080;
    }
  }

  .panel-box {
    padding: 5px 2em;
  }

  .ant-switch {
    border-radius: 8px;

  }

  .ant-switch-checked {

    background: linear-gradient(229deg, #DAB163 0%, #CC8A35 100%);
  }

  .switchh {
    display: flex;
    font-weight: 500;
    justify-content: space-between;

    span {
      margin: 5px 0;
      font-size: 16px;
      font-family: Roboto-Medium, Roboto;
      font-weight: 500;
      color: #8A8080;
      line-height: 21px;
    }
  }


  .status-header {
    border: 1px solid rgba(255, 255, 255, 0.15);
    padding: 25px;
    background: #1B1B1B;
    box-shadow: 0px 5px 20px 5px rgba(0, 0, 0, 0.05);
    border-radius: 20px 20px 20px 20px;
    display: block;
    width: 40%;
    margin: 1em auto;

    .id-box {
      font-size: 18px;
      color: #CD9E57;
      font-weight: bold;
    }

    .signUp-btn {
      width: 100%;
      height: 41px;
      display: flex;
      justify-content: center;
      font-size: 18px;
      font-family: Roboto-SemiBold, Roboto;
      font-weight: 600;
      color: #1A1414;
      line-height: 24px;
      align-items: center;

      cursor: pointer;
    }

  }

  .withdraw {
    background: linear-gradient(229deg, rgb(218, 177, 99) 0%, rgb(204, 138, 53) 100%);
    border-radius: 10px 10px 10px 10px;
    margin: 1em 0;
    width: 100%;
    height: 41px;
    display: flex;
    justify-content: center;
    font-size: 18px;
    font-family: Roboto-SemiBold, Roboto;
    font-weight: 600;
    color: #1A1414;
    line-height: 24px;
    align-items: center;

    cursor: pointer;
  }


  .status-info {
    margin: 8px 0;
    display: block;
    margin-bottom: 10px;

    .info-item {

      flex: 1;
      font-size: 16px;
      font-family: Roboto-Medium, Roboto;
      font-weight: 500;
      color: #333333;
      display: block;
      align-items: center;


    }

    .my-id {
      float: right;
    }

  }

  .active-content-box {
    margin: 1em 0;

    margin-bottom: 20px;

    .content-item-box {
      display: flex;
      width: 100%;
      background: rgb(28, 28, 28);
      box-shadow: 0px 5px 20px 5px rgba(0, 0, 0, 0.05);
      border-radius: 20px 20px 20px 20px;
      padding: 25px 35px;

      .name {
        color: #8A8080;
        font-size: 18px;
      }

      .value {
        color: #FFFFFF;
        font-size: 18px;
      }

      .flex-box {
        width: 32%;
        margin: 10px 0;
        align-items: center;

        .name {
          font-size: 20px;
          font-family: Roboto-Medium, Roboto;
          font-weight: 500;
          color: #8A8080;
          line-height: 26px;
        }


        .value {
          font-size: 20px;
          font-family: Roboto-Bold, Roboto;
          font-weight: bold;
          color: #CD9E57;
          line-height: 26px;
          margin-top: 0.5em;
        }
      }

      .content-list {
        width: 64%;
        justify-content: space-between;

        .content-item {
          .name {
            font-size: 20px;
            font-family: Roboto-Medium, Roboto;
            font-weight: 500;
            color: #8A8080;
            line-height: 26px;
          }

          .value {
            margin-top: 0.5em;
            font-size: 20px;
            font-family: Roboto-Bold, Roboto;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 26px;
          }
        }
      }
    }


  }

  .superdao-list-box {
    margin: 1em 0em;

    .list-header {
      display: flex;
      justify-content: flex-start;
      padding: 20px 2em;
      border-bottom: 1px solid rgba(255,255,255,0.1);
    }

    .list-item {
      display: flex;
      justify-content: flex-start;
      padding: 1em 0.5em;
      margin: 0 auto;
      border-radius: 0px;
      border-bottom: 1px solid #EAEAEA;

      .col {

        overflow: hidden;
        padding-left: 0%;

      }

      .address {
        a {
          color: #CD9E57;
        }
      }
    }
  }


  .search-box {
    display: flex;
    align-items: center;

    border-radius: 30px;
    border: 1px solid rgb(234, 234, 234, 0.1);

    height: 45px;

    .ant-input-affix-wrapper {
      line-height: 2;
      border: none;
      padding: 7px 12px;
      border-radius: 10px;

      &:active {
        border-color: none !important;
      }

      .ant-input {
        background: rgb(47, 47, 47) !important;
        height: auto;
      }
    }

    .ant-select-selector {
      border-radius: 8px;
      background-color: #FFFFFF;
      height: 45px;
    }
  }


  .team-part {
    .in-line {
      color: #FFFFFF;
      display: flex;
      justify-content: flex-start;
      margin: 10px 0;

      .left {
        font-weight: bold;
        margin-right: 20px;
      }

      .info-item {
        margin-right: 10px;

        strong {
          font-size: 18px;
          margin-right: 10px;
          color: #8A8080;
        }

        span {

        }
      }
    }

    .flex-title {
      display: block;

      .right {
        margin: 1.5em 0;

        .reward-item {
          padding: 2em;
          width: 100%;
          background: #1C1C1C;
          box-shadow: 0px 5px 20px 5px rgba(0, 0, 0, 0.05);
          border-radius: 20px 20px 20px 20px;
          border: 1px solid rgba(255, 255, 255, 0.15);

          p {
            font-size: 20px;
            font-family: Roboto-Medium, Roboto;
            font-weight: 500;
            color: #8A8080;
            line-height: 26px;

          }

          img {
            width: 30px;
            height: 30px;
          }

          span {
            vertical-align: middle;
            font-size: 26px;
            font-family: Roboto-Black, Roboto;
            font-weight: 900;
            color: #FFFFFF;
            line-height: 34px;
          }

        }
      }
    }
  }


  .page-title {
    font-size: 30px;
    font-family: Roboto-Bold, Roboto;
    font-weight: bold;
    padding: 0px 11.7%;
  }

  .ant-form {
    position: relative;

    .down-icon {
      width: 40px;
      height: 40px;
      position: absolute;
      top: 18%;
      left: 45%;
    }

  }

  .header-nav {
    padding: 10px 4%;
    display: flex;
    overflow: auto;

    .superdao-nav-list {
      margin: 0em;
      height: 45px;
      width: auto;
      padding: 0;

      .nav-item {
        width: auto !important;
        white-space: nowrap;
        padding: 0 20px;
        font-size: 18px;
      }
    }
  }

  .withdraw-header {
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    line-height: 21px;

    .add-coin {
      font-size: 16px;
      font-family: Roboto-Bold, Roboto;
      font-weight: bold;
      color: rgb(51, 51, 51);
    }

  }

  .panel-container {

    padding: 5px 2%;

    .info {
      font-size: 20px;
      font-family: Roboto-Medium, Roboto;
      font-weight: 500;
      color: #AC8989;
      line-height: 34px;
    }
  }

  .part1 {
    .panel-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .withdraw-info {
      border-bottom: 1px solid rgba(234, 234, 234, 0.10);
      margin-bottom: 1em;

      display: block;

      .info-item {
        display: flex;
        justify-content: space-between;

        .name {
          margin: 8px 0;
          font-size: 16px;
          font-family: Roboto-Medium, Roboto;
          font-weight: 500;
          color: #8A8080;
          line-height: 21px;
        }

        .value {
          font-size: 16px;
          font-family: Roboto-Black, Roboto;
          font-weight: 900;
          color: #FFFFFF;
          line-height: 21px;
        }
      }


      .flex-box {
        margin-bottom: 1em;
        display: block;

        .info-item {

          display: flex;
          justify-content: space-between;

          .name {
            margin: 8px 0;
            font-size: 16px;
            font-family: Roboto-Medium, Roboto;
            font-weight: 500;
            color: #8A8080;
            line-height: 21px;
          }

          .value {
            font-size: 16px;
            font-family: Roboto-Black, Roboto;
            font-weight: 900;
            color: #FFFFFF;
            line-height: 21px;
          }
        }

      }
    }


    .donation-box {
      width: 40%;
      border: 1px solid rgba(255, 255, 255, 0.15);
      margin: 25px auto;
      background: rgb(28, 28, 28);
      box-shadow: 0px 5px 20px 5px rgba(0, 0, 0, 0.05);
      border-radius: 20px 20px 20px 20px;
      padding: 20px;

      .title {
        font-size: 24px;
        font-family: Roboto-Bold, Roboto;
        font-weight: bold;
        padding-bottom: 10px;

      }

      .withdraw-part {
        background: #272727;
        border-radius: 10px 10px 10px 10px;
        opacity: 1;
        border: 1px solid rgba(255, 255, 255, 0.1);
        padding: 20px;
        margin-top: 1.5em;

        .ant-form-item-control-input {
          background: none;
        }

        .balance-box {
          margin-bottom: 0.5em;
          display: flex;
          justify-content: space-between;

          strong {

            font-size: 16px;
            font-family: Roboto-Bold, Roboto;
            font-weight: bold;
            color: #FFFFFF;
          }

          .receive {
            font-size: 16px;
            font-family: Roboto-Bold, Roboto;
            font-weight: bold;
            color: #8A8080;
            line-height: 19px;
          }


          .name {
            font-size: 16px;
            font-family: Roboto-Bold, Roboto;
            font-weight: bold;
            color: #8A8080;
          }

          .value {
            font-size: 16px;
            font-family: Roboto-Bold, Roboto;
            font-weight: bold;
            color: #8A8080;
          }


        }

        .ant-form {
          .ant-form-item {
            margin-bottom: 0px;
          }
        }

        .node-img {
          padding: 20px 0 0;
          width: 100%;
          display: flex;
          justify-content: center;
        }

        .input-box {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .right-tip {
            display: flex;
            align-items: center;
            color: #FFFFFF;
            font-size: 16px;
            font-family: Roboto-Black, Roboto;
            font-weight: 900;

            .coin-icon {
              width: 30px;
              height: 30px;
              margin-right: 6px;
            }
          }

          .exchangeAmount {
            color: #FFFFFF;
            height: 50px;
            display: flex;
            flex-grow: 1;
            align-items: center;
            font-size: 20px;
            padding: 0 10px;
            font-family: Roboto-Black, Roboto;
            font-weight: 900;
          }

          .ant-select-selection-item {
            color: #C3C3C3;
            font-weight: bold;
          }

          .ant-select {

            width: 50% !important;
            border: 1px solid rgba(255, 255, 255, 0.15);
            height: 40px;

          }

          .ant-input-number {
            width: 100% !important;
            height: 45px;
          }

          .ant-input-number, .ant-select {
            font-size: 20px;
            font-family: Roboto-Black, Roboto;
            font-weight: 900;
            border-radius: 10px;

            .ant-select-selector {
              border-radius: 10px;
              height: 45px;

            }

            .ant-input-number-input-wrap, .ant-select-selector, input {
              height: 100%;
              width: 100% !important;
              font-size: 20px;
              font-family: Roboto-Black, Roboto;
              display: flex;
              align-items: center;
              border: none;
              font-weight: 900;

              &:focus-visible {
                outline: none;
              }
            }

            .ant-select-clear {
              margin-right: 66px;
            }
          }

          .max-btn {
            width: 60px;
            position: absolute;
            right: 10px;
            top: 10px;
          }

        }

      }

      .tip {
        margin-top: 2em;
        font-size: 14px;
        font-family: Roboto-SemiBold, Roboto;
        font-weight: 600;
        color: #8A8080;
      }
    }

    .ant-btn-primary {
      margin-top: 1em;
      width: 100%;
      height: 41px;
      font-size: 20px;
      font-family: Helvetica-Bold, Helvetica;
      font-weight: bold;
    }


  }

  .part2 {
    .list-top-part {

      .superdao-nav-list {
        height: 45px;
        width: auto;

        .nav-item {
          width: auto;
          font-size: 18px;
        }
      }
    }

  }


  .team-list {
    margin: 1em 0em;
    background: rgba(28, 28, 28, 1);

    .list-header {

      padding: 20px 2em;
      border-bottom: 1px solid rgba(234, 234, 234, 0.10);
    }

    .list-item, .list-header {
      justify-content: flex-start;

      .col {
        text-align: left;
        font-size: 16px;

        &:nth-child(1) {
          margin-left: 50px;
          width: 17%;
        }

        &:nth-child(2) {
          width: 17%;
          margin-left: 20px;
        }

        &:nth-child(3) {
          width: 17%;
          margin-left: 20px;
        }

        &:nth-child(4) {
          width: 17%;
          margin-left: 20px;
        }

        &:nth-child(5) {
          width: 17%;
          margin-left: 20px;
        }

        /* &:nth-child(6) {
          width: 14%;
           margin-left: 20px;
        } */
      }
    }

    .list-item:last-child {
      border-bottom: none;
    }

    .list-item {
      padding: 1em 2.2em;
      width: 100%;
      color: #FFFFFF;

      margin: 0 auto;
      border-radius: 0px;
      border-bottom: 1px solid rgba(234, 234, 234, 0.10);

      &:nth-child(odd) {
        background: rgba(28, 28, 28, 1);

      }

      &:nth-child(even) {

        background: rgba(62, 62, 62, 1);
      }

      .col {
        overflow: hidden;
        padding-left: 0%;

      }

      .address {
        a {
          color: #CC8A35;
        }
      }
    }
  }

  .addsbt {
    text-align: center;
    width: 13%;
    padding: 6px;
    font-size: 18px;
    margin: 1.5em auto;
    border: 1px solid;
    color: #FFAE4E;

    border-radius: 8px;
  }

  .admin3-list {
    background: rgba(28, 28, 28, 1);

    .list-header {
      display: flex;
      padding: 20px 2em;
      border-bottom: 1px solid rgba(234, 234, 234, 0.10);
    }

    .list-item, .list-header {
      justify-content: flex-start;

      .col {
        text-align: left;
        font-size: 16px;

        &:nth-child(1) {
          margin-left: 60px;
          width: 28%;
        }

        &:nth-child(2) {
          width: 28%;
          margin-left: 20px;
        }

        &:nth-child(3) {
          width: 30%;
          margin-left: 20px;
        }

      }
    }

    .list-item:last-child {
      border-bottom: none;
    }

    .list-item {
      color: #FFFFFF;
      padding: 1em 2.5em;
      width: 100%;
      margin: 0 auto;
      border-radius: 0px;
      border-bottom: 1px solid rgba(234, 234, 234, 0.10);

      &:nth-child(even) {

        background: rgba(62, 62, 62, 1);
      }

      .col {
        overflow: hidden;
        padding-left: 0%;

      }

      .address {
        a {
          color: #CD9E57;
        }
      }
    }
  }


  .withdraw-list {
    margin: 1em 0;
    background: rgba(28, 28, 28, 1);
    overflow-x: scroll;

    .listheadert {
      width: 2100px;
    }

    .list-header {
      display: flex;
      padding: 20px 1.6em;
      border-bottom: 1px solid rgba(234, 234, 234, 0.10);
    }

    .list-item, .list-header {
      justify-content: flex-start;

      .col {
        text-align: left;
        font-size: 16px;

        &:nth-child(1) {
          margin-left: 5px;
          width: 140px;
        }

        &:nth-child(2) {
          width: 140px;
        }

        &:nth-child(3) {
          width: 140px;
        }

        &:nth-child(4) {
          width: 160px;
        }

        &:nth-child(5) {
          width: 160px;
        }

        &:nth-child(6) {
          width: 160px;
        }

        &:nth-child(7) {
          width: 200px;
        }

        &:nth-child(8) {
          width: 200px;
        }

        &:nth-child(9) {
          width: 160px;
        }

        &:nth-child(10) {
          width: 170px;
        }

        &:nth-child(11) {
          width: 200px;
        }
        &:nth-child(12) {
          width: 180px;
        }
      }
    }

    .list-item:last-child {
      border-bottom: none;
    }

    .list-item {
      color: #FFFFFF;
      padding: 1em 2em;
      width: 100%;
      margin: 0 auto;
      border-radius: 0px;
      border-bottom: 1px solid rgba(234, 234, 234, 0.10);

      &:nth-child(even) {
        background: rgba(62, 62, 62, 1);
      }

      .col {
        overflow: hidden;
        padding-left: 0%;

        .item {
          font-size: 16px;
        }
      }

      .address {
        a {
          color: #CD9E57;
        }
      }
    }
  }
 .mylistheadert{
    width: 1630px;
 }

  .mywithdraw-list {
    margin: 1em 0;
    background: rgba(28, 28, 28, 1);
    overflow-x: auto;

    .list-header {
      display: flex;
      padding: 20px 2em;
      border-bottom: 1px solid rgba(234, 234, 234, 0.10);
      
    }

    .list-item, .list-header {
      justify-content: flex-start;

      .col {
        text-align: left;
        font-size: 16px;

        &:nth-child(1) {
          margin-left: 5px;
          width: 140px;
        }

        &:nth-child(2) {
          width: 160px;
        }

        &:nth-child(3) {
          width: 160px;
        }

        &:nth-child(4) {
          width: 160px;
        }

        &:nth-child(5) {
          width: 160px;
        }

        &:nth-child(6) {
          width: 200px;
        }

        &:nth-child(7) {
          width: 200px;
        }

        &:nth-child(8) {
          width: 180px;
        }

        &:nth-child(9) {
          width: 180px;
        }
      }
    }

    .list-item:last-child {
      border-bottom: none;
    }

    .list-item {
      padding: 1em 2em;
      color: #FFFFFF;
      margin: 0 auto;
      border-radius: 0px;
      border-bottom: 1px solid rgba(234, 234, 234, 0.10);;

      &:nth-child(even) {

        background: rgba(62, 62, 62, 1);
      }


      .col {
        overflow: hidden;
        padding-left: 0%;

      }

      .address {
        a {
          color: #CD9E57;
        }
      }
    }
  }

  .pagination {
    display: block !important;
    text-align: center;
  }


  @media screen and (max-width: 1440px) {
    .page-title {
      font-size: 21px;
      font-family: Roboto-Bold, Roboto;
      font-weight: bold;
      padding: 0px 11.7%;
    }

   .switchh span {
    font-size: 15px;
    }
    .signup-dialog {
      .input-title {
        font-size: 15px;
      }
    }


    .ant-form {
      position: relative;

      .down-icon {
        width: 40px;
        height: 40px;
        position: absolute;
        top: 20%;
        left: 45%;
      }

    }


    .header-nav {
      padding: 10px 4%;
      display: flex;
      width: 100%;

      .superdao-nav-list {
        height: 45px;
        width: auto;

        .nav-item {
          width: auto;

          font-size: 16px;
        }
      }
    }

    .withdraw-header {
      display: flex;
      justify-content: space-between;
      font-size: 16px;

      .add-coin {
        font-size: 14px;
        font-family: Roboto-Bold, Roboto;
        font-weight: bold;
        color: rgb(51, 51, 51);
      }


    }

    .panel-container {

      .info {
        font-size: 20px;
        font-family: Roboto-Medium, Roboto;
        font-weight: 500;
        color: #AC8989;
        line-height: 34px;
      }
    }

    .part1 {
      .panel-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .status-info {
        .info-item {
          font-size: 15px;
        }

      }

      .status-header {
        .signUp-btn {
          font-size: 16px;
          height: 36px;
        }
      }

      .withdraw-info {
        .info-item {
          .name {
            font-size: 15px;
          }

          .value {
            font-size: 15px;
          }
        }

        .flex-box {
          .info-item {
            .name {
              font-size: 15px;
            }

            .value {
              font-size: 15px;
            }
          }
        }
      }


      .donation-box {
        width: 40%;
        margin: 20px auto;
        border-radius: 20px;
        border: 1px solid rgba(255, 255, 255, 0.1);
        padding: 20px;

        .title {
          font-size: 16px;
          font-family: Roboto-Bold, Roboto;
          font-weight: bold;
          padding-bottom: 10px;

        }

        .withdraw-part {
          .ant-form-item-control-input {
            background: none;
          }

          .balance-box {
            strong {
              font-size: 15px;
            }

            p {
              width: 61%;
              text-align: left;
              font-size: 15px;
              color: white;
              font-weight: bold;
            }

            .name {

              font-size: 15px;
              font-weight: bold;
              color: #796B6B;
              line-height: 30px;
            }

            .value {
              font-size: 15px;
              font-weight: bold;
              color: #796B6B;
              display: flex;
              line-height: 30px;
              margin-left: 10px;
            }
          }

          .input-box {
            position: relative;
            display: flex;
            align-items: center;

            .right-tip {
              display: flex;
              align-items: center;

              font-size: 15px;
              font-family: Roboto-Black, Roboto;
              font-weight: 900;

              .coin-icon {
                width: 28px;
                height: 28px;
                margin-right: 6px;
              }
            }

            .exchangeAmount {
              font-size: 20px;
            }

            .ant-input-number, .ant-select {
              font-size: 15px;
              font-family: Roboto-Black, Roboto;
              font-weight: 900;
              height: 50px;
              width: 100% !important;

              .ant-input-number-input-wrap, .ant-select-selector, input {
                height: 100%;
                width: 100% !important;
                font-family: Roboto-Black, Roboto;
                display: flex;
                align-items: center;

                font-weight: 900;

                &:focus-visible {
                  outline: none;
                }
              }

              .ant-select-clear {
                margin-right: 66px;
              }
            }

            .max-btn {
              width: 60px;
              position: absolute;
              right: 10px;
              top: 10px;
            }

          }

        }

        .tip {
          margin-top: 2em;
          font-size: 14px;
          font-family: Roboto-SemiBold, Roboto;
          font-weight: 600;
          color: #8A8080;
        }
      }


      .ant-btn-primary {
        margin-top: 1em;
        width: 100%;
        height: 36px;
        font-size: 16px;
        font-family: Helvetica-Bold, Helvetica;
        font-weight: bold;
      }


    }

    .part2 {
      .list-top-part {

        .superdao-nav-list {
          height: 45px;
          width: 43%;

          .nav-item {
            width: 42%;
            font-size: 16px;
          }
        }
      }

    }


    .team-part {
      .in-line {

        .info-item {

          strong {
            font-size: 16px;
          }
        }
      }


      .flex-title .right .reward-item {
        p {
          font-size: 18px;
        }

        span {
          font-size: 20px;
        }

        img {
          width: 28px;
          height: 28px;
        }
      }
    }

    .team-list {
      .list-header {
        font-size: 15px;
      }

      .list-header, .list-item {
        .col {
          font-size: 15px;

          &:nth-child(1) {
            margin-left: 30px;
          }
        }
      }

    }


    .total-box {
      .reward-item {
        p {
          font-size: 18px;
        }

        span {
          font-size: 20px;
        }

        img {
          width: 28px;
          height: 28px;
        }
      }
    }


    .withdraw-list {
      overflow-x: scroll;

      .listheadert {
        width: 2100px;
      }

      .list-header {
        font-size: 15px;
      }

      .list-item {
        width: 100%;
        padding: 1em 1.8em;
      }

      .list-header, .list-item {
        .col {
          font-size: 15px;

          &:nth-child(1) {
            margin-left: 0px;
            width: 140px;
          }

          &:nth-child(6) {
            margin-left: 0px;
            width: 190px;
          }

          &:nth-child(7) {
            margin-left: 0px;
            width: 190px;
          }

          &:nth-child(10) {
            margin-left: 0px;
            width: 190px;
          }

          &:nth-child(11) {
            margin-left: 0px;
            width: 180px;
          }

          &:nth-child(12) {
            margin-left: 0px;
            width: 170px;
          }
        }

        .col {
          .item {
            font-size: 15px;
          }

          .address {
            text-align: left;
          }
        }
      }

    }

    .active-content-box {
      .content-item-box {

        padding: 25px 35px;

        .name, .value {
          font-size: 16px;
        }

        .flex-box {
          .name {
            font-size: 16px;
          }

          .address {
            font-size: 16px;
          }
        }

        .content-list {
          .content-item {
            .name {
              font-size: 16px;
            }

            .value {
              font-size: 16px;
            }
          }
        }

      }
    }

    .addsbt {
      text-align: center;
      width: 13%;
      padding: 5px;
      font-size: 16px;
      margin: 1.5em auto;
      color: #FFAE4E;
      border: 1px solid;

      border-radius: 8px;
    }

    .admin3-list {
      .list-header {
        font-size: 15px;
      }

      .list-item {
        padding: 1em 2.2em;
      }

      .list-header, .list-item {

        .col {
          font-size: 15px;

          &:nth-child(1) {
            width: 26%;
            margin-left: 60px;

          }

          &:nth-child(2) {
            width: 26%;
            margin-left: 10px;

          }

          &:nth-child(3) {
            width: 38%;
            margin-left: 10px;

          }
        }
      }
    }


    .mywithdraw-list {
      margin: 1em 0;

      .list-header {
        display: flex;
      }

      .list-item, .list-header {
        justify-content: flex-start;

        .col {
          text-align: left;
          font-size: 15px;
        }

      }

    }
  }


  @media screen and (max-width: 450px) {
    .panel-box {
      padding: 5px 0%;
    }

    .switchh {
      display: flex;
      font-weight: 500;
      justify-content: space-between;

      span {
        margin: 5px 0;
        font-size: 14px;
        font-family: Roboto-Medium, Roboto;
        font-weight: 500;
        color: #8A8080;
        line-height: 21px;
      }
    }

    .ant-form {
      position: relative;

      .down-icon {
        width: 40px;
        height: 40px;
        position: absolute;
        top:20%;
        left: 45%;
      }

    }

    .withdraw-header {
      display: flex;
      justify-content: space-between;
      font-size: 16px;

      .add-coin {
        font-size: 14px;
        font-family: Roboto-Bold, Roboto;
        font-weight: bold;
        color: rgb(51, 51, 51);
      }


    }


    .header-nav {
      padding: 5px 0%;
      display: flex;
      width: 100%;

      .superdao-nav-list {
        height: 45px;
        width: 100%;

        .nav-item {
          width: 31%;
          font-size: 14px;

        }
      }
    }


    .panel-container {
      /* width: 100%; */

      .info {
        font-size: 18px;
        font-family: Roboto-Medium, Roboto;
        font-weight: 500;
        color: #AC8989;
        line-height: 34px;
      }
    }

    .part1 {
      .panel-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .status-info {
        .info-item {
          font-size: 14px;
        }

      }

      .status-header {
        width: 100%;
        margin: 0.5em 0;

        .signUp-btn {
          height: 36px;
          font-size: 16px;
        }
      }

      .withdraw-info {
        .info-item {
          .name {
            font-size: 14px;
          }

          .value {
            font-size: 14px;
          }
        }

        .flex-box {
          .info-item {
            .name {
              font-size: 14px;
            }

            .value {
              font-size: 14px;
            }
          }
        }
      }


      .donation-box {
        width: 100%;
        margin: 20px auto;
        border-radius: 20px;
        border: 1px solid rgba(255, 255, 255, 0.1);
        padding: 20px;

        .title {
          font-size: 16px;
          font-family: Roboto-Bold, Roboto;
          font-weight: bold;
          padding-bottom: 10px;

        }

        .withdraw-part {
          margin-top: 0.5em;

          .ant-form-item-control-input {
            background: none;
          }

          .balance-box {
            strong {
              font-size: 14px;
            }

            p {
              width: 61%;
              text-align: left;
              font-size: 14px;
              color: white;
              font-weight: bold;
            }

            .name {

              font-size: 14px;
              font-weight: bold;
              color: #796B6B;
              line-height: 30px;
            }

            .value {
              font-size: 14px;
              font-weight: bold;
              color: #796B6B;
              display: flex;
              line-height: 30px;
              margin-left: 10px;
            }
          }

          .input-box {
            position: relative;
            display: flex;
            align-items: center;

            .right-tip {
              display: flex;
              align-items: center;

              font-size: 14px;
              font-family: Roboto-Black, Roboto;
              font-weight: 900;

              .coin-icon {
                width: 28px;
                height: 28px;
                margin-right: 6px;
              }
            }

            .exchangeAmount {
              height: 50px;
              font-size: 18px;
              padding: 0 10px;
            }

            .ant-input-number, .ant-select {
              font-size: 14px;
              font-family: Roboto-Black, Roboto;
              font-weight: 900;
              height: 50px;
              width: 100% !important;

              .ant-input-number-input-wrap, .ant-select-selector, input {
                height: 100%;
                width: 100% !important;
                font-family: Roboto-Black, Roboto;
                display: flex;
                align-items: center;

                font-weight: 900;

                &:focus-visible {
                  outline: none;
                }
              }

              .ant-select-clear {
                margin-right: 66px;
              }
            }

            .max-btn {
              width: 60px;
              position: absolute;
              right: 10px;
              top: 10px;
            }

          }

        }

        .tip {
          margin-top: 2em;
          font-size: 14px;
          font-family: Roboto-SemiBold, Roboto;
          font-weight: 600;
          color: #8A8080;
        }
      }


      .ant-btn-primary {
        margin-top: 1em;
        width: 100%;
        height: 36px;
        font-size: 16px;
        font-family: Helvetica-Bold, Helvetica;
        font-weight: bold;
      }


    }

    .part2 {
      .list-top-part {

        .superdao-nav-list {
          height: 45px;
          width: 43%;

          .nav-item {
            width: 42%;
            font-size: 16px;
          }
        }
      }

    }


    .team-part {

      .in-line {
        display: flex;
        justify-content: space-between;

        .info-item {
          margin-right: 5px;

          strong {
            font-size: 13px;
            margin-right: 5px;
          }

        }
      }

      .filter-box {
        display: block;
        margin-bottom: 1em;

        .search-box {
          margin: 1em 0;
          border-radius: 30px;
        }

        .ant-select {
          width: 100%;
        }
      }

      .flex-title {
        .right {
          display: block;
          margin: 1em 0;

          .reward-item {
            border-radius: 10px;
            width: 100%;
            padding: 1em 1.5em;
            margin: 0.5em 0;

            p {
              font-size: 16px;
              margin-bottom: 5px;
            }

            span {
              font-size: 18px;
            }

            img {
              width: 25px;
              height: 25px;
            }
          }
        }
      }
    }

    .info-item {
      margin-right: 5px;
      font-size: 13px;

      strong {
        margin-right: 5px;
      }
    }


    .team-list {
      overflow-x: scroll;

      .listheadert {
        width: 760px;
      }

      .list-header {
        padding: 20px 1.5em;
        font-size: 15px;
      }

      .list-item {
        padding: 10px 1.6em;
      }

      .list-header, .list-item {
        .col {
          font-size: 15px;

          &:nth-child(1) {
            margin-left: 5px;
            width: 120px;
          }

          &:nth-child(2) {
            margin-left: 5px;
            width: 120px;
          }

          &:nth-child(3) {
            margin-left: 5px;
            width: 120px;
          }

          &:nth-child(4) {
            margin-left: 5px;
            width: 140px;
          }

          &:nth-child(5) {
            margin-left: 5px;
            width: 150px;
          }

          /* &:nth-child(6) {
              margin-left: 5px;
              width:105px;
          } */
        }
      }

    }


    .withdraw-list {
      overflow-x: scroll;

      .listheadert {
        width: 2000px;
      }

      .list-header {
        font-size: 14px;
        padding: 20px 1.8em;
      }

      .list-item {
        padding: 10px 1.8em;
      }

      .list-header, .list-item {
        .col {
          font-size: 14px;

          &:nth-child(5) {
            width: 130px;
            margin-left: 0px;

          }

          &:nth-child(11) {
            width: 150px;
            margin-left: 0px;

          }

          .item {
            font-size: 14px;
          }
        }
      }

    }

    .active-content-box {
      .content-item-box {
        display: block;

        .name, .value {

          font-size: 14px;
        }

        padding: 1em;

        .flex-box {
          width: 29%;

          .name {
            font-size: 14px;
          }

          .address {
            font-size: 14px;
          }
        }

        .content-list {
          width: 100%;

          .content-item {
            .name {
              font-size: 14px;
            }

            .value {
              font-size: 14px;
            }
          }
        }

      }
    }


    .addsbt {
      text-align: center;
      width: 40%;
      padding: 5px;
      font-size: 14px;
      margin: 1.5em auto;
      color: #FFAE4E;
      border: 1px solid;

      border-radius: 8px;
    }

    .admin3-list {
      .list-header {
        font-size: 14px;
        padding: 20px 1em;
      }

      .list-item {
        padding: 5px 1em;
      }

      .list-header, .list-item {
        .col {
          font-size: 14px;

          &:nth-child(1) {
            width: 26%;
            margin-left: 5px;

          }

          &:nth-child(2) {
            width: 26%;
            margin-left: 5px;

          }

          &:nth-child(3) {
            width: 36%;
            margin-left: 5px;

          }
        }
      }
    }


    .mywithdraw-list {
      margin: 1em 0;
      overflow-x: scroll;

      .listheadert {
        width: 920px;
      }

      .list-header {
        display: flex;
      }

      .list-item {
        padding: 10px 2em;
      }

      .list-item, .list-header {
        justify-content: flex-start;

        .col {
          text-align: left;
          font-size: 14px;

          &:nth-child(1) {
            width: 120px;
            margin-left: 5px;
          }

          &:nth-child(2) {
            width: 140px;
            margin-left: 5px;
          }

          &:nth-child(3) {
            width: 140px;
            margin-left: 5px;
          }

          &:nth-child(4) {
            width: 140px;
            margin-left: 5px;
          }

          &:nth-child(5) {
            width: 140px;
            margin-left: 5px;
          }

          &:nth-child(6) {
            width: 170px;
            margin-left: 5px;
          }


        }
      }


    }

  }
`


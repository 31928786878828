import React, {useEffect, useRef, useState} from 'react';
import {useConnect} from "../../../api/contracts";
import * as XLSX from 'xlsx';
import {
    Table,
    Button,
    message,
    Form,
    Input, Select,
    Switch, Pagination, Modal, Empty, Space
} from 'antd';
import {getContractByName, getContractByContract} from "../../../api/connectContract";
import {dealMethod, dealPayMethod, viewMethod} from "../../../utils/contractUtil"
import develop from "../../../env";
import {useNavigate} from "react-router-dom";
import judgeStatus from "../../../utils/judgeStatus";
import OGPoolAdminStyle from "./OGPoolAdminStyle";
import AddAddressRate from "./AddAddressRate.js";
import {showNum} from "../../../utils/bigNumberUtil";
import del from "../../../imgs/sc.png";
import {
    getAllRegisters,
    getDonateRecord,
    getAllRegistersRecord,
    getThreeDonateRecord
} from "../../../graph/purChaseGQL";
import BigNumber from "bignumber.js";
import addressMap from "../../../api/addressMap";
import {SBDDecimals, ETHDecimals, USDTDecimals, NFTMap, SBDCoinDecimals, MaxUint256} from "../../../config/constants";
import {formatAddress} from "../../../utils/publicJs";
import sc from "../../../imgs/sc.png"
import {dealTime} from "../../../utils/timeUtil";
import TeamIncome from "./components/TeamIncome";
import USDTIcon from "../../../imgs/usdt.png";
import {zeroAddress} from "viem";
import {parse} from "url";
import {useTranslation} from "react-i18next";
const SRTDecimal = 18
const OgPoolAdmin = (props) => {

    const [form2] = Form.useForm();
    let {state, dispatch} = useConnect();
    const [initTeamRate, setInitTeamRate] = useState()
    const [initRate, setInitRate] = useState()
    const [initFLMRate, setInitFLMRate] = useState()
    const [searchData, setSearchData] = useState("")
    const [activeNav, setActiveNav] = useState(1)
    const [recordNav, setRecordNav] = useState(1)
    const [form] = Form.useForm();
    const [secondAdmins, setSecondAdmin] = useState([])
    const [assignAmin, setAssignAdmin] = useState([])
    const [total, setTotal] = useState(1)
    const [allRecords, setAllRecords] = useState([])
    const [totalRate, setRate] = useState(1)
    const [allSignRecord, setAllSignRecord] = useState([])
    const [NFTMapArr, setNFTMAP] = useState([NFTMap])
    const [poolSrtBalance, setPoolSRTBalance] = useState(0)


    const [curPage, setCurPage] = useState(1)
    const [pageCount, setPageCount] = useState(20)
    const [exchangeRate, setExchangeRate] = useState(0.001)
    const [SBDBalance, setSBDBalance] = useState(0)
    const [lockSbdBalance, setLockSBDBalance] = useState(0)
    const [totalDonate, setTotalDonate] = useState(0)
    const [salePriceV, setSalePriceV] = useState(0)
    const [maxThreeAdmin, setMaxThreeAdmin] = useState(0)
    const [maxFourAdmin, setMaxFourAdmin] = useState(0)
    const [maxTwoAdmin, setMaxTwoAdmin] = useState(0)
    const [maxFiveAdmin, setMaxFiveAdmin] = useState(0)

    const [maxSixAdmin, setMaxSixAdmin] = useState(0)
    const [maxSevenAdmin, setMaxSevenAdmin] = useState(0)
    const [maxEightAdmin, setMaxEightAdmin] = useState(0)
    const [maxNineAdmin, setMaxNineAdmin] = useState(0)

    const [activateAccountUsedAmount, setActivateAccountUsedAmount] = useState(0)

    const [withdrawAmount, setWithdrawAmount] = useState()

    const [withdrawCoinAddr, setWithdrawCoinAddr] = useState()
    const [sumArr, setSumArr] = useState([])
    const [inputValue, setInputValue] = useState(0)
    const [showAddRate, setShowAddRate] = useState(false)
    const [receiveRemainingTeamRewards, setReceiveRemainingTeamRewards] = useState()
    const [whiteList, setAllWhiteList] = useState([])
    const [status1, setStatus1] = useState()
    const [status2, setStatus2] = useState()
    const [fdtAddr, setSBDAddressValue] = useState()
    const [flmAddr, setFLMAddressValue] = useState()
    const [svt, setsvt] = useState()
    const [supBalance, setSupBalance] = useState()
    const [bigBalance, setBigBalance] = useState()
    const [smallBalance, setSmallBalance] = useState()
    const [SPTAddr, setSPTAddr] = useState("")

    const [isPause, setIsPause] = useState()
    const [ownerAddress, setOwnerAddress] = useState("")
    const [curAddr, setCurAddr] = useState("")
    const [curDelLev2Addr, setCurDelLev2Addr] = useState("")
    const [isShowDelLev2, setShowDelLev2] = useState(false)
    const [isShowAddLev2, setShowAddLev2] = useState(false)
    const [curId, setCurId] = useState("")
    const [coinInfo, setCoinInfo] = useState({})

    const [totalInviteRate, setTotalInviteRate] = useState(0)
    const [inviteRateArr, setInvArr] = useState([])


    const [totalTeamRate, setTotalTeamRate] = useState(0)
    const [teamRateArr, setTeamRateArr] = useState([])
    const [inviteSrtRatio, setInviteSrtRatio] = useState([])

    const [sptRatio, setSptRatio] = useState([])

    const [totalOtherRate, setTotalOtherRate] = useState(0)

    const [nftArr, setNftNumbersArr] = useState([])
    const [amountItemArr, setAmountItemsArr] = useState([])

    const [addrItemArr, setAddressItemsArr] = useState([])

    const [smallNode, setSmallNode] = useState()
    const [bigNode, setBigNode] = useState()
    const [supNode, setSupNode] = useState()
    const [timeBlock, setTimeBlock] = useState()

    const [chooseAmount, setChooseAmount] = useState()
    const [chooseAddress, setChooseAddress] = useState()
    const history = useNavigate();
    const { t } = useTranslation();

    const handleChangeAddr = (value) => {
        setChooseAddress(value)
    };
    const handleChangeAmount = (value) => {
        setChooseAmount(value)

    };
    const items = [
        {
            label: <div className={"nav-item " + (activeNav == 1 ? "active" : "")} onClick={() => {
                setActiveNav(1)
            }}>
                Important Operation
            </div>,
            value: 'Important Operation',
        },
        {
            label: <div className={"nav-item " + (activeNav == 2 ? "active" : "")} onClick={() => {
                setActiveNav(2)
            }}>
                SuperBit Parameters
            </div>,
            value: 'SuperBit Parameters',
        },
        {
            label: <div className={"nav-item " + (activeNav == 3 ? "active" : "")} onClick={() => {
                setActiveNav(3)
            }}>
                Funding Pool
            </div>,
            value: 'Funding Pool',
        },
        {
            label: <div className={"nav-item " + (activeNav == 5 ? "active" : "")} onClick={() => {
                setActiveNav(5)
            }}>
                Rewards And Allocation
            </div>,
            value: 'Rewards And Allocation',
        },

        {
            label: <div className={"nav-item " + (activeNav == 4 ? "active" : "")} onClick={() => {
                setActiveNav(4)
            }}>
                Team Rewards
            </div>,
            value: 'Team Rewards',
        }
    ];
    const onChange = (checked) => {
        console.log(`switch to ${checked}`);
        if (isPause) {
            handleUnpause()

        } else {
            handlePause()
        }
    };
    const onChangeAdmin = (checked) => {
        console.log(`switch to ${checked}`);
        if (status1) {
            setPidStatusForUser()
        } else {
            setPidStatusForAdmin()
        }
    };
    const onChangeUser = (checked) => {
        console.log(`switch to ${checked}`);
        if (status2) {
            setPidStatusForAdmin()

        } else {
            setPidStatusForUser()
        }
    };
    const handleViewMethod = async (name, params) => {
        let contractTemp = await getContractByName("spbd", state.api,)
        if (!contractTemp) {
            message.warn("Please connect", 5)
        }
        return await viewMethod(contractTemp, state.account, name, params)
    }
    const handleCoinViewMethod = async (name, coinName, params) => {
        let contractTemp = await getContractByName(coinName, state.api,)
        if (!contractTemp) {
            message.warn("Please connect", 5)
        }
        return await viewMethod(contractTemp, state.account, name, params)
    }
    const handleDACCOUNTViewMethod = async (name, account, params) => {
        let contractTemp = await getContractByName("spbd", state.api,)
        if (!contractTemp) {
            message.warn("Please connect", 5)
        }
        return await viewMethod(contractTemp, account, name, params)
    }
    const handleDealMethod = async (name, params) => {
        let contractTemp = await getContractByName("spbd", state.api,)
        if (!contractTemp) {
            message.warn("Please connect", 5)
        }
        await dealMethod(contractTemp, state.account, name, params)
    }


    const xlsxExport = async (addr) => {
        let tempArr = [], isHeader = true
        if (isHeader) {
            let tempItemArr = []
            allRecords.forEach(item => {
                Object.getOwnPropertyNames(item).forEach(function (key) {
                    tempItemArr.push(key)
                });
            })
            tempArr.push(tempItemArr)
        }
        isHeader = false

        allRecords.forEach(item => {
            let tempItemArr = []

            Object.getOwnPropertyNames(item).forEach(function (key) {
                tempItemArr.push(item[key])
            });
            console.log(tempArr)

            tempArr.push(tempItemArr)
        })
        const data = tempArr

        const ws = XLSX.utils.aoa_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        // Save the Excel file
        XLSX.writeFile(wb, 'exported_data.xlsx');
    }

    const getBalanceOfSRT =  async () => {
        let contractTemp = await getContractByName("SRT", state.api,)
        const res =  await viewMethod(contractTemp, state.account, "balanceOf", [addressMap["spbd"].address])
        setPoolSRTBalance(BigNumber(res).div(10**SRTDecimal).toString())
    }
    const getBalanceOfSBD = async () => {
        let balance = await handleViewMethod("getBalanceOfSbd", [])
        balance = parseInt(parseInt(balance) / 10 ** 18)
        if (balance > 0) {
            setSBDBalance(balance)
        }
    }
    const getSPTAddr= async () => {
        let res = await handleViewMethod("spt", [])
        setSPTAddr(res)
    }
    const getOwner = async () => {
        let res = await handleViewMethod("owner", [])
        setOwnerAddress(res)
        getSecondAdmins(res)
    }
    const getTotalDonate = async () => {
        let res = await handleViewMethod("totalDonate", [])
        setTotalDonate(BigNumber(res).dividedBy(10 ** SBDDecimals).toString())
    }
    const getSalePrice = async () => {
        let res = await handleViewMethod("salePrice", [])
        setSalePriceV(BigNumber(res).dividedBy(1000).toString())
    }
    const getMaxThree = async () => {
        let res = await handleViewMethod("maxThree", [])
        setMaxThreeAdmin(res)
    }
    const getMaxThreeAdmin = async () => {
        let res = await handleViewMethod("maxThree", [])
        setMaxThreeAdmin(res)
    }
    const getMaxTwoAdmin = async () => {
        let res = await handleViewMethod("maxTwo", [])
        setMaxTwoAdmin(res)
    }
    const getMaxFourAdmin = async () => {
        let res = await handleViewMethod("maxFour", [])
        setMaxFourAdmin(res)
    }
    const getMaxFiveAdmin = async () => {
        let res = await handleViewMethod("maxFive", [])
        setMaxFiveAdmin(res)
    }

    const getMaxSixAdmin = async () => {
        let res = await handleViewMethod("maxSix", [])
        setMaxSixAdmin(res)
    }
    const getMaxSevenAdmin = async () => {
        let res = await handleViewMethod("maxSeven", [])
        setMaxSevenAdmin(res)
    }
    const getMaxEightAdmin = async () => {
        let res = await handleViewMethod("maxEight", [])
        setMaxEightAdmin(res)
    }
    const getMaxNineAdmin = async () => {
        let res = await handleViewMethod("maxNine", [])
        setMaxNineAdmin(res)
    }
    const getPause = async () => {
        let res = await handleViewMethod("paused", [])
        setIsPause(res)
    }
    const getactivateAccountUsedAmount = async () => {
        let res = await handleViewMethod("supAccountTotalUsedAmount", [])
        setActivateAccountUsedAmount(res)
    }
    const getpidStatusForAdmin = async () => {
        let res = await handleViewMethod("pidStatusForAdmin", [])
        setStatus1(res)
    }
    const getpidStatusForUser = async () => {
        let res = await handleViewMethod("pidStatusForUser", [])
        setStatus2(res)
    }


    //Team Addr
    const getReceiveRemainingTeamRewards = async () => {
        let res = await handleViewMethod("receiveRemainingTeamRewards", [])
        setReceiveRemainingTeamRewards(res)
    }


    const getSBDAddress = async () => {
        let res = await handleViewMethod("sbd", [])
        setSBDAddressValue(res)
    }
    const getTimeInterval= async () => {
        let res = await handleViewMethod("timeInterval", [])
        setTimeBlock(res)
    }
    const getBigNode = async () => {
        let res = await handleViewMethod("bigNode", [])
        setBigNode(res)
    }
    const getSmallNode = async () => {
        let res = await handleViewMethod("smallNode", [])
        setSmallNode(res)
    }

    const getSupNode = async () => {
        let res = await handleViewMethod("supNode", [])
        setSupNode(res)
    }



    const getSecondAdmins = async (ownerAddress) => {
        const length = await handleViewMethod("getAdminsLevelOneLength", [ownerAddress])
        let tempArr = []
        for (let i = 0; i < length; i++) {
            const res = await handleViewMethod("setAdminLevelTwo_", [ownerAddress, i])
            tempArr.push(res)
        }
        setSecondAdmin(tempArr)
        return tempArr
    }
    const getLockSBDBalance = async () => {
        try{
            let contractTemp = await getContractByName("lock",  state.api)
            let balance = await viewMethod(contractTemp, state.account, "getContractSbdBalance", [])
            setLockSBDBalance(balance / 10 ** SBDDecimals)
        }catch (e) {
            console.error(e)
        }
    }
    const getRate = async () => {
        let res = await handleViewMethod("getRate", [])
        setRate(res)
    }
    const getPrice = async () => {
        let res = await handleViewMethod("salePrice", [])
        setExchangeRate(res / 1000)
    }
    const getInviteRate = async () => {
        let initRate = await handleViewMethod("initRate", [])
        setInitRate(initRate)
        if (!initRate) {
            return
        }
        let tempArr = [], totalRate = 0
        for (let i = 0; i < 2; i++) {
            const inviteRate = await handleViewMethod("inviteRate", [i])
            tempArr.push({index: i + 1, inviteRate: inviteRate.toString()})
            totalRate = BigNumber(totalRate).plus(inviteRate)
        }
        setTotalInviteRate(totalRate.toString())
        setInvArr(tempArr)
    }
    //  inviteSrtRatio
    const getInviteSrtRatio = async () => {

        let tempArr = []
        for (let i = 0; i < 2; i++) {
            const inviteRate = await handleViewMethod("inviteSrtRatio", [i])
            tempArr.push(inviteRate.toString())
        }
        setInviteSrtRatio(tempArr)
    }
    // spt ratio
    const getSPTRatio = async () => {
        try {
            let tempArr = []
            for (let i = 0; i < 5; i++) {
                const res = await handleViewMethod("SPTrate", [i])
                tempArr.push(BigNumber(res).div(100).toString())
            }
            setSptRatio(tempArr)
        } catch (e) {
            console.log(e)
        }
    }
    const getTeamRate = async () => {

        let initTeamRate = await handleViewMethod("initTeamRate", [])
        setInitTeamRate(initTeamRate)
        if (!initTeamRate) {
            return
        }
        let tempArr = [], totalRate = 0
        for (let i = 0; i < 3; i++) {
            const inviteRate = await handleViewMethod("teamRate", [i])
            tempArr.push({index: i + 1, inviteRate: inviteRate.toString()})
            totalRate = BigNumber(totalRate).plus(inviteRate)
        }
        setTotalTeamRate(totalRate.toString())
        setTeamRateArr(tempArr)
    }

    const getAssignAndRates = async () => {
        const length = await handleViewMethod("getAssignAndRateslength", [])
        let resArr = [], totalRate = 0
        for (let i = 0; i < length; i++) {
            const res = await handleViewMethod("assignAndRates", [i])
            resArr.push(res)
            totalRate = BigNumber(totalRate).plus(res.rate)
        }
        setTotalOtherRate(totalRate.toString())
        setAssignAdmin(resArr)
    }

    const getValidNumbers = async () => {
        let tempArr = [], addrArr = [], numArr = []

        setNFTMAP({
            [smallNode]: "smallNode",
            [supNode]: "supNode",
            [bigNode]: "bigNode",
            "0x0000000000000000000000000000000000000000": "None"
        })
        const NFTMap = {
            [smallNode]: "smallNode",
            [supNode]: "supNode",
            [bigNode]: "bigNode",
            "0x0000000000000000000000000000000000000000": "None"
        }
        for (let i = 0; i < 6; i++) {
            let num = await handleViewMethod("validNumbers", [i])
            let addr = await handleViewMethod("nftType", [num / 10 ** 18])
            addrArr.push({
                label: NFTMap[addr],
                value: addr,
            })
            numArr.push({
                label: num / 10 ** 18,
                value: num / 10 ** 18,
            })
            tempArr.push({
                num, addr: NFTMap[addr]
            })
        }
        setAmountItemsArr(numArr)

        setAddressItemsArr([{
            label: "None",
            value: zeroAddress
        }, {
            label: "SmallNode",
            value: smallNode
        }, {
            label: "BigNode",
            value: bigNode
        }, {
            label: "SupNode",
            value: supNode
        }])

        setNftNumbersArr(tempArr)

    }


    const addInviteRate = async () => {
        const tempArr = [form2.getFieldValue().inviteRate0 * 100, form2.getFieldValue().inviteRate1 * 100,

        ]
        await handleDealMethod("addInviteRate", [tempArr])
        getInviteRate()
    }
    const setTeamRate = async () => {
        await handleDealMethod("setTeamRate", [(3 - form.getFieldValue().level), form.getFieldValue().rate * 100])
        getTeamRate()
    }


    const setPidStatusForAdmin = async () => {
        await handleDealMethod("setPidStatusForAdmin", [])
        getpidStatusForAdmin()
    }
    const handleSetTimeInterval= async () => {
        await handleDealMethod("setTimeInterval", [form.getFieldValue().timeBlock])
        getTimeInterval()
    }
    const handleSetBigNode = async () => {
        await handleDealMethod("setBigNode", [form.getFieldValue().BigNode])
        getBigNode()
    }
    const handleSetSmallNode = async () => {
        await handleDealMethod("setSmallNode", [form.getFieldValue().SmallNode])
        getSmallNode()
    }
    const Row = (item, index) => {
        return <div className="list-item row1" key={index}>
            <div className="col no">
                {allRecords.length - index}
            </div>
            <div className="col address">
                {item.addr && (
                    <a href={develop.ethScan + "address/" + item.addr} target="_blank">
                        {formatAddress(item.addr)}
                    </a>
                )}
            </div>

            <div className="col">
                <img width={18} height={18} style={{margin: "-3px 3px 0 0"}} src={USDTIcon}/>

                {showNum(BigNumber(item.usdtAmount / 10 ** USDTDecimals).toFixed(2))}
            </div>

            <div className="col">
                {showNum(item.usdtAmount / (BigNumber(item.sbdAmount).plus(item.lockSbd)), 4)}
            </div>
            <div className="col receive">
                {showNum(BigNumber(item.sbdAmount) / 10 ** SBDDecimals, 0)}
            </div>
            <div className="col lock">
                {parseInt(showNum(BigNumber(item.lockSbd).dividedBy(10 ** SBDDecimals), 0))}
            </div>
            <div className="col lock">
                {showNum(item.lockSbd / 10 ** SBDDecimals, 0)}
            </div>
            <div className='col receive-nft'>

                <a target="_blank"
                   href={develop.ethScan + '/address/' + item.receiveNft}>{NFTMapArr[item.receiveNft.toLowerCase()]}</a>
            </div>

            <div className="col">
                {dealTime(item.blockTimestamp)}
            </div>

        </div>
    }
    const handleSetNftType = async () => {
        await handleDealMethod("setNftType", [chooseAmount, chooseAddress])
        getValidNumbers()
    }
    const handleSetSupNode = async () => {
        await handleDealMethod("setSupNode", [form.getFieldValue().SupNode])
        getSupNode()
    }


    const setPidStatusForUser = async () => {
        await handleDealMethod("setPidStatusForUser", [])
        getpidStatusForUser()
    }


    const setRateAndAddress = async () => {
        await handleDealMethod("setAssignAddressAndRatio", [parseInt(curId) - 1, curAddr, form2.getFieldValue().assignRate * 100])
        getAssignAndRates()
    }
    const setSptAddress = async () => {
        await handleDealMethod("setSptAddress", [form.getFieldValue().SptAddress])
        getSPTAddr()
    }
    const transferOwnership = async () => {
        await handleDealMethod("transferOwnership", [form.getFieldValue().address])
        getOwner()
    }

    const handlePause = async () => {
        await handleDealMethod("pause", [])
        getPause()
    }
    const handleUnpause = async () => {
        await handleDealMethod("unpause", [])
        getPause()
    }
    const handleAddSptRatio = async () => {
        let paramsArr = []
        for (let i = 0; i < 5; i++) {
            paramsArr.push(form.getFieldValue()["sptRatio" + i] * 100,)
        }
        await handleDealMethod("addSptRatio", [paramsArr])
        getSPTRatio()
    }
    const handleSetSptRatio = async () => {
        await handleDealMethod("setSptRatio", [(parseInt(form.getFieldValue().sptRatioLevel)-1),BigNumber(form.getFieldValue().sptRatioRatio).multipliedBy(100).toFixed(0)])
        getSPTRatio()
    }
    const addInviteSrtRatio = async () => {
        if (!BigNumber(form.getFieldValue()["srtRate0"]).plus(form.getFieldValue()["srtRate1"]).eq(100)) {
            message.error("should be 100")
            return
        }


        await handleDealMethod("addInviteSrtRatio", [[form.getFieldValue()["srtRate0"] * 100, form.getFieldValue()["srtRate1"] * 100]])
        getInviteSrtRatio()
    }
    const addTeamRate = async () => {
        let paramsArr = []
        for (let i = 0; i < 3; i++) {
            paramsArr.push(form.getFieldValue()["teamRate" + (2 - i)] * 100,)
        }
        await handleDealMethod("addTeamRate", [paramsArr])
        getTeamRate()
    }

    const setInviteRate = async () => {
        await handleDealMethod("setInviteRate", [form.getFieldValue().inviteRateID - 1, form.getFieldValue().inviteRate * 100])
        getInviteRate()
    }
    const setAdmins = async () => {
        await handleDealMethod("setAdminLevelTwo", [[form.getFieldValue().adminaddress]])
        getSecondAdmins(ownerAddress)
    }
    const removeAdmin = async () => {
        await handleDealMethod("removeAdminLevelTwo", [form.getFieldValue().adminaddress])
        getSecondAdmins(ownerAddress)
    }
    const deleteLevel2Admin = async () => {
        await handleDealMethod("removeAdminLevelTwo", [curDelLev2Addr])
        getSecondAdmins(ownerAddress)
    }

    const handlesetActivateAccountUsedAmount = async () => {
        await handleDealMethod("setSupAccountUsedAmount", [(form2.getFieldValue().ActivateAccountUsedAmount)])
        getactivateAccountUsedAmount()
    }
    const setAdminLevelThreeMax = async () => {
        await handleDealMethod("setAdminForThree", [(form2.getFieldValue().maxThree)])
        getMaxThreeAdmin()
    }
    const setAdminLevelTwoMax = async () => {
        await handleDealMethod("setAdminForTwo", [(form2.getFieldValue().maxTwo)])
        getMaxTwoAdmin()
    }



    const setSalePrice = async () => {
        if ((form2.getFieldValue().exchangeRate) * 1000 < 1) {
            message.warn("Please input 0 to 0.001")
            return
        }
        await handleDealMethod("setSalePrice", [(form2.getFieldValue().exchangeRate) * 1000])
        getSalePrice()
    }
    const getRecord = async () => {
        try {
            let res = await getDonateRecord()
            let signres = await getAllRegistersRecord()
            if (res.data) {
                let arr = []
                res.data.allRecords.forEach(item => {
                    if (item.time) {
                        item.time = new Date(item.time * 1000).toUTCString()
                    }
                    if (state.account && item.addr.toString() == state.account.toLowerCase()) {
                        arr.push(item)
                    }
                })

                if (res.data.allRecords && res.data.allRecords.length > 0) {
                    setAllRecords(res.data.allRecords)
                    setTotal(res.data.allRecords.length)
                }

            }
            if (signres.data) {
                console.log(signres)
                setAllSignRecord(signres.data.allRegisters)
            }
        } catch (e) {
            console.log(e)
        }
    }
    const getData = async () => {
        let judgeRes = await judgeStatus(state)
        if (!judgeRes) {
            return
        }
        getPrice()
        getRate()
        getTotalDonate()
        getBalanceOfSBD()
        getBalanceOfSRT()
        getLockSBDBalance()
        getOwner()
        getSPTAddr()
        getSalePrice()
        getMaxThreeAdmin()
        getMaxTwoAdmin()


        getPause()
        getSBDAddress()
        getRecord()
        getInviteRate()
        getTeamRate()
        getInviteSrtRatio()
        getSPTRatio()
        getAssignAndRates()
        getactivateAccountUsedAmount()
        getTimeInterval()
        await getSupNode()
        await getSmallNode()
        await getBigNode()
    }
    useEffect(async () => {
        let judgeRes = await judgeStatus(state)
        if (!judgeRes) {
            return
        }
        if (smallNode && bigNode && supNode) {
            setNFTMAP({
                [smallNode]: "smallNode",
                [supNode]: "supNode",
                [bigNode]: "bigNode",
                "0x0000000000000000000000000000000000000000": "None"
            })

            const NFTMap = {
                [smallNode]: "smallNode",
                [supNode]: "supNode",
                [bigNode]: "bigNode",
                "0x0000000000000000000000000000000000000000": "None"
            }
            getValidNumbers(NFTMap)

        }
    }, [supNode, smallNode, bigNode])
    const chooseRow = (item, id) => {
        setCurAddr(item.assign)
        setCurId(id + 1)
    }
    const onChangePage = async (page) => {
        getData(page)
        await setCurPage(page)
    }
    const handleShowSizeChange = async (page, count) => {
        setPageCount(count)
    }
    const delARRow = async (item) => {
        await handleDealMethod("removeAssiginAddressAndRatio", [[item.assign]])
        getAssignAndRates()
    }
    const getCoinBalance = async () => {
        let contractTemp = await getContractByContract("supnodeV1", supNode, state.api,)
        let supbalance = await viewMethod(contractTemp, supNode, "initAmount", [])
        setSupBalance(supbalance)
        let contractTemp2 = await getContractByContract("bignodeV1", bigNode, state.api,)
        let bigbalance = await viewMethod(contractTemp2, bigNode, "initAmount", [])
        setBigBalance(bigbalance)

        let contractTemp3 = await getContractByContract("smallnodeV1", smallNode, state.api,)
        let smallbalance = await viewMethod(contractTemp3, smallNode, "initAmount", [])
        setSmallBalance(smallbalance)

    }
    const getCoinInfo = async (coinAddr) => {
        if (!state.api.utils.isAddress(coinAddr)) {
            return
        }
        let contractTemp = await getContractByContract("erc20", coinAddr, state.api,)
        const decimal = await viewMethod(contractTemp, state.account, "decimals", [])
        let balance = await viewMethod(contractTemp, addressMap["spbd"].address, "balanceOf", [addressMap["spbd"].address])
        let name = await viewMethod(contractTemp, state.account, "name", [])
        let symbol = await viewMethod(contractTemp, state.account, "symbol", [])
        balance = balance / (10 ** parseInt(decimal))
        setCoinInfo({
            balance,
            symbol,
            decimal,
            name,
            address: coinAddr
        })

    }
    const handleApprove = async () => {
        const addr = form.getFieldValue().depositCoinAddr
        let contractTemp = await getContractByContract("erc20", addr, state.api,)
        await dealMethod(contractTemp, state.account, "approve", [addressMap["spbd"].address, MaxUint256])
    }
    const handleDeposit = async () => {
        const addr = form.getFieldValue().depositCoinAddr

        const amount = BigNumber(form.getFieldValue().depositAmount).multipliedBy(10 ** coinInfo.decimal).toFixed(0).toString()
        await handleDealMethod("depositF", [addr, amount])
        getBalanceOfSBD()
        getCoinInfo(form.getFieldValue().depositCoinAddr)
    }
    const withdrawToken = async (item) => {
        const amount = BigNumber(withdrawAmount).multipliedBy(10 ** coinInfo.decimal).toFixed(0).toString()
        await handleDealMethod("Claim", [withdrawCoinAddr, amount])
        getBalanceOfSBD()
        getCoinInfo(withdrawCoinAddr)
    }
    useEffect(() => {
        getData()
    }, [state.account]);

    const nftAmountAddr = [
        {
            title: 'Amount',
            dataIndex: 'num',
            key: 'num',
            render: (amount) => <span>{amount / 10 ** 18}</span>,
        },

        {
            title: 'Address',
            dataIndex: 'addr',
            key: 'addr',
        },
    ]
    const inviteColumns = [
        {
            title: 'Level',
            dataIndex: 'index',
            key: 'index',
        },

        {
            title: 'InviteRate',
            dataIndex: 'inviteRate',
            key: 'inviteRate',
            render: (text) => <span>{text / 100}%</span>,
        },
    ]
    const teamColumns = [
        {
            title: 'Level',
            dataIndex: 'index',
            key: 'index',
            render: (index) => <span>{4 - index}</span>,
        },
        {
            title: 'InviteRate',
            dataIndex: 'inviteRate',
            key: 'inviteRate',
            render: (text) => <span>{text / 100}%</span>,
        },
    ]
    const flmColumns = [
        {
            title: 'Category',
            dataIndex: 'name',
            key: 'name',

        },

        {
            title: 'Percentage',
            dataIndex: 'rate',
            key: 'rate',
            render: (rate) => <span>{rate / 100}%</span>,
        },
    ]
    const FLMColumns = [
        {
            title: 'Level',
            dataIndex: 'index',
            key: 'index',
        },

        {
            title: 'InviteRate',
            dataIndex: 'inviteRate',
            key: 'inviteRate',
            render: (text) => <span>{text}%</span>,
        },
    ]
    return (
        <OGPoolAdminStyle>
            <Modal className="model-dialog" title="Delete  Level 2 Admin" open={isShowDelLev2} onOk={deleteLevel2Admin}
                   onCancel={() => {
                       setShowDelLev2(false)
                   }}>
                <h3>
                    Wallet Address
                </h3>
                <div className="value">
                    {curDelLev2Addr}
                </div>


            </Modal>
            <div className="header-nav2">
                <div className="superdao-nav-list1">
                    <Space wrap>
                        <Select
                            defaultValue="Important Operation"

                            options={
                                items
                            }
                        >

                        </Select>
                    </Space>

                </div>
            </div>
            {activeNav == 1 && (
                <div className="part1">
                    <div className="panel-box">

                        <div className="panel-container">
                            <h1 className='panel-title'>
                               Contract Address( {addressMap["spbd"].address} )
                            </h1>

                            <h1 className='panel-title'>
                                Transfer Administrator
                            </h1>
                            {/* <div className="panel-title1">
                                OG Pool Address
                            </div>
                            <div className="val">
                                {addressMap["spbd"].address}
                            </div> */}

                            <Form form={form} name="control-hooks" className="form">
                                <Form.Item
                                    label="Administrator Address"
                                >
                                    <span> {ownerAddress}</span>
                                </Form.Item>
                                <Form.Item
                                    name="address"
                                    label="New Administrator"
                                    validateTrigger="onBlur"
                                    validateFirst={true}
                                >
                                    <Input/>
                                </Form.Item>
                                <div className='btn-box1'>

                                    <Button type="primary" className="go-btn" onClick={() => {
                                        transferOwnership()
                                    }}>
                                        Confirm
                                    </Button>
                                    {/*<Button type="primary" className="go-btn" onClick={() => {*/}
                                    {/*    setTEST()*/}
                                    {/*}}>*/}
                                    {/*    SET TEST*/}
                                    {/*</Button>*/}
                                </div>
                            </Form>
                        </div>
                    </div>
                    <div className="panel-box">

                        <div className="panel-container">


                            <h1 className='panel-title'>
                                setSptAddress
                            </h1>


                            <Form form={form} name="control-hooks" className="form">
                                <Form.Item
                                    label="spt Address"
                                >
                                    <span> {SPTAddr}</span>
                                </Form.Item>
                                <Form.Item
                                    name="SptAddress"
                                    label="New SptAddress"
                                    validateTrigger="onBlur"
                                    validateFirst={true}
                                >
                                    <Input/>
                                </Form.Item>
                                <div className='btn-box1'>

                                    <Button type="primary" className="go-btn" onClick={() => {
                                        setSptAddress()
                                    }}>
                                        Confirm
                                    </Button>

                                </div>
                            </Form>
                        </div>
                    </div>

                    <div className="panel-box">

                        <div className="panel-container">


                            <h1 className='panel-title'>
                                setTimeInterval({timeBlock})
                            </h1>


                            <Form form={form} name="control-hooks" className="form">

                                <Form.Item
                                    name="timeBlock"
                                    label="New timeInterval"
                                    validateTrigger="onBlur"
                                    validateFirst={true}
                                >
                                    <Input/>
                                </Form.Item>
                                <div className='btn-box1'>

                                    <Button type="primary" className="go-btn" onClick={() => {
                                        handleSetTimeInterval()
                                    }}>
                                        Confirm
                                    </Button>

                                </div>
                            </Form>
                        </div>
                    </div>
                    <div className="panel-box">
                        <div className="panel-container">
                            <h1 className="panel-title">
                                Contract Status
                            </h1>
                            <Form form={form} name="control-hooks" className="form">
                                <div className='switchh'>
                                    <p><span>Running</span><Switch checked={!isPause} onChange={onChange}/></p>
                                    <p><span>Pause</span><Switch checked={isPause} onChange={onChange}/></p>
                                </div>
                                {/*<Button type="primary" onClick={handlePause}>Pause</Button>*/}
                                {/*<Button type="primary" onClick={handleUnpause}>Unpause</Button>*/}
                            </Form>
                            <div className="info tip-box">
                                This function is related to the running status of the contract,
                                please use it with caution.
                            </div>
                        </div>

                    </div>
                    <div className="panel-box">
                        {/*<div className="panel-container">*/}
                        {/*    <div className="panel-title">*/}
                        {/*        Set Funding Token Address*/}
                        {/*    </div>*/}
                        {/*    <Form form={form} name="control-hooks" className="form">*/}
                        {/*        <Form.Item*/}
                        {/*            label="Token Address"*/}
                        {/*        >*/}
                        {/*            <span> {ownerAddress}</span>*/}
                        {/*        </Form.Item>*/}
                        {/*        <Button type="primary" className="go-btn" onClick={() => {*/}
                        {/*            transferOwnership()*/}
                        {/*        }}>*/}
                        {/*            Confirm*/}
                        {/*        </Button>*/}
                        {/*    </Form>*/}

                        {/*</div>*/}
                        {/*<div className="panel-container">*/}
                        {/*    <div className="panel-title">*/}
                        {/*        Set Nft Type*/}
                        {/*    </div>*/}
                        {/*    <div className="current">*/}
                        {/*        BigNode:{bigBalance} <br/>*/}
                        {/*        SmallNode:{smallBalance} <br/>*/}
                        {/*        SuperNode:{supBalance} <br/>*/}

                        {/*    </div>*/}
                        {/*    <Form form={form} name="control-hooks" className="form">*/}
                        {/*        <Form.Item*/}
                        {/*            name="NodeAmount"*/}
                        {/*            label="Node Amount"*/}
                        {/*            validateTrigger="onBlur"*/}
                        {/*            validateFirst={true}*/}
                        {/*        >*/}
                        {/*            <Input/>*/}
                        {/*        </Form.Item>*/}
                        {/*        <Form.Item*/}
                        {/*            name="NodeAddress"*/}
                        {/*            label="Node Address"*/}
                        {/*            validateTrigger="onBlur"*/}
                        {/*            validateFirst={true}*/}
                        {/*        >*/}
                        {/*            <Input/>*/}
                        {/*        </Form.Item>*/}
                        {/*        <Button type="primary" className="go-btn" onClick={() => {*/}
                        {/*            handleSetNftType()*/}
                        {/*        }}>*/}
                        {/*            Confirm*/}
                        {/*        </Button>*/}
                        {/*    </Form>*/}
                        {/*</div>*/}
                        <div className="panel-container">
                            <h1 className="panel-title">
                                Set NFT AMOUNT And Address
                            </h1>
                            <Table pagination={false} columns={nftAmountAddr} dataSource={nftArr}/>
                            <Form form={form} name="control-hooks" className="form">
                                <Select
                                    defaultValue=""
                                    onChange={handleChangeAmount}
                                    options={
                                        amountItemArr
                                    }
                                >

                                </Select>
                                <Select
                                    defaultValue=""
                                    onChange={handleChangeAddr}
                                    options={
                                        addrItemArr
                                    }
                                >

                                </Select>
                                <Button type="primary" className="go-btn" onClick={() => {
                                    handleSetNftType()
                                }}>
                                    Confirm
                                </Button>
                            </Form>
                        </div>
                        <div className="panel-container">
                            <div className="panel-title">
                                Set Small Node NFT({smallNode})
                            </div>

                            <Form form={form} name="control-hooks" className="form">

                                <Form.Item
                                    name="SmallNode"
                                    label="SmallNode Address"
                                    validateTrigger="onBlur"
                                    validateFirst={true}
                                >
                                    <Input/>
                                </Form.Item>
                                <Button type="primary" className="go-btn" onClick={() => {
                                    handleSetSmallNode()
                                }}>
                                    Confirm
                                </Button>
                            </Form>
                        </div>
                        <div className="panel-container">
                            <h1 className="panel-title">
                                Set Big Node NFT({bigNode})
                            </h1>
                            <Form form={form} name="control-hooks" className="form">

                                <Form.Item
                                    name="BigNode"
                                    label="BigNode address"
                                >
                                    <div className="input-box">
                                        <Input/>
                                    </div>
                                </Form.Item>
                                <Button type="primary" onClick={() => {
                                    handleSetBigNode()
                                }}>Confirm</Button>
                            </Form>
                        </div>

                        <div className="panel-container">
                            <h1 className="panel-title">
                                Set Super Node NFT({supNode})
                                {/*    {svt}*/}
                            </h1>
                            <Form form={form} name="control-hooks" className="form">

                                <Form.Item
                                    name="SupNode"
                                    label="SupNode Address"
                                >
                                    <div className="input-box">
                                        <Input/>
                                    </div>
                                </Form.Item>
                                <Button type="primary" onClick={() => {
                                    handleSetSupNode()
                                }}>Confirm</Button>
                            </Form>
                        </div>
                        {/* <div className="panel-container">
                            <div className="panel-title">
                                Set Svt {svt}
                            </div>
                            <Form form={form} name="control-hooks" className="form">
                                <Form.Item
                                    name="svt"
                                    label="Svt"
                                >
                                    <div className="input-box">
                                        <Input />
                                    </div>
                                </Form.Item>
                                <Button type="primary" onClick={handleSetsvt}>Confirm</Button>
                            </Form>
                        </div> */}
                    </div>
                </div>
            )}
            {activeNav == 2 && (

                <div className="panel-box1">

                    <div className="panel-container">
                        <div className="panel-title1">
                            <p>Lv-2 Administrator Amounts</p>  <span>{maxTwoAdmin} </span>
                        </div>
                        <Form form={form2} name="control-hooks" className="form">

                            <Form.Item
                                name="maxTwo"
                                validateTrigger="onBlur"
                                validateFirst={true}
                            >

                                <div className="input-box">
                                    <Input/>
                                </div>
                            </Form.Item>

                            <div className="btns">
                                <div className="tj1-btn" onClick={() => {
                                    setAdminLevelTwoMax()
                                }}>Confirm
                                </div>
                            </div>
                        </Form>
                        <div className="panel-title1">
                            <p>Lv-3 Administrator Super account amounts</p> <span> {maxThreeAdmin} </span>
                        </div>
                        <Form form={form2} name="control-hooks" className="form">
                            <Form.Item
                                name="maxThree"
                                validateTrigger="onBlur"
                                validateFirst={true}
                            >
                                <div className="input-box">
                                    <Input/>
                                </div>
                            </Form.Item>

                            <div className="btns">
                                <div className="tj1-btn" onClick={() => {
                                    setAdminLevelThreeMax()
                                }}>Confirm
                                </div>
                            </div>
                        </Form>


                        <div className="panel-title1">
                            <p>Super account invitation code available times</p>
                            <span> {activateAccountUsedAmount}  </span>
                        </div>
                        <Form form={form2} name="control-hooks" className="form">
                            <Form.Item
                                name="ActivateAccountUsedAmount"
                                validateTrigger="onBlur"
                                validateFirst={true}
                            >
                                <div className="input-box">
                                    <Input/>
                                </div>
                            </Form.Item>

                            <div className="btns">
                                <div className="tj1-btn" onClick={() => {
                                    handlesetActivateAccountUsedAmount()
                                }}>Confirm
                                </div>
                            </div>
                        </Form>


                    </div>
                    <div className="panel-container "
                         style={{padding: '0px', background: 'none', border: "none", boxShadow: 'none'}}>
                        <h1 className="panel-title">
                            Set Lv-2 Administrator
                        </h1>
                        <div className="superdao-list-box setlv">
                            <div className="list-header setlv-header">
                                <div className="col">
                                    {t("No.")}
                                </div>

                                <div className="col">
                                    Address
                                </div>
                                <div className="col">
                                    Delete
                                </div>
                            </div>
                            {
                                secondAdmins.length == 0 ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/> :
                                    secondAdmins.map((item, index) => (
                                        <div className='list-item '>
                                            <div className="col no" key={index}>
                                                {index + 1}
                                            </div>

                                            {state.isMobile &&
                                                <div className="col address">

                                                    <a>{formatAddress(item)}</a>
                                                </div>
                                            }
                                            {!state.isMobile &&
                                                <div className="col address">
                                                    <a>{item}</a>
                                                </div>
                                            }
                                            <div className="col sc1">
                                                <img src={sc} className="sc" id='scc' onClick={() => {
                                                    setShowDelLev2(item);
                                                    setCurDelLev2Addr(item)
                                                }}/>
                                            </div>

                                        </div>
                                    ))
                            }
                            <div className='btn-box'>


                                <div className='addsbt' onClick={() => {
                                    setShowAddLev2(true)
                                }}>
                                    Add
                                </div>
                                <div className='ant-sbt' onClick={() => {
                                    // setShowAddLev2(true)
                                }}>
                                    Confirm
                                </div>
                            </div>
                        </div>
                        <div className='panel-container'>
                            <div className="panel-title1">
                                <p>Set Funding Token Price </p> <span>{salePriceV}</span>
                            </div>
                            <Form form={form2} name="control-hooks" className="form">
                                <div className="flex-box">
                                    <Form.Item
                                        name="exchangeRate"
                                        validateTrigger="onBlur"
                                        validateFirst={true}
                                    >
                                        <div className="input-box">
                                            <Input/>
                                        </div>
                                    </Form.Item>

                                    <div className="btns">
                                        <div className="tj1-btn" onClick={() => {
                                            setSalePrice()
                                        }}>Confirm
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                        <Modal className="model-dialogadd" title="Add" open={isShowAddLev2} onOk={
                            setAdmins
                        }
                               onCancel={() => {
                                   setShowAddLev2(false)
                               }}>
                            <Form form={form} name="control-hooks" className="form">
                                <Form.Item
                                    name="adminaddress"
                                    validateTrigger="onBlur"
                                    label="Address"
                                    validateFirst={true}
                                >
                                    <div className="input-box">
                                        <Input/>
                                    </div>
                                </Form.Item>
                            </Form>
                        </Modal>
                        {/* <div className="btns" style={{ textAlign: 'center', marginTop: '1.7em' }}>
                            <Button className="add-btn" type="primary" onClick={() => {
                                setAdmins()
                            }}>addAdmins</Button>
                            <Button className="add-btn" type="primary" onClick={() => {
                                removeAdmin()
                            }}>removeAdmin</Button>
                        </div> */}
                    </div>

                </div>

            )}
            {
                activeNav == 3 && (
                    <div className="part3">
                        <div className="panel-box">
                            <div className="panel-container" style={{
                                background: 'linear-gradient(rgb(60,60,60) 0%,rgb(54,54,54) )',
                                boxShadow: "none"
                            }}>
                                <div className='donate-box'>
                                    <div className="donate-info">
                                        <h3>Funding Pool</h3>
                                        <div className="info-item">
                                            <div className="name">
                                                SBD Amount
                                            </div>
                                            <div className="value">
                                                {showNum(SBDBalance)}
                                            </div>
                                        </div>
                                        <div className="info-item">
                                            <div className="name">
                                                SRT Amount
                                            </div>
                                            <div className="value">
                                                {showNum(poolSrtBalance)}
                                            </div>
                                        </div>
                                        <div className="flex-box1">
                                            <div className="info-item">
                                                <div className="name">
                                                    Value
                                                </div>
                                                <div className="value">
                                                    ${showNum(BigNumber(SBDBalance).multipliedBy(salePriceV))}
                                                </div>
                                            </div>
                                            <div className="info-item">
                                                <div className="name">
                                                    Total Funding
                                                </div>

                                                <div className="value">
                                                    <p>
                                                        {/* <img src={eth} style={{ marginTop: '-5px', marginRight: '10px' }} /> */}
                                                        {showNum(totalDonate)} USDT</p>
                                                </div>
                                            </div>

                                        </div>
                                        <h3 style={{marginTop: "30px"}}>Funding Lock Pool</h3>
                                        <div className="info-item">
                                            <div className="name">
                                                SBD Lock Amounts
                                            </div>
                                            <div className="value">
                                                {showNum(lockSbdBalance)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='donate-info '>
                                        <Form form={form} name="control-hooks">
                                            <div className="current flex-box">
                                                <div className="name">
                                                    Pool {coinInfo.name} Balance
                                                </div>
                                                <div className="value">
                                                    {showNum(coinInfo.balance)}
                                                </div>
                                            </div>
                                            <div className="current flex-box">
                                                <div className="name">
                                                    Name
                                                </div>
                                                <div className="value">
                                                    {coinInfo.name}
                                                </div>
                                            </div>
                                            <div className="current flex-box">
                                                <div className="name">
                                                    Symbol
                                                </div>
                                                <div className="value">
                                                    {coinInfo.symbol}
                                                </div>
                                            </div>
                                            <div className="current flex-box">
                                                <div className="name">
                                                    Decimals
                                                </div>
                                                <div className="value">
                                                    ${coinInfo.decimal}
                                                </div>
                                            </div>

                                            <Form.Item
                                                name="depositCoinAddr"
                                                label="Token Address"
                                                validateTrigger="onBlur"
                                                validateFirst={true}
                                                rules={[
                                                    {required: true, message: 'Please input Token Address!'},
                                                ]}
                                            >
                                                <Input value={withdrawCoinAddr} onChange={(e) => {
                                                    getCoinInfo(e.target.value)
                                                }}/>
                                            </Form.Item>


                                            <Form.Item
                                                name="depositAmount"
                                                validateTrigger="onBlur"
                                                validateFirst={true}
                                                rules={[
                                                    {required: true, message: 'Please input Token Amount!'},
                                                ]}
                                            >
                                                <Input placeholder='0'/>

                                            </Form.Item>
                                        </Form>
                                        <Button type="primary" className="max-btn" onClick={() => {
                                            handleApprove()
                                        }}>
                                            Approve
                                        </Button>
                                        <Button type="primary" className="max-btn" onClick={() => {
                                            handleDeposit()
                                        }}>
                                            Deposit
                                        </Button>
                                    </div>
                                    <div className='donate-info '>
                                        <Form form={form} name="control-hooks">
                                            <div className="current flex-box">
                                                <div className="name">
                                                    Pool {coinInfo.name} Balance
                                                </div>
                                                <div className="value">
                                                    {BigNumber(coinInfo.balance).toFixed(2,BigNumber.ROUND_DOWN)}
                                                </div>
                                            </div>
                                            <div className="current flex-box">
                                                <div className="name">
                                                    Name
                                                </div>
                                                <div className="value">
                                                    {coinInfo.name}
                                                </div>
                                            </div>
                                            <div className="current flex-box">
                                                <div className="name">
                                                    Symbol
                                                </div>
                                                <div className="value">
                                                    {coinInfo.symbol}
                                                </div>
                                            </div>
                                            <div className="current flex-box">
                                                <div className="name">
                                                    Decimals
                                                </div>
                                                <div className="value">
                                                    ${coinInfo.decimal}
                                                </div>
                                            </div>
                                            {/*<div className="current flex-box">*/}
                                            {/*    <div className="name">*/}
                                            {/*        Supply*/}
                                            {/*    </div>*/}
                                            {/*    <div className="value">*/}

                                            {/*    </div>*/}
                                            {/*</div>*/}
                                            <Form.Item
                                                name="withdrawCoinAddr"
                                                label="Token Address"
                                                validateTrigger="onBlur"
                                                validateFirst={true}
                                                rules={[
                                                    {required: true, message: 'Please input Token Address!'},
                                                ]}
                                            >
                                                <Input value={withdrawCoinAddr} onChange={(e) => {
                                                    setWithdrawCoinAddr(e.target.value)
                                                    getCoinInfo(e.target.value)
                                                }}/>
                                            </Form.Item>


                                            <Form.Item
                                                name="withdrawAmount"
                                                validateTrigger="onBlur"
                                                validateFirst={true}
                                                rules={[
                                                    {required: true, message: 'Please input Token Amount!'},
                                                ]}
                                            >
                                                <Input value={withdrawAmount} onChange={(e) => {
                                                    setWithdrawAmount(e.target.value)
                                                }} placeholder='0'/>
                                            </Form.Item>

                                        </Form>

                                        <Button type="primary" className="max-btn" onClick={() => {
                                            withdrawToken()
                                        }}>
                                            Withdraw
                                        </Button>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="panel-box">
                            <div className="panel-container" style={{padding: '10px 0%'}}>

                                <div className="header-nav1">
                                    <div className="superdao-nav-list ">
                                        <div className={"nav-item " + (recordNav == 1 ? "active" : "")} onClick={() => {
                                            setRecordNav(1)
                                        }}>
                                            Deposit Records
                                        </div>
                                        <div className={"nav-item " + (recordNav == 2 ? "active" : "")} onClick={() => {
                                            setRecordNav(2)
                                        }}>
                                            Withdraw Records
                                        </div>


                                    </div>
                                </div>
                                {recordNav == 1 && (
                                    <div>
                                        <div className="superdao-list-box records-list" style={{boxShadow: 'none'}}>
                                            <div className='listheadert'>
                                                <div className="list-header rowheader">
                                                    <div className="col">
                                                        {t("No.")}
                                                    </div>
                                                    <div className="col">
                                                        Address
                                                    </div>

                                                    <div className="col">
                                                        Amount
                                                    </div>
                                                    <div className="col">
                                                         {t("Time(UTC)")}
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                        <div className="pagination">
                                            {
                                                recordNav == 1 && <Pagination current={curPage} showSizeChanger
                                                                              onShowSizeChange={handleShowSizeChange}
                                                                              onChange={onChangePage} total={total}
                                                                              defaultPageSize={pageCount}/>
                                            }
                                        </div>
                                    </div>
                                )}
                            </div>

                        </div>
                        <div className="panel-box">
                            <div className="panel-container">

                                <h1 className="panel-title">
                                    Sign Up Records
                                </h1>

                                <div className="superdao-list-box sign-list">
                                    <div className='listheader4'>
                                        <div className="list-header signheader">
                                            <div className="col">
                                                {t("No.")}
                                            </div>

                                            <div className="col">
                                                recommenders
                                            </div>
                                            <div className="col">
                                                user addr
                                            </div>
                                        </div>
                                        {

                                            allSignRecord.map((item, index) => (
                                                index >= pageCount * (curPage - 1) && index < pageCount * curPage &&
                                                <div className='list-item signitem' key={index}>
                                                    <div className="col no">
                                                        {index + 1}
                                                    </div>
                                                    <div className='col '>
                                                        {item.recommenders}
                                                    </div>
                                                    <div className='col address'>
                                                        {item._user}
                                                    </div>
                                                </div>

                                            ))


                                        }

                                    </div>
                                </div>
                                <div className="pagination">
                                    <Pagination current={curPage} showSizeChanger
                                                onShowSizeChange={handleShowSizeChange}
                                                onChange={onChangePage} total={total}
                                                defaultPageSize={pageCount}/>

                                </div>
                            </div>


                        </div>

                        <div className="panel-box">
                            <div className="panel-container">
                                <h1 className="panel-title">
                                    Funding Records
                                </h1>
                                <Button onClick={() => {
                                    xlsxExport()
                                }}>导出</Button>

                                <div className="superdao-list-box funding-list records-list">
                                    <div className='listheadert3'>
                                        <div className="list-header rowheader">
                                            <div className="col">
                                                {t("No.")}
                                            </div>

                                            <div className="col">
                                                Address
                                            </div>

                                            <div className="col">
                                                Cost USDT
                                            </div>

                                            <div className="col">
                                                Price
                                            </div>
                                            <div className="col">
                                                Receive SBD
                                            </div>
                                            <div className="col">
                                                Lock SBD
                                            </div>
                                            <div className="col">
                                                Receive SVT
                                            </div>
                                            <div className='col'>
                                                Receive NFT
                                            </div>
                                            <div className="col">
                                                 {t("Time(UTC)")}
                                            </div>
                                        </div>


                                        {
                                            !searchData && recordNav == 1 && allRecords.map((item, index) => (
                                                index >= pageCount * (curPage - 1) && index < pageCount * curPage &&
                                                Row(item, index)
                                            ))
                                        }

                                    </div>

                                </div>
                                <div className="pagination">
                                    <Pagination current={curPage} showSizeChanger
                                                onShowSizeChange={handleShowSizeChange}
                                                onChange={onChangePage} total={total}
                                                defaultPageSize={pageCount}/>

                                </div>
                            </div>


                        </div>

                    </div>
                )
            }
            {
                activeNav == 4 && (
                    <TeamIncome/>
                )
            }
            {
                activeNav == 5 && (<div className="panel-box2">
                    <div className='panel-title'>Total Rate {totalRate / 100}</div>
                    <div className="panel-container fun">

                        <div className="superdao-list-box1 fund-list">
                            <div className="list-title">
                                Category 1 : Other Rewards({totalOtherRate / 100})
                            </div>
                            <div className="list-header">
                                <div className="col">
                                    Address
                                </div>
                                <div className="col">
                                    Percentage
                                </div>
                            </div>
                            {
                                assignAmin.length == 0 ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/> :
                                    assignAmin.map((item, index) => (
                                        <div className="list-item"
                                             key={index}
                                             onClick={() => {
                                                 chooseRow(item, index)
                                             }}
                                        >
                                            <div className="col address">
                                                <a target="_blank"
                                                   href={develop.ethScan + "/address/" + item.assign}> {formatAddress(item.assign)}</a>

                                            </div>
                                            <div className="col ">
                                                {item.rate.toString() / 100}%
                                            </div>

                                            <div className="col sc1">
                                                <img src={sc} className="sc" id='scc' onClick={() => {
                                                    delARRow(item)
                                                }}/>
                                            </div>

                                            {/*<div className="col">*/}
                                            {/*    {totalOtherRate}%*/}
                                            {/*</div>*/}

                                        </div>
                                    ))
                            }
                            <div className='add-btn' onClick={() => {
                                setShowAddRate(true)
                            }}>Add
                            </div>
                            <Form form={form2} name="control-hooks" className="form">

                                <div className=" ">
                                    <Form.Item
                                        name="Id"
                                        label="Id"
                                        validateTrigger="onBlur"
                                        validateFirst={true}
                                        style={{width: '100%'}}
                                    >
                                        <div className="input-box">
                                            <Input value={curId} onChange={(e) => {
                                                setCurId(e.target.value)
                                            }}/>
                                        </div>
                                    </Form.Item>
                                    <Form.Item
                                        name="assignAddress"
                                        label="Other Address"
                                        validateTrigger="onBlur"
                                        validateFirst={true}
                                        style={{width: '100%'}}
                                    >
                                        <div className="input-box">
                                            <Input value={curAddr} onChange={(e) => {
                                                setCurAddr(e.target.value)
                                            }}/>

                                        </div>
                                    </Form.Item>
                                    <Form.Item
                                        name="assignRate"
                                        label="Address Rate"
                                        validateTrigger="onBlur"
                                        validateFirst={true}

                                    >
                                        <div className="input-box" style={{width: '100%'}}>
                                            <Input style={{width: '100%'}}/>
                                        </div>
                                    </Form.Item>
                                </div>
                                <div className='tj-btn' onClick={() => {
                                    setRateAndAddress()
                                }}>Submit
                                </div>
                            </Form>
                        </div>
                        <div className="superdao-list-box1 fund-list">
                            <div className="list-title">
                                Category 2 : Referral Rewards({totalInviteRate / 100})
                            </div>

                            <div className="list-item1">
                                <div className="col1">
                                    {/* <Table pagination={false} columns={inviteColumns} dataSource={inviteRateArr} /> */}
                                    {!initRate && (<div className="content-item team-lev">
                                        <Form form={form2} name="control-hooks" className='form'>
                                            <div className="list-header1">
                                                <div className="col1">
                                                    Referrer
                                                </div>
                                                <div className="col1">
                                                    Percentage
                                                </div>
                                            </div>
                                            {[0, 1].map((index) => {
                                                return (<div className='lev1'>
                                                    <span className='level-name'>Level {index + 1}</span>
                                                    <Form.Item
                                                        name={"inviteRate" + index}
                                                        validateTrigger="onBlur"
                                                        validateFirst={true}
                                                    >
                                                        <div className="input-box">
                                                            <Input/><span>%</span>
                                                        </div>
                                                    </Form.Item>
                                                </div>)
                                            })}
                                            <div className='tj-btn' onClick={() => {
                                                addInviteRate()
                                            }}>Add Invite Rate
                                            </div>
                                        </Form>
                                    </div>)}

                                    {initRate && <div className="content-item ">
                                        <div className="setInvite-title">
                                            Set Invite Rate
                                        </div>
                                        <Form form={form} name="control-hooks" className="form">
                                            <Form.Item
                                                name="inviteRateID"
                                                label="Invite Rate Level"
                                                validateTrigger="onBlur"
                                                validateFirst={true}
                                            >
                                                <div className="input-box">
                                                    <Input/>
                                                </div>
                                            </Form.Item>
                                            <Form.Item
                                                name="inviteRate"
                                                label="Invite Rate"
                                                validateTrigger="onBlur"
                                                validateFirst={true}
                                            >
                                                <div className="input-box">
                                                    <Input/>
                                                </div>
                                            </Form.Item>

                                            <div className="btns">
                                                <div className="tj-btn" onClick={() => {
                                                    setInviteRate()
                                                }}>Submit
                                                </div>
                                            </div>
                                        </Form>
                                    </div>}
                                </div>
                            </div>

                        </div>
                        <div className="superdao-list-box1 fund-list">
                            <div className="list-title">
                                Category 3 : Team Rewards({totalTeamRate / 100})
                            </div>

                            <div className="list-item1">
                                {initTeamRate && <div className="content-item">
                                    <div className="setInvite-title">
                                        Set Team Rate
                                    </div>
                                    <Form form={form} name="control-hooks" className="form">

                                        <Form.Item
                                            name="level"
                                            label="Invite Rate Level"
                                            validateTrigger="onBlur"
                                            validateFirst={true}
                                        >
                                            <div className="input-box">
                                                <Input/>
                                            </div>
                                        </Form.Item>
                                        <Form.Item
                                            name="rate"
                                            label="Invite Rate"
                                            validateTrigger="onBlur"
                                            validateFirst={true}
                                        >
                                            <div className="input-box">
                                                <Input/>
                                            </div>
                                        </Form.Item>
                                        <div className="btns">
                                            <div className="tj-btn" onClick={() => {
                                                setTeamRate()
                                            }}>Submit
                                            </div>
                                        </div>
                                    </Form>

                                </div>}
                                {!initTeamRate && <div className="content-item">
                                    <div className="setInvite-title">
                                        Set Team Rate
                                    </div>
                                    <Form form={form} name="control-hooks" className="form">

                                        {[0, 1, 2].map((index) => {
                                            return (<div className='lev1'>
                                                <span className='level-name'>Level {index + 1}</span>
                                                <Form.Item
                                                    name={"teamRate" + index}
                                                    validateTrigger="onBlur"
                                                    validateFirst={true}
                                                >
                                                    <div className="input-box">
                                                        <Input/><span>%</span>
                                                    </div>
                                                </Form.Item>
                                            </div>)
                                        })}
                                        <div className="btns">
                                            <div className="tj-btn" onClick={() => {
                                                addTeamRate()
                                            }}>Submit
                                            </div>
                                        </div>
                                    </Form>

                                </div>}
                            </div>
                        </div>


                    </div>

                    <div className="panel-container">
                        <h1 className="panel-title">
                            Referral Rewards
                        </h1>
                        <Table pagination={false} columns={inviteColumns} dataSource={inviteRateArr}/>


                    </div>
                    <div className="panel-container">
                        <h1 className="panel-title">
                            Team Rewards
                        </h1>
                        <Table pagination={false} columns={teamColumns} dataSource={teamRateArr}/>

                    </div>
                    <div className="panel-container">
                        <div className="setInvite-title">
                            addInviteSrtRatio
                        </div>
                        <Form form={form} name="control-hooks" className="form">

                            <div className='lev1'>
                                <span className='level-name'>直推({inviteSrtRatio[0] / 100})</span>
                                <Form.Item
                                    name={"srtRate0"}
                                    validateTrigger="onBlur"
                                    validateFirst={true}
                                >
                                    <div className="input-box">
                                        <Input/><span>%</span>
                                    </div>
                                </Form.Item>
                            </div>
                            <div className='lev1'>
                                <span className='level-name'>间推({inviteSrtRatio[1] / 100})</span>
                                <Form.Item
                                    name={"srtRate1"}
                                    validateTrigger="onBlur"
                                    validateFirst={true}
                                >
                                    <div className="input-box">
                                        <Input/><span>%</span>
                                    </div>
                                </Form.Item>
                            </div>
                            <div className="btns">
                                <div className="tj-btn" onClick={() => {
                                    addInviteSrtRatio()
                                }}>Submit
                                </div>
                            </div>
                        </Form>

                    </div>
                    <div className="panel-container">
                        <div className="setInvite-title">
                            Init spt ratio
                        </div>
                        <Form form={form} name="control-hooks" className="form">
                            {[0, 1, 2, 3, 4].map((i) => (
                                <div className='lev1'>
                                    <span className='level-name'>Level{i + 1}({sptRatio[i]})</span>
                                    <Form.Item
                                        name={"sptRatio" + i}
                                        validateTrigger="onBlur"
                                        validateFirst={true}
                                    >
                                        <div className="input-box">
                                            <Input/>
                                        </div>
                                    </Form.Item>
                                </div>
                            ))}


                            <div className="btns">
                                <div className="tj-btn" onClick={() => {
                                    handleAddSptRatio()
                                }}>Submit
                                </div>
                            </div>
                        </Form>

                    </div>
                    <div className="panel-container">
                        <div className="setInvite-title">
                            Set spt ratio
                        </div>
                        <Form form={form} name="control-hooks" className="form">
                            <div className='lev1'>
                                <span className='level-name'>Level</span>
                                <Form.Item
                                    name={"sptRatioLevel" }
                                    validateTrigger="onBlur"
                                    validateFirst={true}
                                >
                                    <div className="input-box">
                                        <Input/>
                                    </div>
                                </Form.Item>
                            </div>
                            <div className='lev1'>
                                <span className='level-name'>Radio</span>
                                <Form.Item
                                    name={"sptRatioRatio" }
                                    validateTrigger="onBlur"
                                    validateFirst={true}
                                >
                                    <div className="input-box">
                                        <Input/>
                                    </div>
                                </Form.Item>
                            </div>

                            <div className="btns">
                                <div className="tj-btn" onClick={() => {
                                    handleSetSptRatio()
                                }}>Submit
                                </div>
                            </div>
                        </Form>

                    </div>
                </div>)
            }
            {showAddRate && (<AddAddressRate updateData={() => {
                getAssignAndRates()
            }} closeDialog={() => {
                setShowAddRate(false)
            }}/>)}

        </OGPoolAdminStyle>
    )
}
export default OgPoolAdmin

import styled from "styled-components";

export default styled.div`
  .ant-form-item-control {
    background: #272727;
    border-radius: 10px 10px 10px 10px;
  }


  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 0;
    background: rgb(39, 39, 39) !important;
  }

  .ant-pagination-options {
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      background: rgb(28, 28, 28) !important;
      border: 1px solid rgb(67, 67, 67);
      cursor: pointer;
      border-radius: 5px;
    }
  }

  .newpower {
    font-size: 16px;
    font-family: Roboto-Bold, Roboto;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 19px;
  }

  .ant-form-item-label {
    margin: 1em 0;

    label {
      font-size: 18px;
      font-family: Roboto-Medium, Roboto;
      font-weight: 500;
      color: #8A8080;
      line-height: 23px;
    }

  }

  .ant-input {
    height: 50px;;
    background: #272727;
    border-radius: 10px 10px 10px 10px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    font-size: 20px;
    font-family: Roboto-Medium, Roboto;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 26px;
  }

  .panel-part1 {
    width: 100%;
  }

  .panel-part2 {
    width: 100%;

    .panel-title {
      margin: 1em 0;
    }

    .content-box {
      display: flex;
      justify-content: space-between;

      .panel-container {
        width: 32%;
        padding: 3em;

        .power-small {
          display: flex;
          justify-content: space-between;

          .name {
            font-size: 20px;
            font-family: Roboto-Medium, Roboto;
            font-weight: 500;
            color: #8A8080;
            line-height: 26px;
          }

          .value {
            word-break: break-all;
            font-size: 20px;
            font-family: Roboto-Medium, Roboto;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 26px;
          }
        }
      }
    }
  }

  .panel-box {
    padding: 1.5em 3em;

    .panel-container {
      padding: 1em 0;
      background: #1C1C1C;
      box-shadow: 0px 5px 20px 5px rgba(0, 0, 0, 0.2);
      border-radius: 20px 20px 20px 20px;
      padding: 3em 7em;
      border: 1px solid rgba(255, 255, 255, 0.15);

      .part-container {
        width: 46%;

        .flex-title {
          font-size: 24px;
          font-family: Roboto-Medium, Roboto;
          font-weight: bold;
          color: #FFFFFF;
          line-height: 31px;
        }
      }

    }
  }

  .current {
    width: 100%;
    height: 50px;
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.10);
    background: rgba(39, 39, 39);
    text-align: left;
    line-height: 50px;

    p {
      width: 100%;
      height: 100%;
      color: white;
      font-size: 20px;
      padding: 0em 1em;
    }
  }

  .submi {
    width: 50%;
    display: flex;
    justify-content: space-between;

    .sub-butt {
      width: 70%;
      font-size: 18px;
      font-family: Roboto-Bold, Roboto;
      font-weight: bold;
      color: #1A1414;
      line-height: 21px;
      height: 50px;
      margin: 1em 0em;
      border-radius: 10px;
      background: linear-gradient(229deg, #DAB163 0%, #CC8A35 100%);
    }
  }

  .submit {
    .submit-button {
      width: 100%;
      font-size: 18px;
      font-family: Roboto-Bold, Roboto;
      font-weight: bold;
      color: #1A1414;
      line-height: 21px;
      height: 50px;
      margin: 1em 0em 0em;
      border-radius: 10px;
      background: linear-gradient(229deg, #DAB163 0%, #CC8A35 100%);
    }
  }

  .content-box1 {
    .nav-box {
      display: flex;
      margin: 3em auto;
      justify-content: space-between;
      width: 35%;
      height: 60px;
      background: rgb(28, 28, 28);
      border-radius: 10px 10px 10px 10px;
      border: 1px solid rgba(255, 255, 255, 0.1);

      .nav-item {
        cursor: pointer;
        width: 48%;
        height: 56px;
        border-radius: 10px 10px 10px 10px;
        font-size: 18px;
        font-family: Roboto-Bold, Roboto;
        font-weight: bold;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;

        &.active {
          background: linear-gradient(229deg, #DAB163 0%, #CC8A35 100%);
          color: #613A00;

        }
      }
    }
  }

  .lock-box {
    margin: 0 auto;
    width: 40%;

    .lock-part {
      padding: 2em;
      background: rgb(28, 28, 28);
      box-shadow: 0px 5px 20px 5px rgba(0, 0, 0, 0.2);
      border-radius: 20px 20px 20px 20px;
      border: 1px solid rgba(255, 255, 255, 0.15);

      .nft-balance {
        width: 100%;
        margin-top: 1em;
        display: flex;
        justify-content: space-between;

        .name {
          font-size: 16px;
          font-family: Roboto-Bold, Roboto;
          font-weight: bold;
          color: #FFFFFF;
          line-height: 19px;
        }

        .value {
          font-size: 16px;
          font-family: Roboto-Bold, Roboto;
          font-weight: bold;
          color: #8A8080;
          line-height: 19px;
        }
      }

      .small-nft {
        background: rgb(39, 39, 39) !important;
        border-radius: 10px;
        border: 1px solid rgba(255, 255, 255, 0.1);
        height: 60px;
        line-height: 60px;

        .right-tip {
          width: 58%;
          float: left;
          font-size: 16px;
          font-family: Roboto-SemiBold, Roboto;
          font-weight: 600;
          color: #FFFFFF;
          padding: 0 1em;
        }

      }

      .max-box {
        .input-box {
          background-color: rgb(39, 39, 39);
          border-radius: 10px;
          border: 1px solid rgba(255, 255, 255, 0.1);
          position: relative;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .ant-select:not(.ant-select-customize-input) .ant-select-selector {
            background: rgb(39, 39, 39) !important;
            border: 0;
          }

          .ant-input {
            font-size: 26px;
            font-weight: 900;
            line-height: 30px;
            height: 50px;
          }
        }
      }

      .approve-box {
        display: block;
        width: 100%;

        .lock-btn {
          background: linear-gradient(229deg, rgb(218, 177, 99) 0%, rgb(204, 138, 53) 100%);
          border-radius: 10px 10px 10px 10px;
          margin: 1em 0;
          width: 100%;
          height: 50px;
          display: flex;
          justify-content: center;
          font-size: 18px;
          font-family: Roboto-SemiBold, Roboto;
          font-weight: bold;
          color: #1A1414;
          line-height: 24px;
          align-items: center;
          cursor: pointer;

        }
      }
    }
  }

  .panel-part3 {
    margin: 2em 0;
    width: 100%;
  }

  .nft-list {
    margin: 1em 0;
    background: rgba(28, 28, 28, 1);

    .list-header {
      display: flex;
      padding: 20px 2em;
      border-bottom: 1px solid rgba(234, 234, 234, 0.10);
    }

    .list-item, .list-header {
      justify-content: flex-start;

      .col {
        text-align: left;
        font-size: 16px;

        &:nth-child(1) {
          margin-left: 50px;
          width: 10%;
        }

        &:nth-child(2) {
          width: 15%;
          margin-left: 15px;
        }

        &:nth-child(3) {
          width: 30%;
          margin-left: 15px;
        }

        &:nth-child(4) {
          width: 17%;
          margin-left: 15px;
        }

        &:nth-child(5) {
          width: 15%;
          margin-left: 10px;
        }


      }
    }

    .list-item:last-child {
      border-bottom: none;
    }

    .list-item {
      padding: 1em 2em;
      color: #FFFFFF;
      margin: 0 auto;
      border-radius: 0px;
      border-bottom: 1px solid rgba(234, 234, 234, 0.10);;

      &:nth-child(even) {

        background: rgba(62, 62, 62, 1);
      }


      .col {
        overflow: hidden;
        padding-left: 0%;

      }

      .address {
        a {
          color: #CD9E57;
        }
      }
    }
  }

  .pagination {
    display: block !important;
    text-align: center;
  }


  @media screen and (max-width: 1440px) {
    .ant-form-item-label label {
      font-size: 16px;
    }

    .ant-input {
      font-size: 15px;
    }

    .current {
      line-height: 45px;

      p {
        font-size: 15px;
      }
    }

    .submi {

      .sub-butt {
        width: 90%;
        height: 45px;
        font-size: 16px;
      }
    }

    .panel-box {
      .panel-container {
        .part-container {
          width: 52%;

          .flex-title {
            font-size: 20px;
          }
        }
      }
    }

    .panel-part2 {
      .panel-title {
        margin: 0.5em 0;
      }

      .content-box {
        .panel-container {
          padding: 2em;

          .power-small {
            .name {
              font-size: 16px;
            }

            .value {
              font-size: 16px;
            }
          }

          .newpower {
            font-size: 15px;
          }
        }
      }
    }

    .submit .submit-button {
      font-size: 16px;
      height: 45px;
    }

    .content-box1 .nav-box {
      margin: 2em auto;
      width: 42%;
      height: 54px;

      .nav-item {
        width: 48%;
        height: 50px;
        border-radius: 10px;
        font-size: 16px;
      }
    }

    .lock-box {
      width: 47%;

      .lock-part {
        .small-nft {
          height: 50px;
          line-height: 50px;

          .right-tip {
            font-size: 14px;
            line-height: 50px;
            width: 50%;
          }
        }

        .nft-balance {
          margin-top: 0em;

          .name {
            font-size: 14px;
          }

          .value {
            font-size: 14px;
          }
        }


        .max-box .input-box .ant-input {
          font-size: 20px;
        }
      }
    }

    .lock-btn {
      height: 45px;

      span {
        font-size: 16px;
      }

    }

    .list-headert {
      width: 1150px;
    }

    .nft-list {
      overflow-x: auto;

      .list-header {
        display: flex;
      }

      .list-item, .list-header {
        justify-content: flex-start;

        .col {
          text-align: left;
          font-size: 15px;

          &:nth-child(1) {
            width: 100px;
            margin-left: 10px;
          }

          &:nth-child(2) {
            width: 180px;
          }

          &:nth-child(3) {
            width: 380px;
          }

          &:nth-child(4) {
            width: 200px;
          }

          &:nth-child(5) {
            width: 160px;
          }


        }

      }

    }

  }

  @media screen and (max-width: 450px) {
    .ant-select {
      width: 47%;
    }

    .ant-form-item-label {
      margin: 0px;
    }

    .panel-box {
      padding: 0em;

      .panel-container {
        padding: 1em 2em;

        .part-container {
          width: 100%;
        }
      }
    }

    .current {
      line-height: 40px;
      height: 40px;
    }

    .ant-input {
      height: 40px;
    }

    .submi {
      width: 100%;

      .sub-butt {
        width: 100%;
        font-size: 16px;
      }
    }

    .panel-part2 .content-box {
      display: block;
      width: 100%;

      .panel-container {
        margin: 1em auto;
        width: 100%;
      }
    }

    .content-box1 .nav-box {
      width: 100%;
      height: 54px;

      .nav-item {
        font-size: 15px;
      }
    }

    .lock-box {
      width: 100%;
    }

    .nft-list {
      .list-item, .list-header {
        .col {
          text-align: left;
          font-size: 14px;
        }

      }

    }
  }
`
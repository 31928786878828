import styled from "styled-components";

export default styled.div`
  .input-box {
    .ant-select {
      width: 200px;
      display: flex;
    }
  }

  .input-box {
    .ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item {
      color: #FFF;
      line-height: 50px;
      font-size: 18px;
    }
  }

  .input-box {
    .ant-select {
      .ant-select-selector {
        line-height: 48px;
        height: 48px;
      }
    }
  }

  .ant-input {
    background-color: rgb(39, 39, 39);
    border: 0;
  }


  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 0;
    background: rgb(39, 39, 39) !important;
  }

  .ant-pagination-options {
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      background: rgb(28, 28, 28) !important;
      border: 1px solid rgb(67, 67, 67);
      cursor: pointer;
      border-radius: 5px;
    }
  }

  .panel-box {
    padding: 1.5em 4em;

    .panel-container {
      padding: 1em 0;
    }
  }

  .panel-title {
    display: flex;
    justify-content: space-between;

    span {
      line-height: 50px;

    }

    .lockadmin {
      width: 10%;
      text-align: center;
      background: #272727;
      border-radius: 10px 10px 10px 10px;
      border: 1px solid rgba(255, 255, 255, 0.1);
      font-size: 18px;
      font-family: Roboto-Bold, Roboto;
      font-weight: bold;
      color: #FFFFFF;
      cursor: pointer;
      line-height: 50px;
    }
  }

  .nft-box {
    display: flex;
    justify-content: space-between;
    .left-part,.right-part{
      width: 48%;
    }
    .nftlock {
      padding: 20px 3em;
      background: #1C1C1C;
      box-shadow: 0px 5px 20px 5px rgba(0, 0, 0, 0.2);
      border-radius: 20px 20px 20px 20px;
      border: 1px solid rgba(255, 255, 255, 0.15);

      .nft-title {
        font-size: 24px;
        font-family: Roboto-Bold, Roboto;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 28px;
        margin: 1em 0 1.5em 0;
        display: flex;
        justify-content: space-between;
      }

      .nft-content {
        display: flex;
        justify-content: space-between;

        .name {
          font-size: 20px;
          font-family: Roboto-Medium, Roboto;
          font-weight: 500;
          color: #8A8080;
          line-height: 26px;

        }

        .value {
          font-size: 20px;
          font-family: Roboto-Bold, Roboto;
          font-weight: bold;
          color: #FFFFFF;
          line-height: 26px;
        }
      }
    }
  }


  .nav-box {
    display: flex;
    margin: 3em auto;
    justify-content: space-between;
    width: 35%;
    height: 60px;
    background: rgb(28, 28, 28);
    border-radius: 10px 10px 10px 10px;
    opacity: 1;
    border: 1px solid rgba(255, 255, 255, 0.1);

    .nav-item {
      width: 32%;
      height: 56px;
      border-radius: 10px 10px 10px 10px;
      font-size: 18px;
      font-family: Roboto-Bold, Roboto;
      font-weight: bold;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &.active {
        background: linear-gradient(229deg, #DAB163 0%, #CC8A35 100%);
        color: #613A00;

      }
    }
  }


  .withdraw-list {
    margin: 1em 0;
    background: rgba(28, 28, 28, 1);

    .withall-records-header {
      display: flex;
      padding: 20px 2em;
      border-bottom: 1px solid rgba(234, 234, 234, 0.10);
    }

    .withall-records-item, .withall-records-header {
      justify-content: flex-start;

      .col {
        text-align: left;
        font-size: 16px;

        &:nth-child(1) {
          margin-left: 150px;
          width: 13%;
        }

        &:nth-child(2) {
          width: 18%;
          margin-left: 10px;
        }

        &:nth-child(3) {
          width: 25%;
          margin-left: 10px;
        }


        &:nth-child(4) {
          width: 15%;
          margin-left: 10px;
        }

      }
    }

    .withall-records-item:last-child {
      border-bottom: none;
    }

    .withall-records-item {
      padding: 1em 2em;
      color: #FFFFFF;
      margin: 0 auto;
      border-radius: 0px;
      border-bottom: 1px solid rgba(234, 234, 234, 0.10);;

      &:nth-child(even) {

        background: rgba(62, 62, 62, 1);
      }


      .col {
        overflow: hidden;
        padding-left: 0%;

      }

      .address {
        a {
          color: #CD9E57;
        }
      }
    }
  }

  .withdrawnft-list {
    margin: 1em 0;
    background: rgba(28, 28, 28, 1);

    .withnft-records-header {
      display: flex;
      padding: 20px 2em;
      border-bottom: 1px solid rgba(234, 234, 234, 0.10);
    }

    .withnft-records-item, .withnft-records-header {
      justify-content: flex-start;

      .col {
        text-align: left;
        font-size: 16px;

        &:nth-child(1) {
          margin-left: 100px;
          width: 13%;
        }

        &:nth-child(2) {
          width: 15%;
          margin-left: 10px;
        }

        &:nth-child(3) {
          width: 18%;
          margin-left: 10px;
        }

        &:nth-child(4) {
          width: 15%;
          margin-left: 10px;
        }

        &:nth-child(5) {
          width: 15%;
          margin-left: 10px;
        }

      }
    }

    .withnft-records-item:last-child {
      border-bottom: none;
    }

    .withnft-records-item {
      padding: 1em 2em;
      color: #FFFFFF;
      margin: 0 auto;
      border-radius: 0px;
      border-bottom: 1px solid rgba(234, 234, 234, 0.10);;

      &:nth-child(even) {

        background: rgba(62, 62, 62, 1);
      }


      .col {
        overflow: hidden;
        padding-left: 0%;

      }

      .address {
        a {
          color: #CD9E57;
        }
      }
    }
  }


  .lock-box {
    margin: 0 auto;
    width: 40%;

    .lock-part {
      padding: 2em;
      background: rgb(28, 28, 28);
      box-shadow: 0px 5px 20px 5px rgba(0, 0, 0, 0.2);
      border-radius: 20px 20px 20px 20px;
      border: 1px solid rgba(255, 255, 255, 0.15);

      .nft-balance {
        width: 100%;
        margin-top: 1em;
        display: flex;
        justify-content: space-between;

        .name {
          font-size: 16px;
          font-family: Roboto-Bold, Roboto;
          font-weight: bold;
          color: #FFFFFF;
          line-height: 19px;
        }

        .value {
          font-size: 16px;
          font-family: Roboto-Bold, Roboto;
          font-weight: bold;
          color: #8A8080;
          line-height: 19px;
        }
      }

      .small-nft {
        background: rgb(39, 39, 39) !important;
        border-radius: 10px;
        border: 1px solid rgba(255, 255, 255, 0.1);
        height: 50px;
        line-height: 50px;

        .right-tip {
          width: 58%;
          float: left;
          font-size: 16px;
          font-family: Roboto-SemiBold, Roboto;
          font-weight: 600;
          color: #FFFFFF;
          padding: 0 1em;
        }

      }

      .max-box {
        .input-box {
          background-color: rgb(39, 39, 39);
          border-radius: 10px;
          border: 1px solid rgba(255, 255, 255, 0.1);
          position: relative;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .ant-select:not(.ant-select-customize-input) .ant-select-selector {
            background: rgb(39, 39, 39) !important;
            border: 0;
          }

          .ant-input {
            font-size: 26px;
            font-weight: 900;
            line-height: 30px;
            height: 50px;
          }
        }
      }
    }
  }

  .max-btn {
    width: 85px;
    height: 48px;
    text-align: center;
    line-height: 48px;
    background: linear-gradient(229deg, #DAB163 0%, #CC8A35 100%);
    border-radius: 10px 10px 10px 10px;
    cursor: pointer;
    font-size: 18px;
    font-family: Roboto-Bold, Roboto;
    font-weight: bold;

    color: #1A1414;
  }

  .lock-btn {
    background: linear-gradient(229deg, rgb(218, 177, 99) 0%, rgb(204, 138, 53) 100%);
    border-radius: 10px 10px 10px 10px;
    margin: 1em 0;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    font-size: 18px;
    font-family: Roboto-SemiBold, Roboto;
    font-weight: bold;
    color: #1A1414;
    line-height: 24px;
    align-items: center;

    cursor: pointer;
    &:disabled{
      opacity: 0.3;
    }
  }


  .nft-list {
    margin: 1em 0;
    background: rgba(28, 28, 28, 1);

    .list-header {
      display: flex;
      padding: 20px 2em;
      border-bottom: 1px solid rgba(234, 234, 234, 0.10);
    }

    .list-item, .list-header {
      justify-content: flex-start;

      .col {
        text-align: left;
        font-size: 16px;

        &:nth-child(1) {
          margin-left: 70px;
          width: 12%;
        }

        &:nth-child(2) {
          width: 15%;
          margin-left: 10px;
        }

        &:nth-child(3) {
          width: 18%;
          margin-left: 10px;
        }

        &:nth-child(4) {
          width: 13%;
          margin-left: 10px;
        }

        &:nth-child(5) {
          width: 15%;
          margin-left: 10px;
        }

        &:nth-child(6) {
          width: 15%;
        }


      }
    }

    .list-item:last-child {
      border-bottom: none;
    }

    .list-item {
      padding: 1em 2em;
      color: #FFFFFF;
      margin: 0 auto;
      border-radius: 0px;
      border-bottom: 1px solid rgba(234, 234, 234, 0.10);;

      &:nth-child(even) {

        background: rgba(62, 62, 62, 1);
      }


      .col {
        overflow: hidden;
        padding-left: 0%;

      }

      .address {
        a {
          color: #CD9E57;
        }
      }
    }
  }

  .pagination {
    display: block !important;
    text-align: center;
  }


  @media screen and (max-width: 1440px) {
    .input-box {
      .ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item {

        font-size: 15px;
      }
    }

    .current {
      line-height: 45px;

      p {
        font-size: 15px;
      }
    }

    .input-box {
      .ant-select {
        width: 160px;
      }
    }

    .panel-title {
      span {
        line-height: 45px;
      }

      .lockadmin {
        font-size: 14px;
        line-height: 45px;
      }
    }

    .nft-box {
      .nftlock {
        .nft-title {
          font-size: 18px;
          margin: 0em 0px 1em;
        }

        .nft-content {
          .name {
            font-size: 15px;
            line-height: 19px;
          }

          .value {
            font-size: 15px;
            line-height: 19px;
          }
        }
      }
    }

    .withdraw-list {
      .withall-records-header {
        display: flex;
      }

      .withall-records-item, .withall-records-header {
        justify-content: flex-start;

        .col {
          text-align: left;
          font-size: 15px;

          &:nth-child(1) {
            width: 15%;
            margin-left: 80px;
          }

          &:nth-child(3) {
            width: 28%;
          }

          &:nth-child(4) {
            width: 18%;

          }

        }

      }

    }


    .withdrawnft-list {
      .withnft-records-header {
        display: flex;
      }

      .withnft-records-item, .withnft-records-header {
        justify-content: flex-start;

        .col {
          text-align: left;
          font-size: 15px;

          &:nth-child(1) {
            width: 10%;
            margin-left: 70px;
          }

          &:nth-child(4) {
            width: 25%;
          }

          &:nth-child(5) {
            width: 18%;
          }

        }

      }

    }


    .nav-box {
      width: 42%;

      .nav-item {
        font-size: 15px;
      }
    }


    .lock-box {
      width: 47%;

      .lock-part {
        padding: 1em 1.5em;

        .nft-balance {
          .name {
            font-size: 14px;
          }

          .value {
            font-size: 14px;

          }
        }

        .small-nft {

          height: 50px;
          line-height: 50px;

          .right-tip {
            font-size: 14px;
          }
        }

        .max-box .input-box .ant-input {
          font-size: 20px;
        }
      }


    }

    .max-btn {
      width: 80px;
      height: 48px;
      line-height: 48px;
      font-size: 16px;
    }

    .lock-btn {
      height: 45px;
      font-size: 16px;
    }

    .nft-list {
      .list-header {
        display: flex;
      }

      .list-item, .list-header {
        justify-content: flex-start;

        .col {
          text-align: left;
          font-size: 15px;

          &:nth-child(1) {
            width: 10%;
            margin-left: 30px;
          }

          &:nth-child(5) {
            width: 20%;
          }

          &:nth-child(6) {
            width: 18%;
          }


        }

      }

    }


  }


  @media screen and (max-width: 450px) {
    .input-box {
      .ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item {
        font-size: 14px;
      }
    }

    .current {
      line-height: 40px;
      height: 40px;
    }

    .panel-box {
      padding: 1.5em 0;

      .panel-container {
        padding: 0em;
      }
    }

    .panel-title {
      .lockadmin {
        width: 22%;
        line-height: 40px;
      }
    }

    .nft-box {
      display: block;
      width: 100%;

      .nftlock {
        padding: 2em;
        margin: 1em auto;
        width: 100%;
        box-shadow: none;

        .nft-title {
          font-size: 16px;
        }

        .nft-content {
          .name {
            font-size: 14px;
          }

          .value {
            font-size: 14px;
          }
        }
      }
    }

    .nav-box {
      width: 100%;
      margin: 2em auto;
      height: 54px;

      .nav-item {
        font-size: 14px;
        height: 50px;
        line-height: 50px;
      }
    }


    .list-headert2 {
      width: 630px;
    }

    .withdraw-list {
      overflow-x: auto;

      .withall-records-item, .withall-records-header {

        .col {
          text-align: left;
          font-size: 14px;

          &:nth-child(1) {
            width: 100px;
            margin-left: 10px;
          }

          &:nth-child(2) {
            width: 120px;
          }

          &:nth-child(3) {
            width: 180px;
          }

          &:nth-child(4) {
            width: 150px;

          }

        }

      }

    }


    .list-headert3 {
      width: 920px;
    }

    .withdrawnft-list {
      overflow-x: auto;

      .withnft-records-item, .withnft-records-header {

        .col {
          text-align: left;
          font-size: 14px;

          &:nth-child(1) {
            width: 100px;
            margin-left: 10px;
          }

          &:nth-child(2) {
            width: 160px;
          }

          &:nth-child(3) {
            width: 170px;
          }

          &:nth-child(4) {
            width: 200px;

          }

          &:nth-child(5) {
            width: 150px;

          }

        }

      }

    }

    .lock-box {
      width: 100%;

      .lock-part {
        .nft-balance {
          margin-top: 0em;

        }

        .small-nft .right-tip {
          width: 52%;
        }
      }
    }

    .max-btn {
      width: 80px;
      height: 46px;
      line-height: 46px;
      font-size: 15px;
    }

    .lock-btn {
      height: 40px;
      font-size: 15px;
    }

    .list-headert {
      width: 1000px;
    }

    .nft-list {
      overflow-x: scroll;

      .list-item, .list-header {

        .col {
          text-align: left;
          font-size: 14px;

          &:nth-child(1) {
            width: 100px;
            margin-left: 10px;
          }

          &:nth-child(2) {
            width: 160px;
          }

          &:nth-child(3) {
            width: 170px;
          }

          &:nth-child(4) {
            width: 100px;
          }

          &:nth-child(5) {
            width: 170px;
          }

          &:nth-child(6) {
            width: 140px;
          }
        }

      }

    }

  }

  @media screen and (max-width: 400px) {
    .ant-select {
      width: 145px;
    }
  }
`
import React, {useEffect, useState} from "react";
import {useConnect} from "../../../../api/contracts";

import {dealMethod, dealPayMethod, viewMethod} from "../../../../utils/contractUtil";
import {getContractByName, getContractByContract} from "../../../../api/connectContract";
import {ETHPriceDecimals, MaxUint256, SBDDecimals} from "../../../../config/constants";
import {getAdminDepositRecord, getAdminWithdrawRecord} from "../../../../graph/NFTLockGQL"

import judgeStatus from "../../../../utils/judgeStatus";
import addressMap from "../../../../api/addressMap"
import {Button, Form, Input, Select, Pagination, Empty, message, notification, DatePicker} from "antd";

import {showNum} from "../../../../utils/bigNumberUtil";
import {dealTime} from "../../../../utils/timeUtil";
import BigNumber from "bignumber.js";

import LockMiningAdminStyle from './style'
import {formatAddress} from "../../../../utils/publicJs";
import {useTranslation} from "react-i18next";

const LockMiningAdmin = () => {
    const [form] = Form.useForm();
    const [activeNav, setActiveNav] = useState(0);
    let {state, dispatch} = useConnect();
    const [total, setTotal] = useState(0);
    const [pageCount, setPageCount] = useState(20);
    const [curPage, setCurPage] = useState(1);
    const [nftAdmin, setNFTAdmin] = useState()

    const [period, setPeriod] = useState()
    const [startTime, setStartTime] = useState()
    const [srtBalance, setSrtBalance] = useState()
    const [parsed, setParsed] = useState()

    const [withRecords, setWithRecords] = useState([]);
    const [depositRecords, setDepositRecords] = useState([]);
    const [time, setTime] = useState();

    const [poolNumber, setPoolNumber] = useState()
    const [smallNode, setSmallNode] = useState()
    const [bigNode, setBigNode] = useState()
    const [supNode, setSupNode] = useState()
    const [rewardDays, setRewardsDays] = useState()

    const { t } = useTranslation();

    const openNotification = (message) => {
        notification.error({
            message: message,
            description:
                "",
            onClick: () => {
            },
        });
    };

    const onChangePage = async (page) => {
        await setCurPage(page)
        withNFTRecords(curPage, page)
        depositSrtRecords(curPage, page)
    }
    const handleShowSizeChange = async (page, count) => {
        setPageCount(count)
        withNFTRecords(count, curPage)
        depositSrtRecords(count, curPage)
    }

    const handleDealMethod = async (name, params) => {
        let contractTemp = await getContractByName("NFTLockV3", state.api,)
        if (!contractTemp) {
            message.warn("Please connect", 5)
        }
        await dealMethod(contractTemp, state.account, name, params)
    }

    const handleViewMethod = async (name, params) => {
        let contractTemp = await getContractByName("NFTLockV3", state.api,)
        if (!contractTemp) {
            openNotification("Please connect")
        }
        return await viewMethod(contractTemp, state.account, name, params)
    }

    const handleApprove = async () => {
        let contractTemp = await getContractByName("SRT", state.api,)
        await dealMethod(contractTemp, state.account, "approve", [addressMap['NFTLockV3'].address, MaxUint256])
    }


    const setSmallAdPow = async () => {
        let contractTemp = await getContractByName("NFTLockV3", state.api,)
        let smallAdd = await handleViewMethod('smallNode', [])
        await dealMethod(contractTemp, state.account, 'setWeight', [[smallAdd], [form.getFieldValue().newSmallPow]])

    }
    const setBigAdPow = async () => {
        let contractTemp = await getContractByName("NFTLockV3", state.api,)
        let bigAdd = await handleViewMethod('bigNode', [])
        await dealMethod(contractTemp, state.account, 'setWeight', [[bigAdd], [form.getFieldValue().newBigPow]])

    }
    const setSuperAdPow = async () => {
        let contractTemp = await getContractByName("NFTLockV3", state.api,)
        let supAdd = await handleViewMethod('superNode', [])
        await dealMethod(contractTemp, state.account, 'setWeight', [[supAdd], [form.getFieldValue().newSuperPow]])
    }

    const getPause = async () => {
        let res = await handleViewMethod('paused', [])
        setParsed(res)
    }
    const getPeriod= async () => {
        let res = await handleViewMethod('period', [])
        setPeriod(res)
    }
    const getStartTime= async () => {
        let res = await handleViewMethod('startTime', [])
        setStartTime(res)
    }
    const transAdmin = async () => {
        let admin = await handleViewMethod('owner', [])
        setNFTAdmin(admin)
    }
    const handlePause = async () => {

        await handleDealMethod('pause', [])
        getPause()
    }
    const handleUnPause = async () => {
        await handleDealMethod('unpause', [])
        getPause()
    }
    const newTransAdmin = async () => {
        await handleDealMethod('transferOwnership', [form.getFieldValue().NewTransAdmin])
        transAdmin()
    }
    const onOk = (value) => {
        console.log('onOk: ', value);
        setTime(new Date(value).getTime())
    };
    const handleInitBonus = async () => {
        await handleDealMethod('initBonus', [BigNumber(time).div(1000).toFixed(0)])
        getStartTime()
    }
    const handleChangePeriod = async () => {
        await handleDealMethod('changePeriod', [form.getFieldValue().changePeriod])
        getPeriod()
    }
    const srtBanlance = async () => {
        let contractTemp = await getContractByName("SRT", state.api,)
        const srtAmounts = await viewMethod(contractTemp, state.account, "balanceOf", [state.account])
        setSrtBalance(srtAmounts)

    }

    const getSmall = async () => {
        let nftSmall = await handleViewMethod('smallNode', [])
        let res = await handleViewMethod('weight', [nftSmall])
        setSmallNode(res)

    }

    const getBig = async () => {
        let nftBig = await handleViewMethod('bigNode', [])
        let res = await handleViewMethod('weight', [nftBig])
        setBigNode(res)

    }
    const getSuper = async () => {
        let nfftSup = await handleViewMethod('superNode', [])
        let res = await handleViewMethod('weight', [nfftSup])
        setSupNode(res)

    }

    const nftDeposit = async () => {
        let times = (rewardDays)
        await handleDealMethod('deposit', [BigNumber(form.getFieldValue().SrtData).multipliedBy(10 ** SBDDecimals).toFixed(0), parseInt(times)])
        srtBanlance()
        setTimeout(() => {
            depositSrtRecords()
        }, 500)
    }
    //获取输入的地址
    const getCoinAdd = async (value) => {
        getPoolNumber(value)
    }
    const getPoolNumber = async (value) => {
        if (!state.api.utils.isAddress(value)) {
            return
        }
        let poolNum = await getContractByContract('erc20', value, state.api,)
        let res = await viewMethod(poolNum, state.account, "balanceOf", [addressMap['NFTLockV3'].address])
        setPoolNumber(BigNumber(res).div(10 ** 18).toString())
    }


    const nftWithdraw = async () => {
        if (!state.api.utils.isAddress(form.getFieldValue().coinedAddress)) {
            message.error("wrong address")
            return
        }
        let poolNum = await getContractByContract('erc20', form.getFieldValue().coinedAddress, state.api,)
        let decimals = await viewMethod(poolNum, state.account, "decimals", [])
        await handleDealMethod('backToken', [form.getFieldValue().coinedAddress, BigNumber(form.getFieldValue().withAmounts).multipliedBy(10 ** decimals).toFixed(0)])
        setTimeout(() => {
            withNFTRecords()
        }, 500)
    }

    const withNFTRecords = async () => {
        let records = await getAdminWithdrawRecord(pageCount, curPage)
        let res = records.data.adminWithdraws
        setWithRecords(res)
    }
    const depositSrtRecords = async () => {
        let records = await getAdminDepositRecord(pageCount, curPage)
        let res = records.data.adminDeposits
        setDepositRecords(res)
    }

    useEffect(async () => {
        withNFTRecords()
        depositSrtRecords()
        let judgeRes = await judgeStatus(state)
        if (!judgeRes) {
            return
        }

        transAdmin()
        srtBanlance()
        getSmall()
        getBig()
        getSuper()
        getPeriod()
        getStartTime()

    }, [state.account])

    return (
        <LockMiningAdminStyle>
            <div className="panel-box">
                <div className="panel-part1">
                    <div className="panel-container">
                        <div className="part-container">
                            <h3 className="flex-title">
                                Transfer Administrator
                            </h3>
                            <Form className="form" form={form} name="control-hooks">
                                <Form.Item label='Admin' name="current">
                                    <div className='current'>
                                        <p>
                                            {nftAdmin}
                                        </p>
                                    </div>
                                </Form.Item>

                                <Form.Item
                                    name="NewTransAdmin"
                                    validateTrigger="onBlur"
                                    validateFirst={true}
                                    label="New Admin">
                                    <Input/>
                                </Form.Item>
                            </Form>

                            <div className='submi'>
                                <Button type="primary" className="sub-butt"
                                        onClick={() => {
                                            newTransAdmin()
                                        }}>
                                    Submit
                                </Button>
                            </div>
                        </div>
                        <div className="part-container">
                            <h3 className="flex-title">
                                InitBonus ( {dealTime(startTime)})
                            </h3>
                            <Form className="form" form={form} name="control-hooks">

                                <Form.Item
                                    name="bonus"
                                    validateTrigger="onBlur"
                                    validateFirst={true}
                                >
                                    <DatePicker showTime onChange={onOk} onOk={onOk}/>
                                </Form.Item>
                            </Form>

                            <div className='submi'>
                                <Button type="primary" className="sub-butt"
                                        onClick={() => {
                                            handleInitBonus()
                                        }}>
                                    Submit
                                </Button>
                            </div>
                        </div>
                        <div className="part-container">
                            <h3 className="flex-title">
                                changePeriod({period})s
                            </h3>
                            <Form className="form" form={form} name="control-hooks">
                                <Form.Item
                                    name="changePeriod"
                                    validateTrigger="onBlur"
                                    validateFirst={true}
                                >
                                    <Input placeholder={"s"}/>
                                </Form.Item>
                            </Form>

                            <div className='submi'>
                                <Button type="primary" className="sub-butt"
                                        onClick={() => {
                                            handleChangePeriod()
                                        }}>
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="panel-part2">
                    <div className="panel-title">
                        Contract Status({parsed ? "paused" : "not pause"})
                    </div>
                    <div className="panel-container">
                        <Button type="primary" className="submit-button"
                                onClick={() => {
                                    handlePause()
                                }}>
                            Paused
                        </Button>
                        <Button type="primary" className="submit-button"
                                onClick={() => {
                                    handleUnPause()
                                }}>
                            Unpause
                        </Button>
                    </div>


                </div>
                <div className="panel-part2">
                    <h1 className="panel-title">
                        Node Pow
                    </h1>
                    <div className="content-box">
                        <div className="panel-container">
                            <div className="power-small">
                                <p className="name">Small Node</p>
                                <p className="value">{smallNode}</p>
                            </div>
                            <Form form={form} className='form'>
                                <p className="newpower">New Pow</p>
                                <Form.Item
                                    name="newSmallPow"
                                    validateTrigger="onBlur"
                                    validateFirst={true}

                                >
                                    <Input/>
                                </Form.Item>
                            </Form>
                            <div className='submit'>
                                <Button type="primary" className="submit-button"
                                        onClick={() => {
                                            setSmallAdPow()
                                        }}>
                                    Submit
                                </Button>
                            </div>
                        </div>
                        <div className="panel-container">
                            <div className="power-small">
                                <p className="name">Big Node</p>
                                <p className="value">{bigNode}</p>
                            </div>
                            <Form form={form} className='form'>
                                <p className="newpower">New Pow</p>
                                <Form.Item
                                    name="newBigPow"
                                    validateTrigger="onBlur"
                                    validateFirst={true}
                                >
                                    <Input/>
                                </Form.Item>
                            </Form>
                            <div className='submit'>
                                <Button type="primary" className="submit-button"
                                        onClick={() => {
                                            setBigAdPow()
                                        }}>
                                    Submit
                                </Button>
                            </div>

                        </div>
                        <div className="panel-container">
                            <div className="power-small">
                                <p className="name">Super Node</p>
                                <p className="value">{supNode}</p>
                            </div>
                            <Form form={form} className='form'>
                                <p className="newpower">New Pow</p>
                                <Form.Item
                                    name="newSuperPow"
                                    validateTrigger="onBlur"
                                    validateFirst={true}

                                >
                                    <Input/>
                                </Form.Item>
                            </Form>
                            <div className='submit'>
                                <Button type="primary" className="submit-button"
                                        onClick={() => {
                                            setSuperAdPow()
                                        }}>
                                    Submit
                                </Button>
                            </div>

                        </div>

                    </div>
                    {/*<div className="content-box1">*/}
                    {/*    <div className="nav-box">*/}
                    {/*        <div className={"nav-item " + (activeNav == 0 ? "active" : "")} onClick={() => {*/}
                    {/*            setActiveNav(0)*/}
                    {/*        }}>*/}
                    {/*            Deposit*/}
                    {/*        </div>*/}
                    {/*        <div className={"nav-item " + (activeNav == 1 ? "active" : "")} onClick={() => {*/}
                    {/*            setActiveNav(1)*/}
                    {/*        }}>*/}
                    {/*            Withdraw*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    {*/}
                    {/*        activeNav == 0 && <div>*/}
                    {/*            <div className="lock-box">*/}
                    {/*                <Form form={form} name="control-hooks" className="form">*/}
                    {/*                    <div className="lock-part">*/}
                    {/*                        <div className="nft-balance">*/}
                    {/*                            <p className="name"> Address</p>*/}
                    {/*                        </div>*/}
                    {/*                        <div className="max-box">*/}
                    {/*                            <Form.Item*/}
                    {/*                                name="coinAddr"*/}
                    {/*                                validateTrigger="onBlur"*/}
                    {/*                                validateFirst={true}*/}
                    {/*                            >*/}
                    {/*                                <Input*/}
                    {/*                                    value={srtInput}*/}
                    {/*                                    placeholder="0"*/}
                    {/*                                    onChange={(e) => {*/}
                    {/*                                        setSrtInput(e.target.value)*/}
                    {/*                                    }} />*/}
                    {/*                            </Form.Item>*/}
                    {/*                        </div>*/}
                    {/*                        <div className="nft-balance" style={{ marginTop: '2em' }}>*/}
                    {/*                            <p className="name">Amount</p>*/}
                    {/*                        </div>*/}
                    {/*                        <div className="max-box">*/}
                    {/*                            <Form.Item*/}
                    {/*                                name="RewardsD"*/}
                    {/*                                validateTrigger="onBlur"*/}
                    {/*                                validateFirst={true}*/}

                    {/*                            >*/}
                    {/*                                <div className="input-box">*/}
                    {/*                                    <Input*/}
                    {/*                                        value={rewardDays}*/}
                    {/*                                        placeholder="0"*/}
                    {/*                                        onChange={(e) => { setRewardsDays(e.target.value) }} />*/}

                    {/*                                </div>*/}
                    {/*                            </Form.Item>*/}
                    {/*                        </div>*/}

                    {/*                        <div className="approve-box">*/}
                    {/*                            <Button onClick={() => {*/}
                    {/*                                handleApprove()*/}
                    {/*                            }} type="primary" className="lock-btn">*/}
                    {/*                                Approve*/}
                    {/*                            </Button>*/}
                    {/*                            <Button onClick={() => {*/}
                    {/*                                nftDeposit()*/}
                    {/*                            }} type="primary" className="lock-btn">*/}
                    {/*                                Submit*/}
                    {/*                            </Button>*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                </Form>*/}


                    {/*            </div>*/}
                    {/*            <div className="panel-part3">*/}
                    {/*                <h1 className="panel-title">*/}
                    {/*                    Deposit Records*/}
                    {/*                </h1>*/}

                    {/*                <div className="superdao-list-box nft-list">*/}
                    {/*                    <div className='list-headert'>*/}
                    {/*                        <div className="list-header ">*/}
                    {/*                            <div className="col">*/}
                    {/*                                No.*/}
                    {/*                            </div>*/}

                    {/*                            <div className="col">*/}
                    {/*                                Address*/}
                    {/*                            </div>*/}

                    {/*                            <div className="col">*/}
                    {/*                                Token*/}
                    {/*                            </div>*/}

                    {/*                            <div className="col">*/}
                    {/*                                Amounts*/}
                    {/*                            </div>*/}
                    {/*                            <div className="col">*/}
                    {/*                                Time(UTC)*/}
                    {/*                            </div>*/}

                    {/*                        </div>*/}
                    {/*                        {*/}
                    {/*                            depositRecords.length == 0 ? <Empty*/}
                    {/*                                image={Empty.PRESENTED_IMAGE_SIMPLE} /> : depositRecords.map((item, index) => {*/}
                    {/*                                    return (*/}
                    {/*                                        <div className="list-item">*/}
                    {/*                                            <div className="col no">*/}
                    {/*                                                {index + 1}*/}
                    {/*                                            </div>*/}
                    {/*                                            <div className="col address">*/}
                    {/*                                                <a>*/}
                    {/*                                                    {formatAddress(item.admin)}*/}
                    {/*                                                </a>*/}
                    {/*                                            </div>*/}
                    {/*                                            <div className="col">*/}
                    {/*                                                {item.token}*/}
                    {/*                                            </div>*/}
                    {/*                                            <div className="col">*/}
                    {/*                                                {BigNumber(item.amount).div(10 ** 18).toFixed(0)}*/}
                    {/*                                            </div>*/}
                    {/*                                            <div className="col">*/}
                    {/*                                                {dealTime(item.blockTimestamp)}*/}
                    {/*                                            </div>*/}
                    {/*                                        </div>*/}
                    {/*                                    )*/}
                    {/*                                })*/}
                    {/*                        }*/}

                    {/*                    </div>*/}

                    {/*                </div>*/}
                    {/*                <div className="pagination">*/}
                    {/*                    <Pagination current={curPage} showSizeChanger*/}
                    {/*                        onShowSizeChange={handleShowSizeChange}*/}
                    {/*                        onChange={onChangePage} total={total}*/}
                    {/*                        defaultPageSize={pageCount} />*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    }*/}
                    {/*    {*/}
                    {/*        activeNav == 1 && <div>*/}
                    {/*            <div className="lock-box">*/}
                    {/*                <Form form={form} name="control-hooks" className="form">*/}
                    {/*                    <div className="lock-part">*/}
                    {/*                        <div className="nft-balance">*/}
                    {/*                            <p className="name">Token Address</p>*/}
                    {/*                        </div>*/}
                    {/*                        <Form.Item*/}
                    {/*                            name="coinedAddress"*/}
                    {/*                            validateTrigger="onBlur"*/}
                    {/*                            validateFirst={true}*/}
                    {/*                        >*/}
                    {/*                            <div className="input-box">*/}
                    {/*                                <Input*/}
                    {/*                                    onChange={(e) => {*/}
                    {/*                                        getCoinAdd(e.target.value)*/}
                    {/*                                    }} />*/}

                    {/*                            </div>*/}
                    {/*                        </Form.Item>*/}
                    {/*                        <div className="nft-balance" style={{ marginTop: '2em' }}>*/}
                    {/*                            <p className="name">Amounts</p>*/}
                    {/*                            <p className="value">Pool Amounts: {showNum(poolNumber)}</p>*/}
                    {/*                        </div>*/}
                    {/*                        <div className="max-box">*/}
                    {/*                            <Form.Item*/}
                    {/*                                name="withAmounts"*/}
                    {/*                                validateTrigger="onBlur"*/}
                    {/*                                validateFirst={true}*/}
                    {/*                            >*/}
                    {/*                                <div className="input-box">*/}
                    {/*                                    <Input*/}
                    {/*                                        placeholder="0"*/}
                    {/*                                        onChange={(e) => { }} />*/}
                    {/*                                </div>*/}
                    {/*                            </Form.Item>*/}
                    {/*                        </div>*/}
                    {/*                        <div className="approve-box">*/}

                    {/*                            <Button onClick={() => {*/}
                    {/*                                nftWithdraw()*/}
                    {/*                            }} type="primary" className="lock-btn">*/}
                    {/*                                Submit*/}
                    {/*                            </Button>*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                </Form>*/}
                    {/*            </div>*/}
                    {/*            <div className="panel-part3">*/}
                    {/*                <h1 className="panel-title">*/}
                    {/*                    Withdraw Records*/}
                    {/*                </h1>*/}

                    {/*                <div className="superdao-list-box nft-list">*/}
                    {/*                    <div className='list-headert'>*/}
                    {/*                        <div className="list-header ">*/}
                    {/*                            <div className="col">*/}
                    {/*                                No.*/}
                    {/*                            </div>*/}

                    {/*                            <div className="col">*/}
                    {/*                                Address*/}
                    {/*                            </div>*/}

                    {/*                            <div className="col">*/}
                    {/*                                Token*/}
                    {/*                            </div>*/}

                    {/*                            <div className="col">*/}
                    {/*                                Amounts*/}
                    {/*                            </div>*/}
                    {/*                            <div className="col">*/}
                    {/*                                Time(UTC)*/}
                    {/*                            </div>*/}

                    {/*                        </div>*/}
                    {/*                        {*/}
                    {/*                            withRecords.length == 0 ? <Empty*/}
                    {/*                                image={Empty.PRESENTED_IMAGE_SIMPLE} /> : withRecords.map((item, index) => {*/}
                    {/*                                    return (*/}
                    {/*                                        <div className="list-item">*/}
                    {/*                                            <div className="col no">*/}
                    {/*                                                {index + 1}*/}
                    {/*                                            </div>*/}
                    {/*                                            <div className="col address">*/}
                    {/*                                                <a>*/}
                    {/*                                                    {formatAddress(item.admin)}*/}
                    {/*                                                </a>*/}
                    {/*                                            </div>*/}
                    {/*                                            <div className="col">*/}
                    {/*                                                {item.token}*/}
                    {/*                                            </div>*/}
                    {/*                                            <div className="col">*/}
                    {/*                                                {BigNumber(item.amount).div(10 ** 18).toFixed(0)}*/}
                    {/*                                            </div>*/}
                    {/*                                            <div className="col">*/}
                    {/*                                                {dealTime(item.blockTimestamp)}*/}
                    {/*                                            </div>*/}
                    {/*                                        </div>*/}
                    {/*                                    )*/}
                    {/*                                })*/}
                    {/*                        }*/}
                    {/*                    </div>*/}

                    {/*                </div>*/}
                    {/*                <div className="pagination">*/}
                    {/*                    <Pagination current={curPage} showSizeChanger*/}
                    {/*                        onShowSizeChange={handleShowSizeChange}*/}
                    {/*                        onChange={onChangePage} total={total}*/}
                    {/*                        defaultPageSize={pageCount} />*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    }*/}
                    {/*</div>*/}

                </div>

            </div>
        </LockMiningAdminStyle>
    )
}
export default LockMiningAdmin
import React, {useEffect, useRef, useState} from 'react';

import NFTStyle from './NFTNavStyle';
import NFTView from "./NFTView";
import NFTLock from "../LockMining/LockMiningView/NFTLockView"
import NFTLockV3 from "../LockMiningV3/LockMiningView/components/NFTLock";
import MyNFTView from "./MyNFTView";
import Back from "../../../imgs/back_icon.png"
import {getContractByName} from "../../../api/connectContract";
import {viewMethod} from "../../../utils/contractUtil";
import {useConnect} from "../../../api/contracts";
import judgeStatus from "../../../utils/judgeStatus";
import {useTranslation} from "react-i18next";

const NFTNavView = (props) => {
    let {state, dispatch} = useConnect();
    const [curNav, setCurNav] = useState(1)
    const [curNode, setCurNode] = useState(1)
    const [showMint, setShowMint] = useState(true)
    const [smallAdminV2, setSmallAdminV2] = useState()
    const [bigAdminV2, setBigAdminV2] = useState()
    const [supAdminV2, setSupAdminV2] = useState()
    const [smallAdminV3, setSmallAdminV3] = useState()
    const [bigAdminV3, setBigAdminV3] = useState()
    const [supAdminV3, setSupAdminV3] = useState()
    const { t } = useTranslation();

    const goNode = (index) => {
        setCurNode(index)
        if (index == 1) {
            setShowMint(true)
        } else if (index == 2) {
            if (smallAdminV2 || bigAdminV2 || supAdminV2 || true) {
                setShowMint(true)
            }
        } else if (index == 3) {
            if (smallAdminV3 || bigAdminV3 || supAdminV3 || true) {
                setShowMint(true)
            }
        }
    }
    const getAdmin = async () => {
        let contractTemp = await getContractByName("smallnodeV2", state.api,)
        const Admin = await viewMethod(contractTemp, state.account, "owner", [])
        setSmallAdminV2(Admin.toString().toLowerCase() == state.account.toLocaleString())
        let contractTemp2 = await getContractByName("bignodeV2", state.api,)
        const Admin2 = await viewMethod(contractTemp2, state.account, "owner", [])
        setBigAdminV2(Admin2.toString().toLowerCase() == state.account.toLocaleString())
        let contractTemp3 = await getContractByName("supnodeV2", state.api,)
        const Admin3 = await viewMethod(contractTemp3, state.account, "owner", [])
        setSupAdminV2(Admin3.toString().toLowerCase() == state.account.toLocaleString())
        try {
            let contractTemp = await getContractByName("smallnodeV3", state.api,)
            const Admin = await viewMethod(contractTemp, state.account, "owner", [])
            setSmallAdminV3(Admin.toString().toLowerCase() == state.account.toLocaleString())

            let contractTemp2 = await getContractByName("bignodeV3", state.api,)
            const Admin2 = await viewMethod(contractTemp2, state.account, "owner", [])
            setBigAdminV3(Admin2.toString().toLowerCase() == state.account.toLocaleString())

            let contractTemp3 = await getContractByName("supnodeV3", state.api,)
            const Admin3 = await viewMethod(contractTemp3, state.account, "owner", [])
            setSupAdminV3(Admin3.toString().toLowerCase() == state.account.toLocaleString())
        } catch (e) {
            console.log(e)
        }
    }
    useEffect(async () => {
        let judgeRes = await judgeStatus(state)
        if (!judgeRes) {
            return
        }
        getAdmin()
    }, [state.account])
    return (

        <NFTStyle>
            <div className="superdao-nav-list">
                <div className={"nav-item " + (curNav == 1 ? "active" : "")} onClick={() => {
                    setCurNav(1)
                }}>
                    {t("Mint")}
                </div>
                <div className={"nav-item " + (curNav == 2 ? "active" : "")} onClick={() => {
                    setCurNav(2)
                }}>
                    {t("Lock Mining")}
                </div>
                <div className={"nav-item " + (curNav == 3 ? "active" : "")} onClick={() => {
                    setCurNav(3)
                }}>
                    {t("Bonus")}
                </div>
                <div className={"nav-item " + (curNav == 4 ? "active" : "")} onClick={() => {
                    setCurNav(4)
                }}>
                    {t("My NFT")}
                </div>
            </div>

            {curNav == 1 && !showMint && <div>
                <div className="node-list">
                    <div className={"node-item " + (curNode == 1 ? "active" : "")} onClick={() => {
                        goNode(1)
                    }}>
                        {t("Node")} V1
                    </div>
                    <div className={"node-item " + (curNode == 2 ? "active" : "")} onClick={() => {
                        goNode(2)
                    }}>
                        {t("Node")} V2
                    </div>
                    <div className={"node-item " + (curNode == 3 ? "active" : "")} onClick={() => {
                        goNode(3)
                    }}>
                        {t("Node")} V3
                    </div>
                </div>
            </div>}
            {curNav == 1 && showMint && <div>
                {/*<div className="back-box" onClick={() => {*/}
                {/*    setCurNode(undefined);*/}
                {/*    setShowMint(false)*/}
                {/*}}>*/}
                {/*    <img src={Back} width={40} height={40} alt=""/>*/}
                {/*</div>*/}
                <NFTView curNode={curNode}/>
            </div>}
            {curNav == 2 && <NFTLock/>}
            {curNav == 3 && <NFTLockV3/>}
            {curNav == 4 && <MyNFTView/>}

        </NFTStyle>
    )
}
export default NFTNavView
import React, {useEffect, useRef, useState} from 'react';
import {useConnect} from "../../../api/contracts";
import BigNumber from "bignumber.js"
import {showNum} from "../../../utils/bigNumberUtil";
import usdtIcon from "../../../imgs/usdt.png";
import SBDCoinIcon from "../../../imgs/SBD.png";
import SVTCoinIcon from "../../../imgs/svt.png";
import USDTIcon from "../../../imgs/usdt.png";
import {formatAddress} from "../../../utils/publicJs";
import ConnectWallet from "../../../component/ConnectWallet/ConnectWallet";
import user3 from "../../../imgs/user3.png"
import usdt from "../../../imgs/usdt.png"
import download from "../../../imgs/download.png"
import {zeroAddress} from "viem";
import {ETHPriceDecimals, MaxUint256, SBDDecimals} from "../../../config/constants";
import SuperNode from "../../../imgs/SuperNode@2x.png"
import WithdrawView from "./components/withdrawView";
import BigNode from "../../../imgs/BigNode@2x.png"

import SmallNode from "../../../imgs/SmallNode@2x.png"
import { useTranslation } from 'react-i18next';

import {
    Button,
    message,
    AutoComplete,
    Switch,
    Select,
    Form,
    Empty,
    Pagination, Input, Modal
} from 'antd';
import {getContractByName, getContractByContract} from "../../../api/connectContract";
import {dealMethod, dealPayMethod, viewMethod} from "../../../utils/contractUtil"

import develop from "../../../env";
import {useNavigate} from "react-router-dom";
import judgeStatus from "../../../utils/judgeStatus";
import {
    getDonateRecord,
    getAllRegisters,
    getRecommender,
    getAddressFromId,
    getAllInvites,
} from "../../../graph/purChaseGQL";
import PurchaseStyle from "./PurchaseStyle";
import {ETHDecimals, SBDCoinDecimals, USDTDecimals, NFTMap} from "../../../config/constants";
import search from "../../../imgs/search.png";
import {dealTime} from "../../../utils/timeUtil";
import coinInfo from "../../../config/coinInfo";
import addressMap from "../../../api/addressMap";

const OGPoolPublic = (props) => {
    let {state, dispatch} = useConnect();
    const [activeNav, setActiveNav] = useState(1)
    const [total, setTotal] = useState(0)
    const [myRecommender, setMyRecommender] = useState()
    const [myId, setMyId] = useState()
    const [recordNav, setRecordNav] = useState(1)
    const [pageCount, setPageCount] = useState(20)
    const [curPage, setCurPage] = useState(1)
    const [SBDCoinBalance, setSBDCoinBalance] = useState(0)
    const [totalDonate, setTotalDonate] = useState(0)
    const [exchangeAmount, setExchangeAmount] = useState(0)
    const [inputValue, setInputValue] = useState(0)
    const [curAddress, setCurAddress] = useState()
    const [NFTMapArr, setNFTMAP] = useState([NFTMap])
    const [isSecondAdmin, setIsSecondAdmin] = useState(false)
    const [isThreeAdmin, setIsThreeAdmin] = useState(false)
    const [isFourAdmin, setIsFourAdmin] = useState(false)
    const [isFiveAdmin, setIsFiveAdmin] = useState(false)

    const [isSixAdmin, setIsSixAdmin] = useState(false)
    const [isSevenAdmin, setIsSevenAdmin] = useState(false)
    const [isEightAdmin, setIsEightAdmin] = useState(false)
    const [isNineAdmin, setIsNineAdmin] = useState(false)

    const [sbdBalance, setSBDBalance] = useState(0)
    const [usdtBalance, setUSDTBalance] = useState(0)
    const [svtBalance, setSVTBalance] = useState(0)

    const [activeUsedAmount, setActiveUsedAmount] = useState(0)
    const [activateAccountUsedAmount, setActivateAccountUsedAmount] = useState(0)
    const [allRecords, setAllRecords] = useState([])
    const [refRecords, setREFRecords] = useState([])
    const [myRecords, seMyRecords] = useState([])
    const [isShowRegister, setIsShowRegister] = useState(false)
    const [salePrice, setSalePriceV] = useState(0.01)
    const [status, setStatus] = useState(0)
    const [isAdmin, setIsAdmin] = useState(false)
    const [myTeam, setMyTeamArr] = useState([])
    const [nodeName, setNodeName] = useState()

    const [levelCountObj, setLevelCountObj] = useState({})
    const [rewardTotalETH, setRewardTotalETH] = useState()
    const [rewardTotalFLM, setRewardTotalFLM] = useState()
    const [myTeamRecord, setMyTeamRecord] = useState([])
    const [inviteRateArr, setInvArr] = useState([])
    const [inviteRecordArr, setInvRecord] = useState([])
    const [nftMapArr, setNftNumbersArr] = useState([])

    const [isPause, setIsPause] = useState()
    const [myStatus, setMyStatus] = useState({})
    const [activeArr, setActiveArr] = useState([])
    const [allowance, setAllowance] = useState()
    const [userBuyMax, setUserBuyMax] = useState(0)
    const [userTotalBuy, setUserTotalBuy] = useState(0)
    const [searchData, setSearchData] = useState()
    const [curLevel, setCurLevel] = useState('0')
    const [receiveSbd, setReceiveSbd] = useState()
    const [receiveSvt, setReceiveSvt] = useState()

    const [supBalance, setSupBalance] = useState()
    const [bigBalance, setBigBalance] = useState()
    const [smallBalance, setSmallBalance] = useState()


    const [smallNode, setSmallNode] = useState()
    const [bigNode, setBigNode] = useState()
    const [supNode, setSupNode] = useState()

    const history = useNavigate();
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    const handleDealMethod = async (name, params) => {
        let contractTemp = await getContractByName("spbd", state.api,)
        if (!contractTemp) {
            message.warn("Please connect", 5)
        }
        await dealMethod(contractTemp, state.account, name, params)
    }


    const handlePayDealMethod = async (name, params, value) => {
        let contractTemp = await getContractByName("spbd", state.api,)
        if (!contractTemp) {
            message.warn("Please connect", 5)
        }
        await dealPayMethod(contractTemp, state.account, name, params, value)
    }
    const handleViewMethod = async (name, params) => {
        let contractTemp = await getContractByName("spbd", state.api,)
        if (!contractTemp) {
            message.warn("Please connect", 5)
        }
        return await viewMethod(contractTemp, state.account, name, params)
    }
    const handleViewMethodByOwner = async (name, params, account) => {
        let contractTemp = await getContractByName("spbd", state.api,)
        if (!contractTemp) {
            message.warn("Please connect", 5)
        }
        return await viewMethod(contractTemp, account, name, params)
    }
    const handleSearchChange = async (e) => {
        setSearchData(e.target.value);
    }
    const handleApprove = async () => {
        let contractTemp = await getContractByContract("erc20", addressMap["USDT"].address, state.api,)
        await dealMethod(contractTemp, state.account, "approve", [addressMap["spbd"].address, MaxUint256])
        getAllowance()
    }
    const getAllowance = async () => {
        let contractTemp = await getContractByContract("erc20", addressMap["USDT"].address, state.api,)
        const res = await viewMethod(contractTemp, state.account, "allowance", [state.account, addressMap["spbd"].address])
        setAllowance(res)
    }
    const handleSearch = async () => {
        const res = await getAddressFromId(searchData)
        if (searchData == undefined) {
            const hide = message.loading('', 0);
            await getMyTeam(state.account)
            setTimeout(hide, 1000)
            setCurAddress(state.account)
            getAddressRecommender(state.account)
        }
        if (res.data && res.data.allRegisters[0]) {
            if (state.api.utils.isAddress(res.data.allRegisters[0]._user)) {
                const address = res.data.allRegisters[0]._user
                setCurAddress(address)
                getAddressRecommender(address)
                const hide = message.loading('', 0);
                await getMyTeam(address)
                setTimeout(hide, 1000)
            }

        }

    }

    const getCoinBalance = async () => {
        let bigNode = await handleViewMethod("bigNode", [])
        let smallNode = await handleViewMethod("smallNode", [])
        let supNode = await handleViewMethod("supNode", [])


        let contractTemp = await getContractByContract("supnodeV1", supNode, state.api,)
        let supbalance = await viewMethod(contractTemp, supNode, "initAmount", [])
        let supMinted = await viewMethod(contractTemp, supNode, "totalMint", [])

        setSupBalance(supbalance - supMinted)

        let contractTemp2 = await getContractByContract("bignodeV1", bigNode, state.api,)
        let bigbalance = await viewMethod(contractTemp2, bigNode, "initAmount", [])
        let bigMinted = await viewMethod(contractTemp2, bigNode, "totalMint", [])

        setBigBalance(bigbalance - bigMinted)

        let contractTemp3 = await getContractByContract("smallnodeV1", smallNode, state.api,)
        let smallbalance = await viewMethod(contractTemp3, smallNode, "initAmount", [])

        let smallMinted = await viewMethod(contractTemp3, smallNode, "totalMint", [])
        setSmallBalance(smallbalance - smallMinted)

    }
    const getAdmin = async () => {
        let res = await handleViewMethod("owner", [])
        if (state.account.toLowerCase() == res.toLowerCase()) {
            setIsAdmin(true)
        } else {
            setIsAdmin(false)
        }
    }
    const handleCoinViewMethod = async (name, coinName, params) => {
        let contractTemp = await getContractByName(coinName, state.api,)
        if (!contractTemp) {
            message.warn("Please connect", 5)
        }
        return await viewMethod(contractTemp, state.account, name, params)
    }

    const Row = (item, index) => {
        return <div className="list-item " key={index}>
            <div className="col no">
                {myRecords.length - index}
            </div>
            <div className="col address">
                {item.addr && (
                    <a href={develop.ethScan + "address/" + item.addr} target="_blank">
                        {formatAddress(item.addr)}
                    </a>
                )}
            </div>

            <div className="col">
                <img width={18} height={18} style={{margin: "-3px 3px 0 0"}} src={USDTIcon}/>

                {showNum(BigNumber(item.usdtAmount / 10 ** USDTDecimals).toFixed(2))}
            </div>

            <div className="col">
                {showNum(BigNumber(item.usdtAmount).div((BigNumber(item.sbdAmount).plus(item.lockSbd))), 4)}
            </div>
            <div className="col receive">
                {showNum(BigNumber(item.sbdAmount).div(10 ** SBDDecimals), 0)}
            </div>
            <div className="col lock">
                {(showNum(BigNumber(item.lockSbd).dividedBy(10 ** SBDDecimals), 0))}
            </div>
            <div className="col lock">
                {showNum(BigNumber(item.lockSbd).div(10 ** SBDDecimals), 0)}
            </div>
            <div className='col receive-nft'>
                {item.receiveNft && (<a target="_blank"
                                        href={develop.ethScan + '/address/' + item.receiveNft}>{NFTMapArr[item.receiveNft.toLowerCase()]}</a>)}

            </div>

            <div className="col">
                {dealTime(item.blockTimestamp)}
            </div>

        </div>
    }
    const getBigNode = async () => {
        let res = await handleViewMethod("bigNode", [])
        setBigNode(res)
    }
    const getSmallNode = async () => {
        let res = await handleViewMethod("smallNode", [])
        setSmallNode(res)
    }

    const getSupNode = async () => {
        let res = await handleViewMethod("supNode", [])
        setSupNode(res)
    }
    const getBalanceOfSBDCoin = async () => {
        let balance = await handleViewMethod("getBalanceOfSbd", [])
        balance = parseInt(BigNumber(balance).dividedBy(10 ** SBDCoinDecimals).toString())
        if (balance > 0) {
            setSBDCoinBalance(balance.toString())
        }
    }

    const getTotalDonate = async () => {
        let res = await handleViewMethod("totalDonate", [])
        setTotalDonate(BigNumber(res).dividedBy(10 ** SBDCoinDecimals).toString())
    }
    const getsbdAmount = async (value) => {
        if (value > 0 || value == 0) {
            setInputValue(value)

            setExchangeAmount(value / salePrice)

            let receiveSbd = await handleViewMethod("receiveSbd", [value])
            let receiveSvt = await handleViewMethod("receiveSvt", [value])
            setReceiveSbd(receiveSbd)
            setReceiveSvt(receiveSvt)
        }
        let hasNode = false
        for (let i = 0; i < nftMapArr.length; i++) {
            if (value == (nftMapArr[i].num / 10 ** 18)) {
                setNodeName(nftMapArr[i].addr)
                hasNode = true
            }
        }
        if (!hasNode) {
        }


    }


    const exchangeSBD = async () => {
        if (inputValue > 0) {
            await handleDealMethod("purchase", [(BigNumber(inputValue).multipliedBy(10 ** ETHDecimals)).toFixed(0).toString()])
            getData()

            setTimeout(() => {
                getData()
            }, 2000)
        }
    }
    const handleRegister = async () => {

        let refAddr = ""
        if (myStatus.activeStatus && myRecommender) {
            refAddr = state.account
        } else {
            if (!form.getFieldValue().referralCode) {
                return
            }
            if (!state.api.utils.isAddress(form.getFieldValue().referralCode.toString().trim())) {
                return
            }

            refAddr = form.getFieldValue().referralCode.toString().trim()


        }
        await handleDealMethod("register", [refAddr.toString()])
        await getMyStatus()
        getAddressRecommender(state.account)
        setTimeout(() => {
            setIsShowRegister(false)
            getAddressRecommender(state.account)
        }, 3000)
    }


    const getIsAdmin = async () => {
        const isS = await handleViewMethod("checkAddrForAdminLevelTwo", [state.account])
        const isT = await handleViewMethod("checkAddrForAdminLevelThree", [state.account])


        setIsSecondAdmin(isS)
        setIsThreeAdmin(isT)

    }
    const getSalePrice = async () => {
        let res = await handleViewMethod("salePrice", [])
        setSalePriceV(BigNumber(res).dividedBy(1000).toString())
    }

    const CoinBalance = async () => {
        let res2 = await handleCoinViewMethod("balanceOf", "SBD", [state.account])
        setSBDBalance(BigNumber(res2).dividedBy(10 ** SBDCoinDecimals).toString())

        let res = await handleCoinViewMethod("balanceOf", "USDT", [state.account])
        setUSDTBalance(BigNumber(res).dividedBy(10 ** USDTDecimals).toString())

        let svt = await handleCoinViewMethod("balanceOf", "SVT", [state.account])
        setSVTBalance(BigNumber(svt).dividedBy(10 ** USDTDecimals).toString())
    }

    const getMyStatus = async () => {

        let activeStatus = await handleViewMethod("checkAddrForSupAccount", [state.account])
        let registerStatus = await handleViewMethod("isNotRegister", [state.account])
        setMyStatus({
            registerStatus,
            activeStatus
        })
    }
    const getActivateAccount = async () => {
        const refRes = await getAllRegisters(state.account)
        try {
            let refArr = refRes.data.allRegisters
            let myActiveArr = []


            refArr.forEach(refItem => {
                if (refItem.recommenders.toLowerCase() == state.account) {
                    if (!myActiveArr.includes(refItem._user)) {
                        myActiveArr.push(refItem._user)

                    }
                }
            })
            setActiveArr(myActiveArr)
        } catch (e) {
            console.log(e)
        }
    }

    const getData = async () => {
        try {
            let judgeRes = await judgeStatus(state)
            if (!judgeRes) {
                return
            }
            getMyTeam(state.account)
            getAllowance()
            getMyInviteCount()
            getAdmin()
            getIsAdmin()
            getTotalDonate()
            getBalanceOfSBDCoin()
            CoinBalance()
            getSalePrice()
            getMyStatus()
            // getUserBuyMax()
            await getAddressRecommender(state.account)

            await getInviteRate()
            await getInviteRecord()


            getSupNode()
            getSmallNode()
            getBigNode()
        } catch (e) {

        }
    }
    const onChangePage = async (page) => {
        await setCurPage(page)
    }


    const handleShowSizeChange = async (page, count) => {
        setPageCount(count)
    }

    const getMyInviteCount = async () => {
        const used = await handleViewMethod("supAccountUsedAmount", [state.account])
        const total = await handleViewMethod("supAccountTotalUsedAmount", [])
        getActivateAccount()
        setActiveUsedAmount(used)
        setActivateAccountUsedAmount(total)
    }

    const getAddressRecommender = async (address) => {
        const res = await getRecommender(address)
        console.log(res)
        if (res && res.data && res.data.allRegisters[0]) {
            const resArr = res.data.allRegisters
            setMyRecommender(resArr[resArr.length - 1].recommenders)
            setMyId(resArr[resArr.length - 1].Contract_id)
        }
    }
    const getUserBuyMax = async () => {
        const buyNum = await handleViewMethod("userTotalBuy", [state.account])
        setUserTotalBuy(BigNumber(buyNum).dividedBy(10 ** ETHDecimals).toString())
    }
    const getRefArr = async (address, myTeamArr, level) => {
        let refRes = await getAllRegisters(address)
        if (refRes.data && refRes.data.allRegisters && refRes.data.allRegisters.length > 0) {
            let refArr = refRes.data.allRegisters

            const realRefArr = []
            refArr.forEach(item => {
                let hasAddress = false;
                realRefArr.forEach(refItem => {
                    if (refItem._user == item._user) {
                        hasAddress = true
                    }
                })
                if (!hasAddress) {
                    realRefArr.push(item)
                }
            })
            refArr = realRefArr

            if (refArr[0]._user != address) {
                refArr.forEach(item => {
                    item.level = level
                })
                myTeamArr.push(...refArr)
            }

            level++
            if (level > 3) {
                return
            }


            for (let i = 0; i < refArr.length; i++) {
                if (refArr[i]._user != address) {
                    await getRefArr(refArr[i]._user, myTeamArr, level)
                }
            }
        }
        return myTeamArr

    }
    const getLevelInviteRate = async (address, recordTime, owner) => {
        for (let i = 0; i < inviteRecordArr.length; i++) {
            const item = inviteRecordArr[i]
            if (item.addr == address && (item.blockTimestamp == recordTime)) {

                if (item.recommender1.toString().toLowerCase() == owner.toString().toLowerCase()) {
                    return {
                        rate: item.rate1,
                    }
                }
                if (item.recommender2.toLowerCase() == owner.toLowerCase()) {
                    return {
                        rate: item.rate2,
                    }
                }
                if (item.recommender3.toLowerCase() == owner.toLowerCase()) {
                    return {
                        rate: item.rate3,
                    }
                }
                if (item.recommender4.toLowerCase() == owner.toLowerCase()) {
                    return {
                        rate: item.rate4,
                    }
                }
                if (item.recommender5.toLowerCase() == owner.toLowerCase()) {
                    return {
                        rate: item.rate5,
                    }
                }
            }
        }
        return 0

    }


    const getMyTeam = async (address) => {
        let res = await getDonateRecord()

        if (res.data && res.data.allRecords) {
            const myTeamArr = await getRefArr(address, [], 1)
            const allRecords = res.data.allRecords
            let myTeamRecord = [], myRecord = []
            for (let i = 0; i < allRecords.length; i++) {
                const record = allRecords[i]
                if (record.addr.toLowerCase() == address.toLowerCase()) {
                    myRecord.push(record)
                }
            }
            seMyRecords(myRecord)


            // count my team level number

            let levelRewardObj = {
                level1Total: 0,
                level2Total: 0,
                level3Total: 0,

                total: 0
            }
            // count my team level number
            for (let i = 0; i < myTeamArr.length; i++) {
                const item = myTeamArr[i]
                levelRewardObj.total++
                if (item.level == 1) {
                    levelRewardObj.level1Total++
                }
                if (item.level == 2) {
                    levelRewardObj.level2Total++
                }
                if (item.level == 3) {
                    levelRewardObj.level3Total++
                }


            }
            // operate reward and get record
            let totalETH = 0, totalFLM = 0

            for (let i = 0; i < myTeamArr.length; i++) {
                const item = myTeamArr[i]
                for (let j = 0; j < allRecords.length; j++) {
                    const record = allRecords[j]
                    if (item._user.toLowerCase() == record.addr.toLowerCase()) {
                        let rate = (await getLevelInviteRate(item._user, record.blockTimestamp, address)).rate / 100
                        if (!rate) {
                            rate = 0
                        }
                        const usdtIncome = BigNumber(record.usdtAmount).multipliedBy(rate).dividedBy(100).dividedBy(10 ** ETHDecimals).toString()

                        if (item.usdtIncome > 0) {
                            item.usdtIncome = BigNumber(item.usdtIncome).plus(usdtIncome)
                        } else {
                            item.usdtIncome = usdtIncome
                        }

                        item.no = record.no

                        if ((curLevel == item.level) || (curLevel == 0)) {
                            totalETH = BigNumber(totalETH).plus(usdtIncome)
                        }
                        if (item.level) {
                            record.level = item.level
                            record.rate = rate
                            myTeamRecord.push(record)
                        }
                    }


                }

            }
            let myTeamIncome = []
            myTeamArr.forEach(item => {
                if (item.usdtIncome > 0) {
                    myTeamIncome.push(item)
                }
            })
            myTeamRecord.sort((a, b) => {
                return BigNumber(b.no).comparedTo(a.no)
            })
            myTeamRecord.sort((a, b) => {
                return BigNumber(b.no).comparedTo(a.no)
            })
            setMyTeamRecord(myTeamRecord)
            setMyTeamArr(myTeamArr)
            setLevelCountObj(levelRewardObj)
            setRewardTotalFLM(totalFLM)
            setRewardTotalETH(totalETH)
        }

    }
    const getValidNumbers = async (NFTMapArr) => {
        try {
            let tempArr = [], addrArr = [], numArr = []


            for (let i = 0; i < 6; i++) {
                let num = await handleViewMethod("validNumbers", [i])
                let addr = await handleViewMethod("nftType", [num / 10 ** 18])
                addrArr.push({
                    label: NFTMapArr[addr],
                    value: addr,
                })
                numArr.push({
                    label: num / 10 ** 18,
                    value: num / 10 ** 18,
                })
                tempArr.push({
                    num, addr: NFTMapArr[addr]
                })
            }
            setNftNumbersArr(tempArr)
        } catch (e) {
            console.error(e)
        }

    }

    const getInviteRate = async () => {
        // const rateLength = await handleViewMethod("getInviteRate", [])
        let tempArr = []
        for (let i = 0; i < 2; i++) {
            const inviteRate = await handleViewMethod("inviteRate", [i])
            tempArr.push(inviteRate.toString())
        }
        await setInvArr(tempArr)
    }
    const getInviteRecord = async () => {
        const res = await getAllInvites()
        if (res && res.data) {
            setInvRecord(res.data.allInvites)
        }

    }

    // useEffect(async () => {
    //     let judgeRes = await judgeStatus(state)
    //     if (!judgeRes) {
    //         return
    //     }
    //     if (inviteRecordArr.length > 0) {
    //         getMyTeam(state.account)
    //     }
    // }, [inviteRateArr, inviteRecordArr]);
    useEffect(() => {
        if (inviteRecordArr.length > 0 && NFTMapArr.length == 4) {
            getMyTeam(curAddress)
        }
    }, [curLevel, NFTMapArr]);

    useEffect(() => {
        setCurAddress(state.account)
        getData()

        form.resetFields()
    }, [state.account]);

    useEffect(async () => {
        let judgeRes = await judgeStatus(state)
        if (!judgeRes) {
            return
        }
        if (smallNode && bigNode && supNode) {
            setNFTMAP({
                [smallNode.toLowerCase()]: "smallNode",
                [supNode.toLowerCase()]: "supNode",
                [bigNode.toLowerCase()]: "bigNode",
                "0x0000000000000000000000000000000000000000": "None"
            })

            const NFTMap = {
                [smallNode]: "smallNode",
                [supNode]: "supNode",
                [bigNode]: "bigNode",
                "0x0000000000000000000000000000000000000000": "None"
            }
            getCoinBalance()

            getValidNumbers(NFTMap)


        }
    }, [supNode, smallNode, bigNode])
    useEffect(() => {
        if (state.account && state.apiState == "READY") {
            setStatus(1)
        } else {
            setStatus(0)
        }
    }, [state.account, state.networkId, state.apiState])

    const addToken = async () => {
        await window.usdtIcon.request({
            method: 'wallet_watchAsset',
            params: {
                type: coinInfo.SBD.type,
                options: {
                    address: coinInfo.SBD.address,
                    symbol: coinInfo.SBD.symbol,
                    image: coinInfo.SBD.image,
                    decimals: coinInfo.SBD.decimals,
                },
            },
        });
    }
    const coinOptions = [
        {
            label: "200",
            value: '200',
        },
        {
            label: "500",
            value: '500',
        },
        {
            label: "1000",
            value: '1000',
        },
        {
            label: "2000",
            value: '2000',
        },

        {
            label: "5000",
            value: '5000',
        },


        {
            label: "10000",
            value: '10000',
        },
    ];
    const levelSelect = [
        {
            label: "All",
            value: '0',
        },
        {
            label: "Level 1",
            value: '1',
        },
        {
            label: "Level 2",
            value: '2',
        },
        {
            label: "Level 3",
            value: '3',
        },
        {
            label: "Level 4",
            value: '4',
        },
        {
            label: "Level 5",
            value: '5',
        },
    ];
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };
    return (

        <PurchaseStyle>
            <Modal className="signup-dialog" title={t("Sign up")} open={isShowRegister} onOk={handleRegister}
                   footer={[
                       <Button className="add-btn" type="primary" onClick={() => {
                           handleRegister()
                       }}>Submit</Button>

                   ]}
                   onCancel={() => {
                       setIsShowRegister(false)
                   }}>
                <Form form={form} name="control-hooks" className="form">
                    <strong className="input-title">{t("Wallet Address")}</strong>
                    <Form.Item
                        name="referralCode"
                        validateTrigger="onBlur"
                        validateFirst={true}
                        className="dialog-input"
                    >
                        <div className="temp-input">
                            {state.account}
                        </div>
                    </Form.Item>
                    <strong className="input-title">{t("Invitation Code")}</strong>

                    {myStatus.activeStatus && myRecommender && <div className="input-content">
                        {myRecommender}
                    </div>}
                    {!(myStatus.activeStatus && myRecommender) && <div>
                        <Form.Item
                            name="referralCode"
                            validateTrigger="onBlur"
                            validateFirst={true}
                            className="dialog-input"
                        >
                            <Input/>
                        </Form.Item>
                    </div>}


                </Form>
            </Modal>
            <div className="page-title">
                {isAdmin && <Button style={{
                    float: 'right',
                    background: '#373232',
                    margin: '0px 13px',
                    textAlign: 'center',
                    lineHeight: '28px',
                    width: "32px",
                    height: '32px',
                    border: '1px solid rgba(255, 255, 255, 0.15)',
                    borderRadius: '50%',
                }}
                                    onClick={() => {
                                        history("/SBDPurchaseManage")
                                    }}>
                    <img src={user3} style={{width: '22px', marginLeft: '-10px', marginTop: '-10px'}}/>
                </Button>}


            </div>
            <div className="header-nav">
                <div className="superdao-nav-list ">
                    <div className={"nav-item " + (activeNav == 1 ? "active" : "")} onClick={() => {
                        setActiveNav(1)
                    }}>
                        {t('Funding')}
                    </div>
                    <div className={"nav-item " + (activeNav == 2 ? "active" : "")} onClick={() => {
                        setActiveNav(2)
                    }}>
                        {t('Team')}
                    </div>
                    <div className={"nav-item " + (activeNav == 5 ? "active" : "")} onClick={() => {
                        setActiveNav(5)
                    }}>
                        {t('Withdraw')}
                    </div>
                    {myStatus.activeStatus &&
                        <div className={"nav-item " + (activeNav == 3 ? "active" : "")} onClick={() => {
                            setActiveNav(3)
                        }}>
                            {t('Super Accounts')}
                        </div>
                    }

                    {
                        (isSecondAdmin || isThreeAdmin) &&

                        <div className={"nav-item " + (activeNav == 4 ? "active" : "")} onClick={() => {
                            history("/SBDPurchaseUserManage")
                        }}>
                            Lv{isSecondAdmin ? 2 : ""}{isThreeAdmin ? 3 : ""}
                            &nbsp;Admin
                        </div>

                    }

                </div>

            </div>
            {activeNav == 5 && <WithdrawView/>}
            {activeNav == 1 && (
                <div className="part1">
                    <div className="panel-box">
                        <div className="panel-container">
                            <div className="flex-box status-header">
                                <div className="status-info">
                                    <div className="info-item">
                                        <div className={"dot" + (myStatus.registerStatus ? " active" : "")}></div>
                                        {/* {
                                            !myStatus.registerStatus && */}
                                        <p className='switchh'>
                                            <span>{t('Basic Accounts')}</span>
                                            <Switch checked={myStatus.registerStatus}/>

                                        </p>
                                        {/* }
                                        {
                                            myStatus.registerStatus && */}
                                        <p className='switchh'>
                                            <span>{t("Super Accounts")}</span>
                                            <Switch checked={myStatus.activeStatus}/>
                                        </p>
                                        {/* } */}
                                    </div>

                                </div>
                                {!myStatus.registerStatus && <div className="signUp-btn" onClick={() => {
                                    setIsShowRegister(true)
                                }}>
                                    {t("Sign Up")}
                                </div>}
                                {myStatus.registerStatus && <div className="id-box">
                                    ID: {myId}
                                </div>}
                            </div>


                            <div className="donation-box">
                                <div className="withdraw-info">
                                    <div className="flex-box" >
                                        <div className="info-item" style={{alignItems:"center"}}>
                                            <div className="name">
                                                {t("Price")}
                                            </div>
                                            <div className="value" style={{fontSize: "18px"}}>
                                                ${salePrice}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <Form form={form} name="control-hooks" className="form">
                                    <div className="withdraw-part">
                                        <div className="balance-box">

                                            <strong>{t("Your Pay")}</strong>

                                            <div className="right flex-box">
                                                <div className="name">
                                                    {t("Balance")}:
                                                </div>
                                                <div className="value">
                                                    <span>
                                                        {showNum(usdtBalance)}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <Form.Item
                                            name="amount"
                                            validateTrigger="onBlur"
                                            validateFirst={true}
                                        >
                                            <div className="input-box">
                                                <Select
                                                    value={inputValue}
                                                    onChange={(e) => {
                                                        getsbdAmount(e)
                                                    }}
                                                    style={{width: 200}}
                                                    options={coinOptions}
                                                    placeholder="0"
                                                    filterOption={(inputValue, option) =>
                                                        option.value.indexOf(inputValue) !== -1 &&
                                                        /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/.test(inputValue)
                                                    }
                                                />
                                                <div className="right-tip">
                                                    <img className="coin-icon" src={usdtIcon} alt=""/>
                                                    USDT
                                                </div>
                                            </div>
                                        </Form.Item>
                                    </div>
                                    <img className="down-icon" src={download} alt=""/>


                                    <div className="withdraw-part" style={{marginTop: '8px'}}>
                                        <div className="balance-box">
                                            <strong className="receive">
                                                {t("Your Receive SBD")}
                                            </strong>
                                            <div className="balance-box ">
                                                <div className="name">
                                                    Balance:
                                                </div>
                                                <div className="value">
                                                    <span>{showNum(sbdBalance)}</span>
                                                </div>
                                            </div>

                                        </div>
                                        <Form.Item

                                            validateTrigger="onBlur"
                                            validateFirst={true}

                                        >
                                            <div className="input-box">
                                                <div className="exchangeAmount">
                                                    {receiveSbd == 0 ? 0 : ""}
                                                    {receiveSbd > 0 ?
                                                        <span>{showNum(BigNumber(receiveSbd).minus(receiveSvt))}</span> : ""}
                                                </div>
                                                <div className="right-tip">
                                                    <img className="coin-icon" width={20} src={SBDCoinIcon} alt=""/>
                                                    SBD
                                                </div>
                                            </div>
                                        </Form.Item>
                                        <hr/>
                                        <div className="balance-box">
                                            <strong className="receive">
                                                {t("Your Lock SBD")}
                                            </strong>

                                        </div>
                                        <Form.Item
                                        >
                                            <div className="input-box">
                                                <div className="exchangeAmount">
                                                    <span>{showNum(receiveSvt)}</span>
                                                </div>
                                                <div className="right-tip">
                                                    <img className="coin-icon" width={20} src={SBDCoinIcon} alt=""/>
                                                    SBD
                                                </div>
                                            </div>
                                        </Form.Item>
                                        <hr/>
                                        <div className="balance-box">
                                            <strong className="receive">
                                                {t("Your Receive SVT")}
                                            </strong>
                                            <div className="balance-box ">
                                                <div className="name">
                                                    Balance:
                                                </div>
                                                <div className="value">
                                                    <span>{showNum(svtBalance)}</span>
                                                </div>
                                            </div>

                                        </div>
                                        <Form.Item>
                                            <div className="input-box">
                                                <div className="exchangeAmount">
                                                    <span>{showNum(receiveSvt)}</span>
                                                </div>
                                                <div className="right-tip">
                                                    <img className="coin-icon" width={20} src={SVTCoinIcon} alt=""/>
                                                    SVT

                                                </div>
                                            </div>
                                        </Form.Item>
                                        <hr/>
                                        {<div>
                                            <div className="balance-box">
                                                {nodeName == "smallNode" && <strong className="receive">
                                                    Your Receive NFT(Available: {smallBalance})
                                                </strong>}
                                                {nodeName == "bigNode" &&
                                                    <strong className="receive">
                                                        Your Receive NFT(Available: {bigBalance})
                                                    </strong>}
                                                {nodeName == "supNode" && <strong className="receive">
                                                    Your Receive NFT(Available: {supBalance})
                                                </strong>}
                                                {/*{BigNumber(inputValue).gt(1900)&& BigNumber(inputValue).lt(5000)&& <strong className="receive">*/}
                                                {/*    Your Receive NFT(Available: {smallBalance})*/}
                                                {/*</strong>}*/}
                                                {/*{BigNumber(inputValue).gt(4900) &&  BigNumber(inputValue).lt(10000) &&*/}
                                                {/*    <strong className="receive">*/}
                                                {/*        Your Receive NFT(Available: {bigBalance})*/}
                                                {/*    </strong>}*/}
                                                {/*{BigNumber(inputValue).gt(10000) && <strong className="receive">*/}
                                                {/*    Your Receive NFT(Available: {supBalance})*/}
                                                {/*</strong>}*/}
                                            </div>
                                            <Form.Item
                                                name="pid"
                                                validateTrigger="onBlur"
                                                validateFirst={true}

                                            >
                                                <div className="input-box">
                                                    {nodeName == "smallNode" &&
                                                        <strong className="node-img">
                                                            <img width={100} src={SmallNode} alt=""/>
                                                        </strong>}
                                                    {nodeName == "bigNode" &&
                                                        <strong className="node-img">
                                                            <img width={100} src={BigNode} alt=""/>
                                                        </strong>}
                                                    {nodeName == "supNode" && <strong className="node-img">
                                                        <img width={100} src={SuperNode} alt=""/>
                                                    </strong>}
                                                    {/*{BigNumber(inputValue).gt(1900) &&  BigNumber(inputValue).lt(5000)  &&*/}
                                                    {/*    <strong className="node-img">*/}
                                                    {/*        <img width={100} src={SmallNode} alt=""/>*/}
                                                    {/*    </strong>}*/}
                                                    {/*{BigNumber(inputValue).gt(4900) &&  BigNumber(inputValue).lt(10000) &&*/}
                                                    {/*    <strong className="node-img">*/}
                                                    {/*        <img width={100} src={BigNode} alt=""/>*/}
                                                    {/*    </strong>}*/}
                                                    {/*{BigNumber(inputValue).gt(9900) && <strong className="node-img">*/}
                                                    {/*    <img width={100} src={SuperNode} alt=""/>*/}
                                                    {/*</strong>}*/}
                                                </div>
                                            </Form.Item>
                                        </div>}
                                    </div>
                                    {/*not Regist*/}
                                    {!myStatus.registerStatus &&

                                        <Button onClick={() => {
                                            setIsShowRegister(true)
                                        }} type="primary" className="withdraw">
                                            {!myStatus.registerStatus && <span>Swap</span>}
                                        </Button>

                                    }
                                    {/*registed*/}
                                    {myStatus.registerStatus && <div>
                                        {status == 0 && <ConnectWallet className="connect-button"/>}
                                        {
                                            status == 1 && !inputValue &&
                                            <Button type="primary" className="withdraw">
                                                Enter an amount
                                            </Button>
                                        }
                                        {
                                            status == 1 && BigNumber(usdtBalance).lt(inputValue) &&
                                            <Button type="primary" className="withdraw">
                                                Balance not enough
                                            </Button>
                                        }
                                        {status == 1 && inputValue > 0 && BigNumber(allowance).lt(exchangeAmount) &&
                                            <Button type="primary" className="withdraw" onClick={() => {
                                                handleApprove()
                                            }}>Approve</Button>

                                        }
                                        {BigNumber(allowance).gt(exchangeAmount) &&
                                            status == 1 && inputValue > 0 && !BigNumber(usdtBalance).lt(inputValue) &&
                                            <Button type="primary" className="withdraw" onClick={() => {
                                                exchangeSBD()
                                            }}>
                                                {t("Swap")}
                                            </Button>
                                        }

                                    </div>

                                    }


                                </Form>
                            </div>
                        </div>
                    </div>
                    <div className="panel-box part2">
                        <div className="panel-container">
                            <div className="list-top-part">
                                <h1 className="panel-title">
                                    {t("My Funding Records")}
                                </h1>
                            </div>
                            <div className="superdao-list-box mywithdraw-list">
                                <div className='mylistheadert'>
                                    <div className="list-header ">
                                        <div className="col">
                                            {t("No.")}
                                        </div>
                                        <div className="col">
                                            {t("Address")}
                                        </div>
                                        <div className="col">
                                            {t("Cost USDT")}
                                        </div>
                                        <div className="col">
                                            {t("Price")}
                                        </div>
                                        <div className="col">
                                            {t("Receive SBD")}
                                        </div>
                                        <div className="col">
                                            {t("Lock SBD")}
                                        </div>
                                        <div className="col">
                                            {t("Receive SVT")}
                                        </div>
                                        <div className='col'>
                                            {t("Receive NFT")}
                                        </div>
                                        <div className="col">
                                            {t("Time(UTC)")}
                                        </div>
                                    </div>
                                    {myRecords.length == 0 ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/> :
                                        myRecords.map((item, index) => (
                                            index >= pageCount * (curPage - 1) && index < pageCount * curPage &&
                                            Row(item, index)
                                        ))}

                                </div>

                            </div>
                            <div className="pagination">
                                {
                                    recordNav == 1 && <Pagination current={curPage} showSizeChanger
                                                                  onShowSizeChange={handleShowSizeChange}
                                                                  onChange={onChangePage} total={total}
                                                                  defaultPageSize={pageCount}/>
                                }
                            </div>
                        </div>

                    </div>
                </div>
            )}

            {activeNav == 2 && (
                <div className="team-part">
                    <div className="panel-box">
                        <div className="panel-container">


                            <div className="team-count">
                                <div className="panel-title flex-title">
                                    {t("Team Rewards")}
                                    <div className="right flex-box">
                                        <div className="reward-item">
                                            <p>{t("USDT Total Income")}</p>
                                            <img width={20} src={usdtIcon} alt=""/>
                                            <span> {showNum(rewardTotalETH, 4)} USDT</span>
                                        </div>

                                    </div>
                                </div>
                                {state.isMobile &&
                                    <div className="in-line">

                                        <div className="info-item">
                                            <strong>{t("Total")}</strong>
                                            <span>{levelCountObj.total}</span>
                                        </div>
                                        <div className="info-item">
                                            <strong>{t("Level")}1</strong>
                                            <span>{levelCountObj.level1Total}</span>
                                        </div>
                                        <div className="info-item">
                                            <strong>{t("Level")}2</strong>
                                            <span>{levelCountObj.level2Total}</span>
                                        </div>
                                    </div>
                                }
                                {state.isMobile &&
                                    <div className="in-line">

                                        <div className="info-item">
                                            <strong>{t("Level")}3</strong>
                                            <span>{levelCountObj.level3Total}</span>
                                        </div>


                                    </div>
                                }
                                {!state.isMobile && <div className="in-line">

                                    <div className="info-item">
                                        <strong>Total</strong>
                                        <span>{levelCountObj.total}</span>
                                    </div>
                                    <div className="info-item">
                                        <strong>{t("Level")}1</strong>
                                        <span>{levelCountObj.level1Total}</span>
                                    </div>
                                    <div className="info-item">
                                        <strong>{t("Level")}2</strong>
                                        <span>{levelCountObj.level2Total}</span>
                                    </div>

                                    <div className="info-item">
                                        <strong>{t("Level")}3</strong>
                                        <span>{levelCountObj.level3Total}</span>
                                    </div>



                                </div>}

                                <div className="in-line filter-box" style={{justifyContent: "space-between"}}>
                                    <Select
                                        value={curLevel}
                                        onChange={(e) => {
                                            setCurLevel(e)
                                        }}

                                        options={levelSelect}
                                    />
                                    <form className="search-box">
                                        <Input allowClear value={searchData}
                                               onChange={handleSearchChange} placeholder="ID"/>
                                        <Button className="search-btn"
                                                style={{width: '45px', borderRadius: '45px', height: '40px'}}
                                                onClick={handleSearch} type="primary">
                                            <img src={search} style={{width: '25px', margin: '0px -10px'}}/>
                                        </Button>
                                    </form>
                                </div>
                            </div>

                            <div className="superdao-list-box team-list" style={{marginTop: "20px"}}>
                                <div className='listheadert'>
                                    <div className="list-header">
                                        <div className="col">
                                            Lv
                                        </div>
                                        <div className="col">
                                            {t("No.")}
                                        </div>
                                        <div className="col">
                                            ID
                                        </div>
                                        <div className="col">
                                            Address
                                        </div>
                                        <div className="col">
                                            USDT Rewards
                                        </div>


                                    </div>
                                    {
                                        myTeam.length == 0 ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/> :
                                            myTeam.map((item, index) => (
                                                index >= pageCount * (curPage - 1) && index < pageCount * curPage &&
                                                (curLevel == 0 || curLevel == item.level) &&
                                                <div className="list-item "
                                                     key={index}
                                                >
                                                    <div className="col">
                                                        {item.level}
                                                    </div>
                                                    <div className="col no">
                                                        {index + 1}
                                                    </div>
                                                    <div className="col id" style={{cursor: "pointer"}} onClick={() => {
                                                        setSearchData(item.Contract_id);
                                                        handleSearch()
                                                    }}>
                                                        {(item.Contract_id || item.Contract_id == 0) ? item.Contract_id : "--"}
                                                    </div>
                                                    <div className="col address">
                                                        <a target="_blank"
                                                           href={develop.ethScan + "/address/" + item._user}> {formatAddress(item._user)} </a>
                                                    </div>
                                                    <div className="col flex-box">
                                                        <img width={24} height={24} style={{margin: "3px"}}
                                                             src={usdtIcon}
                                                             alt=""/>
                                                        {showNum(item.usdtIncome, 6)}
                                                    </div>
                                                </div>
                                            ))
                                    }
                                </div>

                            </div>
                            <div className="pagination">
                                {
                                    recordNav == 1 && <Pagination current={curPage} showSizeChanger
                                                                  onShowSizeChange={handleShowSizeChange}
                                                                  onChange={onChangePage} total={total}
                                                                  defaultPageSize={pageCount}/>
                                }
                            </div>

                        </div>

                        <div className="panel-container">
                            <h1 className="panel-title">
                                {t("Team Funding List")}
                            </h1>
                            <div className="superdao-list-box withdraw-list">
                                <div className='listheadert'>
                                    <div className="list-header ">
                                        <div className="col">
                                            {t("No.")}
                                        </div>

                                        <div className="col">
                                            Lv
                                        </div>
                                        <div className="col">
                                            Percentage
                                        </div>
                                        <div className="col">
                                            ID
                                        </div>
                                        <div className="col">
                                            Address
                                        </div>
                                        <div className="col">
                                            Price
                                        </div>
                                        <div className="col">
                                            Cost
                                        </div>

                                        <div className="col">
                                            Receive SBD
                                        </div>

                                        <div className="col">
                                            Lock SBD
                                        </div>
                                        <div className="col">
                                            Receive SVT
                                        </div>

                                        <div className="col">
                                            Receive NFT
                                        </div>

                                        <div className="col">
                                             {t("Time(UTC)")}
                                        </div>
                                    </div>
                                    {
                                        myTeamRecord.length == 0 ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/> :
                                            myTeamRecord.map((item, index) => (
                                                index >= pageCount * (curPage - 1) && index < pageCount * curPage &&
                                                <div className="list-item"
                                                     key={index}
                                                >
                                                    <div className="col no">
                                                        {parseInt(item.no) + 1}
                                                    </div>

                                                    <div className="col">
                                                        {item.level}
                                                    </div>
                                                    <div className="col">
                                                        {item.rate > 0 &&
                                                            <div className="item">
                                                                {item.rate}%
                                                            </div>
                                                        }

                                                    </div>


                                                    <div className='col id'>
                                                        {parseInt(item.no) + 1}
                                                    </div>
                                                    <div className="col address">
                                                        <a target="_blank"
                                                           href={develop.ethScan + "/address/" + item.addr}> {formatAddress(item.addr)}
                                                        </a>
                                                    </div>
                                                    <div className="col price">
                                                        <img width={18} height={18} style={{marginRight: "3px"}}
                                                             src={USDTIcon}
                                                             alt=""/>
                                                        {showNum(BigNumber(item.usdtAmount).dividedBy(BigNumber(item.sbdAmount).plus(item.lockSbd)), 4)}
                                                    </div>
                                                    <div className="col cost">
                                                        <img width={18} height={18} style={{marginRight: "3px"}}
                                                             src={USDTIcon}
                                                             alt=""/>
                                                        {showNum(item.usdtAmount / 10 ** USDTDecimals, 3)}
                                                    </div>
                                                    <div className="col receive">
                                                        {showNum(item.sbdAmount / 10 ** ETHDecimals, 3)}
                                                    </div>
                                                    <div className="col lock">
                                                        {showNum(item.lockSbd / 10 ** SBDCoinDecimals, 1)}
                                                    </div>
                                                    <div className="col lock">
                                                        {showNum(item.lockSbd / 10 ** SBDCoinDecimals, 1)}
                                                    </div>
                                                    <div className="col receive-nft">
                                                        <a target="_blank"
                                                           href={develop.ethScan + '/address/' + item.receiveNft}>{NFTMapArr[item.receiveNft]}</a>
                                                    </div>
                                                    <div className="col">
                                                        {dealTime(item.blockTimestamp)}
                                                    </div>
                                                </div>
                                            ))
                                    }

                                </div>
                            </div>
                            <div className="pagination">
                                {
                                    <Pagination current={curPage} showSizeChanger
                                                onShowSizeChange={handleShowSizeChange}
                                                onChange={onChangePage} total={total}
                                                defaultPageSize={pageCount}/>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            )}


            {activeNav == 3 && (
                <div className="panel-box">
                    <div className="panel-container">
                        <div className="active-content-box">

                            <div className="content-item-box">
                                <div className="flex-box">
                                    <div className="item">
                                        <div className="name">
                                            lnvitation Code
                                        </div>

                                        {myStatus.activeStatus && myRecommender && <div className="value">
                                            {formatAddress(myRecommender)}
                                        </div>}
                                        {!(myStatus.activeStatus && myRecommender) && <div className="value">-</div>}


                                    </div>

                                </div>
                                <div className="flex-box  content-list">
                                    <div className="content-item">
                                        <div className="name">
                                            Total times
                                        </div>
                                        <div className="value">
                                            {activateAccountUsedAmount}
                                        </div>
                                    </div>
                                    <div className="content-item">
                                        <div className="name">
                                            Used times
                                        </div>
                                        <div className="value">
                                            {activeUsedAmount}
                                        </div>
                                    </div>
                                    <div className="content-item">
                                        <div className="name">
                                            Available times
                                        </div>
                                        <div className="value">
                                            {BigNumber(activateAccountUsedAmount).minus(activeUsedAmount).toString()}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="superdao-list-box admin3-list">
                            <div className="active-list-row list-header">
                                <div className="col">
                                    {t("No.")}
                                </div>
                                <div className="col ">
                                    ID
                                </div>
                                <div className="col ">
                                    Address
                                </div>

                            </div>

                            {
                                activeArr.length == 0 ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/> :
                                    activeArr.map((item, index) => (
                                            <div className="list-item active-list-row"
                                                 key={index}
                                            >
                                                <div className='col'>

                                                </div>
                                                <div className="col id">
                                                    {index + 1}
                                                </div>

                                                <div className="col address">
                                                    {state.isMobile &&
                                                        <a href={develop.ethScan + "/address/" + item} target="_blank">
                                                            {formatAddress(item)}
                                                        </a>
                                                    }
                                                    {!state.isMobile &&
                                                        <a href={develop.ethScan + "/address/" + item} target="_blank">
                                                            {item}
                                                        </a>
                                                    }
                                                </div>


                                            </div>
                                        )
                                    )
                            }
                            {/* <div className='addsbt'>Add</div> */}

                        </div>
                        <div className="pagination">
                            {
                                recordNav == 1 && <Pagination current={curPage} showSizeChanger
                                                              onShowSizeChange={handleShowSizeChange}
                                                              onChange={onChangePage} total={total}
                                                              defaultPageSize={pageCount}/>
                            }
                        </div>
                    </div>
                </div>
            )

            }
        </PurchaseStyle>
    )
}
export default OGPoolPublic
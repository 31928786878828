import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { useConnect } from "../api/contracts";

import Logo from "../imgs/logo.png"
import AppButton from "../imgs/btn.png"

import Img1 from "../imgs/img1.png"
import Img2 from "../imgs/img2.png"
import Img3 from "../imgs/img3.png"
import Twitter from "../imgs/twitter.png"
import Telegram from "../imgs/telegram.png"
import Qr1 from "../imgs/qr1.png"
import Qr2 from "../imgs/qr2.png"
import Qr3 from "../imgs/qr3.png"
import ContentImg from "../imgs/content-img.png"
import ContentImg2 from "../imgs/content-img2.png"
import wechat from "../imgs/wechat.png"
import qq from "../imgs/qq.png"
import Bg from "../imgs/backg.png"
import backpic from "../imgs/background.png"
import BitTime from "../component/BitTime";
import HomeStyle from "./HomeStyle";

const Home = (props) => {
    const history = useNavigate();


    const goPage = (url) => {
        history(url);
    }
    useEffect(() => {


    }, []);
    return (
        <HomeStyle>
            <div className="page1">

                <header>

                    <div className="header-content" onClick={goPage('/SBDPurchase')}>
                        <img src={Logo} alt="" className="logo" />
                        <img src={AppButton} onClick={() => {
                            history("/SBDPurchase")
                        }} style={{ cursor: "pointer" }} alt="" className="button" />
                    </div>
                </header>
                <img className='phone-pic' src={backpic} />
                <div className="container">
                    <h1>
                        SuperBit DAO
                    </h1>
                    <p>
                        Promoting the spirit of Bitcoin, spreading the concept of encryption, and enhancing personal
                        awareness.
                    </p>
                    <img src={AppButton} onClick={() => {
                        history("/SBDPurchase")
                    }} style={{ cursor: "pointer" }} alt="" className="button" />
                </div>

            </div>
            <div className="page2">
                <h2>SuperBit DAO Culture</h2>
                <div className="container text-center">
                    <div className="row">
                        <div className="col">
                            <div className="item-box">
                                <img src={Img1} alt="" className="icon" />
                                <h3>Mission</h3>
                                <p>
                                    To promote the spirit of Bitcoin, spread the concept of encryption, and enhance
                                    personal awareness.
                                </p>
                            </div>
                        </div>
                        <div className="col">
                            <div className="item-box">
                                <img src={Img2} alt="" className="icon" />
                                <h3>Vision</h3>
                                <p>
                                    To create a global encrypted DAO community of one million people.
                                </p>
                            </div>

                        </div>
                        <div className="col">
                            <div className="item-box">
                                <img src={Img3} alt="" className="icon" />
                                <h3>Values</h3>
                                <p>
                                    co-creation, co-building, co-governance, and co- ownership
                                </p>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="container content2">
                    <div className="row ">
                        <div className="col">
                            <div className="left">
                                <h2>
                                    Three-Coin Linkage
                                    Mechanism
                                </h2>

                                <div className="phone-pic2">
                                    <img src={ContentImg} alt="" />
                                </div>

                                <div className="list-box">
                                    <div className="list-item">
                                        <div className="name">
                                            SBD
                                        </div>
                                        <p>
                                            SBD is the ecological token of the DAO. The entire DAO ecosystem revolves
                                            around
                                            SBD, representing the core ownership rights of the DAO.
                                        </p>
                                    </div>

                                    <div className="list-item">
                                        <div className="name">
                                            SRT
                                        </div>
                                        <p>
                                            SRT is the reward token of the DAO, specifically designated for ecological
                                            rewards. SRT can be exchanged for SBD, 20% can be used as spot assets,while
                                            the
                                            remaining 80% is unlocked by block, and it will be released linearly in
                                            about 1
                                            years.
                                        </p>
                                    </div>
                                    <div className="list-item">
                                        <div className="name">
                                            SVT
                                        </div>
                                        <p>
                                            SVT is the voting token of the DAO, representing the locking status of SBD.
                                            SVT
                                            will be automatically minted when DAO members locked.Only those who possess
                                            SVT
                                            have the voting rights within the DAO, and voting and decision-making rights
                                            belong to members who are long-term supporters of the DAO.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="right">
                                <img src={ContentImg} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container content3">
                    <div className="row ">
                        <div className="col">
                            <div className="left">
                                <img src={ContentImg2} alt="" />
                            </div>
                        </div>
                        <div className="col right">
                            <div className="">
                                <h2 style={{ textAlign: "right" }}>
                                    SuperBit DAO
                                    <br /> Ecosystem Token—SBD
                                </h2>
                                <div className="phone-pic3">
                                    <img src={ContentImg2} alt="" />
                                </div>
                                <p style={{ textAlign: "right" }} className="">
                                    SuperBit DAO is a decentralized autonomous organization (DAO) based on the BNB
                                    network.At
                                    its core, SuperBit DAO operates on a community-driven mechanism for development and
                                    governance. We will issue the ecological token SBD to the global DAO community, and
                                    it will
                                    be collectively governed by the holders of SBD within SuperBit DAO.Every holder of
                                    SBD is an
                                    owner of SuperBit DAO, possessing a sense of ownership and participating in the
                                    management
                                    and governance of the DAO through governance voting.
                                </p>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="container content4">
                    <div className="row ">
                        <div className="col">
                            <div className="left">
                                <h3>
                                    The fundamental mechanism of the public offering system.
                               
                                </h3>
                                <p>
                                    All invitation relationships are based on the BNB network through smart contracts
                                    that are automatically generated, bound, and verified, ensuring genuine openness,
                                    fairness, and transparency within the SuperBit DAO community.
                                </p>
                            </div>
                        </div>
                        <div className="col right-bor">
                            <div className='border-bott'>

                            </div>
                            <div className="right">
                                <p style={{ textAlign: "justify" }}>
                                    SuperBit DAO has two membership levels: basic members, who can register by entering
                                    an invitation code; and Super members, who become members by subscribing to the
                                    original SBD shares.Only Super members have the privilege to refer new members, with
                                    each Super member being able to refer a maximum of 50 basic members.
                                </p>
                            </div>
                        </div>

                    </div>
                </div>


            </div>
            <div className="page3">
                <div className="container content5">
                    <h2>SuperBit DAO Node Mechanism</h2>
                    <p>SuperBit DAO implements a node mechanism, currently divided into three types: Super Nodes, Full
                        Nodes, Light Nodes. Each type of node possesses a corresponding Node NFT representing its node
                        identity, and having a Node NFT grants entitlement to the corresponding node's benefits.</p>
                    <div className="row  text-center">
                        <div className="col">
                            <div className="item-box">
                                <h3>Super Nodes</h3>
                                <p>
                                    Subscribing for 10,000U in a single transaction qualifies you to become a SuperBit
                                    Community Super node, allowing you to mint one Super Node NFT. This offer is limited
                                    to the first 100 subscribers.
                                </p>
                            </div>
                        </div>
                        <div className="col">
                            <div className="item-box">
                                <h3>Full Nodes</h3>
                                <p>
                                    Subscribing 5000U, 6000U, 7000U, 8000U, or 9000U in a single purchase can qualify
                                    you as a SuperBit community Full Node. Allowing you to mint a Full Node NFT. This
                                    offer is limited to the first 300 subscribers.
                                </p>
                            </div>

                        </div>
                        <div className="col">
                            <div className="item-box">
                                <h3>Light Nodes</h3>
                                <p>
                                    Subscribing 2000U, 3000U, or 4000U in a single purchase can qualify you as a
                                    SuperBit community Light Node. Allowing you to mint a Light Node NFT. This offer is
                                    limited to the first 900 subscribers.
                                </p>
                            </div>

                        </div>
                    </div>

                </div>

            </div>
            <div className="page4">
                <div className="container">
                    <h2>
                        Social
                    </h2>
                    <div className="row">

                        <div className="col">
                            <img src={Qr1} alt="" className="qr" />
                            <img src={wechat} alt="" className="icon" />
                        </div>
                        <div className="col">
                            <img src={Qr2} alt="" className="qr" />
                            <img src={qq} alt="" className="icon" />
                        </div>
                        <div className="col">
                            <img src={Qr3} alt="" className="qr" />
                            <img src={qq} alt="" className="icon" />
                        </div>
                        <img className='tel' src={Telegram} />
                    </div>
                    <div className="phone-row">
                        <div className="col">
                            <img src={Qr2} alt="" className="qr" />
                            <img src={qq} alt="" className="icon" />
                        </div>
                        <div className="col">
                            <img src={Qr1} alt="" className="qr" />
                            <img src={wechat} alt="" className="icon" />
                        </div>

                        <div className="col">
                            <img src={Qr3} alt="" className="qr" />
                            <img src={qq} alt="" className="icon" />
                        </div>
                        
                    </div>
                   <div className='bottom-round'></div>
                </div>
            </div>
            <footer>
                <div className="footer-content">
                    <div className="left">
                        <img src={Logo} alt="" className="logo" />
                        <p>
                            Promoting the spirit of Bitcoin, spreading the concept
                            of encryption, and enhancing personal awareness.
                        </p>
                    </div>
                    <div className="right">
                        <div className="links">
                            <div className="link">
                                FAQ
                            </div>
                            <div className="link">
                                About
                            </div>
                            <div className="link">
                                Docs
                            </div>
                        </div>
                        <div className="link-icons">
                            <a href="https://twitter.com/Superbitdao" target="_blank">
                                <img src={Twitter} alt="" className="link-icon" />
                            </a>
                            <a href="https://t.me/Superbitdao" target="_blank">
                                <img src={Telegram} alt="" className="link-icon" />

                            </a>
                        </div>
                        <div className="copyright">
                            All rights reserved@jadoo.co
                        </div>
                    </div>


                </div>

            </footer>
        </HomeStyle>
    )
}
export default Home

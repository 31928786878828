import {createGlobalStyle} from "styled-components";
const AntdOverride = createGlobalStyle`
  @primary-color: #FFC02C !important; // 全局主色
  @link-color: #FFC02C; // 链接色
  @success-color: #52c41a; // 成功色
  @warning-color: #faad14; // 警告色
  @error-color: #f5222d; // 错误色
  @font-size-base: 14px; // 主字号
  @heading-color: rgba(0, 0, 0, 0.85); // 标题色
  @text-color: rgba(255, 255, 255, 0.65); // 主文本色
  @text-color-secondary: rgba(255, 255, 255, 0.45); // 次文本色
  @disabled-color: rgba(255, 255, 255, 0.25); // 失效色
  @border-radius-base: 2px; // 组件/浮层圆角
  @border-color-base: #d9d9d9; // 边框色
  @box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
  0 9px 28px 8px rgba(0, 0, 0, 0.05); // 浮层阴影

  .signUp-btn {
    background: linear-gradient(229deg, #DAB163 0%, #CC8A35 100%);
    border-radius: 10px 10px 10px 10px;
  }


  .ant-btn-primary {
    background: linear-gradient(229deg, #DAB163 0%, #CC8A35 100%);
    border-radius: 10px 10px 10px 10px;

    border: none;
    position: relative;

    &:hover {
      color: #1B1B1B;
      background: linear-gradient(229deg, #DAB163 0%, #CC8A35 100%);
    }

    &:focus {
      color: #1B1B1B;
      background: linear-gradient(229deg, #DAB163 0%, #CC8A35 100%);
    }

    &:active {
      background: linear-gradient(229deg, #DAB163 0%, #CC8A35 100%);
    }

    ::after {
      content: '';
      position: absolute;
      /* background: linear-gradient(229deg, #DAB163 0%, #CC8A35 100%);
            width: 80%; */
      left: 10%;
      height: 10%;
      bottom: -10%;
      filter: blur(10px);
    }
  }

  .ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub {
    background: #201414;
  }

  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: #150D0D;
    background: linear-gradient(320deg, #DD3642 0%, #FFC02C 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .ant-menu-dark .ant-menu-inline.ant-menu-sub {
    background: #150D0D;
  }

  .ant-message-notice {

    .ant-message-notice-content {
      background: rgba(0, 0, 0, 0.9);
      border-radius: 20px;
      max-width: 600px;
      padding: 2em 3em;
      color: #FFFFFF;

      .ant-message-custom-content {
        display: flex;
        flex-direction: column;
        align-items: center;

        .anticon {
          font-size: 22px;
        }

        span {
          font-size: 16px;
        }
      }

    }

    &:nth-child(1) {
      margin-top: 20vh;
    }
  }

  .ant-radio-inner::after {
    background: linear-gradient(320deg, #DD3642 0%, #FFC02C 100%);
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: #FFC02C;
  }

  .ant-switch-checked {
    background: #c29322;;
  }

  .ant-form-item {
    color: #FFFFFF;
  }

  .ant-input, .ant-form-item-control-input, .ant-select-selector, .ant-input-status-error, .ant-input-affix-wrapper-status-error, .ant-input-affix-wrapper {
    border-radius: 10px;
    width: 100%;

    &:focus {
      border-color: rgba(255, 255, 255, 0.10) !important;
    }

    &:hover {
      border-color: rgba(255, 255, 255, 0.10) !important;
    }

    &:active {
      border-color: rgba(255, 255, 255, 0.10) !important;
    }

    &::selection {
      background: rgba(255, 255, 255, 0.10) !important;
    }
  }

  .ant-input-affix-wrapper-focus {
    border-color: rgba(255, 255, 255, 0.10) !important;
  }

  /* input */

  .ant-input-number {
    width: 100%;
    border-radius: 10px;
  }

  .ant-select {
    width: 200px;
  }

  /* reset  pagination */
  .ant-pagination-options {
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      background: rgb(28, 28, 28) !important;
      border: 1px solid rgb(67, 67, 67);
      cursor: pointer;
      border-radius: 5px;
    }
  }

  .ant-pagination {
    margin-top: 20px;

    .ant-pagination-item-active:hover a {
      color: #FF8D4D;
    }

    .ant-pagination-item-active {
      border-color: #FF8D4D;

      a {
        color: #FF8D4D;
      }
    }

    .ant-pagination-item-link {
      color: hsla(0, 0%, 100%, .3);
      border-color: #434343;
      cursor: pointer;
      border-radius: 5px;
    }
  }

  .ant-pagination-item-link, .ant-pagination-item {
    background: #1C1C1C !important;
    border-radius: 5px;
  }

  //ant step
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background: #FF8D4D;
    border-color: #FF8D4D;

  }

  .ant-steps-item-finish .ant-steps-item-icon {
    border-color: #FF8D4D;


    svg {
      fill: #FF8D4D;
    }
  }

  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail:after {
    background-color: #FF8D4D;
  }


  .ant-modal {
    width: 35% !important;
    border-radius: 20px 20px 20px 20px;
  }

  .id-box {
    display: flex;
    justify-content: space-between;
    margin-top: 0.5em;

    font-family: Roboto-Bold, Roboto;
    font-weight: bold;
    color: #FFFFFF;

    .id {
      font-size: 16px;

    }

    .number {
      font-size: 16px;
    }
  }

  .max-box {
    .input-box {
      background-color: rgb(39, 39, 39);
      border-radius: 10px;
      border: 1px solid rgba(255, 255, 255, 0.1);
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        background: rgb(39, 39, 39) !important;
        border: 0;
      }

      .ant-input {
        font-size: 26px;
        font-weight: 900;
        line-height: 30px;
        height: 50px;
      }
    }
  }

  .nft-balance {
    width: 100%;
    margin-top: 1em;
    display: flex;
    justify-content: space-between;

    .name {
      font-size: 16px;
      font-family: Roboto-Bold, Roboto;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 19px;
    }

    .modal-value {
      font-size: 16px;
      font-family: Roboto-Bold, Roboto;
      font-weight: bold;
      color: #8A8080;
      line-height: 19px;
    }
  }

  .max-btn {
    width: 85px;
    height: 48px;
    text-align: center;
    line-height: 48px;
    background: linear-gradient(229deg, #DAB163 0%, #CC8A35 100%);
    border-radius: 10px 10px 10px 10px;
    cursor: pointer;
    font-size: 18px;
    font-family: Roboto-Bold, Roboto;
    font-weight: bold;

    color: #1A1414;
  }

  .lock-btn {
    background: linear-gradient(229deg, rgb(218, 177, 99) 0%, rgb(204, 138, 53) 100%);
    border-radius: 10px 10px 10px 10px;
    margin: 1em 0;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    font-size: 18px;
    font-family: Roboto-SemiBold, Roboto;
    font-weight: bold;
    color: #1A1414;
    line-height: 24px;
    align-items: center;

    cursor: pointer;

  }


  .search-box {
    display: flex;
    align-items: center;
    background: none;
    border-radius: 50px;
    border: none;
    padding: 2px;

    .ant-input-affix-wrapper {
      line-height: 2;
      border-radius: 10px;
      font-size: 16px;

      .ant-input {
        background: none;
        width: 100%;
      }
    }

    .ant-select-selector {
      background: rgb(39, 39, 39);
      border-radius: 10px;
      display: flex;
      align-items: center;
   
    }

    .search-btn {
      margin-left: 10px;
      border-radius: 10px;
      width: 30%;
      height: 40px;
      font-size: 18px;
      font-family: Roboto-Bold, Roboto;
      font-weight: bold;
      color: #1A1414;
    }
  }

  .ant-modal-content {

    background-color: #1C1C1C;
    border-radius: 20px 20px 20px 20px;
    opacity: 1;
    border: 1px solid rgba(255, 255, 255, 0.1);

    .ant-modal-header {
      background: none;
      border-bottom: none;

    }

    .ant-modal-body {
      p {
        font-size: 18px;
        color: #FFFFFF;
      }

      .value {
        width: 100%;
        height: 40px;
        margin: 10px 0px;
        background: #272727;
        border-radius: 8px 8px 8px 8px;
        border: 1px solid rgba(255, 255, 255, 0.1);
        text-align: center;
        line-height: 35px;
        font-size: 18px;
        color: #FFFFFF;
      }

      .ant-form-item-label > label {
        font-size: 18px;
      }

      .ant-form-item-control .ant-form-item-control-input .ant-form-item-control-input-content {
        .input-box {
          .ant-input {
            background: #272727;
            border-radius: 8px 8px 8px 8px;
            border: 1px solid rgba(255, 255, 255, 0.1);
            font-size: 18px;
          }
        }
      }

      .ant-form .icon-box {
        width: 20%;
        display: flex;
        justify-content: space-between;
        margin: 0px auto;

        img {
          width: 30px;
          height: 30px;
          margin-top: 15px;
          cursor: pointer;
        }
      }

    }

    .ant-modal-close-x {
      margin: 14px 6px 0 0;

      .anticon-close {
        border-radius: 50%;
        border: 1px solid #FFFFFF;
        padding: 3px;
      }

      svg {
        position: relative;
        transform: translateX(-8000px);
        filter: drop-shadow(#FFFFFF 8000px 0);
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        width: 20px;
        opacity: 1;
        height: 20px;
      }
    }

    .ant-modal-title {
      text-align: center;
      padding: 10px 0;
      font-size: 24px;
      font-family: Roboto-SemiBold, Roboto;
      font-weight: 600;
      color: #FFFFFF;
    }

    .input-title {
      font-size: 20px;
      font-family: Roboto-Bold, Roboto;
      font-weight: bold;
      color: #8A8080;
      line-height: 24px;
    }

    .input-content {
      padding: 0 20px;
      width: 100%;
    }

    .dialog-input, .input-content {
      margin-top: 10px;
      height: 61px;
      background: #272727;
      border-radius: 8px 8px 8px 8px;
      border: 1px solid rgba(255, 255, 255, 0.1);
      font-size: 18px;
      overflow: hidden;
      display: flex;

      .ant-row {
        width: 100%;
      }

      align-items: center;

      .temp-input {
        padding: 0 20px;
        font-size: 18px;
      }

      .ant-form-item-control-input {
        background: none;
        height: 100%;

      }

      .ant-input {
        background: #272727;
        border-radius: 8px 8px 8px 8px;
        border: 1px solid rgba(255, 255, 255, 0.1);
        height: 60px;
        border-radius: 10px;
        border: none;
        padding: 0 20px;
        font-size: 18px;
        color: #FFFFFF;
      }
    }

    .ant-modal-footer {
      padding-bottom: 30px;
      padding: 10px 24px 30px 24px;
      display: flex;
      justify-content: center;
      border: none;

      .ant-btn {
        flex-grow: 1;
        height: 50px;
        font-size: 20px;
        font-family: Roboto-Bold, Roboto;
        font-weight: bold;
        color: #FFAE4E;
        line-height: 24px;
        width: 66px;
        border-radius: 10px;
        border: 1px solid;

      }

      .ant-btn-primary {
        color: #1B1B1B;
        border-radius: 10px;
      }
    }
  }

  .ant-modal-body {

    .up-list-box {
      margin: 1em 0;
      background: rgba(28, 28, 28, 1);
      border: 1px solid rgba(255, 255, 255, 0.15);
      border-radius: 20px 20px 20px 20px;

      .up-header {
        display: flex;
        padding: 10px 1.8em;
        border-bottom: 1px solid rgba(234, 234, 234, 0.10);
      }

      .up-item, .up-header {
        justify-content: flex-start;

        .col {
          text-align: left;
          font-size: 16px;

          &:nth-child(1) {
            margin-left: 10px;
            width: 20%;
          }

          &:nth-child(2) {
            width: 70%;
            margin-left: 10px;
          }
        }
      }

      .up-item:last-child {
        border-bottom: none;
      }

      .up-item {
        padding: 10px 2em;
        color: #FFFFFF;
        margin: 0 auto;
        border-radius: 0px;
        border-bottom: 1px solid rgba(234, 234, 234, 0.10);;

        &:nth-child(even) {

          background: rgba(62, 62, 62, 1);
        }


        .col {
          overflow: hidden;
          padding-left: 0%;

        }

        .address {
          a {
            color: #CD9E57;
          }
        }
      }
    }

    .pagination {
      text-align: center;

      .ant-pagination {
        margin: 10px auto;
      }
    }
  }

  @media screen and (max-width: 1440px) {
    .id-box {
      .id {
        font-size: 15px;
      }

      .number {
        font-size: 15px;
      }
    }

    .ant-modal {

      max-width: calc(100vw - 16px);
      width: 42% !important;
    }

    .search-box {
      .ant-input-affix-wrapper {
        font-size: 15px;
      }

      .search-btn {
        font-size: 16px;
      }
    }

    .ant-modal-content {
      .ant-modal-header {
        padding-top: 16px;
        padding-bottom: 0px;
      }

      .ant-modal-body {
        padding: 0px 25px;

        p {
          font-size: 16px;
          color: #FFFFFF;
        }

        .value {
          font-size: 16px;
        }
      }

      .ant-modal-title {
        font-size: 18px;

      }

      .input-title {
        font-size: 15px;
      }

      .dialog-input, .input-content {
        height: 40px;
        font-size: 15px;

        .temp-input {
          font-size: 15px;

        }

        .ant-input {
          font-size: 15px;

        }
      }

      .ant-modal-footer {
        .ant-btn {
          font-size: 15px;
          height: 40px;
        }
      }
    }

    .ant-modal-body {
      .up-list-box {
        .up-header {
          font-size: 15px;
        }

        .up-item, .up-header {
          .col {
            font-size: 15px;

            &:nth-child(1) {
              margin-left: 10px;
              width: 20%;
            }

            &:nth-child(2) {
              width: 75%;
              margin-left: 10px;
            }
          }
        }
      }
    }

    .nft-balance {
      .name {
        font-size: 14px;
      }

      .value {
        font-size: 14px;

      }
    }

    .max-btn {
      width: 80px;
      height: 48px;
      line-height: 48px;
      font-size: 16px;
    }

    .lock-btn {
      height: 45px;
      font-size: 16px;
    }

    .max-box .input-box .ant-input {
      font-size: 20px;
    }

  }

  @media screen and (max-width: 450px) {
    .ant-modal {
      margin: 35% auto
      max-width: calc(97vw - 16px);
      width: 425px !important;
    }

    .id-box {
      .id {
        font-size: 15px;
      }

      .number {
        font-size: 15px;
      }
    }


    .nft-balance {

    }

    .max-btn {
      width: 80px;
      height: 46px;
      line-height: 46px;
      font-size: 15px;
    }

    .lock-btn {
      height: 40px;
      font-size: 15px;
    }

    .search-box {
      .ant-input-affix-wrapper {
        font-size: 14px;
      }

      .search-btn {
        font-size: 15px;
      }
    }

    .ant-modal-content {
      .ant-modal-header {
        padding-top: 16px;
        padding-bottom: 0px;
      }

      .ant-modal-body {
        padding: 0px 25px;

        p {
          font-size: 14px;
          color: #FFFFFF;
        }

        .value {
          font-size: 14px;
        }
      }

      .ant-modal-title {
        font-size: 16px;
      }

      .input-title {
        font-size: 14px;
      }

      .dialog-input, .input-content {
        height: 40px;
        font-size: 14px;

        .temp-input {
          font-size: 14px;

        }

        .ant-input {
          font-size: 14px;

        }
      }

      .ant-modal-footer {
        .ant-btn {
          font-size: 14px;
          height: 40px;
        }
      }
    }

    .ant-select {
      width: 100%;
    }

  }
  .leaderboard-list {
    .nft-item, .nft-header {
      justify-content: space-between;
      padding: 10px 0;
      border-radius: 0;
      .col {
        text-align: left;
        font-size: 16px;
        width: 150px;
      }
    }
  }
`
export default AntdOverride

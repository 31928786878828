import {Routes, Route, Link,NavLink, useNavigate, useLocation} from "react-router-dom";

import "animate.css";

import GlobalStyle from "./style/style";
import CommonStyle from "./style/commonStyle";
import AntdOverride from "./style/antdOverride";
import React from "react";
import {ConnectProvider, useConnect} from "./api/contracts";
import SuperbitdaoHeader from "./component/SuperbitdaoHeader/SuperbitdaoHeader";
import SuperbitdaoFooter from "./component/SuperbitdaoFooter/SuperbitdaoFooter";

import OGPoolV5 from "./view/Layout/OGPoolV5/OGPool";
import OGPoolV5Admin from "./view/Layout/OGPoolV5/OGPoolAdmin";
import OGV5UserAdmin from "./view/Layout/OGPoolV5/OGUserAdmin";
import HomeView from "./view/HomeView";
import SBDAdmin from "./view/Layout/SBD/admin"
import SwapAdmin from "./view/Layout/Swap/admin"
import SwapView from "./view/Layout/Swap/SwapView"
import NFTAdminV1 from './view/Layout/NFT/NFTAdminV1';
import NFTAdminV2 from './view/Layout/NFT/NFTAdminV2';
import NFTAdminV3 from './view/Layout/NFT/NFTAdminV3';

import SVTAdmin from './view/Layout/SVT/admin';
import SFTAdmin from './view/Layout/SFT/admin';
import SRTAdmin from './view/Layout/SRT/admin';
import SPTAdmin from './view/Layout/SPT/admin';

import LeaderboardBonusNav from "./view/Layout/LeaderboardBonusNav"
import LeaderboardBonusAdmin from './view/Layout/LeaderboardBonus/admin';
import LeaderboardBonus1 from "./view/Layout/LeaderboardBonus1"
import LeaderboardBonusAdmin1 from './view/Layout/LeaderboardBonus1/admin';
import LeaderboardBonusAdmin2 from './view/Layout/LeaderboardBonus2/admin';
import LeaderboardBonus2 from "./view/Layout/LeaderboardBonus2"

import NFTLockAdmin from "./view/Layout/LockMining/NFTLockV1Admin";
import NFTLockV2Admin from "./view/Layout/LockMining/NFTLockV2Admin";

import SBDLockAdmin from "./view/Layout/LockMining/SBDLockAdmin";
import SBDSFTLockAdmin from "./view/Layout/LockMining/SBDSFTLockAdmin";

import NFTLock from './view/Layout/LockMining/LockMiningView/NFTLockView';
import SBDLock from './view/Layout/LockMining/LockMiningView/components/SBDLock';
import SBDSFTLock from './view/Layout/LockMining/LockMiningView/components/SBDSFTLock';
import NFTView from "./view/Layout/NFT/NFTView";

import LockMiningViewV3 from "./view/Layout/LockMiningV3/LockMiningView";
import NFTLockAdminV3 from "./view/Layout/LockMiningV3/NFTLockAdmin";
import NFTLockV3 from './view/Layout/LockMiningV3/LockMiningView/components/NFTLock';
import SBDLockV3 from './view/Layout/LockMiningV3/LockMiningView/components/SBDLock';


import NFTNavView from "./view/Layout/NFT/NFTNavView";
import SPTIssue from "./view/Layout/SPTIssue";

function App() {
    const location = useLocation()

    return (

        <ConnectProvider>
            <CommonStyle/>
            <GlobalStyle/>
            <AntdOverride/>

                <div className="content">
                    <Routes>
                        <Route path="/" element={<HomeView/>}/>
                    </Routes>

                    {location.pathname!="/"&&<>
                        <SuperbitdaoHeader/>
                        <div className="App" style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            paddingTop:"0em"
                        }}>

                            <div className={"flex-container "+ ((location.pathname=="/LeaderboardBonus")?" bonus-bg":"")} style={{
                                width:"100%",
                                flexGrow:"1"
                            }}>
                                <Routes>
                                    <Route path="/SBDPurchase" element={<OGPoolV5/>}/>
                                    <Route path="/SBDPurchaseManage" element={<OGPoolV5Admin/>}/>
                                    <Route path="/SBDPurchaseUserManage" element={<OGV5UserAdmin/>}/>
                                    <Route path="/SBDPurchaseManage" element={<OGV5UserAdmin/>}/>
                                    <Route path="/SBDAdmin" element={<SBDAdmin/>}/>
                                    <Route path="/SwapAdmin" element={<SwapAdmin/>}/>

                                    <Route path="/SwapView" element={<SwapView/>}/>
                                    {/*<Route path="/LeaderboardBonus" element={<LeaderboardBonus/>}/>*/}
                                    <Route path="/LeaderboardBonusAdmin" element={<LeaderboardBonusAdmin/>}/>

                                    <Route path="/LeaderboardBonus" element={<LeaderboardBonusNav/>}/>

                                    <Route path="/LeaderboardBonus1" element={<LeaderboardBonus1/>}/>
                                    <Route path="/LeaderboardBonus1Admin" element={<LeaderboardBonusAdmin1/>}/>

                                    <Route path="/LeaderboardBonus2" element={<LeaderboardBonus2/>}/>
                                    <Route path="/LeaderboardBonus2Admin" element={<LeaderboardBonusAdmin2/>}/>

                                    <Route path="/NFTView" element={<NFTNavView/>}/>
                                    <Route path="/NFTViewV2" element={<NFTView curNode={2}/>}/>
                                    <Route path="/NFTViewV3" element={<NFTView curNode={3}/>}/>

                                    <Route path="/NFTAdminV1" element={<NFTAdminV1/>} />
                                    <Route path="/NFTAdminV2" element={<NFTAdminV2/>} />
                                    <Route path="/NFTAdminV3" element={<NFTAdminV3/>} />

                                    <Route path="/SVTAdmin" element={<SVTAdmin/>} />
                                    <Route path="/SPTAdmin" element={<SPTAdmin/>} />


                                    <Route path="/SFTAdmin" element={<SFTAdmin/>} />
                                    <Route path="/SRTAdmin" element={<SRTAdmin/>} />

                                    <Route path="/LockMiningViewV3" element={<LockMiningViewV3/>} />
                                    <Route path="/NFTLockV3Admin" element={<NFTLockAdminV3/>}/>
                                    <Route path="/LockMiningViewV3/NFTLock" element={<NFTLockV3/>}/>
                                    <Route path="/LockMiningViewV3/SBDLock" element={<SBDLockV3/>} />


                                    <Route path="/NFTLockAdmin" element={<NFTLockAdmin/>}/>
                                    <Route path="/NFTLockV2Admin" element={<NFTLockV2Admin/>}/>

                                    <Route path="/LockMiningView/NFTLock" element={<NFTLock/>}/>
                                    <Route path="/LockMiningView/SBDLock" element={<SBDLock/>} />
                                    <Route path="/SBDLock" element={<SBDSFTLock/>} />


                                    <Route path="/SBDLockAdmin" element={<SBDLockAdmin/>}/>
                                    <Route path="/SBDSFTLockAdmin" element={<SBDSFTLockAdmin/>}/>
                                    <Route path="/SPTIssue" element={<SPTIssue/>} />

                                </Routes>
                            </div>
                            <div className='twindow'>

                            </div>
                        </div>
                        <SuperbitdaoFooter/>
                    </>}

                </div>

        </ConnectProvider>

    )
}

export default App;

import BigNumber from "bignumber.js";

const ZeroAddress = '0x0000000000000000000000000000000000000000';
const MaxUint256 = BigNumber('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff').toFixed(0);
const ETHDecimals = 18
const ETHPriceDecimals = 8
const SBDDecimals = 18
const SVTDecimals = 18
const SRTCoinDecimal = 18
const SFTCoinDecimal = 18

const SPTCoinDecimal = 18
const SBDCoinDecimals = 18
const USDTDecimals = 18
const NFTMap = {
  "0x0000000000000000000000000000000000000000":"none",

}
const Network = {
  '0x61': {
    chainId: '0x61',
    chainName: 'BNB Smart Chain Testnet',
    rpcUrls: ['https://bsc-testnet.blockpi.network/v1/rpc/public'],
    blockExplorerUrls: ['https://testnet.bscscan.com'],
    nativeCurrency: {
      name: 'tBNB',
      symbol: 'tBNB',
      decimals: 18,
    },
  },
  '0x38': {
    chainId: '0x38',
    chainName: 'BNB Smart Chain Mainnet',
    rpcUrls: ['https://bsc-rpc.gateway.pokt.network'],
    blockExplorerUrls: ['https://bscscan.com'],
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18,
    },
  },
};
const TestDecimals = 0
export { Network, ZeroAddress,MaxUint256
  ,ETHDecimals,SBDCoinDecimals,SPTCoinDecimal,SRTCoinDecimal,SFTCoinDecimal,
  ETHPriceDecimals,
  SVTDecimals,
  USDTDecimals,SBDDecimals ,TestDecimals,NFTMap};

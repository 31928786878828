import React, {useEffect, useRef, useState} from 'react';
import {useConnect} from "../../../../api/contracts";
import {Table, Button, Select, Descriptions, message, Form, List, Input, notification, Card, Switch} from 'antd';
import {getContractByName, getContractByContract} from "../../../../api/connectContract";
import {dealMethod, viewMethod} from "../../../../utils/contractUtil"
import {useNavigate, useLocation} from "react-router-dom";
import FireLockStyle from "./style";
import judgeStatus from "../../../../utils/judgeStatus";
import AddWhiteListAddr from "./component/AddWhiteListAddr";
import RemoveWhiteListAddr from "./component/RemoveWhiteListAddr";
import add from "../../../../imgs/add.png";
import cut from "../../../../imgs/remove.png";
import {SVTDecimals} from "../../../../config/constants";
import BigNumber from "bignumber.js";
import addressMap from "../../../../api/addressMap";

const FireLock = (props) => {
    let {state, dispatch} = useConnect();
    const [whitelist, setWhitelistArr] = useState([])


    const [isChecked, setIsChecked] = useState(false)
    const [dividendV2sArr, setDividendV2sArr] = useState()

    const [isTrue, setIsTrue] = useState(false)


    const [curNav, setCurNav] = useState(1)
    const [ownerAddr, setOwner] = useState("")

    const [isShowAddW, setIsShowAddW] = useState(false)


    const [address, setAddress] = useState(false)
    const [isShowRemove, setShowRemove] = useState(false)

    const [feeReceiver, setReceiver] = useState("")
    const [isInFeeWhitelist, setIsInFeeWhitelist] = useState(false)
    const [addWhiteArr, setAddWArr] = useState([{}])
    const [form] = Form.useForm();

    const location = useLocation()

    const openNotification = (message) => {
        notification.error({
            message: message,
            description:
                "",
            onClick: () => {
            },
        });
    };
    const handleDealMethod = async (name, params) => {
        let contractTemp = await getContractByName("SPT", state.api,)
        if (!contractTemp) {
            openNotification("Please connect")
        }
        return dealMethod(contractTemp, state.account, name, params)
    }

    const addOneWhite = async () => {
        let addWhiteArrT = JSON.parse(JSON.stringify(addWhiteArr))
        addWhiteArrT.push({})
        setAddWArr(addWhiteArrT)
    }
    const removeOneWhite = async () => {
        let addWhiteArrT = JSON.parse(JSON.stringify(addWhiteArr))
        addWhiteArrT.shift()
        setAddWArr(addWhiteArrT)
    }
    const handleViewMethod = async (name, params) => {
        let contractTemp = await getContractByName("SPT", state.api,)
        if (!contractTemp) {
            openNotification("Please connect")
        }
        return await viewMethod(contractTemp, state.account, name, params)
    }


    const getData = async (page) => {
        getOwner()
        getAddress()
        getDividend()
    }


    const getOwner = async () => {
        const ownerAddr = await handleViewMethod("admin", [])
        setOwner(ownerAddr)
    }
    const getAddress= async () => {
        const ownerAddr = await handleViewMethod("Aaddress", [])
        setAddress(ownerAddr)
    }

    const getDividend = async () => {
        const length = await handleViewMethod("getDividendV2sLength", [])
        let dividendArr = []
        for (let i = 0; i < length; i++) {
            const res = await handleViewMethod("DividendV2s", [i])
            dividendArr.push(res)
        }
        setDividendV2sArr(dividendArr)
    }
    const getWhiteLists = async () => {
        const res = await handleViewMethod("whiteLists", [])
        setWhitelistArr(res)
    }


    const transferOwnership = async () => {
        await handleDealMethod("transferAdmin", [form.getFieldValue().owner])
        getOwner()
    }
    const setAaddress = async () => {
        await handleDealMethod("setAaddress", [form.getFieldValue().setAddress])
        getAddress()
    }
    const handleInit = async () => {
        let arr = []
        for (let i = 0; i < addWhiteArr.length; i++) {
            arr.push(form.getFieldValue()["address" + i])
        }

        await handleDealMethod("addDividendV2s", [arr])
        getDividend()
    }

    const handleSet = async () => {
        await handleDealMethod("setDividendV2s", [form.getFieldValue().setIndex, form.getFieldValue().setAddr])
        getDividend()
    }
    const handleDelete = async (address) => {
        await handleDealMethod("removeDividendV2s", [address])
        getDividend()
    }
    const handleSetAccess = async () => {
        await handleDealMethod("setAccess", [form.getFieldValue().contractAddress, true])
    }
    useEffect(async () => {
        let judgeRes = await judgeStatus(state)
        if (!judgeRes) {
            return
        }
        await getData()
    }, [state.account]);

    const DividendV2sColumns = [
        {
            title: 'Title',
        },
        {
            title: 'Delete',
            key: 'action',
            render: (item) => (
                <Button onClick={() => handleDelete(item)}>Delete</Button>
            ),
        },


    ]
    return (
        <FireLockStyle>
            {isShowAddW && <AddWhiteListAddr updateData={() => {
                getWhiteLists()
            }} closeDialog={() => {
                setIsShowAddW(false)
            }}/>}
            {isShowRemove && <RemoveWhiteListAddr updateData={() => {
            }} closeDialog={() => {
                setShowRemove(false)
            }}/>}
            <h1 className="title">
                SPT Admin
            </h1>
            <div className="panel-box">
                <div className="panel-container">
                    <div className="nav-list">
                        <div className={"nav-item " + (curNav == 1 ? "active" : "")} onClick={() => {
                            setCurNav(1)
                        }}>
                            Owner
                        </div>

                    </div>
                    {curNav == 1 && <div className="part1">


                        <div className="content-item">
                            <h3>Contract Address({addressMap["SPT"].address})</h3>

                            <h3>Owner Address</h3>
                            <Form form={form} name="control-hooks">
                                <div className="current">
                                    <div className="name">
                                        Current:
                                    </div>
                                    <div className="value">
                                        {ownerAddr}
                                    </div>
                                </div>
                                <Form.Item
                                    name="owner"
                                    label="owner address"
                                    validateTrigger="onBlur"
                                    validateFirst={true}
                                    rules={[
                                        {required: true, message: 'Please input owner Address!'},
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                            </Form>
                            <Button type="primary" onClick={() => {
                                transferOwnership()
                            }}>
                                Submit
                            </Button>
                        </div>

                        <div className="content-item">
                            <h3> setAccess</h3>
                            <Form form={form} name="control-hooks">
                                <div className="current">
                                    <div className="name">
                                    </div>
                                    <div className="value">
                                    </div>
                                </div>
                                <Form.Item
                                    name="contractAddress"
                                    label="contractAddress"
                                    validateTrigger="onBlur"
                                    validateFirst={true}
                                >
                                    <Input/>
                                </Form.Item>

                            </Form>
                            <Button type="primary" className="max-btn" onClick={() => {
                                handleSetAccess()
                            }}>
                                Submit
                            </Button>
                        </div>
                        <div className="content-item">
                            <h3> setAddress(EVENT)({address})</h3>
                            <Form form={form} name="control-hooks">

                                <Form.Item
                                    name="setAddress"
                                    label="contractAddress"
                                    validateTrigger="onBlur"
                                    validateFirst={true}
                                >
                                    <Input/>
                                </Form.Item>

                            </Form>
                            <Button type="primary" className="max-btn" onClick={() => {
                                setAaddress()
                            }}>
                                Submit
                            </Button>
                        </div>
                        <div className="panel-title" style={{marginTop: "50px"}}>
                            DividendV2s List
                        </div>
                        <Table pagination={false} columns={DividendV2sColumns} dataSource={dividendV2sArr}/>
                        <div className="content-item">
                            <h3> Init DividendV2s</h3>
                            <Form form={form} name="control-hooks" className="form">

                                {addWhiteArr.map((item, index) => {
                                    return (
                                        <Form.Item
                                            name={"address" + index}
                                            validateTrigger="onBlur"
                                            label="Address"
                                            validateFirst={true}
                                        >
                                            <div className="input-box">
                                                <Input type="text"></Input>
                                            </div>
                                        </Form.Item>
                                    )
                                })}
                                <div className="icon-box">
                                    <img width={40} style={{cursor: "pointer"}} src={add} onClick={() => {
                                        addOneWhite()
                                    }}/>
                                    <img width={40} style={{cursor: "pointer"}} src={cut} onClick={() => {
                                        removeOneWhite()
                                    }}/>

                                </div>
                            </Form>
                            <Button type="primary" className="max-btn" onClick={() => {
                                handleInit()
                            }}>
                                Submit
                            </Button>
                        </div>
                        <div className="content-item">
                            <h3> Set DividendV2s</h3>
                            <Form form={form} name="control-hooks">
                                <Form.Item
                                    name="setIndex"
                                    label="Index"
                                    validateTrigger="onBlur"
                                    validateFirst={true}
                                >
                                    <Input/>
                                </Form.Item>
                                <Form.Item
                                    name="setAddr"
                                    label="contractAddress"
                                    validateTrigger="onBlur"
                                    validateFirst={true}
                                >
                                    <Input/>
                                </Form.Item>

                            </Form>
                            <Button type="primary" className="max-btn" onClick={() => {
                                handleSet()
                            }}>
                                Submit
                            </Button>
                        </div>

                    </div>}


                </div>

            </div>
        </FireLockStyle>
    )
}
export default FireLock

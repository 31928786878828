import {createGlobalStyle} from "styled-components";

const GlobalStyle = createGlobalStyle`


  @font-face {
    font-family: "RoBoto-Medium";
    src: url("./font/RobotoMedium.ttf");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "Russo One-Regular";
    src: url("./font/RussoOne-Regular.ttf");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "Roboto-Bold";
    src: url("./font/Roboto-Bold.ttf");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "Volkhov-Bold";
    src: url("./font/Volkhov-Bold.ttf");
    font-weight: normal;
    font-style: normal;
  }

  * {
    box-sizing: border-box;
    font-family: Helvetica-Bold, Helvetica, sans-serif;

  }

  .ant-message {
    .ant-message-notice{
      margin-top: 0vh!important;
    }
    .ant-message-notice-content {
      background: rgba(28, 28, 28, 0.9)!important;
      border-radius: 0!important;
      .ant-message-custom-content{
        flex-direction: row!important;
      }
    }
  }

  .ant-pagination .ant-pagination-item-link {
    border-radius: 5px;
  }

  .ant-pagination-disabled {
    border-radius: 5px;

  }

  .panel-container .search-container .search-box .ant-input-affix-wrapper {
    border: none;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {

    background: #1C1C1C !important;
    border: 1px solid rgba(255, 255, 255, 0.15);
    color: #FFFFFF;

    .ant-select-selection-item {
      color: #C3C3C3;
      font-weight: bold;
    }
  }

  .ant-pagination .ant-pagination-item-link {
    background: #1C1C1C !important;

    border: 1px solid rgba(255, 255, 255, 0.15);
    color: #FFFFFF;
  }

  .ant-pagination-item {
    background: #1C1C1C !important;
    color: #FFFFFF;
    border: 1px solid rgba(255, 255, 255, 0.15);
  }


  .anticon-right {
    color: #FFFFFF !important;

  }

  .anticon-left {
    color: #FFFFFF !important;

  }


  .ant-input-affix-wrapper:hover {
    border-color: rgba(255, 255, 255, 0.10);

  }

  ::-webkit-scrollbar {
    width: 14px;
    height: 14px;
  }

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #5b5b5b;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: rgba(36, 27, 27, .7);
  }

  .ant-input-affix-wrapper:focus {
    border-color: rgba(255, 255, 255, 0.10);

  }

  .ant-input {
    border-radius: 10px;

    padding-left: 20px;
    border-color: #F2F2F2;
    background: #C3C3C3;

  }

  .ant-empty-normal {
    color: #FFFFFF;
  }

  .ant-input:focus {
    border-color: rgba(255, 255, 255, 0.10);
  }

  .ant-input:hover {
    border-color: rgba(255, 255, 255, 0.10);
  }

  input::-webkit-validation-bubble {
    display: none !important;
  }

  input::-webkit-inner-spin-button {
    appearance: none !important;
  }

  input::-webkit-outer-spin-button {
    appearance: none !important;
  }

  /* Firefox */

  input:-moz-validation-bubble {
    display: none;
  }

  /* Edge and IE */
  input::-ms-validation-bubble {
    display: none;
  }

  input[type='number'] {
    appearance: textfield;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }


  .ant-space {
    .ant-space-item {
      .ant-select {
        width: 280px;

      }

      .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        height: 45px;
      }
    }
  }

  body {

    background: #3E3E3E;
    position: relative;
  }

  .App {
    min-height: calc(100vh - 12em);
    position: relative;
    z-index: 2;
  }

  .flex-container {
    width: 100%;
    padding: 0 10%;
  }


  .content {

    background: linear-gradient(360deg, #1B1B1B 0%, #3E3E3E 100%);
    position: relative;
    z-index: 1;
    left: 0;
  }

  .superdaobg {
    width: 100vw;
    position: fixed;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 0;
    object-fit: fill;
  }

  div {
    box-sizing: border-box;
    font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;
    font-size: 14px;
  }


  //  common css

  .col {

    flex: none !important;
  }

  .superdao-list-box {
    border-radius: 20px 20px 20px 20px;
    padding: 0em 0 0em;
    margin: 0em;


    .list-header {
      display: flex;
      font-weight: bold;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      color: #8A8080;
      padding: 10px 0;

      .col {

        display: flex;
        white-space: nowrap;

        .list-icon {
          width: 16px;
          height: 13px;
          cursor: pointer;
          margin-top: 4px;
          margin-left: 6px;
        }
      }
    }

    .list-item {
      display: flex;
      border-radius: 10px;
      font-family: Helvetica-Bold, Helvetica, sans-serif;
      align-items: center;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      /* &:last-child{
        border-bottom: none;
      } */

      .no {
        color: #E48686;
      }

    }
  }

  .panel-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .panel-container {
      width: 100%;
      padding: 5px 4%;
      margin: 0.5em auto;

      border-radius: 10px;
      opacity: 1;
      //scroll
      &::-webkit-scrollbar-corner {
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: #535353;
      }

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        background: #ededed;
      }

      .nav-list {
        display: flex;
        background: linear-gradient(32deg, rgb(255, 174, 78) 0%, rgb(249, 212, 35) 100%);
        border-radius: 10px;

        padding: 3px;

        .nav-item {
          cursor: pointer;
          padding: 10px 30px;
          border-radius: 10px;
          margin-right: 10px;
          font-size: 16px;
          font-weight: bold;
          color: #999;
background: rgb(28, 28, 28);
          &.active {
            background: linear-gradient(32deg, rgb(255, 174, 78) 0%, rgb(249, 212, 35) 100%);
            box-shadow: 0px 3px 6px 0px rgba(128, 4, 149, 0.3);
            color: #fff;
          }

          &:nth-last-child(1) {
            margin-right: 0;
          }
        }
      }
    }

    .panel-title {
      text-align: left;
      width: 100%;
      font-size: 30px;
      font-family: Helvetica-Bold, Helvetica, sans-serif;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 1.6;
    }
  }

  .flex-box {
    display: flex;
  }
  .ant-pagination-disabled, .ant-pagination-disabled:hover {
    cursor: pointer;
}
  /* reset button*/
  .ant-btn-primary {
    background: linear-gradient(32deg, rgb(255, 174, 78) 0%, rgb(249, 212, 35) 100%);
    border-radius: 10px;
    text-shadow: none;
  }

  .connect-button {
    border-radius: 10px;
  }

  /* reset form*/

  .ant-form {
    width: 100%;

    .ant-form-item {
      color: #FFFFFF;
      margin-bottom: 10px;
    }

    /* input, textarea {
      font-size: 16px !important;
    } */
  }

  .panel-box {
    .panel-container {
      padding: 6px 2%;
    }
  }

  .ant-form-item-label > label::after {
    display: none;
  }

  .ant-form-item-row {
    display: block !important;
  }

  .ant-form-item-control-input {
    overflow: hidden;
    background: rgba(255, 255, 255, 0.15);
    min-height: 36px;
  }

  .button-box {
    .ant-form-item-control-input {
      background: none !important;
    }
  }

  textarea {
    padding: 10px;

    &:focus-visible {
      outline: none;
    }
  }



  .logo-box1 {
    display: none;
  }

  .pagination {
    text-align: center;
  }

  @media screen and (min-width: 1440px) {
    .App {
      align-items: flex-start;
      overflow-x: auto;
      padding-bottom: 6em;

      &::-webkit-scrollbar {
        width: 20px;
        height: 20px;
      }

      &::-webkit-scrollbar-track,
      &::-webkit-scrollbar-thumb {
        border-radius: 999px;
        border: 5px solid transparent;
      }

      &::-webkit-scrollbar-thumb {
        min-height: 20px;
        background-clip: content-box;
        box-shadow: 0 0 0 10px rgba(0, 0, 0, .8) inset;
      }

      &::-webkit-scrollbar-corner {
        background: transparent;
      }
    }

    .ant-space {
      .ant-space-item {
        .ant-select {
          width: 250px;
        }
      }
    }

    .panel-box {
      .panel-title {
        font-size: 30px;
        margin: 0px;
      }
    }

    .panel-box {
      .panel-container {
        padding: 6px 2%;
      }
    }

    .superdao-list-box {

      .col {
        font-size: 16px;
      }
    }

    .superdao-list-box {
      .list-header {
        font-size: 16px;
      }
    }

  }


  @media screen and (max-width: 1440px) {
    .App {
      align-items: flex-start;
      overflow-x: auto;
      padding-bottom: 6em;

      &::-webkit-scrollbar {
        width: 20px;
        height: 20px;
      }

      &::-webkit-scrollbar-track,
      &::-webkit-scrollbar-thumb {
        border-radius: 999px;
        border: 5px solid transparent;
      }

      &::-webkit-scrollbar-thumb {
        min-height: 20px;
        background-clip: content-box;
        box-shadow: 0 0 0 10px rgba(0, 0, 0, .8) inset;
      }

      &::-webkit-scrollbar-corner {
        background: transparent;
      }
    }


    .panel-box {
      .panel-container {
        padding: 6px 2%;
      }
    }

    .panel-box {
      .panel-title {
        font-size: 21px;
        margin: 0px;
      }
    }

    .superdao-list-box {
      .col {
        font-size: 15px;
      }

      .list-header {
        font-size: 15px;
      }
    }

  }


  @media screen and (max-width: 450px) {

    .App {

      padding-bottom: 2em;
    }

    .ant-space {
      width: 100%;

      .ant-space-item {
        width: 100%;

        .ant-select {
          width: 100%;
        }
      }
    }

    .flex-container {
      width: 100%;
      padding: 0 4%;

      .logo {
        width: 40%;
      }
    }

    .ant-dropdown-trigger {
      padding: 0 20px;
      width: 100%;

      .ant-space {
        width: 100%;
        justify-content: space-between;
        color: #FFFFFF;

        .ant-space-item {
          font-size: 14px;
        }
      }
    }

    .ant-dropdown {
      width: 84%;
      margin: 1em 1em;
      border-radius: 8px;
      background: #1C1C1C;

      .ant-dropdown-menu {
        border-radius: 8px;
        width: 100%;

        .ant-dropdown-menu-item {

          .ant-dropdown-menu-title-content {
            .nav-item {
              font-size: 14px;
              text-align: center;
              margin: 5px 0;
            }
          }
        }
      }
    }

    .superdaobg {
      display: none;
    }

    .logo-box1 {
      display: block;
    }

    .nav-box-box {

      display: none;
    }

    .content {
      padding-left: 0px;
    }

    .Superbitdao-header {
      width: 100% !important;

      .nav-list {
        display: none !important;
      }

      .member {
        border: 1px solid white;
        width: 100%;
        height: 100%;
        background: #1E0000;
        border-radius: 50%;
      }
    }

    .panel-box {
      .panel-container {
        padding: 15px 2%;
      }
    }

    .panel-box {
      .panel-title {
        font-size: 18px;
        margin: 0px;
      }
    }

    .superdao-list-box {
      border-radius: 10px;

      .col {
        font-size: 14px;
      }
    }

    .superdao-list-box {
      .list-header {
        font-size: 14px;
      }
    }
  }


  @media screen and (max-width: 400px) {

    .App {
      padding-bottom: 2em;
    }

    .flex-container {
      width: 100%;
      padding: 0 4%;

      .logo {
        width: 40%;
      }
    }

    .superdaobg {
      display: none;
    }

    .logo-box1 {
      display: block;
    }

    .nav-box-box {

      display: none;
    }

    .content {
      padding-left: 0px;
    }

    .Superbitdao-header {
      width: 100% !important;

      .nav-list {
        display: none !important;
      }

      .member {
        border: 1px solid white;
        width: 100%;
        height: 100%;
        background: #1E0000;
        border-radius: 50%;
      }
    }

    .panel-box {
      .panel-title {
        font-size: 18px;
        margin: 0px;
      }
    }

    .panel-box {
      .panel-container {
        padding: 15px 2%;
      }
    }

    .superdao-list-box {
      border-radius: 10px;

      .col {
        font-size: 14px;
      }
    }

    .superdao-list-box {
      .list-header {
        font-size: 14px;
      }
    }
  }

`
export default GlobalStyle

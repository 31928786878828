import styled from "styled-components";
export default   styled.div`
  .claim-list {
    margin: 1em 0;
    background: rgba(28, 28, 28, 1);

    .list-header {
      display: flex;
      padding: 20px 2em;
      border-bottom: 1px solid rgba(234, 234, 234, 0.10);
    }

    .list-item, .list-header {
      justify-content: flex-start;

      .col {
        text-align: left;
        font-size: 16px;

        &:nth-child(1) {
          margin-left: 60px;
          width: 13%;
        }

        &:nth-child(2) {
          width: 20%;
        }

        &:nth-child(3) {
          width: 20%;
        }

        &:nth-child(4) {
          width: 20%;
        }

        &:nth-child(5) {
          width: 17%;
        }
      }
    }

    .list-item:last-child {
      border-bottom: none;
    }

    .list-item {
      color: #FFFFFF;
      padding: 1em 2em;
      width: 100%;
      margin: 0 auto;
      border-radius: 0px;
      border-bottom: 1px solid rgba(234, 234, 234, 0.10);

      &:nth-child(even) {
        background: rgba(62, 62, 62, 1);
      }

      .col {
        overflow: hidden;
        padding-left: 0%;

        .item {
          font-size: 16px;
        }
      }

      .address {
        a {
          color: #CD9E57;
        }
      }
    }
  }
  
  .header-box{
    width: 80%;
    display: flex;
    justify-content: space-between;
    margin: 30px auto 50px;

    .header-item{
      width: 45%;
      padding: 20px;
      background: #1C1C1C;
      box-shadow: 0px 5px 20px 5px rgba(0,0,0,0.2);
      border-radius: 20px 20px 20px 20px;

      border: 1px solid rgba(255,255,255,0.15);
      .name{
        font-size: 18px;
        font-family: Roboto-Medium, Roboto;
        font-weight: 500;
        color: #8A8080;
      }
      .value{
        font-size: 22px;
        font-family: Roboto-Black, Roboto;
        font-weight: 900;
        color: #FFFFFF;
        
        margin-top: 10px;
      }
    }
  }
  .max-btn{
    width: 80px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    background: linear-gradient(229deg, #DAB163 0%, #CC8A35 100%);
    border-radius: 10px 10px 10px 10px;
    cursor: pointer;
    user-select: none;
    font-size: 18px;
    font-family: Roboto-Bold, Roboto;
    font-weight: bold;

    color: #1A1414;
  }
  .inline{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    .left{
      font-size: 18px;
      font-family: Roboto-Medium, Roboto;
      font-weight: 500;
      color: #8A8080;
    }
    .right{
      font-size: 18px;
      font-family: Roboto-Black, Roboto;
      font-weight: 900;
      color: #FFFFFF;
    }
  }
  .nav-box{
    display: flex;
    margin: 20px auto;
    width: 300px;
    height: 60px;
    background: #272727;
    border-radius: 10px 10px 10px 10px;
    opacity: 1;
    border: 1px solid rgba(255,255,255,0.1);
    .nav-item{
      width: 148px;
      height: 56px;
      border-radius: 10px 10px 10px 10px;
      opacity: 1;
      font-size: 18px;
      font-family: Roboto-Bold, Roboto;
      font-weight: bold;

      color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
      &.active{
        background: linear-gradient(229deg, #DAB163 0%, #CC8A35 100%);
        color: #613A00;
        
      }
    }
  }
  .withdraw-header{
    font-size: 18px;
    margin-top: 20px;
  }
  .operate-box {
    width: 40%;
    border: 1px solid rgba(255,255,255,0.15);
    margin: 25px auto;
    background: rgb(28, 28, 28);
    box-shadow: 0px 5px 20px 5px rgba(0,0,0,0.05);
    border-radius: 20px 20px 20px 20px;
    padding: 35px;
    position: relative;
    .select-box{
      justify-content: space-between;
      align-items: center;
      font-size: 18px;
      padding: 0 0 15px 0;
      .ant-select{
        width: 120px;
      }
    }
    .down-icon{
      position: absolute;
      left: calc(50% - 20px);
      top: 150px;
    }
    .submit-btn{
      width: 100%;
      margin-top: 20px;
      height: 50px;
      background: linear-gradient(229deg, #DAB163 0%, #CC8A35 100%);
      border-radius: 10px 10px 10px 10px;
      font-size: 18px;
      font-family: Roboto-Bold, Roboto;
      font-weight: bold;
      color: #1A1414;
    }
    .title {
      font-size: 24px;
      font-family: Roboto-Bold, Roboto;
      font-weight: bold;
      padding-bottom: 10px;

    }
    .ant-form{
      .ant-form-item{
        margin-bottom:0px;
      }
    }
    .withdraw-part {
      background: #272727;
      border-radius: 10px 10px 10px 10px;
      opacity: 1;
      border: 1px solid rgba(255,255,255,0.1);
      padding: 20px;
      margin-top: 1.5em;
     
      .ant-form-item-control-input {

        background: #272727;
      }

      .balance-box {
        margin-bottom:0.5em;
        display: flex;
        justify-content: space-between;
        strong{

          font-size: 16px;
          font-family: Roboto-Bold, Roboto;
          font-weight: bold;
          color: #FFFFFF;
        }
        .receive{
          font-size: 16px;
          font-family: Roboto-Bold, Roboto;
          font-weight: bold;
          color: #8A8080;
          line-height: 19px;
        }



        .name {
          font-size: 16px;
          font-family: Roboto-Bold, Roboto;
          font-weight: bold;
          color: #8A8080;
        }

        .value {
          font-size: 16px;
          font-family: Roboto-Bold, Roboto;
          font-weight: bold;
          color: #8A8080;
        }



      }
   

      .input-box {
        position: relative;
        display: flex;
        align-items: center;
        justify-content:space-between;

        .right-tip {
          display: flex;
          align-items: center;
          color: #FFFFFF;
          font-size: 16px;
          font-family: Roboto-Black, Roboto;
          font-weight: 900;

          .coin-icon {
            width: 30px;
            height: 30px;
            margin-right: 6px;
          }
        }

        .exchangeAmount {
          color: #FFFFFF;
          height: 50px;
          display: flex;
          flex-grow: 1;
          align-items: center;
          font-size: 18px;
          padding: 0 10px;
          font-family: Roboto-Black, Roboto;
          font-weight: 900;
        }
        .ant-select-selection-item{
          color:#C3C3C3;
          font-weight:bold;
        }
        .ant-select{
          width: 50% !important;
          border: 1px solid rgba(255,255,255,0.15);
          height: 40px;

        }
        .ant-input-number{
          height: 45px;

          background: #272727;
        }

        .ant-input-number, .ant-input {
          font-family: Roboto-Black, Roboto;
          font-weight: 900;
          background: #272727;
          padding: 6px;
          border: none;
          font-size: 18px;
          .ant-select-selector{
            border-radius:10px;
            height: 45px;

          }
          .ant-input-number-input-wrap, .ant-select-selector, input {
            height: 100%;
            width: 100% !important;
            font-size: 20px ;
            font-family: Roboto-Black, Roboto;
            display: flex;
            align-items: center;
            border: none;
            font-weight: 900;

            &:focus-visible {
              outline: none;
            }
          }

          .ant-select-clear {
            margin-right: 66px;
          }
        }



      }

    }

    .tip {
      margin-top: 2em;
      font-size: 14px;
      font-family: Roboto-SemiBold, Roboto;
      font-weight: 600;
      color: #8A8080;
    }
  }

  @media screen and (max-width: 1440px) {


    .operate-box .submit-btn {
        font-size:16px;
        height: 45px;
    }
    .claim-list{
      .list-item, .list-header {
        .col{
            font-size:15px;
            &:nth-child(1) {
          margin-left: 30px;
        width: 12%;
        }
        &:nth-child(3) {
        width: 22%;
        }
        &:nth-child(4) {
        width: 22%;
        }
        }
      }
    }
    .inline {
        .left {
    font-size: 15px;
    }
    .right {
    font-size: 15px;
    }
}

.withdraw-header {
    font-size: 15px;
}

.max-btn {
    font-size:16px;
}
  }



  @media screen and (max-width: 450px) {


.operate-box {
    width: 96%;
    margin: 24px auto;
    padding: 25px;
    .submit-btn {
   height: 40px;
}}
.claim-list{
    overflow-x: scroll;
    .listheadert{
        width: 1000px;
    }
  .list-item, .list-header {

    .col{
        font-size:14px;

        &:nth-child(1) {
          margin-left: 10px;
          width: 140px;
        }

        &:nth-child(2) {
          width: 200px;
        }

        &:nth-child(3) {
          width: 220px;
        }

        &:nth-child(4) {
          width: 220px;
        }

        &:nth-child(5) {
          width: 170px;
        }
    }
  }
}


.inline {
    .left {
font-size: 14px;
}
.right {
font-size: 14px;
}
}

.withdraw-header {
font-size: 14px;
}

.max-btn {
font-size:16px;
}
}
`


import React, {useEffect, useRef, useState} from 'react';
import {useConnect} from "../../../../../api/contracts";
import {Table, Button, Select, Descriptions, message, Form, List, Input, notification, Card, Empty} from 'antd';
import {getContractByName, getContractByContract} from "../../../../../api/connectContract";
import {dealMethod, viewMethod} from "../../../../../utils/contractUtil"
import {useNavigate, useLocation} from "react-router-dom";
import WithdrawViewStyle from "./style";
import judgeStatus from "../../../../../utils/judgeStatus";
import {showNum} from "../../../../../utils/bigNumberUtil";
import BigNumber from "bignumber.js";

import addressMap from "../../../../../api/addressMap";
import {MaxUint256, SBDDecimals} from "../../../../../config/constants";
import {getWithdrawRecords} from "../../../../../graph/lockGQL";
import {formatAddress} from "../../../../../utils/publicJs";
import {dealTime} from "../../../../../utils/timeUtil";
import {useTranslation} from "react-i18next";

const WithdrawView = (props) => {
    let {state, dispatch} = useConnect();
    const [SBDCoinBalance, setSBDCoinBalance] = useState(0)
    const [SRTCoinBalance, setSRTCoinBalance] = useState(0)
    const [myRecords, seMyRecords] = useState([])

    const [lockAddr, setLock] = useState(0)
    const [srtBalance, setSrtBalance] = useState(0)
    const [sbdBalance, setSBDBalance] = useState(0)
    const [exchangeAmount, setExchangeAmount] = useState(0)
    const [activeNav, setActiveNav] = useState(0)
    const [srtDecimal, setSRTDecimal] = useState(18)
    const [chooseWithdraw, setChooseWithdraw] = useState("1")
    const [inputValue, setInputValue] = useState(undefined)
    const [inputSRTValue, setInputSRTValue] = useState(undefined)

    const [owner, setOwner] = useState("")
    const [allowance, setAllowance] = useState(0)
    const [adminCoin, setAdminCoin] = useState({})

    const [pageCount, setPageCount] = useState(20)
    const [curPage, setCurPage] = useState(1)

    const [addressValue, setAddressValue] = useState(undefined)

    const [lockInfo, setLockInfo] = useState({})
    const [canWithdrawSRT, setCanWithdrawSRT] = useState(0)

    const [userTotalSrt, setUserTotalSrt] = useState(0)

    const [form] = Form.useForm();
    const {t} = useTranslation();
    const location = useLocation()
    const handleViewMethod = async (name, params) => {
        let contractTemp = await getContractByName("spbd", state.api,)
        if (!contractTemp) {
            message.warn("Please connect", 5)
        }
        return await viewMethod(contractTemp, state.account, name, params)
    }
    const handleLockViewMethod = async (name, params) => {
        let contractTemp = await getContractByContract("lock", lockAddr, state.api,)
        if (!contractTemp) {
            message.warn("Please connect", 5)
        }
        return await viewMethod(contractTemp, state.account, name, params)
    }
    const openNotification = (message) => {
        notification.error({
            message: message,
            description:
                "",
            onClick: () => {
            },
        });
    };

    const handleDealMethod = async (name, params) => {
        let contractTemp = await getContractByContract("lock", lockAddr, state.api,)
        if (!contractTemp) {
            openNotification("Please connect")
        }
        return dealMethod(contractTemp, state.account, name, params)
    }
    const handleFundMethod = async (name, params) => {
        let contractTemp = await getContractByName("spbd", state.api,)
        if (!contractTemp) {
            openNotification("Please connect")
        }
        return dealMethod(contractTemp, state.account, name, params)
    }
    const getOgLock = async () => {

        const lockAddr = await handleViewMethod("ogLock", [])
        setLock(lockAddr)
    }

    const getPoolBalance = async () => {

        let contractTemp = await getContractByContract("erc20", addressMap["SRT"].address, state.api,)
        const decimal = await viewMethod(contractTemp, state.account, "decimals", [])
        setSRTDecimal(decimal)

        let contractTemp2 = await getContractByContract("erc20", addressMap["SBD"].address, state.api,)
        const decimal2 = await viewMethod(contractTemp2, state.account, "decimals", [])
        setSRTDecimal(decimal2)
        const balance = await getCoinInfo(addressMap["SBD"].address, addressMap["Swap"].address)
        const balance2 = await getCoinInfo(addressMap["SRT"].address, addressMap["Swap"].address)
        setSBDCoinBalance(balance)
        setSRTCoinBalance(balance2)

        const mb = await getCoinInfo(addressMap["SBD"].address, state.account)
        const mb2 = await getCoinInfo(addressMap["SRT"].address, state.account)
        setSBDBalance(mb)
        setSrtBalance(mb2)
    }
    const getLockInfo = async () => {
        let length = await handleLockViewMethod("getUserLockLength", [state.account])
        let totalLock = 0
        for (let i = 0; i < length; i++) {
            const item = await handleLockViewMethod("userLockInfos", [state.account, i])
            totalLock = BigNumber(totalLock).plus(item.amount / 10 ** SBDDecimals)
        }
        try {
            let canClaim = await handleLockViewMethod("getUserCanClaim", [state.account])
            let CanClaimSrt = await handleViewMethod("CanClaimSrt", [state.account])
            let totalR = await handleViewMethod("userTotalSrt", [state.account])
            setUserTotalSrt(BigNumber(totalR).div(10 ** 18).toString())

            setCanWithdrawSRT(BigNumber(CanClaimSrt).div(10 ** 18).toString())
            setLockInfo({
                totalLock,
                canClaim: canClaim / 10 ** 18
            })
        } catch (e) {
            console.log(e)
        }


    }
    const handleWithdrawSRT = async () => {

        await handleFundMethod("ClaimSrt", [state.account])
        getLockInfo()

    }
    const handleWithdraw = async () => {
        if (!inputValue) {
            message.error("Please input amount", 2)
            return
        }
        await handleDealMethod("Claim", [BigNumber(inputValue).multipliedBy(10 ** SBDDecimals).toFixed(0)])
        getLockInfo()
        setTimeout(() => {
            getLockInfo()
            getWithdrawRecord()
        }, 2000)
    }
    const handleApprove = async () => {
        let contractTemp = await getContractByContract("erc20", addressMap["SRT"].address, state.api,)
        await dealMethod(contractTemp, state.account, "approve", [addressMap["Swap"].address, MaxUint256])
        getAllowance()
    }
    const getAllowance = async () => {
        let contractTemp = await getContractByContract("erc20", addressMap["SRT"].address, state.api,)
        const res = await viewMethod(contractTemp, state.account, "allowance", [state.account, addressMap["Swap"].address])
        setAllowance(res)
    }
    const getAdminCoinInfo = async (addressValue) => {
        if (!state.api.utils.isAddress(addressValue)) {
            return
        }
        let contractTemp = await getContractByContract("erc20", addressValue, state.api,)
        const decimal = await viewMethod(contractTemp, state.account, "decimals", [])
        const name = await viewMethod(contractTemp, state.account, "name", [])
        const symbol = await viewMethod(contractTemp, state.account, "symbol", [])
        console.log(name)
        let balance = await viewMethod(contractTemp, state.account, "balanceOf", [addressMap["Swap"].address])
        balance = BigNumber(balance).div(10 ** decimal).toString()
        setAdminCoin({
            balance,
            decimal,
            name,
            symbol
        })

    }
    const getCoinInfo = async (coinAddr, toAddr) => {
        if (!state.api.utils.isAddress(coinAddr)) {
            return
        }
        let contractTemp = await getContractByContract("erc20", coinAddr, state.api,)
        const decimal = await viewMethod(contractTemp, state.account, "decimals", [])
        let balance = await viewMethod(contractTemp, toAddr, "balanceOf", [toAddr])
        balance = BigNumber(balance).div(10 ** decimal)
        return balance

    }
    const getData = async (page) => {

        getLockInfo()

    }


    useEffect(async () => {
        let judgeRes = await judgeStatus(state)
        if (!judgeRes) {
            return
        }
        getOgLock()
    }, [state.account]);
    const getWithdrawRecord = async () => {
        const res = await getWithdrawRecords()
        if (res && res.data) {
            let tempArr = []
            res.data.withdrawRecords.forEach(item => {
                if (item.user == state.account) {
                    tempArr.push(item)
                }
            })
            seMyRecords(tempArr)
        }
    }
    useEffect(async () => {
        getWithdrawRecord()
        let judgeRes = await judgeStatus(state)
        if (!judgeRes) {
            return
        }
        if (!lockAddr) {
            return
        }
        await getData()

    }, [lockAddr]);

    const whitelistColumns = [
        {
            title: 'Address',
            dataIndex: '',
            key: '',
        },

    ]
    const WithdrawSelect = [

        {
            label: "SBD",
            value: '1',
        },
        {
            label: "SRT",
            value: '2',
        },
    ];
    return (
        <WithdrawViewStyle>
            {/*<div className="header-box">*/}

            {/*<div className="header-item">*/}
            {/*    <div className="name">*/}
            {/*        SRT Amounts*/}
            {/*    </div>*/}
            {/*    <div className="value">*/}
            {/*        {showNum(SRTCoinBalance)} SRT*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/* </div>*/}
            <div className="operate-box">
                <div className="flex-box select-box">
                    {t("Choose to Withdraw Tokens")}
                    <Select
                        value={chooseWithdraw}
                        onChange={(e) => {
                            setChooseWithdraw(e)
                        }}

                        options={WithdrawSelect}
                    />
                </div>
                {chooseWithdraw == 1 ? (
                    <div>
                        <div className="inline">
                            <div className="left">
                                {t("Your Lock Amount(s)")}
                            </div>
                            <div className="right">
                                {showNum(lockInfo.totalLock)}
                            </div>
                        </div>
                        <div className="inline">
                            <div className="left">
                                {t("You can withdraw")}
                            </div>
                            <div className="right">
                                {showNum(lockInfo.canClaim)}
                            </div>
                        </div>
                        <Form form={form} name="control-hooks" className="form">
                            <strong className="withdraw-header" style={{margin: "10px 0"}}>
                                {t("Withdraw Amount(s)")}
                            </strong>
                            <div className="withdraw-part" style={{marginTop: '8px'}}>

                                <Form.Item
                                    name="claimAmount"
                                    validateTrigger="onBlur"
                                    validateFirst={true}

                                >
                                    <div className="input-box">
                                        <Input placeholder="0" value={inputValue}
                                               onChange={(e) => {
                                                   setInputValue(e.target.value)
                                               }}></Input>
                                        <div className="max-btn" onClick={() => {
                                            setInputValue(lockInfo.canClaim)
                                        }}>
                                            Max
                                        </div>
                                    </div>
                                </Form.Item>

                            </div>

                            <Button className="submit-btn" onClick={() => {
                                handleWithdraw()
                            }}>{t("Withdraw")}</Button>


                        </Form>
                    </div>

                ) : <div>
                    <div className="inline">
                        <div className="left">
                            Total reward
                        </div>
                        <div className="right">
                            {BigNumber(userTotalSrt).toFixed(4)}
                        </div>
                    </div>
                    <div className="inline">
                        <div className="left">
                            {t("You can withdraw")}
                        </div>
                        <div className="right">
                            {BigNumber(canWithdrawSRT).toFixed(4)}
                        </div>
                    </div>

                    <Form form={form} name="control-hooks" className="form">

                        <Button className="submit-btn" onClick={() => {
                            handleWithdrawSRT()
                        }}>Withdraw</Button>


                    </Form>
                </div>}

            </div>
            <div className="superdao-list-box claim-list">
                <div className='listheadert'>
                    <div className="list-header ">
                        <div className="col">
                            {t("No.")}
                        </div>

                        <div className="col">
                            Address
                        </div>

                        <div className="col">
                            Withdraw SBD
                        </div>

                        <div className="col">
                            {t("Burn SVT")}
                        </div>

                        <div className="col">
                             {t("Time(UTC)")}
                        </div>
                    </div>
                    {myRecords.length == 0 ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/> :
                        myRecords.map((item, index) => (
                            index >= pageCount * (curPage - 1) && index < pageCount * curPage &&
                            <div className="list-item">
                                <div className="col">
                                    {myRecords.length - index}
                                </div>
                                <div className="col">
                                    {formatAddress(item.user)}
                                </div>
                                <div className="col">
                                    {showNum(BigNumber(item.amount).dividedBy(10 ** SBDDecimals))}
                                </div>
                                <div className="col">
                                    {showNum(BigNumber(item.amount).dividedBy(10 ** SBDDecimals))}
                                </div>
                                <div className="col">
                                    {dealTime(item.blockTimestamp)}
                                </div>

                            </div>
                        ))}

                </div>

            </div>
        </WithdrawViewStyle>
    )
}
export default WithdrawView

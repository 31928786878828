import styled from "styled-components";
import Bg from "../imgs/bg.png"
import Back from "../imgs/backg.png";
import light from "../imgs/light.png";

export default styled.div`
  background: #fff;
  footer {
    display: flex;
    width: 100%;
    .footer-content {
      display: flex;
      justify-content: space-between;
      width: 70%;
      margin: 0 auto;
      padding: 20px 0;
      align-items: center;

      .logo {
        width: 140px;
        height: 60px;
      }

      p {
        width: 55%;
        font-size: 16px;
        font-family: Poppins-Medium, Poppins;
        font-weight: 500;
        color: #5E6282;
        line-height: 30px;
        margin-top: 20px;
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .links {
        display: flex;

        .link {
          margin-left: 40px;
          font-size: 21px;
          font-family: Poppins-Bold, Poppins;
          font-weight: bold;
          color: #080809;
          line-height: 26px;
        }
      }

      .link-icons {
        margin: 20px 0;
        display: flex;

        .link-icon {
          width: 30px;
          height: 30px;
          cursor: pointer;
          margin-left: 20px;
        }
      }

      .copyright{
        font-size: 16px;
font-family: Poppins-Medium, Poppins;
font-weight: 500;
color: #5E6282;
line-height: 20px;
      }
    }
  }

  .container {
    .col {
      display: flex;
      justify-content: center;

    }
  }

  .page4 {
    margin:10em auto;
    .phone-row{
        display: none;
    }
    .row {
        border-radius:80px 10px 10px 10px;
      background: rgba(255, 241, 218, 0.2);
      padding-top: 30px;
      position:relative;
      .tel{
        position: absolute;
        top:-10px;
        right:-20px;
                width: 68px;
                height: 42px;;
            }
    }
    
    .row{
      display: flex!important;
      flex-wrap: nowrap;
      justify-content: space-between;
      .col{
        flex: auto !important;
      }
    }
    h2 {
      text-align: center;
      margin: 60px 0 30px;
      color:#14183E;
      font-size: 50px;
      font-family: Volkhov-Bold, Volkhov;
      font-weight: bold;
    }
    
    .col {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      .qr {
        width: 50%;
      }

      .icon {
        width: 30px;
        height: 30px;
        margin: 30px auto;

      }
    }
  }

  .page3 {
    .row{
        
      display: flex!important;
      flex-wrap: nowrap;
      justify-content: space-between;
      .col{
        flex: auto !important;
      }
    }
    .container {
      margin-top: 60px;

      h2 {
        font-size: 50px;
        font-family: Volkhov-Bold, Volkhov;
        font-weight: bold;
        color: #14183E;
        text-align: center;
      }

      .row {
        margin-top: 50px;
      }

      .col {
        .item-box {
          width: 100%;
          margin-top: 20px;
          padding: 30px;
          background: #FFFFFF;
          box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0), 0px 8px 7px 0px rgba(0, 0, 0, 0.01), 0px 20px 13px 0px rgba(0, 0, 0, 0.01), 0px 39px 25px 0px rgba(0, 0, 0, 0.01), 0px 65px 47px 0px rgba(0, 0, 0, 0.02), 0px 100px 80px 0px rgba(0, 0, 0, 0.02);
          border-radius: 36px 36px 36px 36px;
        }

        .name {
          font-size: 26px;
          margin-top: 10px;
          font-family: Roboto-Bold, Roboto;
          font-weight: bold;
          color: #5E6282;
        }

        h2 {
          font-size: 50px;
          font-family: Volkhov-Bold, Volkhov;
          font-weight: bold;
          color: #14183E;
          text-align: left;
        }

        h3 {
          font-size: 30px;
          font-family: Roboto-Bold, Roboto;
          font-weight: bold;
          color: #1E1D4C;
          line-height: 35px;
        }

        p {
          font-size: 18px;
          font-family: Roboto-Medium, Roboto;
          font-weight: 500;
          color: #5E6282;
          line-height: 26px;
          width: 100%;
        }
      }

    }
  }

  .content5 {
    p {
      font-size: 20px;
      font-family: Poppins-Medium, Poppins;
      font-weight: 500;
      color: #5E6282;
      width: 1000px;
      margin: 0 auto;
      text-align: center;
    }
    h2{
        margin-top:3em;
    }
  }

  .page2 {
    .row{
        display: flex;
        justify-content:space-between;
      
      .col{
        width: 31%!important;
      }
    }
    .content2{
      
      .row{
        display: flex;
        justify-content:space-between;
        .col{
            .left{
                h2{
                    margin-top: 2em;
                }
                .phone-pic2{
                    display: none;
                }
            }
            .right{
                img{
                    margin-left:18em;
                }
            }
        }
      }
      
    }
    h2 {
      font-size: 50px;
      font-family: Volkhov-Bold, Volkhov;
      font-weight: bold;
      color: #14183E;
      text-align: center;
     
    }
    .content4 {
      .row{
        margin: 6em auto;
        display: flex!important;
        flex-wrap: nowrap;
        justify-content: space-between;
        .right-bor{
            margin: 3em 0;
        }
        .col{
          
            position: relative;
          flex: auto !important;
          .border-bott{
            border-radius:5px;
            position: absolute;
            top: 40px;
            width: 87%;
box-shadow: 0px 0px 1px 0px rgba(0,0,0,0), 0px 2px 1px 0px rgba(0,0,0,0.01), 0px 4px 3px 0px rgba(0,0,0,0.01), 0px 8px 5px 0px rgba(0,0,0,0.01), 0px 13px 10px 0px rgba(0,0,0,0.02), 0px 20px 16px 0px rgba(0,0,0,0.02);
            margin: 30px 0px 0 60px;
            height: 210px;
          border: 0px solid #F7F7F7;
          }
          .right{
            z-index:1;
            p{
                margin:0;
            }
          }
          .left{
            h3{
                margin: 20px 0;
                font-size: 50px;
      font-family: Volkhov-Bold, Volkhov;
      font-weight: bold;
      color: #14183E;
      line-height: 55px;
            }
          }
        }
      }
       .text-center{
         display: flex;
         text-align: center;
       }
      .align-items-start{
        justify-content: center!important;
      }
      
      p {
        width: 60%;
        margin-top: 30px;
        line-height:32px;

      }

      .right {
        display: flex;
        justify-content: flex-end;

        margin-left:-90px;
        margin-right: 40px;
        p {
          width: 70%;
          background: #FFFFFF;
          box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0), 0px 8px 7px 0px rgba(0, 0, 0, 0.01), 0px 20px 13px 0px rgba(0, 0, 0, 0.01), 0px 39px 25px 0px rgba(0, 0, 0, 0.01), 0px 65px 47px 0px rgba(0, 0, 0, 0.02), 0px 100px 80px 0px rgba(0, 0, 0, 0.02);
          border-radius: 10px 10px 10px 10px;
          padding: 30px;
        }
      }
    }

    .content3 {
   
      .row{
        margin-top:8em;
        display: flex!important;
        flex-wrap: nowrap;
        justify-content: space-between;
        .col{
          flex: auto !important;
          .left {
        img {
          width: 80%;
          margin-left:-80px;
        }
      }
    
        }

          .right {
        width: 45% !important;
        .phone-pic3{
            display: none;
        }
      }
      }
      
    }

    .content2 {
        margin:3em auto;
      .row{
        display: flex!important;
        flex-wrap: nowrap;
        justify-content: space-between;
        .col{
          flex: auto !important;
          
        }
      }
     

      .list-item:nth-child(n+1) {
        margin-top: 30px;

        p {
          margin-top: 10px;
        }
      }

      .right {
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 100%;
        }
      }
    }

    .container {
      margin-top: 60px;
      width: 70%;

      .col {
        width: 30%;
        .item-box {
          width: 100%;
          margin-top: 20px;
          padding: 2em;
          background: #FFFFFF;
          box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0), 0px 8px 7px 0px rgba(0, 0, 0, 0.01), 0px 20px 13px 0px rgba(0, 0, 0, 0.01), 0px 39px 25px 0px rgba(0, 0, 0, 0.01), 0px 65px 47px 0px rgba(0, 0, 0, 0.02), 0px 100px 80px 0px rgba(0, 0, 0, 0.02);
          border-radius: 36px 36px 36px 36px;
        }

        .name {
          font-size: 26px;
          font-family: Roboto-Bold, Roboto;
          font-weight: bold;
          color: #5E6282;
          margin-top: 10px;
        }

        h2 {
          font-size: 50px;
          font-family: Volkhov-Bold, Volkhov;
          font-weight: bold;
          color: #14183E;
          text-align: left;
        }

        h3 {
          font-size: 30px;
          font-family: Roboto-Bold, Roboto;
          font-weight: bold;
          color: #1E1D4C;
          margin: 1.8em auto 1em auto;
          line-height: 35px;
        }

        p {
          font-size: 18px;
          font-family: Roboto-Medium, Roboto;
          font-weight: 500;
          color: #5E6282;
          line-height: 32px;
        }
      }

    }
  }

  .page1 {
    background: url("${Bg}");
    background-size: cover;
    /* margin-bottom:2em; */
    height: 850px;
width: 100%;
.phone-pic{
            display: none;
        }
    .container {
        
        width: 70%;
      margin: 0 auto;
    }

    h1 {
      margin-top: 100px;
      font-size: 80px;
      font-family: Volkhov-Bold, Volkhov;
      font-weight: bold;
      color: #181E4B;
      line-height: 120px;
    }

    p {
      font-size: 18px;
      font-family: Poppins-Medium, Poppins;
      font-weight: 500;
      color: #5E6282;
      width: 477px;
    }

    .button {
      width: 170px;
      margin-left: -24px;
    }
  }

  header {
    width: 100%;

    .header-content {
      display: flex;
      justify-content: space-between;
      width:68%;
      margin: 0 auto;
      padding: 20px 0;
      align-items: center;

      .logo {
        width: 140px;
        height: 60px;
      }

      .button {
        width: 170px;
      }
    }
  }



  @media screen and (max-width: 1440px){
    
    header{
        .header-content{
            width: 70%;
            padding: 20px 1em;
        }
    }
    .page1 {
        height: 650px;
        
        h1 {
        font-size:60px;
        margin-top: 75px;
    }
    p{
        font-size:15px;
    }
}

.page2{
    .container{
        .row{
        .col{
          
          h3{
              font-size: 23px;
          }
          p{
              font-size: 15px;
              line-height:25px;
          }
          .name{
              font-size:20px;
          }

      }
         .col{
            .item-box{
            width: 100%;
            padding:0em 2em;
            border-radius: 27px 27px 27px 27px;
            margin-top:0px;
            img{
                width: 20%;
                margin:1.5em;
            }
        }
         h2{
            font-size:38px;
         }
        }
    }
       
    }
     

    .content2{
        .row{
            .col{
                .left{
            padding-left:7em;
            .list-box{
                
                .list-item{
                    p{
                        width: 580px;
                    }
                }
            }
        }
        .right{
            img{
                margin-left:12em;
            }
        }
            }
        }
    }
    .content3{
        .row{
            .col{
               
                .left{
                    
                    img{
                margin-left:-5em;
                
            }
                }
            }
            .right{     
                width: 26%!important;
                    p{
                        width:550px;
                        margin-left:-100px;
                    }    
            }
        }
    }
    .content4{
      
        .row{
            .col{

                .left{
                   p{
                    width: 85%;
                    line-height:25px;
                   }
                   h3{
                    
                    font-size:38px;
                    line-height: 44px;
                   }
                }
               
            }
            .right-bor{
                .right{
                    width: 900px;
                    margin-left:-90px;
                    margin-right: 20px;
                    p{
                        margin:0em;
                        padding: 2em;
                    }
                }
                .border-bott{
            position: absolute;
            top: 40px;
            width: 95%;
            margin: 30px 0px 0 40px;
            height: 200px;
          }
        
        }
    }

   
    h2 {
        font-size: 38px;
    }
  }
}
  .page3{
    margin:2em auto;
    .content5{   
         margin: 8em auto;
        h2{
        
        font-size:38px;
        }
        p{
            width:60%;
            font-size:15px;
        }

        .row{
            width: 78%;
    margin: 1em auto;
    .col{
        .item-box{
            border-radius: 27px 27px 27px 27px;
            h3{
                font-size: 23px;
            }
            p{
                font-size: 15px;
            }
        }
    }
        }
    }
    
  }
  .page4{
    
    .container{
        h2{
            font-size:38px;
        }
        .row{
            width: 60%;
            margin:0 auto;
            .tel{
                width: 55px;
                height: 32px;;
            }
        }
    }
  }

  footer{
     .footer-content{
        width: 70%;
        .left{
            p{
                width: 60%;
               font-size:15px; 
            }
            
        }
        .right{
            .links{
                .link{
                    font-size:16px; 
                }
            }
            .link-icons{
                .link-icon{
                    width: 23px;
                    height: 23px;
                }
            
            }    
            .copyright{
                    font-size:13px;
                }
        }
  }
  }
 
}

@media screen and (max-width: 450px){
    .page1{
        background: url("${light}");
        width: 100%;
        background-size: cover;

        height: 550px;

        header{
            .header-content{
                width: 90%;
               
                .logo{ 
                    width: 30%;
                    height: auto;
                    margin: 0.5em 0;
                }
                .button{
                    display: none;
                }
                
            }
          
        }
        .phone-pic{

display: block;
width:85%;
margin:0 auto;
}
        .container{
            width: 95%;
            margin:0 auto;
            text-align:center;
            h1{
                margin:0em;
                font-size:42px;
                text-align:center;
            }
            p{
                width: 100%;
                margin:0 auto;
                text-align:center;
                font-size: 14px;
            }
            .button{
                margin:1.5em auto;
            }
        }
    }
    .page2{
        h2{
            margin: 8em auto 0em auto;
            font-size: 24px;
        }
        .container{
            margin:0 auto;
            width: 95%;
            .row{
                display: block;
                width: 100%;
                margin:0 auto;
                
                .col{
                    margin:1em auto;
                    width: 100%!important;
                    .item-box{
                        width: 100%;
                        img{
                            width: 10%;
                        }
                        h3{
                            margin: 0 auto 0.6em auto;
                            font-size:17px;
                            line-height:25px;
                        }
                        p{
                            font-size:14px;
                            line-height:20px;
                        }
                    }
                }
            }
        }
        .content2{
            width: 95%;
            margin:0 auto;
            .row{
                display: block!important;
                width: 100%;
                margin:0 auto;
                .col{
                    margin:1em auto;
                    width: 100%!important;
                    .left{
                        padding: 0em;
                        .phone-pic2{
                            display: block;
                            img{
                                width: 100%;
                            }
                        }
                        h2{
                            font-size:24px;
                            margin: 2.5em auto 0em auto;
                        }

                        .list-box{
                            .list-item{
                                width: 100%;
                                .name{
                                    font-size:16px;

                                }
                                p{
                                font-size:14px;
                                    width: 100%;
                                    text-align:justify;
                                }
                            }
                        }
                    }
                    .right{
                        display: none;
                    }
                }
                
            }
        }

        .content3{
            width: 95%;
            margin:0 auto;
            .row{
                display: block!important;
                width: 100%;
                margin:0 auto;
                .col{
                    margin:1em auto;
                    width: 100%!important;

                    h2{
                        font-size:24px;
                        margin: 0em auto 1.5em auto;
                    }
                    .left{
                        img{
                            display: none;
                        }
                        padding: 0em;
                        .phone-pic2{
                            display: block;
                            img{
                                width: 100%;
                            }
                        }
                    }
                }
                .right{
                    .phone-pic3{
                        display: block;
                        margin: 0 auto;
                        text-align:center;
                        img{
                            width: 95%;
                        }
                    }
                    p{
                        font-size:14px;
                        width: 100%;
                        margin:0px;
                    }
                }
            }
        }

        .content4{
            width: 95%;
            margin:4em auto;
            .row{
                display: block!important;
                width: 100%;
                margin:0 auto;
                .col{
                    margin:1em auto;
                    width: 100%!important;
                    .left{
                        h3{
                            font-size:24px;
                            line-height: 28px;
                            margin:1em 0;
                        }
                        p{
                            width: 100%;
                            font-size:14px;
                            line-height:20px;
                        }
                    }
                }
                .right-bor{
                    .right{
                        margin:0 auto;
                        width: 100%;
                        p{
                            margin:0 auto;
                            width: 80%;
                            font-size:14px;
                            border-radius: 0px;
                        }
                    }

                 
                .border-bott{
            position: absolute;
            top: 40px;
            width: 90%;
            margin: 20px 0px 0 0px;
            height: 200px;
          }
        
     
                }
            }
        }


    }

    .page3{
        margin: 1em auto;
        .content5{
            width: 100%;
            h2{
                width: 100%;
                font-size:24px;
                margin: 0em 0 1em 0;
            }
            p{
                width: 100%;
                font-size:14px;
            }
            .row{
                width: 100%;
                display: block!important;
                margin: 0 auto;
                .col{
                    margin: 0 auto;
                    width: 100%;
                    .item-box{
                        padding: 25px 20px;
                        border-radius:22px;
                        
box-shadow: 0px 1px 2px 0px rgba(0,0,0,0), 0px 4px 3px 0px rgba(0,0,0,0.01), 0px 10px 7px 0px rgba(0,0,0,0.01), 0px 20px 13px 0px rgba(0,0,0,0.01), 0px 34px 24px 0px rgba(0,0,0,0.02), 0px 52px 42px 0px rgba(0,0,0,0.02);
                        h3{
                            font-size:17px;
                        }
                        p{
                            font-size:14px;
                            line-height: 20px;
                        }
                    }
                }
            }
        }
    }
    .page4{
        margin:1em auto;
        .container{
            height: 950px;
            position: relative;
            background: #FFFFFF;
            width: 100%;
            h2{
                font-size:24px;
            }
            .row{
                display: none!important;
             
            }
            .phone-row{
                display: block;
                .qr{
                    width: 40%;
                }
                .icon{
                    width: 22px;
                    height: 22px;;
                }
            }
            .bottom-round{
                /* text-align:right; */
                position: absolute;
                right: 0px;
                bottom: 0px;
			width: 150px;
			height: 150px;
			margin: 0 auto;
            background: radial-gradient(160px at right bottom, #D5AEE4, #FFFFFF);
		

            }
        }
    }
    footer{
        display: none;
    }
}

`


import styled from "styled-components";
export default styled.div`

  .active-list-row{
    .col:nth-child(2){
      width: 60%!important;
    }
  }
  .address{
background: none !important;
border:none!important;
  }
 
.sc{
    width: 25px;
  }


  .admin3-list {
    margin: 1em 0em;
    background: rgba(28, 28, 28, 1);
    .list-header {
        display: flex;
        justify-content:flex-start;
            padding: 20px 0.6em;
            border-bottom:1px solid rgba(234, 234, 234, 0.10);
        }
        
 .list-item, .list-header {
   justify-content: flex-start;
     .col {
       text-align: left;
       font-size:16px;
   &:nth-child(1) {
     margin-left:100px;
     width:30%;
   }

   &:nth-child(2) {
     width:50%;
     margin-left:10px;
   }

   &:nth-child(3) {
     width: 10%;
     margin-left:10px;

   }

 }
}

.list-item:last-child {
         border-bottom:none;
     }
     .list-item {
        color: #FFFFFF;
            display: flex;
        justify-content:flex-start;
            padding: 1em 0.5em;
        width: 100%;
            margin: 0 auto;
            border-radius:0px;
            border-bottom:1px solid rgba(234, 234, 234, 0.10);
         &:nth-child(even){
            background:rgba(62, 62, 62, 1);   
        }
       .col {
         overflow: hidden;
         padding-left: 0%;
         
       }     
       .address{
a{
 color:#CD9E57;
}
     }
}
}
.form{
    width: 70%;
    .ant-form-item{
        width: 80%;
        float:left;
    }
}
.ant-form-item-label>label{
    font-size:18px;
    font-weight:bold;
    color: #8A8080;

 }
 .ant-form-item-control-input{
    background: #8A8080;
 }
.addsbt{
    text-align: center;
    width: 13%;
    padding: 6px;
    font-size: 18px;
    margin: 1.5em auto;
    color:#FFAE4E;
    border: 1px solid;
    
border-radius: 8px;
}

  .part3 {
 
    .btns {
width: 65%;
  margin: 0px 0;
  height: 80px;
  .add-btn{
    width: 22%;
    height: 40px;
  font-size: 18px;
  color: rgb(51, 51, 51);;
  font-weight:bold;
}
    }

    .tip-box{
        background: rgb(28, 28, 28);
        border-radius: 15px 15px 15px 15px;
padding: 25px 35px;
border: 1px solid rgba(255,255,255,0.15);
    }
    .tip {
        color:#8A8080;
        font-size:18px;
      strong {
        color: #FFFFFF;
      }
    }

    
  }



  @media screen and (max-width: 1440px){
    .ant-modal-body {
    p {
        font-size:16px;
    }
  }
    .ant-form-item-label>label{
    font-size:16px;
    font-weight:bold;
    color: #8A8080;
 }

 .part3{ 
    .tip{
    font-size:16px;
 }
    .btns {
    .add-btn{
        width: 25%;
  font-size: 16px;
  color: rgb(51, 51, 51);;
  font-weight:bold;
}
    }
 }
    .admin3-list {
        .list-header{
            font-size: 15px;
        }
        .list-header,.list-item{
        .col {
            font-size: 15px;
            &:nth-child(1) {
     margin-left:60px;
   }

            }
        }
 
    
 }
 .addsbt{
    text-align: center;
    width: 13%;
    padding: 5px;
    font-size: 16px;
    margin: 1.5em auto;
    color:#FFAE4E;
    border: 1px solid;
    
border-radius: 8px;
}

}

@media screen and (max-width: 450px){
    .ant-modal-body {
    p {
        font-size:16px;
    }
}
    .ant-form-item-label>label{
    font-size:14px;
    font-weight:bold;
    color: #8A8080;
 }
    .admin3-list {
        overflow-x: scroll;
       
        .list-header{
            font-size: 14px;
            padding: 20px 0.5em;
        }
        .list-item{
            padding: 10px 0.5em;
        }
        .list-header,.list-item{
        .col {
            font-size: 14px;
            &:nth-child(1) {
     margin-left:5px;
     width:30%;
   }
   &:nth-child(2) {
     margin-left:5px;
     width:45%;
   }
   &:nth-child(3) {
     margin-left:5px;
     width:15%;
   }

            }
        }
 
    
 }
 .form{
    width: 100%;
}
 .part3{
    .tip{
    font-size:14px;
 }
    
    .btns {
  width: 100%;
      margin: 0px;
 
  .add-btn{
    width: 40%;
     font-size: 14px;
  color: rgb(51, 51, 51);;
  font-weight:bold;
  }
    }

}

 .addsbt{
    text-align: center;
    width: 40%;
    padding: 5px;
    font-size: 14px;
    margin: 1.5em auto;
    color:#FFAE4E;
    border: 1px solid;
    
border-radius: 8px;
}

}
`


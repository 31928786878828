import styled from "styled-components";
export default  styled.div`


  .coount-down {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 60px;

    .title {
      font-size: 30px;
      font-family: Roboto, Roboto;
      font-weight: bold;
      color: #FFFFFF;
    }

    .time-box {
      font-size: 80px;
      font-family: Roboto, Roboto;
      font-weight: 600;
      line-height: 104px;
      background: linear-gradient(180deg, #F2D7A4 0%, #CC8A35 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent
    }

    .can-receive {
      font-size: 30px;
      font-family: Roboto, Roboto;
      font-weight: bold;
      margin-top: 30px;
      text-transform: uppercase;
      color: #FFFFFF;
    }

    .usdt-btc {
      margin-top: 30px;
      font-size: 50px;
      font-family: Roboto, Roboto;
      font-weight: 600;
      line-height: 65px;
      background: linear-gradient(180deg, #F2D7A4 0%, #CC8A35 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

    }

    .unqualified {
      font-size: 30px;
      background: #737373;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .bonus-btn {
      idth: 200px;
      height: 60px;
      background: linear-gradient(229deg, #DAB163 0%, #CC8A35 100%);
      border-radius: 10px 10px 10px 10px;
    }
  }

  .detail-box {
    padding: 30px;
    width: 100%;
    background: NONE;

    .usdt-box {
      align-items: center;
      justify-content: center;
      height: 100px;
    }

    .total-usdt {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-left: 100px;

      &:nth-child(1) {
        margin-left: 0;
      }

      .name {
        font-size: 20px;
        font-family: Roboto, Roboto;
        font-weight: 500;
        color: #8A8080;
        height: 50px;
      }

      .value {
        font-size: 60px;
        font-family: Roboto, Roboto;
        font-weight: 600;
        background: linear-gradient(180deg, #F2D7A4 0%, #CC8A35 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        span {
          font-size: 20px;
          margin-left: 10px;
        }
      }
    }

    .panel-title {
      margin-top: -10px;
      text-align: center;
      text-transform: uppercase;
    }

    .bonus-btn {
      width: 200px;
      height: 60px;
      background: linear-gradient(229deg, #DAB163 0%, #CC8A35 100%);
      border-radius: 10px 10px 10px 10px;
      margin-top: 50px;

      span {
        font-size: 24px;
        font-family: Roboto, Roboto;
        font-weight: 600;
        line-height: 31px;
        background: linear-gradient(31deg, #1B1B1B 0%, #3E3E3E 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .detail-list {
      width: 100%;
      padding-top: 20px;
      display: flex;
      justify-content: space-between;


      .flex-box {
        align-items: center;
        justify-content: space-between;
        flex-direction: column;

        .Tooltip {
          position: absolute;
          height: 30px;
          top: 0;
        }

        .name {
          font-size: 18px;
          position: relative;
          color: rgb(138, 128, 128);
          margin-top: 20px;

        }

        .value {
          text-align: center;
          font-weight: bold;
          font-size: 26px;
        }
      }


    }
  }

  .list-box {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    .my-spt {
      display: flex;
      justify-content: center;
      width: 100%;

      .content-item {
        text-align: center;
        margin-left: 30px;

        &:nth-child(1) {
          margin-left: 0;
        }

        .value {
          font-size: 30px;
          font-family: Roboto, Roboto;
          font-weight: bold;
          color: #FFFFFF;
          line-height: 39px;

        }

        .name {
          font-size: 24px;
          font-family: Roboto, Roboto;
          font-weight: 500;
          color: #8A8080;
          margin-top: 20px;
        }
      }
    }


    .right-part {
      text-align: center;
      margin: 10px;
      border-radius: 10px;
      background: rgb(28, 28, 28);
      padding: 20px;

      .content-item {
        .value {
          font-size: 30px;
          font-weight: bold;
        }

        &:nth-child(2) {
          margin-top: 10px;
        }
      }
    }
  }

  .bonus-record {
    margin-top: 30px;
  }

  .panel-box {
    .panel-container {
      .detail-btn {
        margin-left: 20px;

        .ant-btn {
          font-family: Roboto, Roboto;
          font-weight: bold;
          color: #CD9E57;
          border: none;
        }
      }

      .nft-list-box {
        width: auto;
        margin: 1em 0;
        background: rgba(28, 28, 28, 1);

        .nft-header {
          display: flex;
          padding: 20px 2em;
          border-bottom: 1px solid rgba(234, 234, 234, 0.10);
        }

        .nft-item, .nft-header {
          justify-content: flex-start;

          .col {
            text-align: left;
            font-size: 16px;
            width: 150px;

            &.active {
              color: #fa7676;
            }
          }
        }


        .nft-item {
          padding: 1em 2em;
          color: #FFFFFF;
          width: 100%;
          border-radius: 0px;
          border-bottom: 1px solid rgba(234, 234, 234, 0.10);;

          &:nth-child(even) {
            background: rgba(62, 62, 62, 1);
          }


          .col {
            overflow: hidden;
            padding-left: 0%;

          }

          .address {
            a {
              color: #CD9E57;
            }
          }
        }
      }

      .bonus-record {
        .nft-item, .nft-header {
          .col {
            width: 135px;

            &:nth-child(1) {
              width: 50px;
            }

            &:nth-child(6) {
              width: 200px;
            }
          }
        }

      }

      .list1 {
        .nft-item, .nft-header {
          justify-content: flex-start;

          .col {
            &:nth-child(2) {
              width: 200px;
            }
          }
        }

      }
    }
  }

  @media screen and (max-width: 1000px) {
    .Tooltip {
      display: none !important;
    }

    .detail-box {
      padding: 0;
    }

    .usdt-box {
      flex-direction: column;
      height: auto !important;

      .total-usdt {
        margin-top: 10px;
        margin-left: 0;

        .value {
          font-size: 30px;
        }

        .name {
          font-size: 16px;
          height: 30px;
        }
      }

    }

    .detail-list {
      display: flex;
      margin-top: 20px;
      flex-wrap: wrap;
      flex-direction: row !important;

      .flex-box {
        width: 48%;
        margin: 0;
      }
    }

    .list-box {
      flex-direction: column;

      .list-item {
        overflow: hidden;
        width: 100% !important;
      }

      .select-box {
        width: 100% !important;
      }

      .right-part {
        margin: 10px 0;
      }
    }

    .detail-list {
      flex-direction: column;

      .list-container {
        width: 100% !important;
      }

      .flex-box {
        padding: 10px 0;

        .value {
          font-size: 16px !important;
        }
      }
    }

    .panel-title.flex-box {
      flex-direction: column;
      align-items: flex-start !important;

      .ant-select {
        margin: 10px 0;
      }
    }

    .bonus-record {
      .nft-item, .nft-header {
        .col {
          font-size: 12px;
        }
      }
    }

    .superdao-list-box {
      overflow: auto;

      .list-header, .list-item {
        width: 1100px !important;
      }
    }

    .coount-down {
      .title {
        font-size: 30px;
      }

      .time-box {
        font-size: 40px;
      }

      .can-receive {
        font-size: 20px;
      }
    }

    .usdt-btc {
      font-size: 20px;

    }

    .bonus-btn {
      width: 150px !important;
      height: 50px !important;
      margin-top: 10px !important;

      span {
        font-size: 20px !important;
      }
    }

    .my-spt {
      .content-item {
        .value {
          font-size: 16px !important;
        }

        .name {
          font-size: 12px !important;
        }
      }
    }

    .ant-select {
      margin-left: 0!important;
    }

    .ant-select-selector {
      width: 90%;
      font-size: 12px !important;
    }
  }
`


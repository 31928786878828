import React, {useEffect, useRef, useState} from 'react';
import {useConnect} from "../../../../api/contracts";
import {Table, Button, DatePicker, message, Form, List, Input, notification, Card} from 'antd';
import {getContractByName, getContractByContract} from "../../../../api/connectContract";
import {dealMethod, viewMethod} from "../../../../utils/contractUtil"
import {useNavigate, useLocation} from "react-router-dom";
import LeaderboardBonus2AdminStyle from "./style";
import judgeStatus from "../../../../utils/judgeStatus";
import {SBDDecimals, SPTCoinDecimal} from "../../../../config/constants";
import BigNumber from "bignumber.js";
import {dealTime} from "../../../../utils/timeUtil";
import addressMap from "../../../../api/addressMap";
import {useTranslation} from "react-i18next";

const LeaderboardBonus2Admin = (props) => {
    let {state, dispatch} = useConnect();
    const [rewardThreshold, setRewardThreshold] = useState()
    const [cycle, setCycle] = useState()
    const [ownerAddr, setOwner] = useState("")
    const [time, setTime] = useState()
    const [status, setStatus] = useState("")
    const [bufferTime, setBufferTime] = useState("")

    const [startTime, setStartTime] = useState("")


    const [addWhiteArr, setAddWArr] = useState([{}])
    const [form] = Form.useForm();
    const { t } = useTranslation();

    const location = useLocation()

    const openNotification = (message) => {
        notification.error({
            message: message,
            description:
                "",
            onClick: () => {
            },
        });
    };
    const handleDealMethod = async (name, params) => {
        let contractTemp = await getContractByName("LeaderboardBonus2", state.api,)
        if (!contractTemp) {
            openNotification("Please connect")
        }
        return dealMethod(contractTemp, state.account, name, params)
    }

    const handleViewMethod = async (name, params) => {
        let contractTemp = await getContractByName("LeaderboardBonus2", state.api,)
        if (!contractTemp) {
            openNotification("Please connect")
        }
        return await viewMethod(contractTemp, state.account, name, params)
    }


    const getData = async (page) => {
        getOwner()
        getStatus()
        getCycle()
        getStartDividendTime()
        getRewardThreshold()
        getBufferTime()
    }


    const getStartDividendTime = async () => {
        const res = await handleViewMethod("startDividendTime", [])
        setStartTime(res)
    }
    const getStatus = async () => {
        const res = await handleViewMethod("getStatus", [])
        setStatus(res)
    }
    const getOwner = async () => {
        const res = await handleViewMethod("owner", [])
        setOwner(res)
    }
    const getBufferTime = async () => {
        const res = await handleViewMethod("bufferTime", [])
        setBufferTime(res)
    }
    const getCycle = async () => {
        const res = await handleViewMethod("cycle", [])
        setCycle(res / 86400)
    }
    const getRewardThreshold = async () => {
        const res = await handleViewMethod("rewardThreshold", [])
        setRewardThreshold(BigNumber(res).div(10 ** SPTCoinDecimal).toString())
    }

    const setAccess = async () => {
        await handleDealMethod("setAccess", [form.getFieldValue().accessAddr, true])
    }

    const handleSetBufferTime = async () => {
        await handleDealMethod("setBufferTime", [form.getFieldValue().BufferTime * 60 ])
        getBufferTime()
    }
    const transferOwnership = async () => {
        await handleDealMethod("transferOwnership", [form.getFieldValue().owner])
        getOwner()
    }

    const changeStatus = async () => {
        await handleDealMethod("changeStatus", [])
        getStatus()
    }


    const handleSetRewardThreshold = async () => {
        await handleDealMethod("setRewardThreshold", [BigNumber(form.getFieldValue().Threshold).multipliedBy(10 ** SPTCoinDecimal).toFixed(0)])
        getRewardThreshold()
    }
    const handleSetCycle = async () => {
        await handleDealMethod("setCycle", [form.getFieldValue().day * 86400])
        getCycle()
    }

    const handleSetStartDividendTime = async () => {
        console.log(BigNumber(time).div(1000).toFixed(0))
        await handleDealMethod("setStartDividendTime", [BigNumber(time).div(1000).toFixed(0)])
        getStartDividendTime()
    }

    const onOk = (value) => {
        console.log('onOk: ', value);
        setTime(new Date(value).getTime())
    };
    useEffect(async () => {
        let judgeRes = await judgeStatus(state)
        if (!judgeRes) {
            return
        }
        await getData()
    }, [state.account]);
    const whitelistColumns = [
        {
            title: 'Address',
            dataIndex: '',
            key: '',
        },

    ]
    return (
        <LeaderboardBonus2AdminStyle>
            <h1 className="title">
                {t("Leaderboard Bonus")} Admin
            </h1>
            <div className="panel-box">
                <div className="panel-container">

                    <div className="part1">

                        <div className="content-item">
                            <h3>Contract Address({addressMap["LeaderboardBonus2"].address})</h3>
                            <h3>Owner Address</h3>
                            <Form form={form} name="control-hooks">
                                <div className="current">
                                    <div className="name">
                                        Current:
                                    </div>
                                    <div className="value">
                                        {ownerAddr}
                                    </div>
                                </div>
                                <Form.Item
                                    name="owner"
                                    label="owner address"
                                    validateTrigger="onBlur"
                                    validateFirst={true}
                                    rules={[
                                        {required: true, message: 'Please input owner Address!'},
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                            </Form>
                            <Button type="primary" className="max-btn" onClick={() => {
                                transferOwnership()
                            }}>
                                Submit
                            </Button>
                        </div>

                        <div className="content-item">
                            <h3>Contract Status</h3>
                            <Form form={form} name="control-hooks">
                                <div className="current">
                                    <div className="name">
                                        Current:
                                    </div>
                                    <div className="value">
                                        {status ? "on" : "off"}
                                    </div>
                                </div>
                            </Form>
                            <Button type="primary" className="max-btn" onClick={() => {
                                changeStatus()
                            }}>
                                Submit
                            </Button>
                        </div>
                        <div className="content-item">
                            <h3>Set Access Address</h3>
                            <Form form={form} name="control-hooks">

                                <Form.Item
                                    name="accessAddr"
                                    label="access address"
                                    validateTrigger="onBlur"
                                    validateFirst={true}
                                    rules={[
                                        {required: true, message: 'Please input owner Address!'},
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                            </Form>
                            <Button type="primary" className="max-btn" onClick={() => {
                                setAccess()
                            }}>
                                Submit
                            </Button>
                        </div>

                        <div className="content-item">
                            <h3>Set BufferTime({bufferTime/60}mins)</h3>
                            <Form form={form} name="control-hooks">

                                <Form.Item
                                    name="BufferTime"
                                    label="BufferTime(mins)"
                                    validateTrigger="onBlur"
                                    validateFirst={true}
                                    rules={[
                                        {required: true, message: 'Please input owner Address!'},
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                            </Form>
                            <Button type="primary" className="max-btn" onClick={() => {
                                handleSetBufferTime()
                            }}>
                                Submit
                            </Button>
                        </div>
                        <div className="content-item">
                            <h3> {t("Bonus Threshold")} ({rewardThreshold}) SPT</h3>
                            <Form form={form} name="control-hooks">

                                <Form.Item
                                    name="Threshold"
                                    label="New Threshold"
                                    validateTrigger="onBlur"
                                    validateFirst={true}
                                >
                                    <Input/>
                                </Form.Item>
                            </Form>
                            <Button type="primary" className="max-btn" onClick={() => {
                                handleSetRewardThreshold()
                            }}>
                                Submit
                            </Button>
                        </div>

                        <div className="content-item">
                            <h3> {t("Bonus Period")} ({cycle}) Days</h3>
                            <Form form={form} name="control-hooks">

                                <Form.Item
                                    name="day"
                                    label="New Day"
                                    validateTrigger="onBlur"
                                    validateFirst={true}
                                >
                                    <Input/>
                                </Form.Item>
                            </Form>
                            <Button type="primary" className="max-btn" onClick={() => {
                                handleSetCycle()
                            }}>
                                Submit
                            </Button>
                        </div>

                        <div className="content-item">
                            <h3> Start Time({dealTime(startTime)})</h3>
                            <Form form={form} name="control-hooks">

                                <Form.Item
                                    name="time"
                                    label="Start Time"
                                    validateTrigger="onBlur"
                                    validateFirst={true}
                                >
                                    <DatePicker showTime onChange={onOk} onOk={onOk}/>

                                </Form.Item>
                            </Form>
                            <Button type="primary" className="max-btn" onClick={() => {
                                handleSetStartDividendTime()
                            }}>
                                Submit
                            </Button>
                        </div>


                    </div>


                </div>

            </div>
        </LeaderboardBonus2AdminStyle>
    )
}
export default LeaderboardBonus2Admin

import styled from "styled-components";

export default styled.div`

  .ant-pagination .ant-pagination-item-link {
    border-radius: 5px;
  }

  .ant-pagination-disabled {
    border-radius: 5px;

  }

  .panel-container .search-container .search-box .ant-input-affix-wrapper {
    border: none;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 5px;
    background: rgb(39, 39, 39);
    border: 1px solid rgba(255, 255, 255, 0.15);
    color: #FFFFFF;

  }

  .ant-pagination-item-link {
    background: #1C1C1C !important;

    border: 1px solid rgba(255, 255, 255, 0.15);
    color: #FFFFFF;
  }

  .ant-pagination-item {
    background: #1C1C1C !important;
    color: #FFFFFF;
    border: 1px solid rgba(255, 255, 255, 0.15);
  }

  .anticon-right {
    color: #FFFFFF !important;

  }

  .anticon-left {
    color: #FFFFFF !important;

  }

  .panel-box {
    padding: 5px 2%;
  }


  .signup-dialog {
    .input-title {
      font-size: 20px;
      font-family: Roboto-Bold, Roboto;
      font-weight: bold;
      color: #8A8080;
    }
  }

  .icon-box {
    display: flex;
    justify-content: center;

    .icon {
      cursor: pointer;
    }

    .icon:nth-child(2) {
      margin-left: 10px;
    }

  }

  .btns {
    display: flex;
    justify-content: center;
    padding: 20px 0 20px;

    .add-btn {
      min-width: 120px;
    }
  }

  .status-header {
    display: flex;
    justify-content: space-between;

    .signUp-btn {
      width: 160px;
      height: 40px;
      border-radius: 50px;
      opacity: 1;
      border: 1px solid rgba(255, 174, 78, 1);
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .status-info {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;

      .info-item {
        margin-right: 10px;
        flex: 1;
        height: 40px;
        background: rgba(255, 255, 255, 0.1);
        border-radius: 49px 49px 49px 49px;
        opacity: 1;
        border: 1px solid rgba(255, 255, 255, 0.1);
        width: 160px;
        padding: 0 20px;
        font-size: 16px;
        font-family: Roboto-Medium, Roboto;
        font-weight: 500;
        color: #FFFFFF;
        display: flex;
        align-items: center;

        .dot {
          width: 8px;
          height: 8px;
          background: #D8D8D8;
          border-radius: 50%;
          margin-right: 20px;

          &.active {
            background: #60C064;
            border: 2px solid rgba(255, 255, 255, 0.3);
          }
        }
      }

      .my-id {
        float: right;
      }

    }

  }

  .reward-item {
    display: flex;
    align-items: center;
    margin-left: 10px;

    img {
      margin-right: 6px;
    }
  }

  .active-content-box {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .content-item-box {
      flex-wrap: wrap;
      width: 48%;
      background: #1A1414;
      border-radius: 20px;
      padding: 20px;
      border: 1px solid rgba(255, 255, 255, 0.1);

      .name {
        opacity: 0.5;
        margin-bottom: 10px;
      }

      .address {
        padding: 3px 6px;

        color: #CD9E57;

        a {
          color: #CD9E57;
        }
      }

      & {
        &::-webkit-scrollbar {
          display: none;
        }

        scrollbar-width: none;
      }

      .item {
        .address {
          font-size: 12px;
        }
      }
    }

    .flex-box {
      margin-top: 10px;
      align-items: center;

      .name {
        font-size: 16px;
      }

      .value {
        font-size: 18px;
      }
    }

    .content-list {
      justify-content: space-between;
      margin-bottom: 10px;

      .content-item {
        .name {
          font-size: 16px;
        }

        .value {
          font-size: 18px;
        }
      }
    }
  }

  .total-box {
    margin: 0;

    .item {
      padding: 2em;
      width: 100%;
      background: #1C1C1C;
      box-shadow: 0px 5px 20px 5px rgba(0, 0, 0, 0.05);
      border-radius: 20px 20px 20px 20px;

      p {
        font-size: 20px;
        font-family: Roboto-Medium, Roboto;
        font-weight: 500;
        color: #8A8080;
        line-height: 26px;

      }

      img {
        width: 30px;
        height: 30px;
      }

      span {
        vertical-align: middle;
        font-size: 26px;
        font-family: Roboto-Black, Roboto;
        font-weight: 900;
        color: #FFFFFF;
        line-height: 34px;
      }

    }

  }


  .donate-list {
    margin: 1em 0;
    background: rgba(28, 28, 28, 1);
    overflow-x: scroll;

    .listheadert {
      width: 1950px;
    }

    .list-header {
      display: flex;
      padding: 20px 1.6em;
      border-bottom: 1px solid rgba(234, 234, 234, 0.10);
    }

    .list-item, .list-header {
      justify-content: flex-start;

      .col {
        text-align: left;
        font-size: 16px;

        &:nth-child(1) {
          margin-left: 5px;
          width: 130px;
        }

        &:nth-child(2) {
          width: 130px;
        }

        &:nth-child(3) {
          width: 150px;
        }

        &:nth-child(4) {
          width: 150px;
        }

        &:nth-child(5) {
          width: 200px;
        }

        &:nth-child(6) {
          width: 200px;
        }

        &:nth-child(7) {
          width: 180px;
        }

        &:nth-child(8) {
          width: 180px;
        }

        &:nth-child(9) {
          width: 180px;
        }

        &:nth-child(10) {
          width: 190px;
        }

        &:nth-child(11) {
          width: 180px;
        }

      }
    }


    .list-item:last-child {
      border-bottom: none;
    }

    .list-item {
      color: #FFFFFF;
      padding: 1em 2em;
      width: 100%;
      margin: 0 auto;
      border-radius: 0px;
      border-bottom: 1px solid rgba(234, 234, 234, 0.10);

      &:nth-child(even) {
        background: rgba(62, 62, 62, 1);
      }

      .col {
        overflow: hidden;
        padding-left: 0%;

        .item {
          font-size: 16px;
        }
      }

      .address {
        a {
          color: #CD9E57;
        }
      }
    }
  }

  .pagination {
    display: block !important;
    text-align: center;
  }


  .search-box {
    display: flex;
    align-items: center;
    background: #3F3535;

    border-radius: 45px;
    border: 1px solid #333333;
    padding: 2px;
    height: 45px;

    .ant-input-affix-wrapper {
      line-height: 2;
    }

    .ant-select-selector {
      background: #1F1212;
      border-radius: 8px;
    }
  }

  .active-list-row {
    .col:nth-child(2) {
      width: 60% !important;
    }
  }


  .page-title {
    font-size: 30px;
    font-family: Roboto-Bold, Roboto;
    font-weight: bold;
    padding: 0px 11.7%;
  }


  .header-nav {
    padding: 10px 4%;
    display: flex;

    .superdao-nav-list {
      height: 45px;
      width: auto;
      margin: 0em;
      padding: 0em;

      .nav-item {
        min-width: 120px;
        width: auto;
        padding: 0 20px;
        font-size: 18px;
      }
    }
  }

  .header-nav2 {
    width: 100%;
    margin-left: 0;
    display: flex;
    padding: 0 4%;

    .listheadert1 {
      width: 1000px;

      .superdao-nav-list1 {
        margin: 0;

        width: 100%;

        .ant-select-selection-item {
          font-size: 16px;
          line-height: 40px;
          color: #FFFFFF;
          font-family: Roboto-Bold, Roboto;
          font-weight: bold;
        }

        .nav-item {
          font-size: 18px;
          line-height: 40px;
          color: #FFFFFF;
          font-family: Roboto-Bold, Roboto;
          font-weight: bold;
        }
      }
    }
  }


  @media screen and (max-width: 1440px) {

    .total-box .item {
      p {
        font-size: 18px;
      }

      span {
        font-size: 20px;
      }

      img {
        width: 28px;
        height: 28px;
      }
    }

    .header-nav {
      padding: 10px 4%;
      display: flex;

      .superdao-nav-list {
        height: 45px;
        width: auto;

        .nav-item {
          width: auto;
          padding: 0 20px;
          font-size: 16px;
        }
      }
    }


    .header-nav2 {
      width: 100%;

      .listheadert1 {
        .superdao-nav-list1 {
          width: 90%;

          .nav-item {

            font-size: 16px;
          }
        }
      }

    }


    .donate-list {
      overflow-x: scroll;

      .listheadert {
        width: 1970px;
      }

      .list-header {
        font-size: 15px;
      }

      .list-item {
        width: 100%;
        padding: 1em 1.8em;
      }

      .list-header, .list-item {
        .col {
          font-size: 15px;

          &:nth-child(1) {
            margin-left: 0px;
            width: 120px;
          }

          &:nth-child(2) {
            margin-left: 0px;
            width: 120px;
          }

          &:nth-child(3) {
            margin-left: 0px;
            width: 140px;
          }

          &:nth-child(4) {
            margin-left: 0px;
            width: 130px;
          }
          &:nth-child(11) {
            margin-left: 0px;
            width: 100px;
          }
          &:nth-child(12) {
            margin-left: 0px;
            width: 170px;
          }
        }

        .col {
          .item {
            font-size: 15px;
          }

          .address {
            text-align: left;
          }
        }
      }

    }
  }

  @media screen and (max-width: 450px) {
    .panel-box {
      padding: 5px 0%;
    }

    .header-nav {
      padding: 5px 2%;
      display: flex;
      width: 100%;
      overflow-x: scroll;

      .headerlist {
        width: 800px;
      }

      .superdao-nav-list {
        margin: 0px;
        display: flex;

        .nav-item {
          width: 150px;
          font-size: 14px;
          padding: 0 5px;
        }
      }
    }


    .header-nav2 {
      padding: 10px 2%;
      width: 100%;

      .listheadert1 {
        .superdao-nav-list1 {
          width: 100%;

          .nav-item {
            font-size: 14px;
          }
        }
      }
    }

    .total-box {
      display: block;
      margin: 0;

      .item {
        width: 100%;
        padding: 1em 1.5em;
        margin: 0.5em 0;

        p {
          font-size: 16px;
          margin-bottom: 5px;
        }

        span {
          font-size: 18px;
        }

        img {
          width: 25px;
          height: 25px;
        }
      }
    }


    .donate-list {
      overflow-x: scroll;

      .listheadert {
        width: 1840px;
      }

      .list-header {
        font-size: 14px;
        padding: 20px 1.8em;
      }

      .list-item {
        padding: 10px 1.8em;
      }

      .list-header, .list-item {
        .col {
          font-size: 14px;

          &:nth-child(1) {
            margin-left: 0px;
            width: 100px;
          }

          &:nth-child(11) {
            margin-left: 0px;
            width: 160px;
          }
        }

        .col {
          .item {
            font-size: 14px;
          }
        }
      }

    }
  }


`


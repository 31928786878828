import React, {useEffect, useRef, useState} from 'react';
import {useConnect} from "../../../api/contracts";
import BigNumber from "bignumber.js"
import AddThreeWhiteList from "./components/OgAdminLevel2";



import {formatAddress} from "../../../utils/publicJs";
import {
    message,
    Form, Pagination, Empty, Dropdown, Space, Select
} from 'antd';
import {getContractByName, getContractByContract} from "../../../api/connectContract";
import {dealMethod, dealPayMethod, viewMethod} from "../../../utils/contractUtil"

import develop from "../../../env";
import {useNavigate} from "react-router-dom";
import judgeStatus from "../../../utils/judgeStatus";
import {
    getDonateRecord,
    getAllRegisters,
    getAllInvites,
    getAllAdminRate,
    getBlackUsers
} from "../../../graph/purChaseGQL";
import OGUserAdminStyle from "./OGUserAdminStyle";
import OgSetActive from "./components/OgSetActive";
import OgSetBlacklist from "./components/OgSetBlacklist";
import SBDIcon from "../../../imgs/SBD.png";
import SBD from "../../../imgs/SBD.png";
import BackIcon from "../../../imgs/back.png";
import ethereum from "../../../imgs/ethereum.png";
import {ETHDecimals, NFTMap, SBDDecimals, TestDecimals, USDTDecimals} from "../../../config/constants";
import {showNum} from "../../../utils/bigNumberUtil";
import {dealTime} from "../../../utils/timeUtil";
import USDTIcon from "../../../imgs/usdt.png";
import {useTranslation} from "react-i18next";

const OGPoolPublic = (props) => {
    let {state, dispatch} = useConnect();
    const [activeNav, setActiveNav] = useState(1)

    const [isSecondAdmin, setIsSecondAdmin] = useState(true)
    const [isThreeAdmin, setIsThreeAdmin] = useState(true)
    const [isFourAdmin, setIsFourAdmin] = useState(true)
    const [isFiveAdmin, setIsFiveAdmin] = useState(true)
    const [isSixAdmin, setIsSixAdmin] = useState(true)
    const [isSevenAdmin, setIsSevenAdmin] = useState(true)
    const [isEightAdmin, setIsEightAdmin] = useState(true)
    const [isNineAdmin, setIsNineAdmin] = useState(true)
    const [selectList, setSelectList] = useState([])


    const [teamRecordArr, setTeamRecordArr] = useState([])
    const [totalETH, setTotalEth] = useState(0)
    const [totalUSDT, setTotalUSDT] = useState(0)

    const [total, setTotal] = useState(0)
    const [allRecords, setAllRecords] = useState([])
    const [curPage, setCurPage] = useState(1)
    const [pageCount, setPageCount] = useState(20)

    const [rateRecordArr, setRateRecord] = useState([])
    const [rateAdminRecordArr, setAdminRateRecord] = useState([])

    const [curSelect, setCurSelect] = useState()
    const { t } = useTranslation();

    const handleChange = (value,label) => {
        setCurSelect(label)
        setActiveNav(value)
    };

    const items = [



        {
            value: 2,
            label: <div className={"nav-item " + (activeNav == 2 ? "active" : "")} onClick={() => {
                setActiveNav(2)
            }}>
                Team Rewards
            </div>,

        },

    ];


    const history = useNavigate();
    const [form] = Form.useForm();

    const onChangePage = async (page) => {
        await setCurPage(page)
    }


    const handleShowSizeChange = async (page, count) => {
        setPageCount(count)
    }
    const handleUserViewMethod = async (name, params) => {
        let contractTemp = await getContractByName("user", state.api,)
        if (!contractTemp) {
            message.warn("Please connect", 5)
        }
        return await viewMethod(contractTemp, state.account, name, params)
    }
    const handleDealMethod = async (name, params) => {
        let contractTemp = await getContractByName("spbd", state.api,)
        if (!contractTemp) {
            message.warn("Please connect", 5)
        }
        await dealMethod(contractTemp, state.account, name, params)
    }


    const handlePayDealMethod = async (name, params, value) => {
        let contractTemp = await getContractByName("spbd", state.api,)
        if (!contractTemp) {
            message.warn("Please connect", 5)
        }
        await dealPayMethod(contractTemp, state.account, name, params, value)
    }
    const handleViewMethod = async (name, params) => {
        let contractTemp = await getContractByName("spbd", state.api,)
        if (!contractTemp) {
            message.warn("Please connect", 5)
        }
        return await viewMethod(contractTemp, state.account, name, params)
    }


    const getIsAdmin = async () => {
        const isSecond = await handleViewMethod("checkAddrForAdminLevelTwo", [state.account])
        const isThree = await handleViewMethod("checkAddrForAdminLevelThree", [state.account])

        setIsSecondAdmin(isSecond)
        setIsThreeAdmin(isThree)

        if(isThree){
            items.push(    {
                value: 1,
                label: "Set Super Accounts"

            },)
        }

        if(isSecond){
            items.push(    {
                value: 1,
                label: "Set Super Accounts"

            },  )

        }

        if(isSecond){
            items.push({
                value:4,
                label:"Set Lv-3 Administrator"
            })
        }


        setSelectList(items)
    }

    const getData = async () => {
        try {
            let judgeRes = await judgeStatus(state)
            if (!judgeRes) {
                return
            }
            await getIsAdmin()

            getAdminRate()
        } catch (e) {
            console.log(e)
        }
    }
    const getAdminRate = async () => {
        const res = await getAllAdminRate()
        if (res && res.data) {
            setAdminRateRecord(res.data.allteams)
            setRateRecord(res.data.allteamrates)
        }

    }

    const getLevelInviteRate = async (address, recordTime) => {

        for (let i = 0; i < rateRecordArr.length; i++) {
            const  rateItem = rateRecordArr[i]
            const  item= rateAdminRecordArr[i]
            if ((item.addr == address) && (item.blockTimestamp == recordTime)) {

                if (item.admin1.toLowerCase() == state.account.toLowerCase()) {
                    return {
                        rate: rateItem.rate1,
                    }
                }
                if (item.admin2.toLowerCase() == state.account.toLowerCase()) {
                    return {
                        rate: rateItem.rate2,
                    }
                }
                if (item.admin3.toLowerCase() == state.account.toLowerCase()) {
                    return {
                        rate: rateItem.rate3,
                    }
                }


            }
        }
        return 0

    }
    const getRefArr = async (address, myTeamArr, level) => {
        let refRes = await getAllRegisters(address)
        if (refRes.data && refRes.data.allRegisters && refRes.data.allRegisters.length > 0) {
            let refArr = refRes.data.allRegisters
            const realRefArr = []
            refArr.forEach(item => {
                let hasAddress = false;
                realRefArr.forEach(refItem => {
                    if (refItem._user == item._user) {
                        hasAddress = true
                    }
                })
                if (!hasAddress) {
                    realRefArr.push(item)
                }
            })
            refArr = realRefArr
            if (refArr[0]._user != address) {
                refArr.forEach(item => {
                    item.level = level
                })
                myTeamArr.push(...refArr)
            }
            level++
            for (let i = 0; i < refArr.length; i++) {
                if (refArr[i]._user != address) {
                    await getRefArr(refArr[i]._user, myTeamArr, level)
                }
            }
        }
        return myTeamArr

    }


    const getRecord = async () => {
        try {
            let res = await getDonateRecord()

            // get blacklist
            const blackUserRes = await getBlackUsers()
            let timeStamp = undefined, timeStampArr = []
            if (blackUserRes && blackUserRes.data) {
                const arr = blackUserRes.data.blackUsers

                // get is in black

                // get timeStampArr
                arr.forEach(item => {
                    if (item.user == state.account) {
                        timeStampArr.push(item.blockTimestamp)
                    }
                })
                // get timeStamp
                if (timeStampArr.length % 2 == 1) {
                    for (let i = 0; i < timeStampArr.length; i++) {
                        const item = timeStampArr[i]
                        if (item.user == state.account) {
                            timeStamp = item.blockTimestamp
                            break;
                        }
                    }
                    timeStampArr.pop()
                }


            }


            if (res.data && res.data.allRecords) {
                const recordArr = res.data.allRecords
                const refArr = await getRefArr(state.account, [], 1)
                let teamRecordArr = [], totalETH = 0

                for (let i = 0; i < refArr.length; i++) {
                    const refItem = refArr[i]
                    for (let j = 0; j < recordArr.length; j++) {
                        const recordItem = recordArr[j]
                        if (recordItem.addr.toLowerCase() == refItem._user.toLowerCase()) {

                            if (timeStamp && BigNumber(timeStamp).lt(recordItem.blockTimestamp)) {
                                continue
                            }
                            if (timeStampArr.length > 0) {
                                let isInBlackTime = false
                                timeStampArr.forEach((timeItem, index) => {
                                    if (index % 2 == 1) {
                                        if (BigNumber(timeStamp).lt(timeItem) && BigNumber(timeStamp).gt(timeStampArr[index - 1])) {
                                            isInBlackTime = true
                                        }
                                    }
                                })
                                if (isInBlackTime) {
                                    continue
                                }
                            }
                            recordItem.level = refItem.level
                            recordItem.rate = (await getLevelInviteRate(refItem._user, recordItem.blockTimestamp)).rate / 100
                            console.log(recordItem.rate)
                            if (!recordItem.rate) {
                                recordItem.rate = 0
                            }

                            teamRecordArr.push(recordItem)

                            totalETH = BigNumber(totalETH).plus(recordItem.usdtAmount / 10 ** ETHDecimals * recordItem.rate / 100)
                        }
                    }
                }

                setTotalUSDT(totalETH.toString())
                setTeamRecordArr(teamRecordArr)
            }


        } catch (e) {
            console.log(e)
        }
    }


    useEffect(() => {
        setSelectList([])
        setCurSelect()
        setActiveNav()
        getData()
    }, [state.account]);

    useEffect(async () => {
        if (rateRecordArr.length>0) {
            getRecord()
        }
    }, [rateRecordArr,]);
    useEffect(() => {

    }, [state.account, state.networkId, state.apiState])

    return (
        <OGUserAdminStyle>

            {/* <div className="page-title">
                <img onClick={() => {
                    history("/")
                }} src={BackIcon} width={36} height={36} alt="" style={{ marginRight: "6px" }} />
                
                 OG Pool Manage
            </div> */}
            <div className="header-nav">
                <div className='headerlist'>

                </div>
            </div>

            <div className="header-nav2">
                <div className='listheadert1'>
                    <div className="superdao-nav-list1">
                        <Space wrap>
                            <Select
                                value={curSelect}
                                onChange={handleChange}
                                options={
                                    selectList
                                }
                            >

                            </Select>
                        </Space>

                    </div>
                </div>
            </div>

            {activeNav == 1 && (<OgSetActive isThreeAdmin={isThreeAdmin}/>)}
            {activeNav == 5 && (<OgSetBlacklist/>)}

            {
                activeNav == 2 && (<div className="panel-box part2">
                    <div className="panel-container">

                        <div className="flex-box total-box">

                            <div className="item">
                                <p className="name">USDT Total Income</p>

                                <img src={USDTIcon} width={20} height={20} alt=""/>
                                <span> {showNum(totalUSDT)} USDT</span>
                            </div>
                        </div>
                        <h1 className='panel-title' style={{margin: '1em 0'}}>
                            Team Funding List
                        </h1>
                        <div className="superdao-list-box donate-list" style={{minWidth: '100%'}}>
                            <div className='listheadert'>
                                <div className="list-header ">
                                    <div className="col">
                                        {t("No.")}
                                    </div>
                                    <div className="col">
                                        Percentage
                                    </div>
                                    <div className="col">
                                        ID
                                    </div>
                                    <div className="col">
                                        Address
                                    </div>
                                    <div className="col">
                                        Price
                                    </div>
                                    <div className="col">
                                        Cost
                                    </div>
                                    <div className="col">
                                        Receive SBD
                                    </div>

                                    <div className="col">
                                        Lock SBD
                                    </div>
                                    <div className="col">
                                        Receive SVT
                                    </div>
                                    <div className='col'>
                                        Receive NFT
                                    </div>
                                    <div className="col">
                                        Rewards
                                    </div>
                                    <div className="col">
                                         {t("Time(UTC)")}
                                    </div>
                                </div>

                                {
                                    teamRecordArr.length == 0 ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/> :
                                        teamRecordArr.map((item, index) => (
                                            index >= pageCount * (curPage - 1) && index < pageCount * curPage &&
                                            <div className="list-item"
                                                 key={index}
                                            >
                                                <div className="col no">
                                                    {index + 1}
                                                </div>

                                                <div className="col">
                                                    {item.rate > 0 &&
                                                        <div className="item">
                                                            {item.rate}%
                                                        </div>
                                                    }

                                                    {item.flmRate > 0 &&
                                                        <div className="item">
                                                            {item.flmRate}%
                                                        </div>
                                                    }
                                                </div>
                                                <div className='col id'>
                                                    {item.no}
                                                </div>
                                                <div className="col address">
                                                    <a target="_blank"
                                                       href={develop.ethScan + "/address/" + item.addr}> {formatAddress(item.addr)}
                                                    </a>
                                                </div>
                                                <div className="col price">
                                                    <img width={18} height={18} style={{margin: "-3px 3px 0 0"}} src={USDTIcon}
                                                         alt=""/>
                                                    {showNum(BigNumber(item.usdtAmount).dividedBy(BigNumber(item.sbdAmount).plus(item.lockSbd)), 4)}
                                                </div>

                                                <div className="col cost">
                                                    <img width={18} height={18} style={{margin: "-3px 3px 0 0"}} src={USDTIcon}
                                                         alt=""/>
                                                    {showNum(item.usdtAmount / 10 ** USDTDecimals, 3)}
                                                </div>

                                                <div className="col receive">

                                                    {showNum(item.sbdAmount/ 10 ** SBDDecimals , 3)}
                                                </div>
                                                <div className="col lock">

                                                    {showNum(item.lockSbd/ 10 ** SBDDecimals , 3)}
                                                </div>
                                                <div className="col lock">

                                                    {showNum(item.lockSbd/ 10 ** SBDDecimals , 3)}
                                                </div>
                                                <div className='col receive-nft'>

                                                    <a target="_blank" href={develop.ethScan+'/address/'+item.receiveNft}>{NFTMap[item.receiveNft]}</a>
                                                </div>
                                                <div className="col ">
                                                    <div className="item">
                                                        <img width={18} height={18} style={{margin: "-3px 3px 0 0"}} src={USDTIcon}
                                                             alt=""/>
                                                        {showNum(BigNumber(item.usdtAmount / 10 ** SBDDecimals).multipliedBy(item.rate / 100), 3)}
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    {dealTime(item.blockTimestamp)}
                                                </div>
                                            </div>
                                        ))
                                }

                            </div>
                        </div>
                        <div className="pagination">
                            {
                                <Pagination current={curPage} showSizeChanger
                                            onShowSizeChange={handleShowSizeChange}
                                            onChange={onChangePage} total={total}
                                            defaultPageSize={pageCount}/>
                            }
                        </div>
                    </div>

                </div>)
            }
            {
                activeNav == 4 && (
                    <AddThreeWhiteList
                        isLevel2={isSecondAdmin}
                        isThreeAdmin={isThreeAdmin}

                        allRecords={allRecords}/>)
            }




        </OGUserAdminStyle>
    )
}
export default OGPoolPublic
import styled from "styled-components";
export default  styled.div`
  .flex-box{
    margin-top: 30px;
    .title{
      font-size: 30px;
      font-family: Roboto, Roboto;
      font-weight: bold;
    }
  }
  .select-box .ant-select-selector{
    height: 50px;
    background: #272727;
    display: flex;
    align-items: center;
    border-radius: 10px 10px 10px 10px;
  }
}
  .detail-box {
    padding: 30px;
    
    .detail-list {
      padding-top: 20px;
      display: flex;
      justify-content: space-between;

      .list-container {
        border-radius: 10px;
        width: 60%;

        .flex-box {
          align-items: center;
          justify-content: space-between;

          .Tooltip {
            position: absolute;
            height: 30px;
            top: 0;
          }

          .name {
            font-size: 18px;
            position: relative;
            color: rgb(138, 128, 128);

          }

          .value {
            text-align: center;
            font-weight: bold;
            font-size: 26px;
          }
        }

        &:nth-child(2) {
          width: 30%;
          text-align: center;

          .title {
            font-size: 26px;
            font-weight: bold;
          }

          .countdown {
            font-size: 24px;
            padding: 10px 0;
          }
        }
      }


    }
  }

  .list-box {
    display: flex;
    justify-content: space-between;

    .list-item {
      width: 48%;
    }
  }


  .panel-box {
    .panel-container {

      .nft-list-box {
        width: auto;
        margin: 1em 0;
        background: rgba(28, 28, 28, 1);

        .nft-header {
          display: flex;
          padding: 20px 2em;
          border-bottom: 1px solid rgba(234, 234, 234, 0.10);
        }

        .nft-item, .nft-header {
          justify-content: flex-start;

          .col {
            text-align: left;
            font-size: 16px;
            width: 150px;

            &.active {
              color: #fa7676;
            }
          }
        }


        .nft-item {
          padding: 1em 2em;
          color: #FFFFFF;
          width: 100%;
          border-radius: 0px;
          border-bottom: 1px solid rgba(234, 234, 234, 0.10);;

          &:nth-child(even) {
            background: rgba(62, 62, 62, 1);
          }


          .col {
            overflow: hidden;
            padding-left: 0%;

          }

          .address {
            a {
              color: #CD9E57;
            }
          }
        }
      }

      .bonus-record {
        .nft-item, .nft-header {
          .col {
            width: 135px;

            &:nth-child(1) {
              width: 50px;
            }

            &:nth-child(6) {
              width: 200px;
            }
          }
        }

      }

      .list1 {
        .nft-item, .nft-header {
          justify-content: flex-start;

          .col {
            &:nth-child(2) {
              width: 200px;
            }
          }
        }

      }
    }
  }

  @media screen and (max-width: 1000px) {
    .bonus-record {
      .nft-item, .nft-header {
        .col {
          font-size: 12px;
        }
      }
    }
`


import React, {useEffect, useRef, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {Button} from "antd"
import {useConnect, connect} from "../../api/contracts";
import detectEthereumProvider from '@metamask/detect-provider';
import WalletOutlined from "../../imgs/connect.png"
import develop from "../../env";

import ConnectWalletStyle from "./ConnectWalletStyle";

import ConnectWt from "./component/ConnectWt"

const ConnectWallet = (props) => {
    const {closeDialog, updateData} = props
    let {state, dispatch} = useConnect();
    const location = useLocation()
    const [isShowWallet, setShowWallet] = useState(false)

    useEffect(() => {
        detectEthereumProvider()
            .then(async (provider) => {
                if (!provider) {
                    console.log("noe provider")
                } else {
                    const accounts = await window.ethereum.request({method: 'eth_accounts'});
                    if (accounts && accounts[0]) {
                        await connect(state, dispatch)

                    } else {

                    }

                }
            })
    }, [])
    const connectWallet = async () => {
        try {
            let curChainId = await window.ethereum.request({method: "eth_chainId"})
            if (curChainId != develop.chainId) {

            }
        } catch (e) {
            console.log(e)
        }
        await connect(state, dispatch)
    }
    return (
        <ConnectWalletStyle>


            {
                location.pathname === "/" && (
                    <div>
                        <Button type="normal" onClick={(e) => e.preventDefault()}
                                style={{background: 'linear-gradient(32deg, rgb(255, 174, 78) 0%, rgb(249, 212, 35) 100%)'}}
                                className="connect-button but2">
                            <img src={WalletOutlined} alt=""
                                 style={{width: '20px', marginRight: '10px', verticalAlign: 'middle',}}/>
                            {
                                state.account ? state.account.substr(0, 5) + "..." + state.account.substr(state.account.length - 5, state.account.length) : " Connect Wallet"
                            }

                        </Button>
                        {/* e.preventDefault() */}
                        <Button type="normal" onClick={(e) => {
                            setShowWallet(true)
                        }} className="connect-button but1">
                            <img src={WalletOutlined} alt=""
                                 style={{width: '20px', marginRight: '10px', verticalAlign: 'middle',}}/>
                            {
                                state.account ? state.account.substr(0, 5) + "..." + state.account.substr(state.account.length - 5, state.account.length) : " Connect Wallet"
                            }

                        </Button>
                    </div>
                )
            }
            {
                location.pathname !== "/" && (
                    <div className='buttt'>
                        <Button
                            style={{background: " linear-gradient(32deg, rgb(255, 174, 78) 0%, rgb(249, 212, 35) 100%);"}}
                            type="primary" onClick={(e) => {connectWallet()}} className="connect-button ">
                            {
                                state.account ? state.account.substr(0, 5) + "..." + state.account.substr(state.account.length - 5, state.account.length) : " Connect Wallet"
                            }
                        </Button>


                    </div>
                )
            }


        </ConnectWalletStyle>
    )

}
export default ConnectWallet

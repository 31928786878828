import React, {useEffect, useRef, useState} from 'react';
import {useConnect} from "../../../api/contracts";

import LeaderboardBonusNav from "./style";
import LeaderboardBonus from "../LeaderboardBonus";
import LeaderboardBonus1 from "../LeaderboardBonus1";
import LeaderboardBonus2 from "../LeaderboardBonus2";
import {useTranslation} from "react-i18next";
import {Select} from "antd";

const SwapView = (props) => {
    let {state, dispatch} = useConnect();
    let [curNav,setCurNav] = useState(1)
    const { t } = useTranslation();

    return (
        <LeaderboardBonusNav>
            <div className="flex-box" style={{justifyContent:"space-between"}}>
                <h2 className="title">{t("Leaderboard Bonus")}</h2>
                {/*<Select*/}
                {/*    value={curNav}*/}
                {/*    onChange={(value) => {*/}
                {/*        setCurNav(value)*/}
                {/*    }}*/}
                {/*    className='select-box'*/}
                {/*    options={[*/}
                {/*        {label: "Bonus-5000", value: "1"},*/}
                {/*        {label: "Bonus-10000", value: "2"},*/}
                {/*        {label: "Bonus-30000", value: "3"},]}*/}
                {/*    style={{width:"140px"}}*/}

                {/*/>*/}

            </div>
            <div className="superdao-nav-list " style={{width:"100%"}}>
                <div className={"nav-item " + (curNav == 1 ? "active" : "")} onClick={() => {
                    setCurNav(1)
                }}>
                    Bonus-5000
                </div>
                <div className={"nav-item " + (curNav == 2 ? "active" : "")} onClick={() => {
                    setCurNav(2)
                }}>
                    Bonus-10000
                </div>
                <div className={"nav-item " + (curNav == 3 ? "active" : "")} onClick={() => {
                    setCurNav(3)
                }}>
                    Bonus-30000
                </div>
            </div>
            {curNav==1&&<LeaderboardBonus/>}
            {curNav==2&&<LeaderboardBonus1/>}
            {curNav==3&&<LeaderboardBonus2/>}
        </LeaderboardBonusNav>
    )
}
export default SwapView

import styled from "styled-components";
export default styled.div`
  .nav-list{
    display: flex;
    justify-content: space-between;
    width: 600px;
    margin: 30px auto;
    .nav-item{
      padding: 10px 20px;
      border-radius: 10px;
      text-align: center;
      border: 2px solid #fff;
      font-weight: 600;
      
      cursor: pointer;
      &.active{
        background: linear-gradient(32deg, rgb(255, 174, 78) 0%, rgb(249, 212, 35) 100%);
        color: rgb(97, 58, 0);
        border: none;
      }
    }
  }

  @media screen and (max-width: 1440px) {
    .nav-list{
      width: 100%!important;
    }
    .header-box {
      width: 75%;
      margin: 12% auto 50px;

      .header-item {
        .introduce {
          font-size: 18px;
        }
      }
    }

    .submit-btn {
      font-size: 16px;
    }
  }


  @media screen and (max-width: 450px) {
    .header-box {
      width: 100%;
      display: block;
      margin: 2em auto;

      .header-item {
        margin: 1em 0;
        width: 100%;

        .introduce {
          font-size: 18px;
        }
      }
    }

    .submit-btn {
      font-size: 16px;
    }
  }

`
import React, {useEffect, useState, useRef} from "react";
import {useConnect} from "../../../../../api/contracts";
import {ETHPriceDecimals, MaxUint256, SBDDecimals, USDTDecimals} from "../../../../../config/constants";
import {Button, Form, Input, Select, Pagination, Empty, notification, message} from "antd";
import {dealMethod, dealPayMethod, viewMethod} from "../../../../../utils/contractUtil";
import addressMap from "../../../../../api/addressMap";
import {getContractByName, getContractByContract} from "../../../../../api/connectContract";
import judgeStatus from "../../../../../utils/judgeStatus";
import {getMiningRecord, getUnMiningRecord, getWithdrawSrtRecord} from '../../../../../graph/NFTLockV3GQL'
import BigNumber from "bignumber.js";
import {formatAddress} from "../../../../../utils/publicJs";
import {useLocation, useNavigate} from "react-router-dom";
import NFTLockV3Style from './NFTLockStyle'
import {dealTime} from "../../../../../utils/timeUtil";
import {showNum} from "../../../../../utils/bigNumberUtil";
import {getBTCPrice} from "../../../../../api/coinPrice";
import {useTranslation} from "react-i18next";


let timer = null;
let timer2 = null
const NFTLockV3 = () => {


    let {state, dispatch} = useConnect();
    const [total, setTotal] = useState(0);
    const [pageCount, setPageCount] = useState(20);
    const [nftSrt, setNftSrt] = useState();
    const [isApprove, setIsApprove] = useState(false);

    const [nodeLock, setNodeLock] = useState()
    const [withdrawLock, setWithdrawLock] = useState()
    const [curId, setCurId] = useState()
    const [withId, setWithId] = useState()

    const [totalNFT, setTotalNFT] = useState([]);


    const [indexArr, setIndexArr] = useState([]);
    const [myIdArr, setmyIdArr] = useState([]);
    const [chooseWithdrawIdArr, setChooseWithdrawIdArr] = useState([]);

    const [IdArr, setIdArr] = useState([])
    const [period, setPeriod] = useState()
    const [showStartTime, setShowStartTime] = useState()
    const [startTime, setStartTime] = useState()
    const [countdownStartTime, setCountdownStartTime] = useState()

    const [time, setTime] = useState('');
    const [countdownTime, setCountDownTime] = useState('');

    const [totalUSDT, setTotalUSDT] = useState(0)
    const [srtBalance, setSrtBalance] = useState();
    const [rewards, setRewars] = useState([]);

    const [smallNft, setSmallNftAmount] = useState();
    const [bigNft, setBigNftAmount] = useState();
    const [supNft, setSupNftAmount] = useState();
    const [lockAmouns, setLockAmouns] = useState();

    const [adminAddr, setAdmin] = useState("");
    const [btcPrice, setBTCPrice] = useState(30000);

    const [smallNode, setSmallNode] = useState();
    const [bigNode, setBigNode] = useState();
    const [superNode, setSuperNode] = useState();

    const [useSmallNft, setUseSmallNft] = useState();
    const [useBigNft, setUseBigNft] = useState();
    const [useSupNft, setUseSupNft] = useState();

    const [totalPower, setTotalPower] = useState(0);

    const [smallPower, setSmallPower] = useState();
    const [bigPower, setBigPower] = useState();
    const [supPower, setSupPower] = useState();

    const [userPower, setUserPower] = useState(0);
    const [nftPower, setNftPower] = useState();


    const [miningRecord, setMiningRecord] = useState([]);
    const [unMiningRecord, setUnMiningRecord] = useState([]);
    const [withSrtRecord, setWithSrtRecord] = useState([]);

    const [curPage, setCurPage] = useState(1);
    const [activeNav, setActiveNav] = useState(2);
    const [form] = Form.useForm();
    const history = useNavigate()
    const { t } = useTranslation();

    const openNotification = (message) => {
        notification.error({
            message: message,
            description:
                "",
            onClick: () => {
            },
        });
    };

    const goPage = (url) => {
        history(url)
    }

    const nodeList = [
        {
            value: smallNode,
            label: 'Small Node V3',
        },
        {
            value: bigNode,
            label: 'Big Node V3',
        },
        {
            value: superNode,
            label: 'Super Node V3',
        },

    ]


    const onChangePage = async (page) => {
        await setCurPage(page)
        getunMiningRecord(curPage, page)
        getwithdrawSrtRecord(curPage, page)
        getminingRecord(curPage, page)
    }
    const handleShowSizeChange = async (page, count) => {
        setPageCount(count)
        getwithdrawSrtRecord(count, curPage)
        getunMiningRecord(count, curPage)
        getminingRecord(count, curPage)
    }

    const handleDealMethod = async (name, params) => {
        let contractTemp = await getContractByName("NFTLockV3", state.api,)
        if (!contractTemp) {
            message.warn("Please connect", 5)
        }
        await dealMethod(contractTemp, state.account, name, params)
    }

    const handleViewMethod = async (name, params) => {
        let contractTemp = await getContractByName("NFTLockV3", state.api,)
        if (!contractTemp) {
            openNotification("Please connect")
        }
        return await viewMethod(contractTemp, state.account, name, params)
    }
    const getAdmin = async () => {
        let admin = await handleViewMethod('owner', [])
        setAdmin(admin)
    }
    const handleApprove = async () => {
        let contractTemp
        //授权需要使用erc721中setApprovalForAll是给一类节点下所有的nft授权
        if (nodeLock == smallNode) {
            contractTemp = await getContractByContract("smallnodeV1", smallNode, state.api,)
        }
        if (nodeLock == bigNode) {
            contractTemp = await getContractByContract("bignodeV1", bigNode, state.api,)
        }
        if (nodeLock == superNode) {
            contractTemp = await getContractByContract("supnodeV1", superNode, state.api,)
        }

        await dealMethod(contractTemp, state.account, "setApprovalForAll", [addressMap["NFTLockV3"].address, true])
        getAllowance()
    }
    const getAllowance = async () => {
        let contractTemp
        //授权需要使用erc721中setApprovalForAll是给一类节点下所有的nft授权
        if (nodeLock == smallNode) {
            contractTemp = await getContractByContract("smallnodeV3", smallNode, state.api,)
        }
        if (nodeLock == bigNode) {
            contractTemp = await getContractByContract("bignodeV3", bigNode, state.api,)
        }
        if (nodeLock == superNode) {
            contractTemp = await getContractByContract("supnodeV3", superNode, state.api,)
        }

        const isApprove = await viewMethod(contractTemp, state.account, "isApprovedForAll", [state.account, addressMap["NFTLockV3"].address])
        setIsApprove(isApprove)
    }
    useEffect(() => {
        if (nodeLock) {
            getAllowance()

        }
    }, [nodeLock])
    const getMyNFT = async () => {
        //调用本合约地址
        let smallNode = await handleViewMethod("smallNode", [])
        let bigNode = await handleViewMethod("bigNode", [])
        let superNode = await handleViewMethod("superNode", [])

        setSmallNode(smallNode)

        setBigNode(bigNode)
        setSuperNode(superNode)


        //生成合约实例
        let contractTempSmall = await getContractByContract("smallnodeV3", smallNode, state.api,)
        let contractTempBig = await getContractByContract("bignodeV3", bigNode, state.api,)
        let contractTempSuper = await getContractByContract("supnodeV3", superNode, state.api,)
        //在json文件获取总量
        let smallMintedId = await viewMethod(contractTempSmall, state.account, "getUserMintIds", [state.account])
        let bigMintedId = await viewMethod(contractTempBig, state.account, "getUserMintIds", [state.account])
        let superMintedId = await viewMethod(contractTempSuper, state.account, "getUserMintIds", [state.account])
        //遍历出每个对应的id放入到total中

        let totalNFT = []

        for (let i of smallMintedId) {
            if ([].indexOf(i) === -1) {
                totalNFT.push({
                    type: smallNode,
                    id: i
                })
            }
        }

        for (let i of bigMintedId) {
            if ([].indexOf(i) === -1) {
                totalNFT.push({
                    type: bigNode,
                    id: i
                })
            }
        }
        for (let i of superMintedId) {
            if ([].indexOf(i) === -1) {
                totalNFT.push({
                    type: superNode,
                    id: i
                })
            }
        }
        setTotalNFT(totalNFT)

    }
    const dealData2 = () => {

        const tap = BigNumber(new Date().getTime() / 1000).minus(countdownStartTime).toFixed(0).toString()
        let cd = tap % period
        cd = period - cd
        const d = parseInt(cd / (24 * 60 * 60) + '');
        const h = parseInt(((cd / (60 * 60)) % 24) + '');
        const m = parseInt(((cd / 60) % 60) + '');
        const s = parseInt((cd % 60) + '');
        setCountDownTime(` ${d}d ${h}h ${m}m ${s}s`);
        cd--;

        timer2 = setTimeout(() => {
            dealData2();
        }, 1000);
    };
    const dealData = () => {
        let cd = BigNumber(startTime).plus(period).minus(new Date().getTime() / 1000).toString()
        if (cd <= 0) {
            setTime('end');
            return timer && clearTimeout(timer);
        }
        const d = parseInt(cd / (24 * 60 * 60) + '');
        const h = parseInt(((cd / (60 * 60)) % 24) + '');
        const m = parseInt(((cd / 60) % 60) + '');
        const s = parseInt((cd % 60) + '');
        setTime(` ${d}d${h} h${m}m${s}s`);
        cd--;
        timer = setTimeout(() => {
            dealData();
        }, 1000);
    };
    useEffect(() => {
        if (startTime > 0 && countdownStartTime > 0) {
            dealData();
            dealData2()
        }
        return () => {
            timer && clearTimeout(timer);
            timer2 && clearTimeout(timer2);
        }
    }, [startTime, countdownStartTime]);

    const getPeriod = async () => {
        let res = await handleViewMethod('period', [])
        setPeriod(res)
    }
    const getStartTime = async () => {
        let period = await handleViewMethod('period', [])
        let res = await handleViewMethod('bonusTime', [])
        setStartTime(res)
        let res2 = await handleViewMethod('startTime', [])
        setCountdownStartTime(res2)

        const tap = BigNumber(new Date().getTime() / 1000).minus(res2).toFixed(0).toString()
        setShowStartTime(BigNumber(res2).plus(parseInt(tap / period) * period).toString())
    }

    const getAmountsId = async (curVal) => {
        let amountIdArr = []

        totalNFT.map((item) => {
            if (curVal == item.type) {
                amountIdArr.push({
                    label: "#" + item.id,
                    value: item.id
                });
            }

        })
        setIdArr(amountIdArr)

    }
    const getInitAmount = async () => {
        let contractTemp = await getContractByName('SRT', state.api,)
        let initSrtAmount = await viewMethod(contractTemp, state.account, "totalSupply", [])
        setNftSrt(BigNumber(initSrtAmount).div(10 ** 18).toString())
    }


    const totalPow = async () => {
        let res = await handleViewMethod('getTotalWeight', [])
        if (res > 0) {
            setTotalPower(res)
        }
    }

    const userPow = async () => {
        let res = await handleViewMethod('getUserTotalWeight', [state.account])
        if (res > 0) {
            setUserPower(res)
        }
    }

    const nftPow = async () => {

        let resSmall = await handleViewMethod('weight', [smallNode])
        setSmallPower(resSmall)
        let resBig = await handleViewMethod('weight', [bigNode])
        setBigPower(resBig)
        let resSup = await handleViewMethod('weight', [superNode])
        setSupPower(resSup)

        // SmallNftAmount
        let res1 = await handleViewMethod('totalWeight', [smallNode])
        setSmallNftAmount(res1 / resSmall)
        let res2 = await handleViewMethod('totalWeight', [bigNode])
        setBigNftAmount(res2 / resBig)
        let res3 = await handleViewMethod('totalWeight', [superNode])
        setSupNftAmount(res3 / resSup)

        // userSmallNftAmount
        let userWeight1 = await handleViewMethod('userTotalWeight', [smallNode, state.account])
        setUseSmallNft(userWeight1 / resSmall)
        let userWeight2 = await handleViewMethod('userTotalWeight', [bigNode, state.account])
        setUseBigNft(userWeight2 / resBig)
        let userWeight3 = await handleViewMethod('userTotalWeight', [superNode, state.account])
        setUseSupNft(userWeight3 / resSup)

    }
    const getNodeLock = async (value) => {
        setNodeLock(value)
        setCurId(undefined)
        getAmountsId(value);

        if (value == smallNode) {
            setNftPower(smallPower)
        }
        if (value == bigNode) {
            setNftPower(bigPower)
        }
        if (value == superNode) {
            setNftPower(supPower)
        }

    }
    const getWithdrawLock = async (value) => {
        setWithdrawLock(value)
        setWithId(undefined)
        getLockIdOption(value)

    }

    const getMyLockId = async () => {
        const nftMap = [smallNode, bigNode, superNode]
        let tempArr = []
        nftMap.map(async address => {
            let lockIds = await handleViewMethod('getLockIds', [address, state.account])
            lockIds.forEach(id => {
                tempArr.push({
                    address: address,
                    value: id
                })
            })

        })
        setmyIdArr(tempArr)
    }
    const getLockIdOption = (address) => {
        let tempArr = []
        myIdArr.map(item => {
            if (item.address.toLowerCase() == address.toLowerCase()) {
                tempArr.push({
                    label: "#" + item.value,
                    value: item.value
                })
            }
        })
        setLockAmouns(tempArr.length)

        setChooseWithdrawIdArr(tempArr)
    }

    const getPendingUsdt = async () => {
        let usdt = await getContractByName("USDT", state.api,)
        let res = await viewMethod(usdt, state.account, 'balanceOf', [addressMap["NFTLockV3"].address])
        setTotalUSDT(BigNumber(res).div(10 ** USDTDecimals).toFixed(3))
    }


    const getLock = async () => {

        await handleDealMethod('lock', [nodeLock, curId])
        getMyLockId()
        getLockData()
        setTimeout(() => {
            getminingRecord()
            getAmountsId()
        }, 500)
    }


    const getWithdraw = async () => {
        await handleDealMethod('withdraw', [withdrawLock, withId])
        getLockData()
        getMyLockId()
        nftPow()
        setTimeout(() => {
            getunMiningRecord()
        }, 500)

    }

    const userSRTBalance = async () => {
        let contracts = await getContractByName('SRT', state.api,)
        let userLock = await viewMethod(contracts, state.account, "balanceOf", [state.account])
        setSrtBalance(BigNumber(userLock).div(10 ** 18).toString())
    }

    const getminingRecord = async () => {
        let records = await getMiningRecord(state.account)
        let res = records.data.lockRecords
        setMiningRecord(res)
    }
    const getunMiningRecord = async () => {
        let records = await getUnMiningRecord(state.account)
        let res = records.data.unLockRecords
        setUnMiningRecord(res)
    }

    const getwithdrawSrtRecord = async () => {
        let records = await getWithdrawSrtRecord(pageCount, curPage, state.account)
        let res = records.data.claimSrtRecords
        setWithSrtRecord(res)
    }

    const bonus = async () => {
        await handleDealMethod('bonus', [])
        getPendingUsdt()
        setTimeout(() => {
            getwithdrawSrtRecord()
        }, 500)

    }

    const getLockData = async () => {


        totalPow()

        userPow()
        getMyNFT()

    }

    useEffect(async () => {
        getunMiningRecord()
        getwithdrawSrtRecord()
        try {
            const priceRes = await getBTCPrice()
            setBTCPrice(priceRes.price)
        } catch (e) {
            console.log(e)
        }
        let judgeRes = await judgeStatus(state)
        if (!judgeRes) {
            return
        }
        getAdmin()
        getminingRecord()

        getPeriod()
        getStartTime()
        getInitAmount()

        getLockData()

        getPendingUsdt()
        userSRTBalance()

    }, [state.account]);
    useEffect(() => {
        if (smallNode && bigNode && superNode) {
            nftPow()
            getMyLockId()
            getLockIdOption(smallNode)
        }
    }, [smallNode, bigNode, superNode])
    return (
        <NFTLockV3Style>
            <div className="panel-box">
                <div className="panel-title">
                    {
                        adminAddr.toString().toLowerCase() == state.account.toLowerCase() &&
                        <div className="lockadmin" onClick={() => {
                            goPage('/NFTLockV3Admin')
                        }}>
                            Admin
                        </div>
                    }
                </div>

                <div className="panel-container">
                    <div className="nft-box">
                        <div className="left-part nftlock">
                            <div className="nft-title">
                                Node V3 Lock Mining Pool
                            </div>

                            <div className="nft-content">
                                <p className="name"> USDT </p>
                                <p className="value">{showNum(totalUSDT)}</p>
                            </div>
                            <div className="nft-content">
                                <p className="name">SuperNodeV3 ({supPower})</p>
                                <p className="value">{supNft}</p>
                            </div>
                            <div className="nft-content">
                                <p className="name">BigNodeV3 ({bigPower})</p>
                                <p className="value">{bigNft}</p>
                            </div>
                            <div className="nft-content">
                                <p className="name">SmallNodeV3 ({smallPower})</p>
                                <p className="value">{smallNft}</p>
                            </div>
                            <div className="nft-content">
                                <p className="name">Total Power</p>
                                <p className="value">{totalPower}</p>
                            </div>
                        </div>
                        <div className="nftlock right-part">
                            <div className="nft-title">
                                My Node V3 Lock
                            </div>
                            <div className="nft-content">
                                <p className="name">SuperNodeV3 </p>
                                <p className="value">{useSupNft}</p>
                            </div>
                            <div className="nft-content">
                                <p className="name">BigNodeV3 </p>
                                <p className="value">{useBigNft}</p>
                            </div>
                            <div className="nft-content">
                                <p className="name">SmallNodeV3 </p>
                                <p className="value">{useSmallNft}</p>
                            </div>
                            <div className="nft-content">
                                <p className="name">My Power</p>
                                <p className="value">{userPower}</p>
                            </div>
                        </div>
                    </div>
                    <div className="nav-box">
                        <div className={"nav-item " + (activeNav == 2 ? "active" : "")} onClick={() => {
                            setActiveNav(2)
                        }}>
                            {t("Bonus")}
                        </div>
                        <div className={"nav-item " + (activeNav == 0 ? "active" : "")} onClick={() => {
                            setActiveNav(0)
                        }}>
                            Lock Mining
                        </div>
                        <div className={"nav-item " + (activeNav == 1 ? "active" : "")} onClick={() => {
                            setActiveNav(1)
                        }}>
                            Withdraw
                        </div>

                    </div>
                    {activeNav == 0 && <div>
                        <div className="lock-box">
                            <Form form={form} name="control-hooks" className="form">
                                <div className="lock-part">
                                    <div className="nft-balance">
                                        <p className="name">Your Lock NFT</p>
                                        <p className="value">Balance: {IdArr.length}</p>
                                    </div>
                                    <div className="small-nft">

                                        <div className="input-box">
                                            <Select

                                                value={nodeLock}
                                                onChange={(value) => {
                                                    getNodeLock(value)
                                                }}
                                                className='select-box'
                                                options={nodeList}
                                                style={{
                                                    width: "100%",
                                                    color: '#FFF'
                                                }}

                                            />
                                        </div>
                                    </div>
                                    <div className="nft-balance" style={{marginTop: '2em'}}>
                                        <p className="name">Node ID</p>
                                        <p className="value">Power:{nftPower}</p>
                                    </div>
                                    <div className="max-box">

                                        <Select
                                            value={curId}
                                            onChange={(value) => {
                                                setCurId(value)
                                            }}
                                            className='select-box'
                                            options={IdArr}
                                            style={{
                                                width: "100%",
                                                color: '#FFF'
                                            }}

                                        >

                                        </Select>

                                    </div>
                                    {!isApprove && <Button onClick={() => {
                                        handleApprove()
                                    }} type="primary" className="lock-btn">
                                        Approve
                                    </Button>}
                                    {isApprove && <Button onClick={() => {
                                        getLock()
                                    }} type="primary" className="lock-btn">
                                        Lock Mining
                                    </Button>}

                                </div>
                            </Form>
                        </div>
                        <div className="panel-container">
                            <h1 className="panel-title">
                                {t("NFT Lock Mining Records")}
                            </h1>

                            <div className="superdao-list-box nft-list">
                                <div className='list-headert'>
                                    <div className="list-header ">
                                        <div className="col">
                                            {t("No.")}
                                        </div>
                                        <div className="col">
                                            {t("Address")}
                                        </div>

                                        <div className="col">
                                            NFT
                                        </div>

                                        <div className="col">
                                            ID
                                        </div>
                                        <div className="col">
                                            {t("Power")}
                                        </div>
                                        <div className="col">
                                            {t("Time(UTC)")}
                                        </div>

                                    </div>

                                    {
                                        miningRecord.length == 0 ? <Empty
                                            image={Empty.PRESENTED_IMAGE_SIMPLE}/> : miningRecord.map((item, index) => {
                                            return (
                                                <div className="list-item">
                                                    <div className="col no">
                                                        {index + 1}
                                                    </div>
                                                    <div className="col address">
                                                        <a>
                                                            {formatAddress(item.user)}
                                                        </a>
                                                    </div>
                                                    <div className="col">
                                                        {item.nft}
                                                    </div>
                                                    <div className="col">
                                                        {item.tokenId}
                                                    </div>
                                                    <div className="col">
                                                        {item.power}
                                                    </div>
                                                    <div className="col">
                                                        {dealTime(item.blockTimestamp)}
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>

                            </div>
                            <div className="pagination">
                                <Pagination current={curPage} showSizeChanger
                                            onShowSizeChange={handleShowSizeChange}
                                            onChange={onChangePage} total={total}
                                            defaultPageSize={pageCount}/>
                            </div>
                        </div>
                    </div>
                    }
                    {activeNav == 1 && <div>
                        <div className="lock-box">
                            <Form form={form} name="control-hooks" className="form">
                                <div className="lock-part">
                                    <div className="nft-balance">
                                        <p className="name">Choose NFT</p>
                                        <p className="value">Lock Amounts: {lockAmouns}</p>
                                    </div>
                                    <div className="small-nft">
                                        <div className="input-box">
                                            <Select
                                                value={withdrawLock}
                                                onChange={(value) => {
                                                    getWithdrawLock(value)
                                                }}
                                                options={nodeList}
                                                style={{
                                                    width: "100%",
                                                    color: '#FFF'
                                                }}

                                            />
                                        </div>
                                    </div>
                                    <div className="nft-balance" style={{marginTop: '2em'}}>
                                        <p className="name">Node ID</p>
                                        {/* <p className="value">{ }</p> */}
                                    </div>
                                    <div className="max-box">
                                        <Select
                                            value={withId}
                                            onChange={(value) => {
                                                setWithId(value)
                                            }}
                                            className='select-box'
                                            options={chooseWithdrawIdArr}
                                            style={{
                                                width: "100%",
                                                color: '#FFF'
                                            }}

                                        >

                                        </Select>

                                    </div>
                                    <Button onClick={() => {
                                        getWithdraw()
                                    }} type="primary" className="lock-btn">
                                        Withdraw
                                    </Button>

                                </div>
                            </Form>
                        </div>

                        <div className="panel-container">
                            <h1 className="panel-title">
                                Withdraw NFT Records
                            </h1>

                            <div className="superdao-list-box withdrawnft-list">
                                <div className='list-headert3'>
                                    <div className="list-header withnft-records-header">
                                        <div className="col">
                                            {t("No.")}
                                        </div>

                                        <div className="col">
                                            Address
                                        </div>
                                        <div className="col">
                                            NFT
                                        </div>
                                        <div className="col">
                                            ID
                                        </div>

                                        <div className="col">
                                             {t("Time(UTC)")}
                                        </div>

                                    </div>

                                    {unMiningRecord.length == 0 ? <Empty
                                        image={Empty.PRESENTED_IMAGE_SIMPLE}/> : unMiningRecord.map((item, index) => {
                                        return (<div key={index} className="list-item withnft-records-item">
                                            <div className="col no">
                                                {index + 1}
                                            </div>
                                            <div className="col address">
                                                <a>
                                                    {formatAddress(item.user)}
                                                </a>
                                            </div>
                                            <div className="col">
                                                {item.nft}
                                            </div>
                                            <div className="col">
                                                {item.tokenId}
                                            </div>

                                            <div className="col">
                                                {dealTime(item.blockTimestamp)}
                                            </div>
                                        </div>)
                                    })}
                                </div>

                            </div>
                            <div className="pagination">
                                <Pagination current={curPage} showSizeChanger
                                            onShowSizeChange={handleShowSizeChange}
                                            onChange={onChangePage} total={total}
                                            defaultPageSize={pageCount}/>
                            </div>

                        </div>
                    </div>
                    }
                    {activeNav == 2 && <div>
                        <div className="lock-box">
                            <Form form={form} name="control-hooks" className="form">
                                <div className="lock-part">
                                    <div className="nft-balance" style={{marginTop: '2em'}}>
                                        <p className="name">Start Time</p>
                                        <p className="value">{dealTime(startTime)}</p>
                                    </div>
                                    <div className="nft-balance" style={{marginTop: '2em'}}>
                                        <p className="name">End Time</p>
                                        <p className="value">{dealTime(BigNumber(startTime).plus(period).toString())}</p>
                                    </div>
                                </div>
                                <div className="lock-part" style={{marginTop: "20px"}}>
                                    <div className="nft-balance">
                                        <p className="name">Total Power</p>
                                        <p className="value">{totalPower}</p>
                                    </div>
                                    <div className="nft-balance">
                                        <p className="name">My Power</p>
                                        <p className="value">{userPower}</p>
                                    </div>

                                    <div className="nft-balance" style={{marginTop: '2em'}}>
                                        <p className="name">Total USDT</p>
                                        <p className="value"> {totalUSDT}</p>
                                    </div>
                                    <div className="nft-balance" style={{marginTop: '2em'}}>
                                        <p className="name">USDT I can receive</p>
                                        <p className="value"> {totalPower > 0 ? BigNumber(userPower).multipliedBy(totalUSDT).div(totalPower).toFixed(1) : 0}</p>
                                    </div>
                                    <div className="nft-balance" style={{marginTop: '2em'}}>
                                        <p className="name">BTC I can receive</p>
                                        <p className="value"> {totalPower > 0 ? BigNumber(userPower).multipliedBy(totalUSDT).div(btcPrice).div(totalPower).toFixed(7) : 0}</p>
                                    </div>
                                    <div className="nft-balance" style={{marginTop: '2em'}}>
                                        <p className="name">{t("Countdown")}</p>
                                        <p className="value"> {time != "end"?time:0}</p>
                                    </div>

                                    <Button disabled={time != "end"} onClick={() => {
                                        bonus()
                                    }} type="primary" className="lock-btn">
                                        {t("Bonus")}
                                    </Button>

                                </div>
                            </Form>
                        </div>

                        <div className="panel-container">
                            <h1 className="panel-title">
                                {t("Bonus Records")}
                            </h1>

                            <div className="superdao-list-box withdraw-list">
                                <div className='list-headert2'>
                                    <div className="list-header withall-records-header">
                                        <div className="col">
                                            {t("No.")}
                                        </div>

                                        <div className="col">
                                            Address
                                        </div>

                                        <div className="col">
                                            Amounts
                                        </div>

                                        <div className="col">
                                             {t("Time(UTC)")}
                                        </div>

                                    </div>

                                    {withSrtRecord.length == 0 ? <Empty
                                        image={Empty.PRESENTED_IMAGE_SIMPLE}/> : withSrtRecord.map((item, index) => {
                                        return (<div key={index} className="list-item withall-records-item">
                                            <div className="col no">
                                                {index + 1}
                                            </div>
                                            <div className="col address">
                                                <a>
                                                    {formatAddress(item.user)}
                                                </a>
                                            </div>
                                            <div className="col">
                                                {item.amount}
                                            </div>

                                            <div className="col">
                                                {dealTime(item.blockTimestamp)}
                                            </div>
                                        </div>)
                                    })}
                                </div>

                            </div>
                            <div className="pagination">
                                <Pagination current={curPage} showSizeChanger
                                            onShowSizeChange={handleShowSizeChange}
                                            onChange={onChangePage} total={total}
                                            defaultPageSize={pageCount}/>
                            </div>

                        </div>
                    </div>
                    }

                </div>


            </div>
        </NFTLockV3Style>
    )
}

export default NFTLockV3;
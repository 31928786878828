import React, { useEffect, useState } from "react";
import { useConnect } from "../../../../api/contracts";
import { dealMethod, dealPayMethod, viewMethod } from "../../../../utils/contractUtil";

import { getContractByName, getContractByContract } from "../../../../api/connectContract";
import { ETHPriceDecimals, MaxUint256, SBDDecimals } from "../../../../config/constants";
import judgeStatus from "../../../../utils/judgeStatus";
import addressMap from "../../../../api/addressMap"
import { getAdminDepositRecord, getAdminWithdrawRecord } from "../../../../graph/SBDLockGQL"
import { Button, Form, Input, Select, Pagination, Empty, message, notification } from "antd";

import BigNumber from "bignumber.js";
import SBDAdminStyle from './style'
import { showNum } from "../../../../utils/bigNumberUtil";
import { dealTime } from "../../../../utils/timeUtil";
import { formatAddress } from "../../../../utils/publicJs";
import {useTranslation} from "react-i18next";

const SBDAdmin = (props) => {
    const [form] = Form.useForm();
    const [activeNav, setActiveNav] = useState(0);
    let { state, dispatch } = useConnect();
    const [total, setTotal] = useState(0);
    const [pageCount, setPageCount] = useState(20);
    const [curPage, setCurPage] = useState(1);

    const [srtInput, setSrtInput] = useState();
    const [sbdAdmin, setSBDAdmin] = useState();
    const [rewardDays, setRewardDays] = useState();

    const [withRecords, setWithRecords] = useState([]);
    const [depositRecords, setDepositRecords] = useState([]);

    const [srtBalance, setSrtBalance] = useState();
    const [poolNumber, setPoolNumber] = useState();
    const { t } = useTranslation();

    const openNotification = (message) => {
        notification.error({
            message: message,
            description:
                "",
            onClick: () => {
            },
        });
    };

    const onChangePage = async (page) => {
        await setCurPage(page)
        depositSRTRecords(curPage,page)
        withSBDRecords(curPage,page)
    }
    const handleShowSizeChange = async (page, count) => {
        setPageCount(count)
        depositSRTRecords(count,curPage)
        withSBDRecords(count,curPage)
    }

    const handleDealMethod = async (name, params) => {
        let contractTemp = await getContractByName("SBDLock", state.api,)
        if (!contractTemp) {
            message.warn("Please connect", 5)
        }
        await dealMethod(contractTemp, state.account, name, params)
    }

    const handleViewMethod = async (name, params) => {
        let contractTemp = await getContractByName("SBDLock", state.api,)
        if (!contractTemp) {
            openNotification("Please connect")
        }
        return await viewMethod(contractTemp, state.account, name, params)
    }

    const handleApprove = async () => {
        let contractTemp = await getContractByName("SRT", state.api,)
        await dealMethod(contractTemp, state.account, "approve", [addressMap['SBDLock'].address, MaxUint256])

    }

    const transAdmin = async () => {
        let admin = await handleViewMethod('owner', [])
        setSBDAdmin(admin)
    }

    const newTransAdmin = async () => {
        await handleDealMethod('transferOwnership', [form.getFieldValue().NewTransAdmin])

    }

    const srtBanlance = async () => {
        let contractTemp = await getContractByName("SRT", state.api,)
        const srtAmounts = await viewMethod(contractTemp, state.account, "balanceOf", [state.account])
        setSrtBalance(srtAmounts)

    }

    const getCoinAdd = async (val) => {
        getPoolNumber(val)
    }
    const getPoolNumber = async (val) => {
        
        if (!state.api.utils.isAddress(val)) {
            return
        }
        let poolNum = await getContractByContract('erc20', val, state.api,)
        let res = await viewMethod(poolNum, state.account, "balanceOf", [addressMap['SBDLock'].address])
        let decimals = await viewMethod(poolNum, state.account, "decimals", [])
        setPoolNumber(BigNumber(res).div(10 ** decimals).toString())
    }

    const sbdDeposit = async () => {
        let times = (rewardDays)
        await handleDealMethod("deposit", [(BigNumber(form.getFieldValue().SrtData).multipliedBy(10 ** SBDDecimals)).toFixed(0).toString(), parseInt(times).toString()])
        srtBanlance()
        setTimeout(()=>{
            depositSRTRecords()
        },500)
    }

    const sbdWithdraw = async () => {
        if (!state.api.utils.isAddress(form.getFieldValue().coinAddress)) {
            message.error("wrong address")
            return
        }
        let poolNum = await getContractByContract('erc20', form.getFieldValue().coinAddress, state.api,)
        let decimals = await viewMethod(poolNum, state.account, "decimals", [])
        await handleDealMethod('backToken', [form.getFieldValue().coinAddress, BigNumber(form.getFieldValue().withAmount).multipliedBy(10 ** decimals).toFixed(0)])
        setTimeout(()=>{
            withSBDRecords()
        },500)
    }

    const withSBDRecords = async () => {
        let records = await getAdminWithdrawRecord(pageCount, curPage)
        let res = records.data.adminWithdraws
        setWithRecords(res)
    }
    const depositSRTRecords = async () => {
        let records = await getAdminDepositRecord(pageCount, curPage)
        let res = records.data.adminDeposits
        setDepositRecords(res)
    }

    useEffect(async () => {

        withSBDRecords()
        depositSRTRecords()

        let judgeRes = await judgeStatus(state)
        if (!judgeRes) {
            return
        }
        transAdmin()
        srtBanlance()

    }, [state.account]);

    return (
        <SBDAdminStyle>
            <div className="panel-box">
                <div className="panel-part1">
                    <div className="panel-container">
                        <div className="part-container">
                            <h3 className="flex-title">
                                Transfer Administrator
                            </h3>
                            <Form className="form" form={form} name="control-hooks">
                                <Form.Item label='Admin' name="current">
                                    <div className='current'>
                                        <p>
                                            {sbdAdmin}
                                        </p>
                                    </div>
                                </Form.Item>

                                <Form.Item
                                    name="NewTransAdmin"
                                    validateTrigger="onBlur"
                                    validateFirst={true}
                                    label="New Admin">
                                    <Input />
                                </Form.Item>
                            </Form>

                            <div className='submi'>
                                <Button type="primary" className="sub-butt"
                                    onClick={() => { newTransAdmin() }}>
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="panel-part2">

                    <div className="content-box1">
                        <div className="nav-box">
                            <div className={"nav-item " + (activeNav == 0 ? "active" : "")} onClick={() => {
                                setActiveNav(0)
                            }}>
                                Deposit SRT
                            </div>
                            <div className={"nav-item " + (activeNav == 1 ? "active" : "")} onClick={() => {
                                setActiveNav(1)
                            }}>
                                Withdraw
                            </div>
                        </div>
                        {
                            activeNav == 0 && <div>
                                <div className="lock-box">
                                    <Form form={form} name="control-hooks" className="form">
                                        <div className="lock-part">
                                            <div className="nft-balance">
                                                <p className="name">SRT Amounts</p>
                                                <p className="value">Balance: {showNum(BigNumber(srtBalance).div(10 ** 18).toString())}</p>
                                            </div>
                                            <div className="max-box">
                                                <Form.Item
                                                    name="SrtData"
                                                    validateTrigger="onBlur"
                                                    validateFirst={true}

                                                >
                                                    <div className="input-box">
                                                        <Input
                                                            value={srtInput}
                                                            placeholder="0"
                                                            onChange={(e) => {
                                                                setSrtInput(e.target.value)
                                                            }} />

                                                    </div>
                                                </Form.Item>
                                            </div>
                                            <div className="nft-balance" style={{ marginTop: '2em' }}>
                                                <p className="name">Reward Days</p>
                                            </div>
                                            <div className="max-box">
                                                <Form.Item
                                                    name="RewardsD"
                                                    validateTrigger="onBlur"
                                                    validateFirst={true}

                                                >
                                                    <div className="input-box">
                                                        <Input
                                                            value={rewardDays}
                                                            placeholder="0"
                                                            onChange={(e) => {
                                                                setRewardDays(e.target.value)
                                                            }} />

                                                    </div>
                                                </Form.Item>
                                            </div>

                                            <div className="nft-balance" style={{ marginTop: '2em' }}>
                                                <p className="name"> Block Reward</p>
                                            </div>
                                            <Form.Item>
                                                <div  style={{paddingLeft:"20px"}}>
                                                    {srtInput>0?BigNumber(srtInput).div(10368).div(rewardDays).toString():0}
                                                </div>
                                            </Form.Item>
                                            <div className="approve-box">
                                                <Button onClick={() => {
                                                    handleApprove()
                                                }} type="primary" className="lock-btn">
                                                    Approve
                                                </Button>
                                                <Button onClick={() => {
                                                    sbdDeposit()
                                                }} type="primary" className="lock-btn">
                                                    Submit
                                                </Button>
                                            </div>
                                        </div>
                                    </Form>


                                </div>
                                <div className="panel-part3">
                                    <h1 className="panel-title">
                                        Deposit Records
                                    </h1>

                                    <div className="superdao-list-box nft-list">
                                        <div className='list-headert'>
                                            <div className="list-header ">
                                                <div className="col">
                                                    {t("No.")}
                                                </div>

                                                <div className="col">
                                                    Address
                                                </div>

                                                <div className="col">
                                                    Token
                                                </div>

                                                <div className="col">
                                                    Amounts
                                                </div>
                                                <div className="col">
                                                     {t("Time(UTC)")}
                                                </div>

                                            </div>
                                            {depositRecords.length == 0 ? <Empty
                                                image={Empty.PRESENTED_IMAGE_SIMPLE} /> : depositRecords.map((item, index) => {
                                                    return (
                                                        <div className="list-item">
                                                            <div className="col no">
                                                                {index + 1}
                                                            </div>
                                                            <div className="col address">
                                                                <a>
                                                                    {formatAddress(item.admin)}
                                                                </a>
                                                            </div>
                                                            <div className="col">
                                                                {item.token}
                                                            </div>
                                                            <div className="col">
                                                                {BigNumber(item.amount).div(10**18).toFixed(0)}
                                                            </div>
                                                            <div className="col">
                                                                {dealTime(item.blockTimestamp)}
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>

                                    </div>
                                    <div className="pagination">
                                        <Pagination current={curPage} showSizeChanger
                                            onShowSizeChange={handleShowSizeChange}
                                            onChange={onChangePage} total={total}
                                            defaultPageSize={pageCount} />
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            activeNav == 1 && <div>
                                <div className="lock-box">
                                    <Form form={form} name="control-hooks" className="form">
                                        <div className="lock-part">
                                            <div className="nft-balance">
                                                <p className="name">Token Address</p>
                                            </div>
                                            <Form.Item
                                                name="coinAddress"
                                                validateTrigger="onBlur"
                                                validateFirst={true}
                                            >
                                                <div className="input-box">
                                                    <Input
                                                        onChange={(e) => {
                                                            getCoinAdd(e.target.value)
                                                        }} />
                                                </div>
                                            </Form.Item>
                                            <div className="nft-balance" style={{ marginTop: '2em' }}>
                                                <p className="name">Amounts</p>
                                                <p className="value">Pool Amounts: {showNum(poolNumber)}</p>
                                            </div>
                                            <div className="max-box">
                                                <Form.Item
                                                    name="withAmount"
                                                    validateTrigger="onBlur"
                                                    validateFirst={true}
                                                >
                                                    <div className="input-box">
                                                        <Input
                                                            placeholder="0"
                                                            onChange={(e) => { }} />
                                                    </div>
                                                </Form.Item>
                                            </div>
                                            <div className="approve-box">

                                                <Button onClick={() => {
                                                    sbdWithdraw()
                                                }} type="primary" className="lock-btn">
                                                    Submit
                                                </Button>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                                <div className="panel-part3">
                                    <h1 className="panel-title">
                                        Withdraw Records
                                    </h1>

                                    <div className="superdao-list-box nft-list">
                                        <div className='list-headert'>
                                            <div className="list-header ">
                                                <div className="col">
                                                    {t("No.")}
                                                </div>

                                                <div className="col">
                                                    Address
                                                </div>

                                                <div className="col">
                                                    Token
                                                </div>

                                                <div className="col">
                                                    Amounts
                                                </div>
                                                <div className="col">
                                                     {t("Time(UTC)")}
                                                </div>

                                            </div>
                                            {withRecords.length == 0 ? <Empty
                                                image={Empty.PRESENTED_IMAGE_SIMPLE} /> : withRecords.map((item, index) => {
                                                    return (
                                                        <div className="list-item">
                                                            <div className="col no">
                                                                {index + 1}
                                                            </div>
                                                            <div className="col address">
                                                                <a>
                                                                    {formatAddress(item.admin)}
                                                                </a>
                                                            </div>
                                                            <div className="col">
                                                                {item.token}
                                                            </div>
                                                            <div className="col">
                                                                {BigNumber(item.amount).div(10**18).toFixed(0)}
                                                            </div>
                                                            <div className="col">
                                                                {dealTime(item.blockTimestamp)}
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }

                                        </div>

                                    </div>
                                    <div className="pagination">
                                        <Pagination current={curPage} showSizeChanger
                                            onShowSizeChange={handleShowSizeChange}
                                            onChange={onChangePage} total={total}
                                            defaultPageSize={pageCount} />
                                    </div>
                                </div>
                            </div>
                        }
                    </div>

                </div>

            </div>
        </SBDAdminStyle>
    )
}
export default SBDAdmin
import styled from "styled-components";
export default  styled.div`

  .form {
    width: 70%;

    .ant-form-item {
      width: 80%;
      float: left;
    }
  }

  .ant-form-item-label > label {
    font-size: 18px;
    font-weight: bold;
    color: #8A8080;

  }

  .part3 {

    .btns {
      width: 65%;
      margin: 0px 0;
      height: 80px;

      .add-btn {
        width: 20%;
        height: 40px;
        font-size: 18px;
        color: rgb(51, 51, 51);;
        font-weight: bold;
      }
    }
  }

  .tip-box {
    background: rgb(28, 28, 28);
    border-radius: 15px;
    padding: 25px 35px;
    opacity: 1;
    border: 1px solid rgba(255, 255, 255, 0.15);
  }

  .ant-form-item-control-input {
    background: rgb(138, 128, 128);
  }

  .ant-input {
    font-size: 18px;
  }

  .tip {
    font-size: 18px;
    color: #8A8080;

    strong {
      color: #FFFFFF;
    }
  }

  .icon-box {
    width: 100px;
    display: flex;
    justify-content: space-between;
    margin: 0px 10px;

    img {
      width: 30px;
      height: 30px;
      margin-top: 35px;
      cursor: pointer;
    }
  }


  .sc {
    width: 25px;
  }

  .addsbt {
    text-align: center;
    width: 13%;
    padding: 6px;
    font-size: 18px;
    margin: 1.5em auto;
    border: 1px solid;
    color: #FFAE4E;

    border-radius: 8px;
  }


  .superdao-list-box {
    margin: 1em 0em;
    background: rgba(28, 28, 28, 1);
    padding-bottom: 10px;

    .list-header {

      display: flex;
      justify-content: flex-start;
      padding: 20px 0.5em;
      border-bottom: 1px solid rgba(234, 234, 234, 0.10);;
    }

    .list-item, .list-header {
      justify-content: flex-start;

      .col {

        text-align: left;
        font-size: 16px;

        &:nth-child(1) {
          margin-left: 100px;
          width: 20%;
        }

        &:nth-child(2) {
          width: 40%;
          margin-left: 10px;
        }

        &:nth-child(3) {
          width: 35%;
          margin-left: 10px;

        }

        &:nth-child(4) {
          width: 10%;
          margin-left: 10px;

        }

      }
    }

    .list-item:last-child {
      border-bottom: none;
    }

    .list-item {
      display: flex;
      justify-content: flex-start;
      padding: 10px 0.5em;
      width: 100%;
      margin: 0 auto;
      border-radius: 0px;
      border-bottom: 1px solid rgba(234, 234, 234, 0.10);;
      color: #FFFFFF;

      &:nth-child(even) {
        background: rgba(62, 62, 62, 1);
      }

      .col {
        overflow: hidden;
        padding-left: 0%;

      }

      .address {
        background: none !important;
        border: none !important;

        a {
          color: #CD9E57;
        }
      }
    }
  }


  @media screen and (max-width: 1440px) {
    .ant-form-item-control-input {
      background: rgb(138, 128, 128);
    }

    .ant-input {
      font-size: 16px;
    }

    .tip {
      font-size: 16px;
    }

    .ant-form-item-label > label {
      font-size: 16px;
      font-weight: bold;
      color: #8A8080;
    }

    .btns {
      .add-btn {
        font-size: 16px;
        color: rgb(51, 51, 51);;
        font-weight: bold;
      }
    }

    .superdao-list-box {
      .list-header {
        font-size: 15px;
      }

      .list-header, .list-item {
        .col {
          font-size: 15px;

          &:nth-child(1) {
            margin-left: 60px;
          }

          &:nth-child(3) {
            width: 38%;
          }


        }
      }


    }

    .addsbt {
      text-align: center;
      width: 13%;
      padding: 5px;
      font-size: 16px;
      margin: 1.5em auto;
      color: #FFAE4E;
      border: 1px solid;

      border-radius: 8px;
    }

  }

  @media screen and (max-width: 450px) {
    .ant-form-item-control-input {
      background: rgb(138, 128, 128);
    }

    .ant-input {
      font-size: 14px;
    }

    .tip {
      font-size: 14px;
    }

    .ant-form-item-label > label {
      font-size: 14px;
      font-weight: bold;
      color: #8A8080;

    }

    .superdao-list-box {
      overflow-x: scroll;

      .listheadert {
        width: 460px;
      }

      .list-header {
        font-size: 14px;
        padding: 20px 0.5em;
      }

      .list-header, .list-item {
        .col {
          font-size: 14px;

          &:nth-child(1) {
            margin-left: 5px;
            width: 120px;
          }

          &:nth-child(2) {
            margin-left: 5px;
            width: 120px;
          }

          &:nth-child(3) {
            margin-left: 5px;
            width: 120px;
          }

          &:nth-child(4) {
            margin-left: 5px;
            width: 80px;
          }

        }
      }

    }

    .form {
      width: 100%;
    }

    .part3 {
      .icon-box {
        width: 80px;
        display: flex;
        justify-content: space-between;
        margin: 0px auto;

        img {
          width: 30px;
          cursor: pointer;
          margin-top: 42px;

        }
      }

      .btns {
        width: 100%;
        margin: 0px;

        .add-btn {
          width: 40%;
          font-size: 14px;
          color: rgb(51, 51, 51);;
          font-weight: bold;
        }
      }

      .addsbt {
        text-align: center;
        width: 40%;
        padding: 5px;
        font-size: 14px;
        margin: 1.5em auto;
        color: #FFAE4E;
        border: 1px solid;

        border-radius: 8px;
      }

    }
  }
`


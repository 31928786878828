import {fetchQueryBase} from "./index";

// test05 new

// let sptUrl = "patton-sr/05ca"

// let bonus = "patton-sr/d686"
// test06
let sptUrl = "patton-sr/5f7d"
//
let bonus = "patton-sr/e9196"
if (true) {
}

export function getBonusRecords(page, pageSize) {
    return fetchQueryBase(bonus, {
        text: `{
           bonusRecords(first: 1000) {
            operate
            _to
            _amount
            _threshold
            period
            bonusAmount
            blockTimestamp
            transactionHash
          }
        }`
    }, "")
}

export function getRecords(page, pageSize) {
    return fetchQueryBase(sptUrl, {
        text: `{
           records(first:1000 where:{DividendV2Id:0}){
            user
            amount
            blockTime
          }
        }`
    }, "")
}

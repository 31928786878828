import {fetchQueryBase} from "./index";

// test05 new


let sptUrl = "patton-sr/5f7d"

// let bonus = "patton-sr/7d3a"
// test06
// let sptUrl = "patton-sr/6ab8"
//
let bonus = "patton-sr/610d"
if (true) {
}

export function getBonusRecords(page, pageSize) {
    return fetchQueryBase(bonus, {
        text: `{
           bonusRecords(first: 1000) {
            operate
            _to
            _amount
            _threshold
            period
            bonusAmount
            blockTimestamp
            transactionHash
          }
        }`
    }, "")
}

export function getRecords(page, pageSize) {
    return fetchQueryBase(sptUrl, {
        text: `{
   records(first:1000  where:{DividendV2Id:2}){
            user
            amount
            blockTime
          }
        }`
    }, "")
}

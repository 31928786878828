import React,{useEffect,useState} from "react";
import {Button} from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { useConnect } from "../../../../api/contracts";
import { dealMethod,dealPayMethod,viewMethod } from "../../../../utils/contractUtil";
import {formatAddress} from "../../../../utils/publicJs";


import LockMiningStyle from "./style";

const LockMining=()=>{
   
    const history = useNavigate();
    const goPage = (url) => {
        history(url);
    }
 

    return (
        <LockMiningStyle>
            <div className="header-box">
                <div className="header-item">
                    <div className="introduce">
                    Node NFT Lock Mining Introduce
                    </div>
                    <Button className="submit-btn"  type="primary" onClick={() => {goPage("/LockMiningView/NFTLock") }}>NFT Lock Mining </Button>
                </div>
                <div className="header-item">
                    <div className="introduce">
                    SBD Lock Mining Introduce
                    </div>
                    <Button className="submit-btn" type="primary" onClick={() => { goPage("/LockMiningView/SBDLock") }}>SBD Lock Mining</Button>
                </div>
            </div>
        </LockMiningStyle>
    )

}

export default LockMining
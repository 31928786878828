import {createGlobalStyle} from "styled-components";
import bonusBg from "../imgs/bonus_bg.png"
import bonusBgM from "../imgs/bonus_bg_m.png"
const CommonStyle = createGlobalStyle`
  .superdao-list-box{
    border-radius: 10px;
    overflow: hidden;
  }
  .bonus-bg{
    background: url("${bonusBg}");
    background-size: cover;
  }
  .superdao-nav-list {

    margin: 1em 0;
    display: flex;
    height: 50px;
    border-radius: 10px;
    user-select: none;
    font-size: 24px;
    font-family: Roboto-Bold, Roboto;
    font-weight: bold;
    color: #999999;
    padding: 3px;
 
    .nav-item {
    
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      flex-grow: 1;
      padding: 0px;
      cursor: pointer;
      color: #8A8080;
      margin-left: 8px;

      &:first-child {
        margin-left: 0;
      }

      &.active {
        border: none;
        font-weight: bold;
        color: #CC8A35;
        border-bottom:2px solid #CC8A35;

      }
    }
  }

  .admin-icon-box {
    padding: 3px;
    border: 1px solid #fff;
    float: right;
    width: 50px;
    height: 50px;
    margin-right: 6vw;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 50%;
    background: rgba(183, 183, 183, 0.3);

    .admin-icon {
      width: 30px;
      height: 30px;
    }
  }

  @media screen and (max-width: 700px) {
    .bonus-bg{
      background: url("${bonusBgM}");
      background-size: cover;
    }
  }

`
export default CommonStyle
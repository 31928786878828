module.exports = {
    api: null,
    apiError: null,
    apiState: null,
    isShowNav:false,
    account:"",
    ethBalance:0,
    myRecommender:"0x0000000000000000000000000000000000000000",
    networkId:null,
    userInfo:{},
    isMobile:false,
};

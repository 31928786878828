import React, {useEffect, useRef, useState} from 'react';
import {useConnect} from "../../../api/contracts";
import {Table, Button, Select, Descriptions, message, Form, List, Input, notification, Card, Switch} from 'antd';
import {getContractByName, getContractByContract} from "../../../api/connectContract";
import {dealMethod, viewMethod} from "../../../utils/contractUtil"
import {useNavigate, useLocation} from "react-router-dom";
import SPTIssueStyle from "./style";
import judgeStatus from "../../../utils/judgeStatus";
import AddWhiteListAddr from "./component/AddWhiteListAddr";
import RemoveWhiteListAddr from "./component/RemoveWhiteListAddr";
import add from "../../../imgs/add.png";
import cut from "../../../imgs/remove.png";
import addressMap from "../../../api/addressMap";
import BigNumber from "bignumber.js";
import {SPTCoinDecimal} from "../../../config/constants";


const SPTIssue = (props) => {
    let {state, dispatch} = useConnect();
    const [whitelist, setWhitelistArr] = useState([])


    const [dividendV2sArr, setDividendV2sArr] = useState()

    const [isTrue, setIsTrue] = useState(false)


    const [selectValue, setSelectValue] = useState()
    const [ownerAddr, setOwner] = useState("")

    const [isShowAddW, setIsShowAddW] = useState(false)


    const [secondAdminArr, setSecondAdmin] = useState([])
    const [isShowRemove, setShowRemove] = useState(false)

    const [sptRatio, setSptRatio] = useState([])

    const [addWhiteArr, setAddWArr] = useState([{}])

    const [addIssueWhiteArr, setIssueAddWArr] = useState([{}])
    const [form] = Form.useForm();

    const location = useLocation()

    const openNotification = (message) => {
        notification.error({
            message: message,
            description:
                "",
            onClick: () => {
            },
        });
    };
    const handleDealMethod = async (name, params) => {
        let contractTemp = await getContractByName("SPTIssue", state.api,)
        if (!contractTemp) {
            openNotification("Please connect")
        }
        return dealMethod(contractTemp, state.account, name, params)
    }

    const addOneWhite = async () => {
        let addWhiteArrT = JSON.parse(JSON.stringify(addWhiteArr))
        addWhiteArrT.push({})
        setAddWArr(addWhiteArrT)
    }
    const removeOneWhite = async () => {
        let addWhiteArrT = JSON.parse(JSON.stringify(addWhiteArr))
        addWhiteArrT.shift()
        setAddWArr(addWhiteArrT)
    }

    const addIssueOneWhite = async () => {
        let addIssueWhiteArrT = JSON.parse(JSON.stringify(addIssueWhiteArr))
        if(addIssueWhiteArrT.length>=5){
            return
        }
        addIssueWhiteArrT.push({})
        setIssueAddWArr(addIssueWhiteArrT)
    }
    const removeIssueOneWhite = async () => {
        let addIssueWhiteArrT = JSON.parse(JSON.stringify(addIssueWhiteArr))
        addIssueWhiteArrT.shift()
        setIssueAddWArr(addIssueWhiteArrT)
    }

    const handleViewMethod = async (name, params) => {
        let contractTemp = await getContractByName("SPTIssue", state.api,)
        if (!contractTemp) {
            openNotification("Please connect")
        }
        return await viewMethod(contractTemp, state.account, name, params)
    }
    const handlePurchaseViewMethod = async (name, params) => {
        let contractTemp = await getContractByName("spbd", state.api,)
        if (!contractTemp) {
            message.warn("Please connect", 5)
        }
        return await viewMethod(contractTemp, state.account, name, params)
    }
    const getSPTRatio = async () => {
        try {
            let tempArr = []
            for (let i = 0; i < 5; i++) {
                const res = await handlePurchaseViewMethod("SPTrate", [i])
                tempArr.push(BigNumber(res).div(100).toString())

            }
            setSptRatio(tempArr)
        } catch (e) {
            console.log(e)
        }
    }
    const getData = async (page) => {
        getOwner()
        getSecondAdmin()
        getSPTRatio()
    }


    const getOwner = async () => {
        const ownerAddr = await handleViewMethod("owner", [])
        setOwner(ownerAddr)
    }

    const getDividend = async () => {
        const length = await handleViewMethod("getDividendV2sLength", [])
        let dividendArr = []
        for (let i = 0; i < length; i++) {
            const res = await handleViewMethod("DividendV2s", [i])
            dividendArr.push(res)
        }
        setDividendV2sArr(dividendArr)
    }
    const getWhiteLists = async () => {
        const res = await handleViewMethod("whiteLists", [])
        setWhitelistArr(res)
    }


    const transferOwnership = async () => {
        await handleDealMethod("transferOwnership", [form.getFieldValue().owner])
        getOwner()
    }
    const setSpt = async () => {
        await handleDealMethod("setSpt", [form.getFieldValue().sptAddr])
    }

    const handleInit = async () => {
        let arr = []
        for (let i = 0; i < addWhiteArr.length; i++) {
            arr.push(form.getFieldValue()["address" + i])
        }
        await handleDealMethod("addSecondAdmin", [arr])
        getSecondAdmin()
    }

    const handleIssue = async () => {
        let arr = []
        for (let i = 0; i < addIssueWhiteArr.length; i++) {
            arr.push(form.getFieldValue()["address" + i])
        }

        let amountArr = []
        for (let i = 0; i < addIssueWhiteArr.length; i++) {
            amountArr.push(BigNumber([sptRatio[i]*selectValue/100]).multipliedBy(10 ** SPTCoinDecimal).toFixed(0))
        }
        if (ownerAddr.toLowerCase() == state.account.toLowerCase()) {
            await handleDealMethod("mintWithOwner", [arr, amountArr])
        } else {
            await handleDealMethod("mintWithSecondAdmin", [arr, amountArr])
        }

        getSecondAdmin()
    }
    const getSecondAdmin = async () => {
        const res = await handleViewMethod("getSecondAdmin", [])
        setSecondAdmin(res)
    }

    const handleDelete = async (address) => {
        await handleDealMethod("removeSecondAdmin", [[address]])
        getSecondAdmin()
    }

    useEffect(async () => {
        let judgeRes = await judgeStatus(state)
        if (!judgeRes) {
            return
        }
        await getData()
    }, [state.account]);
    const NumSelect = [
        {
            value: '200',
        },
        {
            value: '500',
        },
        {
            value: '1000',
        },
        {
            value: '2000',
        },
        {
            value: '5000',
        },
        {
            value: '10000',
        },
    ];
    const DividendV2sColumns = [
        {
            title: 'Address',
        },
        {
            title: 'Delete',
            key: 'action',
            render: (item) => (
                <Button onClick={() => handleDelete(item)}>Delete</Button>
            ),
        },


    ]
    return (
        <SPTIssueStyle>
            {isShowAddW && <AddWhiteListAddr updateData={() => {
                getWhiteLists()
            }} closeDialog={() => {
                setIsShowAddW(false)
            }}/>}
            {isShowRemove && <RemoveWhiteListAddr updateData={() => {
            }} closeDialog={() => {
                setShowRemove(false)
            }}/>}
            <h1 className="title">
                SPT Issue
            </h1>
            <div className="panel-box">
                <div className="panel-container">
                    <h4>
                        Contract Address({addressMap["SPTIssue"].address})
                    </h4>

                    <div className="part1">


                        {ownerAddr.toLowerCase() == state.account.toLowerCase() && <div>
                            <div className="content-item">
                                <h3>Owner Address</h3>
                                <Form form={form} name="control-hooks">
                                    <div className="current">
                                        <div className="name">
                                            Current:
                                        </div>
                                        <div className="value">
                                            {ownerAddr}
                                        </div>
                                    </div>

                                    <Form.Item
                                        name="owner"
                                        label="owner address"
                                        validateTrigger="onBlur"
                                        validateFirst={true}
                                        rules={[
                                            {required: true, message: 'Please input owner Address!'},
                                        ]}
                                    >
                                        <Input/>
                                    </Form.Item>
                                </Form>
                                <Button type="primary" onClick={() => {
                                    transferOwnership()
                                }}>
                                    Submit
                                </Button>
                            </div>
                            <div className="content-item">
                                <h3>Set SPT</h3>
                                <Form form={form} name="control-hooks">

                                    <Form.Item
                                        name="sptAddr"
                                        label="spt address"
                                        validateTrigger="onBlur"
                                        validateFirst={true}
                                        rules={[
                                            {required: true, message: 'Please input spt Address!'},
                                        ]}
                                    >
                                        <Input/>
                                    </Form.Item>
                                </Form>
                                <Button type="primary" onClick={() => {
                                    setSpt()
                                }}>
                                    Submit
                                </Button>
                            </div>
                            <div className="panel-title" style={{marginTop: "50px"}}>
                                Set Lv-2 Admin
                            </div>
                            <Table pagination={false} columns={DividendV2sColumns} dataSource={secondAdminArr}/>
                            <div className="content-item">
                                <Form form={form} name="control-hooks" className="form">

                                    {addWhiteArr.map((item, index) => {
                                        return (
                                            <Form.Item
                                                name={"address" + index}
                                                validateTrigger="onBlur"
                                                label="Address"
                                                validateFirst={true}
                                            >
                                                <div className="input-box">
                                                    <Input type="text"></Input>
                                                </div>
                                            </Form.Item>
                                        )
                                    })}
                                    <div className="icon-box">
                                        <img width={40} style={{cursor: "pointer"}} src={add} onClick={() => {
                                            addOneWhite()
                                        }}/>
                                        <img width={40} style={{cursor: "pointer"}} src={cut} onClick={() => {
                                            removeOneWhite()
                                        }}/>

                                    </div>
                                </Form>
                                <Button type="primary" className="max-btn" onClick={() => {
                                    handleInit()
                                }}>
                                    Submit
                                </Button>
                            </div>


                        </div>}
                        <div className="panel-title" style={{marginTop: "50px"}}>
                            SPT Issue
                        </div>

                        <div className="content-item">
                            <Form form={form} name="control-hooks" className="form">
                                <Select
                                    value={selectValue}
                                    onChange={(e)=>{
                                        setSelectValue(e)
                                    }}
                                    options={NumSelect}
                                />
                                {addIssueWhiteArr.map((item, index) => {
                                    return (
                                        <div className="flex-box">
                                            <Form.Item
                                                name={"address" + index}
                                                validateTrigger="onBlur"
                                                label="Address"
                                                style={{width: "400px"}}
                                                validateFirst={true}
                                            >
                                                <div className="input-box">
                                                    <Input type="text"></Input>
                                                </div>
                                            </Form.Item>
                                            <Form.Item
                                                name={"amount" + index}
                                                label="amount"
                                                validateTrigger="onBlur"
                                                validateFirst={true}
                                                style={{width: "100px"}}
                                                rules={[
                                                    {required: true, message: 'Please input amount!'},
                                                ]}
                                            >
                                                {[sptRatio[index]*selectValue/100]}
                                            </Form.Item>
                                        </div>
                                    )
                                })}
                                <div className="icon-box">
                                    <img width={40} style={{cursor: "pointer"}} src={add} onClick={() => {
                                        addIssueOneWhite()
                                    }}/>
                                    <img width={40} style={{cursor: "pointer"}} src={cut} onClick={() => {
                                        removeIssueOneWhite()
                                    }}/>

                                </div>
                            </Form>
                            <Button type="primary" className="max-btn" onClick={() => {
                                handleIssue()
                            }}>
                                Submit
                            </Button>
                        </div>
                    </div>


                </div>

            </div>
        </SPTIssueStyle>
    )
}
export default SPTIssue 

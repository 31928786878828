import React, {useEffect, useRef, useState} from 'react';
import {useConnect} from "../../../api/contracts";
import {
    Button,
    message,
    AutoComplete,
    Switch,
    Select,
    Form,
    Empty,
    Pagination, Input, Modal
} from 'antd';

import {getContractByName, getContractByContract} from "../../../api/connectContract";
import {dealMethod, dealPayMethod, viewMethod} from "../../../utils/contractUtil"

import develop from "../../../env";
import {useNavigate} from "react-router-dom";
import judgeStatus from "../../../utils/judgeStatus";
import {dealTime} from "../../../utils/timeUtil";
import bigNodeIMG from "../../../imgs/BigNode.png"
import smallNodeIMG from "../../../imgs/SmallNode.png"
import superNodeIMG from "../../../imgs/SuperNode.png"
import NFTStyle from './NFTStyle';

import addressMap from "../../../api/addressMap";
import {getMintId} from "../../../api/getEvent";


const NFTView = (props) => {
    let {state, dispatch} = useConnect();


    const [smallMinted, setSmallMinted] = useState()
    const [bigMinted, setBigMinted] = useState()
    const [supMinted, setSupMinted] = useState()
    const [totalNFT, setTotalNFT] = useState([])


    const [smallMintedV2, setSmallMintedV2] = useState()
    const [bigMintedV2, setBigMintedV2] = useState()
    const [supMintedV2, setSupMintedV2] = useState()
    const [totalNFTV2, setTotalNFTV2] = useState([])

    const [smallMintedV3, setSmallMintedV3] = useState()
    const [bigMintedV3, setBigMintedV3] = useState()
    const [supMintedV3, setSupMintedV3] = useState()
    const [totalNFTV3, setTotalNFTV3] = useState([])

    const history = useNavigate();
    const [form] = Form.useForm();


    const getInitAmountV1 = async () => {

        let contractTemp = await getContractByName("smallnodeV1", state.api,)
        let smallMinted = await viewMethod(contractTemp, state.account, "totalMint", [])
        console.log("v1", smallMinted)
        setSmallMinted(smallMinted)
        let contractTemp2 = await getContractByName("bignodeV1", state.api,)
        let bigMinted = await viewMethod(contractTemp2, state.account, "totalMint", [])
        console.log("v1", bigMinted)
        setBigMinted(bigMinted)

        let contractTemp3 = await getContractByName("supnodeV1", state.api,)
        let supMinted = await viewMethod(contractTemp3, state.account, "totalMint", [])
        console.log("v1", supMinted)
        setSupMinted(supMinted)
    }
    const getInitAmountV2 = async () => {

        let contractTemp = await getContractByName("smallnodeV2", state.api,)
        let smallMinted = await viewMethod(contractTemp, state.account, "totalMint", [])
        console.log("v2", smallMinted)
        setSmallMintedV2(smallMinted)
        let contractTemp2 = await getContractByName("bignodeV2", state.api,)
        let bigMinted = await viewMethod(contractTemp2, state.account, "totalMint", [])
        console.log("v2", bigMinted)
        setBigMintedV2(bigMinted)

        let contractTemp3 = await getContractByName("supnodeV2", state.api,)
        let supMinted = await viewMethod(contractTemp3, state.account, "totalMint", [])
        console.log("v2", supMinted)
        setSupMintedV2(supMinted)
    }
    const getInitAmountV3 = async () => {

        let contractTemp = await getContractByName("smallnodeV3", state.api,)
        let smallMinted = await viewMethod(contractTemp, state.account, "totalMint", [])
        console.log("v3", smallMinted)
        setSmallMintedV3(smallMinted)
        let contractTemp2 = await getContractByName("bignodeV3", state.api,)
        let bigMinted = await viewMethod(contractTemp2, state.account, "totalMint", [])
        console.log("v3", bigMinted)
        setBigMintedV3(bigMinted)

        let contractTemp3 = await getContractByName("supnodeV3", state.api,)
        let supMinted = await viewMethod(contractTemp3, state.account, "totalMint", [])
        console.log("v3", supMinted)

        setSupMintedV3(supMinted)
    }
    const getBalanceV1 = async () => {
        //生成合约实例
        let contractTempSmall = await getContractByName("smallnodeV1", state.api,)
        let contractTempBig = await getContractByName("bignodeV1",  state.api,)
        let contractTempSuper = await getContractByName("supnodeV1",  state.api,)

        //minted
        let smallMintedId = await viewMethod(contractTempSmall, state.account, "getUserMintIds", [state.account])
        let bigMintedId = await viewMethod(contractTempBig,  state.account, "getUserMintIds", [state.account])
        let superMintedId = await viewMethod(contractTempSuper,  state.account, "getUserMintIds", [state.account])
        // burned
        let smallBurnIds = await viewMethod(contractTempSmall,  state.account, "getUserBurnIds", [state.account])
        let bigBurnIds = await viewMethod(contractTempBig,  state.account, "getUserBurnIds", [state.account])
        let superBurnIds = await viewMethod(contractTempSuper,  state.account, "getUserBurnIds", [state.account])
        let totalNFT = []
        for (let i of smallMintedId) {
            if (smallBurnIds.indexOf(i) === -1) {
                totalNFT.push({
                    type: "small",
                    id:i
                })
            }
        }

        for (let i of bigMintedId) {
            if (bigBurnIds.indexOf(i) === -1) {
                totalNFT.push({
                    type: "big",
                    id:i
                })
            }
        }
        for (let i of superMintedId) {
            if (superBurnIds.indexOf(i) === -1) {
                totalNFT.push({
                    type: "sup",
                    id:i
                })
            }
        }
        setTotalNFT(totalNFT)
    }

    const getBalanceV2= async () => {
        //生成合约实例
        let contractTempSmall = await getContractByName("smallnodeV2", state.api,)
        let contractTempBig = await getContractByName("bignodeV2",  state.api,)
        let contractTempSuper = await getContractByName("supnodeV2",  state.api,)

        //minted
        let smallMintedId = await viewMethod(contractTempSmall, state.account, "getUserMintIds", [state.account])
        let bigMintedId = await viewMethod(contractTempBig,  state.account, "getUserMintIds", [state.account])
        let superMintedId = await viewMethod(contractTempSuper,  state.account, "getUserMintIds", [state.account])
        // burned
        let smallBurnIds = await viewMethod(contractTempSmall,  state.account, "getUserBurnIds", [state.account])
        let bigBurnIds = await viewMethod(contractTempBig,  state.account, "getUserBurnIds", [state.account])
        let superBurnIds = await viewMethod(contractTempSuper,  state.account, "getUserBurnIds", [state.account])
        let totalNFT = []

        for (let i of smallMintedId) {
            if (smallBurnIds.indexOf(i) === -1) {
                totalNFT.push({
                    type: "small",
                    id:i
                })
            }
        }

        for (let i of bigMintedId) {
            if (bigBurnIds.indexOf(i) === -1) {
                totalNFT.push({
                    type: "big",
                    id:i
                })
            }
        }
        for (let i of superMintedId) {
            if (superBurnIds.indexOf(i) === -1) {
                totalNFT.push({
                    type: "sup",
                    id:i
                })
            }
        }
        setTotalNFTV2(totalNFT)
    }

    const getBalanceV3= async () => {
        //生成合约实例
        let contractTempSmall = await getContractByName("smallnodeV3", state.api,)
        let contractTempBig = await getContractByName("bignodeV3",  state.api,)
        let contractTempSuper = await getContractByName("supnodeV3",  state.api,)

        //minted
        let smallMintedId = await viewMethod(contractTempSmall, state.account, "getUserMintIds", [state.account])
        let bigMintedId = await viewMethod(contractTempBig,  state.account, "getUserMintIds", [state.account])
        let superMintedId = await viewMethod(contractTempSuper,  state.account, "getUserMintIds", [state.account])
        // burned
        // let smallBurnIds = await viewMethod(contractTempSmall,  state.account, "getUserBurnIds", [state.account])
        // let bigBurnIds = await viewMethod(contractTempBig,  state.account, "getUserBurnIds", [state.account])
        // let superBurnIds = await viewMethod(contractTempSuper,  state.account, "getUserBurnIds", [state.account])
        let totalNFT = []

        for (let i of smallMintedId) {
            if ([].indexOf(i) === -1) {
                totalNFT.push({
                    type: "small",
                    id:i
                })
            }
        }

        for (let i of bigMintedId) {
            if ([].indexOf(i) === -1) {
                totalNFT.push({
                    type: "big",
                    id:i
                })
            }
        }
        for (let i of superMintedId) {
            if ([].indexOf(i) === -1) {
                totalNFT.push({
                    type: "sup",
                    id:i
                })
            }
        }
        setTotalNFTV3(totalNFT)
    }
    useEffect(async () => {
        let judgeRes = await judgeStatus(state)
        if (!judgeRes) {
            return
        }
        getBalanceV1()
        getBalanceV2()
        getBalanceV3()
        // try {
        //     const arr1 = await getMintId(state, addressMap["smallnodeV1"].address, "small")
        //     const arr2 = await getMintId(state, addressMap["bignodeV1"].address, "big")
        //     const arr3 = await getMintId(state, addressMap["supnodeV1"].address, "sup")
        //     console.log([...arr1, ...arr2, ...arr3])
        //     setTotalNFT([...arr1, ...arr2, ...arr3])
        // } catch (e) {
        //     console.log(e)
        // }
        // try {
        //     const arr1 = await getMintId(state, addressMap["smallnodeV2"].address, "small")
        //     const arr2 = await getMintId(state, addressMap["bignodeV2"].address, "big")
        //     const arr3 = await getMintId(state, addressMap["supnodeV2"].address, "sup")
        //     setTotalNFTV2([...arr1, ...arr2, ...arr3])
        // } catch (e) {
        //     console.log(e)
        // }
        // try {
        //     const arr1 = await getMintId(state, addressMap["smallnodeV2"].address, "small")
        //     const arr2 = await getMintId(state, addressMap["bignodeV2"].address, "big")
        //     const arr3 = await getMintId(state, addressMap["supnodeV2"].address, "sup")
        //     setTotalNFTV3([...arr1, ...arr2,...arr3])
        // } catch (e) {
        //     console.log(e)
        // }

    }, [state.account])
    // useEffect(() => {
    //     if (smallMinted >= 0 && bigMinted >= 0 && supMinted >= 0) {
    //         getBalanceV1()
    //
    //     }
    // }, [smallMinted, bigMinted, supMinted])
    //
    // useEffect(() => {
    //     if (smallMintedV2 >= 0 && bigMintedV2 >= 0 && supMintedV2 >= 0) {
    //         getBalanceV2()
    //     }
    // }, [smallMintedV2, bigMintedV2, supMintedV2])
    // useEffect(() => {
    //     if (smallMintedV3 >= 0 && bigMintedV3 >= 0 && supMintedV3 >= 0) {
    //         getBalanceV3()
    //     }
    // }, [smallMintedV3, bigMintedV3, supMintedV3])
    return (

        <NFTStyle>
            <div className="my-nft">
                <div className="panel-box">
                    <div className="panel-container">
                        <div className="panel-title">
                            Node V1
                        </div>
                        <div className="nft-list">
                            {totalNFT.map((item, index) => {
                                return (<div className="nft-item" key={index}>
                                    {item.type == "small" && <img src={smallNodeIMG}/>}
                                    {item.type == "big" && <img src={bigNodeIMG}/>}
                                    {item.type == "sup" && <img src={superNodeIMG}/>}
                                    <div className="id-box">
                                        <div className="left">ID</div>
                                        <div className="right">
                                            {item.id}
                                        </div>
                                    </div>
                                </div>)
                            })}

                        </div>
                    </div>
                    <div className="panel-container">
                        <div className="panel-title">
                            Node V2
                        </div>
                        <div className="nft-list">
                            {totalNFTV2.map((item, index) => {
                                return (<div className="nft-item" key={index}>
                                    {item.type == "small" && <img src={smallNodeIMG}/>}
                                    {item.type == "big" && <img src={bigNodeIMG}/>}
                                    {item.type == "sup" && <img src={superNodeIMG}/>}
                                    <div className="id-box">
                                        <div className="left">ID</div>
                                        <div className="right">
                                            {item.id}
                                        </div>
                                    </div>
                                </div>)
                            })}

                        </div>
                    </div>
                    <div className="panel-container">
                        <div className="panel-title">
                            Node V3
                        </div>
                        <div className="nft-list">
                            {totalNFTV3.map((item, index) => {
                                return (<div className="nft-item" key={index}>
                                    {item.type == "small" && <img src={smallNodeIMG}/>}
                                    {item.type == "big" && <img src={bigNodeIMG}/>}
                                    {item.type == "sup" && <img src={superNodeIMG}/>}
                                    <div className="id-box">
                                        <div className="left">ID</div>
                                        <div className="right">
                                            {item.id}
                                        </div>
                                    </div>
                                </div>)
                            })}

                        </div>
                    </div>
                </div>
            </div>
        </NFTStyle>
    )
}
export default NFTView
import erc20Abi from "../abi/erc20.json";

import spbd from "../abi/spbd.json"
import lock from "../abi/oglock.json"

import erc1155 from "../abi/erc1155.json"

import SBD from "../abi/sbd.json"
import SRT from "../abi/SRT.json"
import SVT from "../abi/svt.json"
import SPT from "../abi/spt.json"
import SFT from "../abi/sft.json"

import Swap from "../abi/swap.json"
import BigNode from "../abi/bigNode.json"
import SmallNode from "../abi/smallNode.json"
import SupNode from "../abi/supNode.json"


import NFTV3 from "../abi/NFTV3.json"


import SBDLock from "../abi/sbdlock.json"
import NFTLock from "../abi/nftlock.json"
import SBDSFTLock from "../abi/sbdsftlock.json"
import NFTLockV3 from "../abi/nftlockV3.json"
import leaderboardBonus from "../abi/dividend.json"
import SPTIssue from "../abi/sptIssue.json"
let CONTRACTS

if (true) {
    CONTRACTS = {
        erc20: {address: "", abi: erc20Abi},


        //test06
        spbd: {address: "0x65d741A9d21cA93AB8aC202b7e6693AE3656CEFa", abi: spbd},
        // new
        // spbd: {address: "0xD732E6c39Fb614518dAF00CEed31aE72763E7EAB", abi: spbd},

        lock: {address: "0xeF904F5Bd06E284Cb5140F651B67ee7BE7bebA5f", abi: lock},
        Swap: {address: "0x93d809098DD687162b81C9f13198e75af73D2E0f", abi: Swap},
        // test
        SRT: {address: "0x283cc14385BEfe07ff12D3Aaa1248F1Dfdf4DA35", abi: SRT},

        SVT: {address: "0x93d04c7311282114F79380F4624EBe3267bBd36A", abi: SVT},
        SFT: {address: "0xc2844f7c9d2982c1510c36c61ad76ef88b7e88d1", abi: SFT},


        SBD: {address: "0x3FED298a1Dd942b9E0F05A5d58f1AFA741847781", abi: SBD},


        SBDLock: {address: "0xb7c359518b3565489a20cd061b42ee57cfb5b0df", abi: SBDLock},


        USDT: {address: "0x19589F875420b7F0eC16c8D781cD9Ee1f86d8134", abi: erc20Abi},
        // test060x5D3bea722035f37ebA22Af4eC2A4ed20d08C3335
        SPT: {address: "0x85165F16BC0188FE6F83037Fba10a568acC65f7D", abi: SPT},
        leaderboardBonus: {address: "0x4b9333947532F9ec98361cfd04f7f86Db59e9196", abi: leaderboardBonus},
        NFTLock: {address: "0x0FAC788f05A30745b238968eF8c9626e5Dda31bB", abi: NFTLock},
        LeaderboardBonus1: {address: "0x924F5854a989e58F2d7a8054eae80FB98C7bd81e", abi: leaderboardBonus},
        LeaderboardBonus2: {address: "0xB0ae37dd044A3cC7B47695d7003c9a464117610d", abi: leaderboardBonus},
        NFTLockV2: {address: "0x8A3d1568E234cE1e77Ad25f0091b543dEF586aFb", abi: NFTLock},
        NFTLockV3: {address: "0xCD0dcE1ABACa6143a9119C89a2965923C7242725", abi: NFTLockV3},
        SBDSFTLock: {address: "0x0Dc695364DC1Bb3310DbE166CB8167270B223c58", abi: SBDSFTLock},


        SBDLockV3: {address: "0x725f3aA8efc28d2DEC86d09992E2b9159d8bf49c", abi: SBDSFTLock},



        // test07
        supnode: {address: "0xc3679f1f3aBedC165F54fAE413f611C436d1E0D6", abi: SupNode},
        bignode: {address: "0xEc86BA83251c709a30C2548BA5E4064b272cFF84", abi: BigNode},
        smallnode: {address: "0x3ffbD63Dcc8C96E3B4f040EF69c01c562D38aC10", abi: SmallNode},
        supnodeV1: {address: "0xc3679f1f3aBedC165F54fAE413f611C436d1E0D6", abi: SupNode},
        bignodeV1: {address: "0xEc86BA83251c709a30C2548BA5E4064b272cFF84", abi: BigNode},
        smallnodeV1: {address: "0x3ffbD63Dcc8C96E3B4f040EF69c01c562D38aC10", abi: SmallNode},
        supnodeV2: {address: "0x5687d3bD8aD6C86fC8dE1aD8267bA749c0F108Bf", abi: SupNode},
        bignodeV2: {address: "0xa7bc812ABF232C571408Bb3ecF4c2540efA2C617", abi: BigNode},
        smallnodeV2: {address: "0x61A46B5C4c442eCADBA7f8839BeCAf27c714E0aB", abi: SmallNode},

        supnodeV3: {address: "0x596Ae51a068DCD54e391D4A0843981C5e9460670", abi: NFTV3},
        bignodeV3: {address: "0xaC5924C466bE00a7912b92C45FB0Da00EBd58B2d", abi: NFTV3},
        smallnodeV3: {address: "0x9d5F2218842DA51e2Ac005561Ba37d3d3e7679E3", abi: NFTV3},

        // NFTLock: {address: "0x8036E6619AB21035904E1Ceac9e0275fcE1C6277", abi: NFTLock},
        // NFTLockV2: {address: "0xC9Ae81f38f7a80e48cb713a18ed8cC1Ac92ca741", abi: NFTLock},
        // NFTLockV3: {address: "0x91B0F0e737a609184ccbb62a637Aa54AbFc9f8a8", abi: NFTLockV3},
        // SBDSFTLock: {address: "0x01429266EC319FecBC8029deD87f34B2E5d067C1", abi: SBDSFTLock},
        // // SPT: {address: "0x41d018b92f929d5724C34032Cc10A6707A5405Ca", abi: SPT},
        //
        // // leaderboardBonus: {address: "0x4b9333947532F9ec98361cfd04f7f86Db59e9196", abi: leaderboardBonus},
        //
        // leaderboardBonus: {address: "0xB979a2Ed55815e9Bc1D46950A4a8161FFB29D686", abi: leaderboardBonus},
        // LeaderboardBonus1: {address: "0xE011a2AA6D225c17c9e668817bBCA70f18249810", abi: leaderboardBonus},
        // LeaderboardBonus2: {address: "0x3411FAd6bAe0C679a44ACD107dBEc809d46E7d3a", abi: leaderboardBonus},

        SPTIssue: {address: "0x0adcD38D20bdACe4893880DB24732E08965f98f8", abi: SPTIssue},
        erc1155: {abi: erc1155},
    };
} else {
    CONTRACTS = {
        erc20: {address: "", abi: erc20Abi},
        // SBD:{address: "0x19ED6899843234839e6Ab15aBf5d748299edf621", abi: SBD},

        SBD: {address: "0x84F19f4Fd5Beb26c4ec5C9e3FEed6e052Fe9645a", abi: SBD},


        // SRT:{address:"0x120D58c426F622b57FA14a8AfAb0F54429C7a1b6", abi: SRT},
        SRT: {address: "0x4069d0926Cc454e3d801F2999049bBD8B2c55509", abi: SRT},

        SPT: {address: "0xB2210e8aaACd56e34640a344e2074c529b2d8C0C", abi: SPT},
        // test04
        // spbd: {address: "0xc33d36a893971bad1879a49c4c8ee11242b077f5", abi: spbd},

        //test05

        // spbd: {address: "0x946df4570fd3d3c827f7e13c22eae37a835ea81a", abi: spbd},
        spbd: {address: "0x9CDC617eDe347a1CeB73DA317818Dd4F6B827508", abi: spbd},

        lock: {address: "0x34cae606E47423ae797F574E4C6a0C2069a6f818", abi: lock},

        Swap: {address: "0xcD95DFecE754E61C9e8981cE238e165B66A6088d", abi: Swap},

        SVT: {address: "0x7c893B81eF793a6541Cdeca519c385db587700F8", abi: SVT},
        SFT: {address: "0xCc425093298d8d44882bB54Be60b8814415Da152", abi: SFT},

        SBDLock: {address: "0x59DFA1145FC634b5EA994dd19b9C2aE6eAA32a33", abi: SBDLock},
        NFTLock: {address: "0x7BDec1a21B9193B8eDf3511034Fb376fefF34d13", abi: NFTLock},

        // main
        // SBD:{address: "0xaff416794e0b7cb2d1f842bcaea698ef1114a3f7", abi: SBD},
        // main2
        // SBD:{address: "0x7b54D42868635c6550E8C37216541312F6D8190D", abi: SBD},
        USDT: {address: "0x55d398326f99059fF775485246999027B3197955", abi: SBD},
        supnode: {address: "0xb76A93C29caa38e7e31eb3546A330AF14b8Ad9D1", abi: SupNode},
        bignode: {address: "0x7DC23763e85cAa79Ef5d14486B49C907Da4909Ce", abi: BigNode},
        smallnode: {address: "0x00dE2270e26828422BAC0aE9Fe2e2EB2b5305275", abi: SmallNode},
        erc1155: {abi: erc1155},
    };
}
export default CONTRACTS

import styled from "styled-components";
export default   styled.div`

  .ant-btn-primary::after {
    background: linear-gradient(229deg, #DAB163 0%, #CC8A35 100%);;

  }

  .ant-btn-primart {
    height: 35px;
    background: linear-gradient(229deg, #DAB163 0%, #CC8A35 100%);
  !important;

  }

  .connect-button {
    height: 36px;
    margin: 0.9em 0;
  }

  .ant-dropdown-menu-item {
    .ant-dropdown-menu-item-only-child {
      background-color: hsla(0, 14%, 12%, 1) !important;
    }
  }

  .ant-dropdown-menu-item
  .ant-dropdown-menu-item-active
  .ant-dropdown-menu-item:hover {
    background-color: hsla(0, 14%, 12%, 1) !important;
  }


  .ant-dropdown-menu-item:hover {
    background-color: hsla(0, 14%, 12%, 1) !important;
  }

  @media screen and (min-width: 1950px) {
    .ant-btn-primary {
      font-size: 16px;
      font-family: Roboto-Medium, Roboto;
      font-weight: 600;
      color: #1A1414;
      background: linear-gradient(229deg, #DAB163 0%, #CC8A35 100%);
    }

    .but2 {
      display: block;
    }

    .but1 {
      display: none;
    }

    .ant-btn .ant-btn-primary {
      border: 1px solid rgba(255, 255, 255, 0.15);
    }

    .ant-dropdown-menu-submenu-title:hover {
      background-color: rgb(138, 128, 128);
    }

    .ant-dropdown-menu-item-only-child {
      padding: 0px;
    }

    .ant-dropdown-menu-item {
      .ant-dropdown-menu-item-active >
      .ant-dropdown-menu-item-only-child {
        padding: 0px;
        background-color: hsl(0deg 0% 12.16%);
      }
    }

    .connect-button {

      border-radius: 10px;
      border: 1px solid rgba(255, 255, 255, 0.15);
      background: linear-gradient(229deg, #DAB163 0%, #CC8A35 100%);
    }
  }
  @media screen and (max-width: 1950px) {
    .ant-btn-primary {
      font-size: 16px;
      font-family: Roboto-Medium, Roboto;
      font-weight: 600;
      color: #1A1414;
      border-radius: 10px;
    }

    .but2 {
      display: block;
    }

    .but1 {
      display: none;
    }

    .ant-dropdown-menu-item {
      overflow: hidden;
    }

    .ant-btn .ant-btn-primary {
      font-size: 16px;
      font-family: Roboto-Medium, Roboto;
      font-weight: 600;
      color: #1A1414;
      border: 1px solid rgba(255, 255, 255, 0.15);
    }

    .ant-dropdown-menu-submenu-title:hover {
      background: linear-gradient(229deg, #DAB163 0%, #CC8A35 100%);
    }

    .ant-dropdown-menu-item-only-child {
      padding: 0px;
    }

    .ant-dropdown-menu-item {
      .ant-dropdown-menu-item-active >
      .ant-dropdown-menu-item-only-child {
        padding: 0px;
        background-color: hsl(0deg 0% 12.16%);
      }
    }

    .connect-button {
      border-radius: 10px;
      border: 1px solid rgba(255, 255, 255, 0.15);
    }
  }

  @media screen and (max-width: 1500px) {
    .ant-btn-primary {
      font-size: 16px;
      font-family: Roboto-Medium, Roboto;
      font-weight: 600;
      color: #1A1414;
    }

    .ant-btn .ant-btn-primary {
      font-size: 16px;
      font-family: Roboto-Medium, Roboto;
      font-weight: 600;
      color: #1A1414;
      border: 1px solid rgba(255, 255, 255, 0.15);
    }

    .but2 {
      display: block;
    }

    .but1 {
      display: none;
    }

    .ant-dropdown-menu-item-only-child {
      padding: 0px;
    }

    .ant-dropdown-menu-item {
      .ant-dropdown-menu-item-active >
      .ant-dropdown-menu-item-only-child {
        padding: 0px;
        background-color: hsl(0deg 0% 12.16%);
      }
    }

    .ant-dropdown-menu-submenu-title:hover {
      background: linear-gradient(229deg, #DAB163 0%, #CC8A35 100%);
    }

    .connect-button {
      border-radius: 10px;
      border: 1px solid rgba(255, 255, 255, 0.15);
      background: linear-gradient(229deg, #DAB163 0%, #CC8A35 100%);
    }

  }

  @media screen and (max-width: 450px) {


    .ant-btn-primary {
      font-size: 16px;
      font-family: Roboto-Medium, Roboto;
      font-weight: 600;
      color: #1A1414;
      background: linear-gradient(229deg, #DAB163 0%, #CC8A35 100%);
      border-radius: 10px;
    }

    .ant-btn .ant-btn-primary {
      font-size: 16px;
      font-family: Roboto-Medium, Roboto;
      font-weight: 600;
      color: #1A1414;
      background: linear-gradient(229deg, #DAB163 0%, #CC8A35 100%);
      border: 1px solid rgba(255, 255, 255, 0.15);

    }

    .ant-dropdown {
      display: none;
    }

    .but2 {
      display: none;
    }

    .but1 {
      display: block;
    }

    .ant-dropdown-menu-item-only-child {
      padding: 0px;
    }

    .ant-dropdown-menu-item {
      .ant-dropdown-menu-item-active >
      .ant-dropdown-menu-item-only-child {
        padding: 0px;
        background-color: hsl(0deg 0% 12.16%);
      }
    }

    .connect-button {
      border-radius: 10px;
      border: 1px solid rgba(255, 255, 255, 0.15);
      background: linear-gradient(229deg, #DAB163 0%, #CC8A35 100%);
    }

  }
  @media screen and (max-width: 400px) {

    .ant-dropdown {
      display: none;
    }

    .but1 {
      display: block;
      border-radius: 10px;
    }

    .but2 {
      display: none;
    }

    .ant-btn-primary {
      height: 35px;
      font-size: 16px;
      font-family: Roboto-Medium, Roboto;
      font-weight: 600;
      color: #1A1414;
      background: linear-gradient(229deg, #DAB163 0%, #CC8A35 100%);
    }

    .ant-btn .ant-btn-primary {
      border: 1px solid rgba(255, 255, 255, 0.15);
      background: linear-gradient(229deg, #DAB163 0%, #CC8A35 100%);
      font-size: 16px;
      font-family: Roboto-Medium, Roboto;
      font-weight: 600;
      color: #1A1414;
    }

    .ant-dropdown-menu-item-only-child {
      padding: 0px;
    }

    .ant-dropdown-menu-item {
      .ant-dropdown-menu-item-active >
      .ant-dropdown-menu-item-only-child {
        padding: 0px;
        background-color: hsl(0deg 0% 12.16%);
      }
    }

    .connect-button {
      border-radius: 10px;
      border: 1px solid rgba(255, 255, 255, 0.15);
      background: linear-gradient(229deg, #DAB163 0%, #CC8A35 100%);
    }

  }


`


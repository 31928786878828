import React, {useEffect, useState} from "react";
import {useConnect} from "../../../../api/contracts";

import {dealMethod, dealPayMethod, viewMethod} from "../../../../utils/contractUtil";
import {getContractByName, getContractByContract} from "../../../../api/connectContract";
import {ETHPriceDecimals, MaxUint256, SBDDecimals, SPTCoinDecimal, SRTCoinDecimal} from "../../../../config/constants";
import {getAdminDepositRecord, getAdminWithdrawRecord} from "../../../../graph/NFTLockGQL"

import judgeStatus from "../../../../utils/judgeStatus";
import addressMap from "../../../../api/addressMap"
import {Button, Form, Input, Select, Pagination, Empty, message, notification} from "antd";

import {showNum} from "../../../../utils/bigNumberUtil";
import {dealTime} from "../../../../utils/timeUtil";
import BigNumber from "bignumber.js";

import LockMiningAdminStyle from './style'
import {formatAddress} from "../../../../utils/publicJs";
import {useTranslation} from "react-i18next";

const LockMiningAdmin = () => {
    const [form] = Form.useForm();
    const [activeNav, setActiveNav] = useState(0);
    let {state, dispatch} = useConnect();
    const [total, setTotal] = useState(0);
    const [pageCount, setPageCount] = useState(20);
    const [curPage, setCurPage] = useState(1);
    const [nftAdmin, setNFTAdmin] = useState()
    const [srtBalance, setSrtBalance] = useState()
    const [parsed, setParsed] = useState()
    const [srtPoolBalance, setSrtPoolBalance] = useState()
    const [time, setTime] = useState()
    const [mintNewAddr, setMintNewAddr] = useState()

    const [withRecords, setWithRecords] = useState([]);
    const [depositRecords, setDepositRecords] = useState([]);
    const [srtInput, setSrtInput] = useState();

    const [poolNumber, setPoolNumber] = useState()
    const [smallNode, setSmallNode] = useState()
    const [bigNode, setBigNode] = useState()
    const [supNode, setSupNode] = useState()
    const [rewardDays, setRewardsDays] = useState()

    const [smallPower, setSmallPower] = useState()
    const [bigPower, setBigPower] = useState()
    const [supPower, setSupPower] = useState()
    const { t } = useTranslation();

    const openNotification = (message) => {
        notification.error({
            message: message,
            description:
                "",
            onClick: () => {
            },
        });
    };

    const onChangePage = async (page) => {
        await setCurPage(page)
        withNFTRecords(curPage, page)
        depositSrtRecords(curPage, page)
    }
    const handleShowSizeChange = async (page, count) => {
        setPageCount(count)
        withNFTRecords(count, curPage)
        depositSrtRecords(count, curPage)
    }

    const handleDealMethod = async (name, params) => {
        let contractTemp = await getContractByName("NFTLock", state.api,)
        if (!contractTemp) {
            message.warn("Please connect", 5)
        }
        await dealMethod(contractTemp, state.account, name, params)
    }

    const handleViewMethod = async (name, params) => {
        let contractTemp = await getContractByName("NFTLock", state.api,)
        if (!contractTemp) {
            openNotification("Please connect")
        }
        return await viewMethod(contractTemp, state.account, name, params)
    }

    const handleApprove = async () => {
        let contractTemp = await getContractByName("SRT", state.api,)
        await dealMethod(contractTemp, state.account, "approve", [addressMap['NFTLock'].address, MaxUint256])
    }


    const setSmallAdAddress = async () => {
        let contractTemp = await getContractByName("NFTLock", state.api,)
        await dealMethod(contractTemp, state.account, 'setSmallNode', [form.getFieldValue().newSmallAddress])

    }
    const setBigAdAddress = async () => {
        let contractTemp = await getContractByName("NFTLock", state.api,)
        await dealMethod(contractTemp, state.account, 'setBigNode', [form.getFieldValue().newBigAddress])

    }

    const setSuperAdAddress = async () => {
        let contractTemp = await getContractByName("NFTLock", state.api,)
        await dealMethod(contractTemp, state.account, 'setSupNode', [ form.getFieldValue().newSuperAddress])

    }
    const getTime = async () => {
        let lockBlockNumber = await handleViewMethod('lockBlockNumber', [])
        let day = await handleViewMethod('day', [])
        let intervalTime = await handleViewMethod('intervalTime', [])

        setTime(BigNumber(lockBlockNumber).div(day).multipliedBy(intervalTime).toFixed(1,BigNumber.ROUND_CEIL))
    }
    const getPause = async () => {
        let res = await handleViewMethod('paused', [])
        setParsed(res)
    }
    const transAdmin = async () => {
        let admin = await handleViewMethod('owner', [])
        setNFTAdmin(admin)
    }
    const getMintNew = async () => {
        if(state.api.utils.isAddress(form.getFieldValue().mintToNewAddr1)){
            let res = await handleViewMethod('mintNew', [form.getFieldValue().mintToNewAddr1])
            setMintNewAddr(res)
        }
    }

    const handlePause = async () => {

        await handleDealMethod('pause', [])
        getPause()
    }
    const handleUnPause = async () => {
        await handleDealMethod('unpause', [])
        getPause()
    }

    const newTransAdmin = async () => {
        await handleDealMethod('transferOwnership', [form.getFieldValue().NewTransAdmin])
        transAdmin()
    }
    const mintToNew = async () => {
        await handleDealMethod("mintToNew",[form.getFieldValue().mintToNewAddr1,form.getFieldValue().mintToNewAddr2])
        getMintNew()
    }
    const mintBigNode = async () => {
        await handleDealMethod("mintBigNode",[state.account])
    }
    const handleSetLockTime = async () => {
        await handleDealMethod('setLockTime', [form.getFieldValue().LockDay])
        getTime()
    }

    const setSuperAdReward = async () => {
        await handleDealMethod('setNftToReward', [supNode, BigNumber(form.getFieldValue().newSuperReward).multipliedBy(10 ** SPTCoinDecimal).toFixed(0)])
        getPower()
    }
    const setSmallAdReward = async () => {
        await handleDealMethod('setNftToReward', [smallNode, BigNumber(form.getFieldValue().newSmallReward).multipliedBy(10 ** SPTCoinDecimal).toFixed(0)])
        getPower()
    }
    const setBigAdReward = async () => {
        await handleDealMethod('setNftToReward', [bigNode, BigNumber(form.getFieldValue().newBigReward).multipliedBy(10 ** SPTCoinDecimal).toFixed(0)])
        getPower()
    }
    const srtBanlance = async () => {

        let contractTemp = await getContractByName("SRT", state.api,)
        const srtAmounts = await viewMethod(contractTemp, state.account, "balanceOf", [state.account])
        setSrtBalance(srtAmounts)


        const poolsrtAmounts = await viewMethod(contractTemp, state.account, "balanceOf", [addressMap["NFTLock"].address])
        setSrtPoolBalance(poolsrtAmounts)
    }

    const getSmall = async () => {
        let nftSmall = await handleViewMethod('smallNode', [])
        setSmallNode(nftSmall)

    }

    const getBig = async () => {
        let nftBig = await handleViewMethod('bigNode', [])
        setBigNode(nftBig)

    }
    const getSuper = async () => {
        let nfftSup = await handleViewMethod('supNode', [])
        setSupNode(nfftSup)

    }

    const nftDeposit = async () => {

        await handleDealMethod('deposit', [BigNumber(form.getFieldValue().SrtData).multipliedBy(10 ** SRTCoinDecimal).toFixed(0)])
        srtBanlance()
        setTimeout(() => {
            depositSrtRecords()
        }, 500)
    }
    //获取输入的地址
    const getCoinAdd = async (value) => {
        getPoolNumber(value)
    }
    const getPoolNumber = async (value) => {
        if (!state.api.utils.isAddress(value)) {
            return
        }
        let poolNum = await getContractByContract('erc20', value, state.api,)
        let res = await viewMethod(poolNum, state.account, "balanceOf", [addressMap['NFTLock'].address])
        setPoolNumber(BigNumber(res).div(10 ** 18).toString())
    }


    const nftWithdraw = async () => {
        if (!state.api.utils.isAddress(form.getFieldValue().coinedAddress)) {
            message.error("wrong address")
            return
        }
        let poolNum = await getContractByContract('erc20', form.getFieldValue().coinedAddress, state.api,)
        let decimals = await viewMethod(poolNum, state.account, "decimals", [])
        await handleDealMethod('backToken', [BigNumber(form.getFieldValue().withAmounts).multipliedBy(10 ** decimals).toFixed(0)])
        setTimeout(() => {
            withNFTRecords()
        }, 500)
    }

    const withNFTRecords = async () => {
        let records = await getAdminWithdrawRecord(pageCount, curPage)
        let res = records.data.adminWithdraws
        setWithRecords(res)
    }
    const depositSrtRecords = async () => {
        let records = await getAdminDepositRecord(pageCount, curPage)
        let res = records.data.adminDeposits
        setDepositRecords(res)
    }

    useEffect(async () => {
        withNFTRecords()
        depositSrtRecords()
        let judgeRes = await judgeStatus(state)
        if (!judgeRes) {
            return
        }

        transAdmin()
        getPause()
        srtBanlance()
        getSmall()
        getBig()
        getSuper()

    }, [state.account])
    const getPower = async () => {
        let smallPow = await handleViewMethod("nftToReward", [smallNode])
        let bigPow = await handleViewMethod("nftToReward", [bigNode])
        let superPow = await handleViewMethod("nftToReward", [supNode])
        console.log(addressMap["NFTLock"].address,smallNode,smallPow,"smallNode")
        console.log(addressMap["NFTLock"].address,bigNode,bigPow,"smallNode")
        console.log(addressMap["NFTLock"].address,supNode,superPow,"smallNode")
        setSmallPower(BigNumber(smallPow).div(10 ** SRTCoinDecimal).toString())
        setBigPower(BigNumber(bigPow).div(10 ** SRTCoinDecimal).toString())
        setSupPower(BigNumber(superPow).div(10 ** SRTCoinDecimal).toString())
    }
    useEffect(async () => {
        if (smallNode && bigNode && supNode) {
            console.log(111111111)
            getPower()
            getTime()
        }
    }, [smallNode, bigNode, supNode,state.account])
    return (
        <LockMiningAdminStyle>
            <div className="panel-box">
                <div className="panel-part1">
                    <div className="panel-container">
                        <div className="part-container">
                            <h3 className="flex-title">
                                Transfer Administrator
                            </h3>
                            <Form className="form" form={form} name="control-hooks">
                                <Form.Item label='Admin' name="current">
                                    <div className='current'>
                                        <p>
                                            {nftAdmin}
                                        </p>
                                    </div>
                                </Form.Item>

                                <Form.Item
                                    name="NewTransAdmin"
                                    validateTrigger="onBlur"
                                    validateFirst={true}
                                    label="New Admin">
                                    <Input/>
                                </Form.Item>
                            </Form>

                            <div className='submi'>
                                <Button type="primary" className="sub-butt"
                                        onClick={() => {
                                            newTransAdmin()
                                        }}>
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="panel-container">
                        <div className="part-container">
                            <h3 className="flex-title">
                                Mint NFT
                            </h3>

                            <div className='submi'>
                                <Button type="primary" className="sub-butt"
                                        onClick={() => {
                                            mintToNew()
                                        }}>
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="panel-container">
                        <div className="part-container">
                            <h3 className="flex-title">
                                 Mint New Address
                            </h3>
                            <Form className="form" form={form} name="control-hooks">
                                Old:{mintNewAddr}
                                <Form.Item
                                    name="mintToNewAddr1"
                                    validateTrigger="onBlur"
                                    validateFirst={true}
                                    label="NFT Address">
                                    <Input onChange={()=>{getMintNew()}}/>
                                </Form.Item>
                                <Form.Item
                                    name="mintToNewAddr2"
                                    validateTrigger="onBlur"
                                    validateFirst={true}
                                    label="New Address">
                                    <Input/>
                                </Form.Item>
                            </Form>

                            <div className='submi'>
                                <Button type="primary" className="sub-butt"
                                        onClick={() => {
                                            mintToNew()
                                        }}>
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="panel-part2">
                    <div className="panel-title">
                        Contract Status({parsed ? "paused" : "not pause"})
                    </div>
                    <div className="panel-container">
                        <Button type="primary" className="submit-button"
                                onClick={() => {
                                    handlePause()
                                }}>
                            Paused
                        </Button>
                        <Button type="primary" className="submit-button"
                                onClick={() => {
                                    handleUnPause()
                                }}>
                            Unpause
                        </Button>
                    </div>
                    <div className="panel-title">
                        Pool SRT Balance
                    </div>
                    <div className="panel-container">
                        <strong style={{fontSize:"20px"}}>
                            {BigNumber(srtPoolBalance).div(10**SRTCoinDecimal).toFixed(2)}
                        </strong>
                    </div>
                    <div className="panel-title">
                        Node NFT V1 Rewards
                    </div>
                    <div className="content-box">
                        <div className="panel-container">
                            <div className="power-small">
                                <p className="name">Small Node</p>
                                <p className="value">{smallPower}</p>
                            </div>
                            <Form form={form} className='form'>
                                <p className="newpower">New Reward</p>
                                <Form.Item
                                    name="newSmallReward"
                                    validateTrigger="onBlur"
                                    validateFirst={true}

                                >
                                    <Input/>
                                </Form.Item>
                            </Form>
                            <div className='submit'>
                                <Button type="primary" className="submit-button"
                                        onClick={() => {
                                            setSmallAdReward()
                                        }}>
                                    Submit
                                </Button>
                            </div>
                        </div>
                        <div className="panel-container">
                            <div className="power-small">
                                <p className="name">Big Node</p>
                                <p className="value">{bigPower}</p>
                            </div>
                            <Form form={form} className='form'>
                                <p className="newpower">New Reward</p>
                                <Form.Item
                                    name="newBigReward"
                                    validateTrigger="onBlur"
                                    validateFirst={true}
                                >
                                    <Input/>
                                </Form.Item>
                            </Form>
                            <div className='submit'>
                                <Button type="primary" className="submit-button"
                                        onClick={() => {
                                            setBigAdReward()
                                        }}>
                                    Submit
                                </Button>
                            </div>

                        </div>
                        <div className="panel-container">
                            <div className="power-small">
                                <p className="name">Super Node</p>
                                <p className="value">{supPower}</p>
                            </div>
                            <Form form={form} className='form'>
                                <p className="newpower">New Reward</p>
                                <Form.Item
                                    name="newSuperReward"
                                    validateTrigger="onBlur"
                                    validateFirst={true}

                                >
                                    <Input/>
                                </Form.Item>
                            </Form>
                            <div className='submit'>
                                <Button type="primary" className="submit-button"
                                        onClick={() => {
                                            setSuperAdReward()
                                        }}>
                                    Submit
                                </Button>
                            </div>

                        </div>

                    </div>
                    <div className="panel-title">
                        Set Unlocking Days({time})
                    </div>

                    <div className="panel-container">
                        <Form form={form} className='form'>
                            <p className="newpower">Days</p>
                            <Form.Item
                                name="LockDay"
                                validateTrigger="onBlur"
                                validateFirst={true}

                            >
                                <Input/>
                            </Form.Item>
                        </Form>
                        <div className='submit'>
                            <Button type="primary" className="submit-button"
                                    onClick={() => {
                                        handleSetLockTime()
                                    }}>
                                Submit
                            </Button>
                        </div>
                    </div>

                    <h1 className="panel-title">
                        3 Catecory NFT V1 Address
                    </h1>
                    <div className="content-box">
                        <div className="panel-container">
                            <div className="power-small">
                                <p className="name">Small Node</p>
                                <p className="value" style={{wordBreak: "break-word"}}>{smallNode}</p>
                            </div>
                            <Form form={form} className='form'>
                                <p className="newpower">New Address</p>
                                <Form.Item
                                    name="newSmallAddress"
                                    validateTrigger="onBlur"
                                    validateFirst={true}

                                >
                                    <Input/>
                                </Form.Item>
                            </Form>
                            <div className='submit'>
                                <Button type="primary" className="submit-button"
                                        onClick={() => {
                                            setSmallAdAddress()
                                        }}>
                                    Submit
                                </Button>
                            </div>
                        </div>
                        <div className="panel-container">
                            <div className="power-small">
                                <p className="name">Big Node</p>
                                <p className="value" style={{wordBreak: "break-word"}}>{bigNode}</p>
                            </div>
                            <Form form={form} className='form'>
                                <p className="newpower">New Address</p>
                                <Form.Item
                                    name="newBigAddress"
                                    validateTrigger="onBlur"
                                    validateFirst={true}
                                >
                                    <Input/>
                                </Form.Item>
                            </Form>
                            <div className='submit'>
                                <Button type="primary" className="submit-button"
                                        onClick={() => {
                                            setBigAdAddress()
                                        }}>
                                    Submit
                                </Button>
                            </div>

                        </div>
                        <div className="panel-container">
                            <div className="power-small">
                                <p className="name">Super Node</p>
                                <p className="value" style={{wordBreak: "break-word"}}>{supNode}</p>
                            </div>
                            <Form form={form} className='form'>
                                <p className="newpower">New Address</p>
                                <Form.Item
                                    name="newSuperAddress"
                                    validateTrigger="onBlur"
                                    validateFirst={true}

                                >
                                    <Input/>
                                </Form.Item>
                            </Form>
                            <div className='submit'>
                                <Button type="primary" className="submit-button"
                                        onClick={() => {
                                            setSuperAdAddress()
                                        }}>
                                    Submit
                                </Button>
                            </div>

                        </div>

                    </div>
                    <div className="content-box1">
                        <div className="nav-box">
                            <div className={"nav-item " + (activeNav == 0 ? "active" : "")} onClick={() => {
                                setActiveNav(0)
                            }}>
                                Deposit SRT
                            </div>
                            <div className={"nav-item " + (activeNav == 1 ? "active" : "")} onClick={() => {
                                setActiveNav(1)
                            }}>
                                Withdraw
                            </div>
                        </div>
                        {
                            activeNav == 0 && <div>
                                <div className="lock-box">
                                    <Form form={form} name="control-hooks" className="form">
                                        <div className="lock-part">
                                            <div className="nft-balance">
                                                <p className="name">SRT Amounts</p>
                                                <p className="value">Balance: {showNum(BigNumber(srtBalance).div(10 ** 18).toString())}</p>
                                            </div>
                                            <div className="max-box">
                                                <Form.Item
                                                    name="SrtData"
                                                    validateTrigger="onBlur"
                                                    validateFirst={true}
                                                >
                                                    <Input
                                                        value={srtInput}
                                                        placeholder="0"
                                                        onChange={(e) => {
                                                            setSrtInput(e.target.value)
                                                        }}/>
                                                </Form.Item>
                                            </div>

                                            <div className="approve-box">
                                                <Button onClick={() => {
                                                    handleApprove()
                                                }} type="primary" className="lock-btn">
                                                    Approve
                                                </Button>
                                                <Button onClick={() => {
                                                    nftDeposit()
                                                }} type="primary" className="lock-btn">
                                                    Submit
                                                </Button>
                                            </div>
                                        </div>
                                    </Form>


                                </div>
                                <div className="panel-part3">
                                    <h1 className="panel-title">
                                        Deposit Records
                                    </h1>

                                    <div className="superdao-list-box nft-list">
                                        <div className='list-headert'>
                                            <div className="list-header ">
                                                <div className="col">
                                                    {t("No.")}
                                                </div>

                                                <div className="col">
                                                    Address
                                                </div>

                                                <div className="col">
                                                    Token
                                                </div>

                                                <div className="col">
                                                    Amounts
                                                </div>
                                                <div className="col">
                                                     {t("Time(UTC)")}
                                                </div>

                                            </div>
                                            {
                                                depositRecords.length == 0 ? <Empty
                                                    image={Empty.PRESENTED_IMAGE_SIMPLE}/> : depositRecords.map((item, index) => {
                                                    return (
                                                        <div className="list-item">
                                                            <div className="col no">
                                                                {index + 1}
                                                            </div>
                                                            <div className="col address">
                                                                <a>
                                                                    {formatAddress(item.admin)}
                                                                </a>
                                                            </div>
                                                            <div className="col">
                                                                {item.token}
                                                            </div>
                                                            <div className="col">
                                                                {BigNumber(item.amount).div(10 ** 18).toFixed(0)}
                                                            </div>
                                                            <div className="col">
                                                                {dealTime(item.blockTimestamp)}
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }

                                        </div>

                                    </div>
                                    <div className="pagination">
                                        <Pagination current={curPage} showSizeChanger
                                                    onShowSizeChange={handleShowSizeChange}
                                                    onChange={onChangePage} total={total}
                                                    defaultPageSize={pageCount}/>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            activeNav == 1 && <div>
                                <div className="lock-box">
                                    <Form form={form} name="control-hooks" className="form">
                                        <div className="lock-part">
                                            <div className="nft-balance">
                                                <p className="name">Token Address</p>
                                            </div>
                                            <Form.Item
                                                name="coinedAddress"
                                                validateTrigger="onBlur"
                                                validateFirst={true}
                                            >
                                                <div className="input-box">
                                                    <Input
                                                        onChange={(e) => {
                                                            getCoinAdd(e.target.value)
                                                        }}/>

                                                </div>
                                            </Form.Item>
                                            <div className="nft-balance" style={{marginTop: '2em'}}>
                                                <p className="name">Amounts</p>
                                                <p className="value">Pool Amounts: {showNum(poolNumber)}</p>
                                            </div>
                                            <div className="max-box">
                                                <Form.Item
                                                    name="withAmounts"
                                                    validateTrigger="onBlur"
                                                    validateFirst={true}
                                                >
                                                    <div className="input-box">
                                                        <Input
                                                            placeholder="0"
                                                            onChange={(e) => {
                                                            }}/>
                                                    </div>
                                                </Form.Item>
                                            </div>
                                            <div className="approve-box">

                                                <Button onClick={() => {
                                                    nftWithdraw()
                                                }} type="primary" className="lock-btn">
                                                    Submit
                                                </Button>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                                <div className="panel-part3">
                                    <h1 className="panel-title">
                                        Withdraw Records
                                    </h1>

                                    <div className="superdao-list-box nft-list">
                                        <div className='list-headert'>
                                            <div className="list-header ">
                                                <div className="col">
                                                    {t("No.")}
                                                </div>

                                                <div className="col">
                                                    Address
                                                </div>

                                                <div className="col">
                                                    Token
                                                </div>

                                                <div className="col">
                                                    Amounts
                                                </div>
                                                <div className="col">
                                                     {t("Time(UTC)")}
                                                </div>

                                            </div>
                                            {
                                                withRecords.length == 0 ? <Empty
                                                    image={Empty.PRESENTED_IMAGE_SIMPLE}/> : withRecords.map((item, index) => {
                                                    return (
                                                        <div className="list-item">
                                                            <div className="col no">
                                                                {index + 1}
                                                            </div>
                                                            <div className="col address">
                                                                <a>
                                                                    {formatAddress(item.admin)}
                                                                </a>
                                                            </div>
                                                            <div className="col">
                                                                {item.token}
                                                            </div>
                                                            <div className="col">
                                                                {BigNumber(item.amount).div(10 ** 18).toFixed(0)}
                                                            </div>
                                                            <div className="col">
                                                                {dealTime(item.blockTimestamp)}
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>

                                    </div>
                                    <div className="pagination">
                                        <Pagination current={curPage} showSizeChanger
                                                    onShowSizeChange={handleShowSizeChange}
                                                    onChange={onChangePage} total={total}
                                                    defaultPageSize={pageCount}/>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>

                </div>

            </div>
        </LockMiningAdminStyle>
    )
}
export default LockMiningAdmin
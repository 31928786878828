// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// 引入语言文件
import translationEN from './locales/en.json';
import translationZH from './locales/zh.json';

const resources = {
    en: {
        translation: translationEN,
    },
    zh: {
        translation: translationZH,
    },
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: 'en', // 默认语言
        fallbackLng: 'en',
        keySeparator: false, // 不使用默认的 '.' 作为 key 分隔符
        interpolation: {
            escapeValue: false, // 不转义HTML标签
        },
    });

export default i18n;
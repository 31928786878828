import React, {useEffect, useState, useRef} from "react";
import {useConnect} from "../../../../../api/contracts";
import {dealMethod, dealPayMethod, viewMethod} from "../../../../../utils/contractUtil";
import {ETHPriceDecimals, MaxUint256, SBDDecimals} from "../../../../../config/constants";

import {dealTime} from "../../../../../utils/timeUtil";
import addressMap from "../../../../../api/addressMap"
import {getContractByName, getContractByContract} from "../../../../../api/connectContract";
import judgeStatus from "../../../../../utils/judgeStatus";
import {showNum} from "../../../../../utils/bigNumberUtil";
import {formatAddress} from "../../../../../utils/publicJs";
import {
    getMiningRecord,
    getWithdrawRecord,
    getWithdrawSrtRecord,
    getMyMiningRecord,
    getMyWithdrawRecord,
    getMyWithdrawSrtRecord
} from "../../../../../graph/SBDLockGQL";
import BigNumber from "bignumber.js";
import {Button, Form, Input, Select, Pagination, Empty, Modal, message, notification} from "antd";
import {useLocation, useNavigate} from "react-router-dom";
import SBDSFTLockStyle from './SBDSFTLockStyle'
import {useTranslation} from "react-i18next";


const SBDLock = (props) => {
    let {state, dispatch} = useConnect();
    const [total, setTotal] = useState(0);
    const [pageCount, setPageCount] = useState(20);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [curOrderId, setCurOrderId] = useState();

    const [adminAddr, setAdmin] = useState("");

    const [curPage, setCurPage] = useState(1);
    const [activeNav, setActiveNav] = useState(0);
    const [indexArr, setIndexArr] = useState([]);

    const [curIndex, setCurIndex] = useState(0);

    const [sbdRewards, setSbdRewardsTime] = useState([])
    const [sbdRewardsNum, setSbdRewardsNum] = useState([])
    const [sbdData, setSbdData] = useState([]);

    const [weight, setWeight] = useState(1)
    const [sbdSrt, setSbdSrt] = useState();
    const [svtAmount, setSvtAmount] = useState();

    const [allowanceNum, setAllowanceNum] = useState(0);

    const [allRecords, setAllRecords] = useState([]);
    const [myRecords, setMyRecords] = useState([]);
    const [myOrderArr, setMyOrderArr] = useState([]);
    const [myWithRecords, setMyWithRecords] = useState([]);

    const [withRecords, setWithRecords] = useState([]);
    const [srtRecords, setSrtRecords] = useState([]);
    const [myClaimRecords, setMyClaimRecords] = useState([]);

    const [canClaim, setClaimWith] = useState();
    const [annualized, setAnnualized] = useState();

    const [periodValue, setPeriodValue] = useState('0');
    const [listNum, setListNum] = useState('0');
    const [poolSFTBalance, setPoolSftBalance] = useState();
    const [poolSBDBalance, setPoolSbdBalance] = useState();

    const [sbdBalance, setSbdBalance] = useState();
    const [sftBalance, setSFTBalance] = useState();
    const [svtBalance, setSvtBalance] = useState();
    const [receiveSVT, setReceiveSVT] = useState();
    const [inputValue, setInputValue] = useState()
    const [estimatedRevenue, setEstimatedRevenue] = useState(0)

    const [pendingSrt, setPendingSFT] = useState()

    const [listActiveNav, setListActiveNav] = useState(0);
    const [ordersActiveNav, setOrdersActiveNav] = useState(0);
    const [withdrawActiveNav, setWithdrawActiveNav] = useState(0);
    const [claimActiveNav, setClaimActiveNav] = useState(0);
    const [selectOption, setSelectOption] = useState([]);

    const { t } = useTranslation();

    const [form] = Form.useForm();
    const history = useNavigate()

    const openNotification = (message) => {
        notification.error({
            message: message,
            description:
                "",
            onClick: () => {
            },
        });
    };
    const getAllProfit = async () => {
        let res = await handleViewMethod('getAllProfit', [])
        let selectOption = []
        if(res&&res.length>0){
            res.forEach(item=>{
                if(item==0){
                    selectOption.push(  {
                        value: '0',
                        label: t("Demand deposits"),
                    },)
                }else{
                    selectOption.push({
                        value:item,
                        label:item+" Month"
                    })
                }

            })
        }
        selectOption.sort((a,b)=>{
            return a.value-b.value
        })
        setSelectOption(selectOption)
    }
    const lockList = [
        {
            value: '0',
            label: t('Demand deposits'),
        },

        {
            value: '3',
            label: '3 Month',
        },
        {
            value: '6',
            label: '6 Month',
        },
        {
            value: '9',
            label: '9 Month',
        },
        {
            value: '12',
            label: '12 Month',
        },

    ]


    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const goPage = (url) => {
        history(url)
    }

    const onChangePage = async (page) => {
        await setCurPage(page)
        MiningRecord(curPage, page)
        withOrderRecords(curPage, page)
        withSrtRecords(curPage, page)
    }
    const handleShowSizeChange = async (page, count) => {
        setPageCount(count)
        MiningRecord(count, curPage)
        withOrderRecords(count, curPage)
        withSrtRecords(count, curPage)

    }

    const handleDealMethod = async (name, params) => {
        let contractTemp = await getContractByName("SBDSFTLock", state.api,)
        if (!contractTemp) {
            message.warn("Please connect", 5)
        }
        await dealMethod(contractTemp, state.account, name, params)
    }

    const handleViewMethod = async (name, params) => {
        let contractTemp = await getContractByName("SBDSFTLock", state.api,)
        if (!contractTemp) {
            openNotification("Please connect")
        }
        return await viewMethod(contractTemp, state.account, name, params)
    }

    const handleApprove = async () => {
        let contractTemp = await getContractByName("SBD", state.api,)
        await dealMethod(contractTemp, state.account, "approve", [addressMap['SBDSFTLock'].address, MaxUint256])
        getAllowance()
    }
    const getAdmin = async () => {
        let admin = await handleViewMethod('owner', [])
        setAdmin(admin)
    }
    const handleAllowance = async () => {
        let contractTemp = await getContractByName("SBD", state.api,)
        const allowanceNum = await viewMethod(contractTemp, state.account, "allowance", [state.account, addressMap['SBDSFTLock'].address])
        setAllowanceNum(BigNumber(allowanceNum).div(10 ** 18))
    }

    const getAllowance = async () => {
        let contractTemp = await getContractByName("SBD", state.api,)
        let res = await viewMethod(contractTemp, state.account, "allowance", [state.account, addressMap['SBDSFTLock'].address])
        setAllowanceNum(res)
    }

    const getInitAmount = async () => {
        let contractTemp = await getContractByName('SRT', state.api,)
        let initSrtAmount = await viewMethod(contractTemp, state.account, "totalSupply", [])
        setSbdSrt(BigNumber(initSrtAmount).div(10 ** 18).toString())

    }

    const getReward = async () => {
        let length = await handleViewMethod('getRewardLength', [])
        let rewardSrt = [], endTimeArr = [], rewardsNumArr = [], tempArr = []

        for (let i = 0; i < length; i++) {

            let rewardTime = await handleViewMethod("rewardTime", [i])
            let rewardsTimes = await handleViewMethod("startRewardTime", [i])

            let rewardsNum = await handleViewMethod("reward", [i])
            tempArr.push({
                value: i + 1,
                label: i + 1 + "#",
            })
            endTimeArr.push(rewardsTimes)
            rewardsNumArr.push(BigNumber(rewardsNum).div(10 ** 18).toString())
            rewardSrt.push(BigNumber(rewardTime).div(86400).toString())
        }
        setIndexArr(tempArr)
        setSbdRewardsTime(endTimeArr)
        setSbdData(rewardSrt)
        setSbdRewardsNum(rewardsNumArr)
    }

    const userSBDBalance = async () => {
        let contracts = await getContractByName('SBD', state.api,)
        let userLock = await viewMethod(contracts, state.account, "balanceOf", [state.account])
        setSbdBalance(BigNumber(userLock).div(10 ** 18).toString())
    }
    const getPoolBalance = async () => {
        let contracts = await getContractByName('SFT', state.api,)
        let res = await viewMethod(contracts, state.account, "balanceOf", [addressMap["SBDSFTLock"].address])
        console.log(res)
        setPoolSftBalance(BigNumber(res).div(10 ** 18).toString())
        let contracts2 = await getContractByName('SBD', state.api,)
        let res2 = await viewMethod(contracts2, state.account, "balanceOf", [addressMap["SBDSFTLock"].address])
        setPoolSbdBalance(BigNumber(res2).div(10 ** 18).toString())
    }
    const userSRTBalance = async () => {
        let contracts = await getContractByName('SFT', state.api,)
        let res = await viewMethod(contracts, state.account, "balanceOf", [state.account])
        console.log(res)
        setSFTBalance(BigNumber(res).div(10 ** 18).toString())
    }

    const userSVTBalance = async () => {
        let contracts = await getContractByName('SVT', state.api,)
        let userLock = await viewMethod(contracts, state.account, "balanceOf", [state.account])
        setSvtBalance(BigNumber(userLock).div(10 ** 18).toString())
    }

    const getPeriod = async (value) => {
        setPeriodValue(value)
        userWeigh(value)

    };
    const getEstimatedRevenue = async () => {
        let monthRate = 1
        if(periodValue!=0){
            monthRate = BigNumber(periodValue).div(12)
        }
        if (inputValue && weight) {
            setEstimatedRevenue(BigNumber(inputValue).multipliedBy(weight).div(100).multipliedBy(monthRate).toFixed(1,BigNumber.ROUND_CEIL))
        }
    };
    useEffect(() => {
        getEstimatedRevenue()
    }, [inputValue, weight])
    const usersLock = async () => {
        if (!form.getFieldValue().locksbd || BigNumber(form.getFieldValue().locksbd).multipliedBy(10 ** 18).toFixed(0) <= 0) {
            message.error("Input amount")
            return
        }
        await handleDealMethod('stake', [periodValue, BigNumber(form.getFieldValue().locksbd).multipliedBy(10 ** 18).toFixed(0).toString()])

        setTimeout(() => {
            MiningRecord()
            getUserLock()
            userSBDBalance()
        }, 500)
    }

    const userWeigh = async (periodValue) => {
        if (periodValue) {
            let res = await handleViewMethod('profit', [periodValue])
            setWeight(res)
        }

    }

    const getPendingSFT = async () => {
        let res = await handleViewMethod('canClaim', [])
        console.log(res)
        setPendingSFT(BigNumber(res).div(10 ** 18).toFixed(3))
    }

    const getSbdWithdraw = async () => {
        let res = await handleViewMethod('canWithdraw', [])
        setClaimWith(BigNumber(res).div(10 ** 18).toString())
    }
    const getAnnualized = async () => {
        try {
            let res = await handleViewMethod('outPutAnnualized', [])
            setAnnualized(res)
        } catch (e) {

        }
    }


    const userSvt = async () => {
        let res = (form.getFieldValue().locksbd) * (weight)
        setReceiveSVT(res)
    }

    const claimSFT = async () => {
        await handleDealMethod('claim', [BigNumber(form.getFieldValue().claimAmount).multipliedBy(10 ** 18).toFixed(0)])
        getPendingSFT()
        userSRTBalance()
        setTimeout(() => {
            withSrtRecords()
        }, 500)
    }


    const getWithSBD = async () => {
        console.log(form.getFieldValue().withAmount)
        await handleDealMethod('withdraw', [BigNumber(form.getFieldValue().withAmount).multipliedBy(10 ** SBDDecimals).toFixed(0)])
        getUserLock()
        withOrderRecords()

    }

    const MiningRecord = async () => {
        try {
            let records = await getMiningRecord(pageCount, curPage)
            let res = records.data.lockRecords
            let myrecords = await getMyMiningRecord(state.account)
            let res2 = myrecords.data.lockRecords
            setMyRecords(res2)
            setAllRecords(res)
        } catch (e) {
            console.log(e)
        }

    }
    const getUserLock = async () => {
        let length = await handleViewMethod('getUserLockCount', [state.account])
        let tempArr = []
        for (let i = 0; i < length; i++) {
            const res = await handleViewMethod('userLock', [state.account, i])
            tempArr.push({
                id: i + 1,
                ...res
            })
        }
        tempArr.reverse()
        setMyOrderArr(tempArr)
    }

    const withOrderRecords = async () => {
        try{
            let records = await getWithdrawRecord(pageCount, curPage)
            let res = records.data.withdrawRecords
            let myrecords = await getMyWithdrawRecord(state.account)
            let res2 = myrecords.data.withdrawRecords
            setWithRecords(res)
            setMyWithRecords(res2)
        }catch (e) {
            console.log(e)
        }
    }


    const withSrtRecords = async () => {
        let records = await getWithdrawSrtRecord(pageCount, curPage)
        if (records.data) {
            let res = records.data.claimSrtRecords
            setSrtRecords(res)
        }
        let myrecords = await getMyWithdrawSrtRecord(state.account)

        if (myrecords.data) {
            let res2 = myrecords.data.claimSrtRecords
            setMyClaimRecords(res2)
        }


    }

    const getMaxValue = async () => {
        let res = form.getFieldValue().withAmounts
    }


    useEffect(() => {
            if (inputValue > 0 && weight > 0) {
                userSvt()
            }
        }, [inputValue, weight]
    )
    useEffect(async () => {
        MiningRecord()
        withOrderRecords()
        withSrtRecords()
        let judgeRes = await judgeStatus(state)
        if (!judgeRes) {
            return
        }
        getAdmin()
        getAllProfit()
        userSBDBalance()
        getPoolBalance()
        userSVTBalance()
        getInitAmount()
        getSbdWithdraw()

        getAnnualized()
        userWeigh()
        userSRTBalance()
        getAllowance()
        getPendingSFT()
        getUserLock()
        handleAllowance()
        getPeriod('0')
    }, [state.account]);

    return (
        <SBDSFTLockStyle>

            <Modal title="Withdraw" className="withdraw-modal" open={isModalOpen} onOk={handleOk} footer={null}
                   onCancel={handleCancel}>
                <div className="withdraw-box">
                    <div className="withsbd" style={{display: 'flex', justifyContent: 'space-between'}}>
                        <p className="name" style={{color: 'rgba(138, 128, 128, 1)'}}>You Can Withdraw SBD</p>
                        <p className="modal-value">{showNum(canClaim)}</p>
                    </div>
                    <div className="nft-balance" style={{marginTop: '2em'}}>
                        <p className="name">Withdraw Amounts</p>
                    </div>
                    <Form form={form} name="control-hooks" className="form">
                        <Form.Item
                            name="withAmount"
                            validateTrigger="onBlur"
                            validateFirst={true}
                        >
                            <Input/>
                        </Form.Item>
                        <Button onClick={() => {
                            getWithSBD()
                        }} type="primary" className="lock-btn">
                            Withdraw
                        </Button>
                    </Form>
                </div>
            </Modal>
            <div className="panel-box">
                <div className="panel-title">
                    <span>{t("SBD Lock Mining")}</span>
                    {
                        adminAddr.toString().toLowerCase() == state.account.toLowerCase() &&
                        <div className="lockadmin" onClick={() => {
                            goPage('/SBDSFTLockAdmin')
                        }}>
                            Admin
                        </div>
                    }

                </div>

                <div className="panel-container">
                    <div className="nft-box">
                        <div className="nftlock">
                            <div className="nft-title">
                                <span>{t("SBD/SFT Lock Mining Pool")}</span>

                            </div>
                            <div className="nft-content">
                                <p className="name">SFT Amounts</p>
                                <p className="value">
                                    {BigNumber(poolSFTBalance).toFixed(1)}
                                </p>
                            </div>


                            <div className="nft-content">
                                <p className="name">SBD Amounts</p>
                                <div className="value">{BigNumber(poolSBDBalance).toFixed(1)}</div>
                            </div>
                        </div>
                    </div>
                    <div className="nav-box">
                        <div className={"nav-item " + (activeNav == 0 ? "active" : "")} onClick={() => {
                            setActiveNav(0)
                        }}>
                            SBD {t("Lock")}
                        </div>
                        <div className={"nav-item " + (activeNav == 1 ? "active" : "")} onClick={() => {
                            setActiveNav(1)
                        }}>
                            {t("Withdraw")} SBD
                        </div>
                        <div className={"nav-item " + (activeNav == 2 ? "active" : "")} onClick={() => {
                            setActiveNav(2)
                        }}>
                            {t("Claim")} SFT
                        </div>
                    </div>
                    {activeNav == 0 && <div>
                        <div className="lock-box1">
                            <Form form={form} name="control-hooks" className="form">
                                <div className="lock-part">
                                    <div className="nft-balance">
                                        <p className="name">{t("Your Lock SBD")}</p>
                                        <p className="value">{t("Balance")}: {showNum(sbdBalance)}</p>
                                    </div>
                                    <div className="max-box">
                                        <Form.Item
                                            name="locksbd"
                                            validateTrigger="onBlur"
                                            validateFirst={true}
                                        >
                                            <div className="input-box">
                                                <Input
                                                    value={inputValue}
                                                    placeholder="0"
                                                    onChange={(e) => {
                                                        setInputValue(e.target.value)
                                                    }}/>
                                            </div>
                                        </Form.Item>
                                    </div>
                                    <div className="nft-balance">
                                        <p className="name">{t("Lock Period")}</p>
                                        <p className="value">Annualized: {weight}%</p>
                                    </div>
                                    <div className="small-nft" style={{marginBottom: '2em'}}>
                                        <div className="input-box">
                                            <Select
                                                value={periodValue}
                                                onChange={(e) => {
                                                    getPeriod(e)
                                                }}
                                                style={{
                                                    width: "100%",
                                                    color: '#FFF'
                                                }}
                                                options={selectOption}

                                                filterOption={(periodValue, option) =>
                                                    option.value.indexOf(periodValue) !== -1 &&
                                                    /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/.test(periodValue)
                                                }
                                            />
                                        </div>
                                    </div>


                                    <div className="nft-balance">
                                        <p className="name"> {t("Estimated Revenue")}</p>
                                        <p className="value"></p>
                                    </div>
                                    <div className="small-nft" style={{marginBottom: '2em'}}>
                                        <div
                                            className="flex-box"
                                            style={{
                                                display: "flex",
                                                fontSize: "20px",
                                                color: "#fff",
                                                width: "100%",
                                                padding :"0 20px",
                                                justifyContent: "space-between"
                                            }}>{estimatedRevenue}
                                            <span>SFT</span>
                                        < /div>
                                    </div>
                                    {BigNumber(allowanceNum).lt(inputValue) ? <Button onClick={() => {
                                            handleApprove()
                                        }} type="primary" className="lock-btn">
                                            Approve
                                        </Button>
                                        : <Button onClick={() => {
                                            usersLock()
                                        }} type="primary" className="lock-btn">
                                            {t("Lock Mining")}
                                        </Button>}


                                </div>
                            </Form>
                        </div>
                        <div className="panel-container">
                            <h1 className="panel-title">
                                {t("Mining Records")}
                            </h1>

                            <div className="list-nav-box">
                                <div className={"list-nav-item " + (listActiveNav == 0 ? "active" : "")}
                                     style={{width:"auto",whiteSpace:"nowrap",padding:"0 10px"}}

                                     onClick={() => {
                                         setListActiveNav(0)
                                     }}>
                                    {t("All Records")}
                                </div>
                                <div className={"list-nav-item " + (listActiveNav == 1 ? "active" : "")}
                                     style={{width:"auto",whiteSpace:"nowrap",padding:"0 10px",marginLeft:"10px"}}

                                     onClick={() => {
                                         setListActiveNav(1)
                                     }}>
                                    {t("My Records")}
                                </div>
                            </div>
                            {
                                listActiveNav == 0 && <div>
                                    <div className="superdao-list-box nft-list">
                                        <div className='list-headert'>
                                            <div className="list-header all-records-header">
                                                <div className="col">
                                                    {t("No.")}
                                                </div>

                                                <div className="col">
                                                    Address
                                                </div>

                                                <div className="col">
                                                    Lock SBD
                                                </div>

                                                <div className="col">
                                                    {t("Period")}(month)
                                                </div>
                                                <div className="col">
                                                    Weight
                                                </div>
                                                <div className="col">
                                                    Receive SVT
                                                </div>

                                                <div className="col">
                                                     {t("Time(UTC)")}
                                                </div>

                                            </div>

                                            {allRecords.length == 0 ? <Empty
                                                image={Empty.PRESENTED_IMAGE_SIMPLE}/> : allRecords.map((item, index) => {
                                                return (<div key={index} className="list-item all-records-item">
                                                    <div className="col no">
                                                        {index + 1}
                                                    </div>
                                                    <div className="col address">
                                                        <a>
                                                            {formatAddress(item.user)}
                                                        </a>
                                                    </div>
                                                    <div className="col">
                                                        {BigNumber(item.lockAmount).div(10 ** 18).toString()}
                                                    </div>
                                                    <div className="col">
                                                        {item.month}
                                                    </div>
                                                    <div className="col">
                                                        {item.weight}
                                                    </div>
                                                    <div className="col">
                                                        {BigNumber(item.receiveSVT).div(10 ** 18).toString()}

                                                    </div>
                                                    <div className="col">
                                                        {dealTime(item.blockTimestamp)}
                                                    </div>
                                                </div>)
                                            })}
                                        </div>

                                    </div>
                                    <div className="pagination">
                                        <Pagination current={curPage} showSizeChanger

                                                    onShowSizeChange={handleShowSizeChange}
                                                    onChange={onChangePage} total={total}

                                                    defaultPageSize={pageCount}/>
                                    </div>
                                </div>
                            }
                            {
                                listActiveNav == 1 && <div>
                                    <div className="superdao-list-box nft-list">
                                        <div className='list-headert'>
                                            <div className="list-header all-records-header">
                                                <div className="col">
                                                    {t("No.")}
                                                </div>

                                                <div className="col">
                                                    Address
                                                </div>

                                                <div className="col">
                                                    Lock SBD
                                                </div>

                                                <div className="col">
                                                    {t("Period")}(month)
                                                </div>
                                                <div className="col">
                                                    Weight
                                                </div>
                                                <div className="col">
                                                    Receive SVT
                                                </div>

                                                <div className="col">
                                                     {t("Time(UTC)")}
                                                </div>

                                            </div>

                                            {myRecords.length == 0 ? <Empty
                                                image={Empty.PRESENTED_IMAGE_SIMPLE}/> : myRecords.map((item, index) => {
                                                return (<div key={index} className="list-item all-records-item">
                                                    <div className="col no">
                                                        {index + 1}
                                                    </div>
                                                    <div className="col address">
                                                        <a>
                                                            {formatAddress(item.user)}
                                                        </a>
                                                    </div>
                                                    <div className="col">
                                                        {BigNumber(item.lockAmount).div(10 ** 18).toString()}
                                                    </div>
                                                    <div className="col">
                                                        {item.period != 0 ? BigNumber(item.period).div(2592000).toString() + " Month" : t("Demand deposits")}

                                                    </div>
                                                    <div className="col">
                                                        {item.weight}
                                                    </div>
                                                    <div className="col">
                                                        {BigNumber(item.receiveSVT).div(10 ** 18).toString()}

                                                    </div>
                                                    <div className="col">
                                                        {dealTime(item.blockTimestamp)}
                                                    </div>
                                                </div>)
                                            })}
                                        </div>

                                    </div>
                                    <div className="pagination">
                                        <Pagination current={curPage} showSizeChanger

                                                    onShowSizeChange={handleShowSizeChange}
                                                    onChange={onChangePage} total={total}

                                                    defaultPageSize={pageCount}/>
                                    </div>
                                </div>
                            }

                        </div>
                    </div>
                    }
                    {activeNav == 1 && <div>
                        <div className="lock-box">
                            <div className="panel-title">
                                {t("My Lock Mining Orders")}
                            </div>

                            <Button onClick={() => {
                                setIsModalOpen(true)
                            }}>Withdraw SBD</Button>

                            {
                                ordersActiveNav == 0 && <div className="superdao-list-box nft-list">
                                    <div className='list-headert'>
                                        <div className="list-header all-records-header">
                                            <div className="col">
                                                {t("No.")}
                                            </div>


                                            <div className="col">
                                                Lock SBD
                                            </div>

                                            <div className="col">
                                                {t("Period")}(month)
                                            </div>
                                            <div className="col">
                                                Annualized
                                            </div>
                                            <div className="col">
                                                 {t("Time(UTC)")}
                                            </div>


                                        </div>
                                        {myOrderArr.length == 0 ? <Empty
                                            image={Empty.PRESENTED_IMAGE_SIMPLE}/> : myOrderArr.map((item, index) => {
                                            return (
                                                <div className="list-item all-records-item">
                                                    <div className="col no">
                                                        {item.id}
                                                    </div>

                                                    <div className="col">
                                                        {showNum(BigNumber(item.amount).div(10 ** 18).toString())}
                                                    </div>
                                                    <div className="col month">
                                                        {item.month}
                                                    </div>
                                                    <div className="col">
                                                        {item.rate}%
                                                    </div>
                                                    <div className="col">
                                                        {
                                                            dealTime(item.lockTime)

                                                        }

                                                    </div>

                                                </div>
                                            )
                                        })
                                        }

                                    </div>

                                </div>
                            }
                            {
                                ordersActiveNav == 1 && <div className="superdao-list-box orders-list">
                                    <div className='list-headert1'>
                                        <div className="list-header my-records-header">
                                            <div className="col">
                                                {t("No.")}
                                            </div>


                                            <div className="col">
                                                Lock SBD
                                            </div>

                                            <div className="col">
                                                {t("Period")}(month)
                                            </div>
                                            <div className="col">
                                                Weight
                                            </div>

                                            <div className="col">
                                                 {t("Time(UTC)")}
                                            </div>
                                            <div className="col">
                                                Operation
                                            </div>

                                        </div>

                                        {myOrderArr.length == 0 ? <Empty
                                            image={Empty.PRESENTED_IMAGE_SIMPLE}/> : myOrderArr.map((item, index) => {
                                            return (

                                                <div className="list-item my-records-item">
                                                    <div className="col no">
                                                        {index + 1}
                                                    </div>
                                                    <div className="col">
                                                        {showNum(BigNumber(item.amount).div(10 ** 18).toString())}
                                                    </div>
                                                    <div className="col">
                                                        {item.month}
                                                    </div>
                                                    <div className="col">
                                                        {item.rate}%
                                                    </div>

                                                    <div className="col">
                                                        {dealTime(item.lockTime)}
                                                    </div>
                                                    <div className="col">

                                                        {
                                                            BigNumber(item.amount).eq(0) ? <Button onClick={() => {
                                                                    setCurOrderId(index)
                                                                    setIsModalOpen(true);
                                                                }} type="primary" disabled>
                                                                    Extracted
                                                                </Button>
                                                                :
                                                                <Button onClick={() => {
                                                                    setCurOrderId(index)
                                                                    setIsModalOpen(true);
                                                                    setSvtAmount(showNum(BigNumber(item.svtAmount).div(10 ** 18).toString()))
                                                                }} className="lock-btn">
                                                                    Withdraw
                                                                </Button>
                                                        }
                                                    </div>

                                                </div>
                                            )
                                        })
                                        }

                                    </div>

                                </div>

                            }
                        </div>
                        <div className="panel-container">
                            <h1 className="panel-title">
                                {t("Withdraw Records")}
                            </h1>

                            <div className="list-nav-box">
                                <div className={"list-nav-item " + (withdrawActiveNav == 0 ? "active" : "")}
                                     style={{width:"auto",whiteSpace:"nowrap",padding:"0 10px"}}
                                     onClick={() => {
                                         setWithdrawActiveNav(0)
                                     }}>
                                    {t("All Records")}
                                </div>
                                <div className={"list-nav-item " + (withdrawActiveNav == 1 ? "active" : "")}
                                     style={{width:"auto",whiteSpace:"nowrap",padding:"0 10px",marginLeft:"10px"}}
                                     onClick={() => {
                                         setWithdrawActiveNav(1)
                                     }}>
                                    {t("My Records")}
                                </div>
                            </div>
                            {
                                withdrawActiveNav == 0 && <div>
                                    <div className="superdao-list-box nft-list">
                                        <div className='list-headert'>
                                            <div className="list-header all-records-header">
                                                <div className="col">
                                                    {t("No.")}
                                                </div>

                                                <div className="col">
                                                    Address
                                                </div>

                                                <div className="col">
                                                    Lock SBD
                                                </div>

                                                <div className="col">
                                                    {t("Period")}
                                                </div>
                                                <div className="col">
                                                    Weight
                                                </div>
                                                <div className="col">
                                                    {t("Burn SVT")}
                                                </div>

                                                <div className="col">
                                                     {t("Time(UTC)")}
                                                </div>

                                            </div>

                                            {withRecords.length == 0 ? <Empty
                                                image={Empty.PRESENTED_IMAGE_SIMPLE}/> : withRecords.map((item, index) => {
                                                return (<div key={index} className="list-item all-records-item">
                                                    <div className="col no">
                                                        {index + 1}
                                                    </div>
                                                    <div className="col address">
                                                        <a>
                                                            {formatAddress(item.user)}
                                                        </a>
                                                    </div>
                                                    <div className="col">
                                                        {BigNumber(item.unLockAmount).div(10 ** 18).toString()}
                                                    </div>
                                                    <div className="col">
                                                        {item.period}
                                                    </div>
                                                    <div className="col">
                                                        {item.weight}
                                                    </div>
                                                    <div className="col">
                                                        {BigNumber(item.burnSVT).div(10 ** 18).toString()}
                                                    </div>
                                                    <div className="col">
                                                        {dealTime(item.blockTimestamp)}
                                                    </div>
                                                </div>)
                                            })}


                                        </div>

                                    </div>
                                    <div className="pagination">
                                        <Pagination current={curPage} showSizeChanger

                                                    onShowSizeChange={handleShowSizeChange}
                                                    onChange={onChangePage} total={total}

                                                    defaultPageSize={pageCount}/>
                                    </div>
                                </div>
                            }
                            {
                                withdrawActiveNav == 1 && <div>
                                    <div className="superdao-list-box nft-list">
                                        <div className='list-headert'>
                                            <div className="list-header all-records-header">
                                                <div className="col">
                                                    {t("No.")}
                                                </div>

                                                <div className="col">
                                                    Address
                                                </div>

                                                <div className="col">
                                                    Lock SBD
                                                </div>

                                                <div className="col">
                                                    Period
                                                </div>
                                                <div className="col">
                                                    Weight
                                                </div>
                                                <div className="col">
                                                    {t("Burn SVT")}
                                                </div>

                                                <div className="col">
                                                     {t("Time(UTC)")}
                                                </div>

                                            </div>

                                            {myWithRecords.length == 0 ? <Empty
                                                image={Empty.PRESENTED_IMAGE_SIMPLE}/> : myWithRecords.map((item, index) => {
                                                return (<div key={index} className="list-item all-records-item">
                                                    <div className="col no">
                                                        {index + 1}
                                                    </div>
                                                    <div className="col address">
                                                        <a>
                                                            {formatAddress(item.user)}
                                                        </a>
                                                    </div>
                                                    <div className="col">
                                                        {BigNumber(item.unLockAmount).div(10 ** 18).toString()}
                                                    </div>
                                                    <div className="col">
                                                        {item.period}
                                                    </div>
                                                    <div className="col">
                                                        {item.weight}
                                                    </div>
                                                    <div className="col">
                                                        {BigNumber(item.burnSVT).div(10 ** 18).toString()}
                                                    </div>
                                                    <div className="col">
                                                        {dealTime(item.blockTimestamp)}
                                                    </div>
                                                </div>)
                                            })}


                                        </div>

                                    </div>
                                    <div className="pagination">
                                        <Pagination current={curPage} showSizeChanger

                                                    onShowSizeChange={handleShowSizeChange}
                                                    onChange={onChangePage} total={total}

                                                    defaultPageSize={pageCount}/>
                                    </div>
                                </div>
                            }
                        </div>

                    </div>
                    }
                    {activeNav == 2 && <div>
                        <div className="lock-box1">
                            <Form form={form} name="control-hooks" className="form">
                                <div className="lock-part">
                                    <div className="nft-balance">
                                        <p className="name">{t("You Can Claim SFT")}</p>
                                        <p className="value"> {pendingSrt}</p>
                                    </div>

                                    <div className="nft-balance" style={{marginTop: '2em'}}>
                                        <p className="name">{t("Claim Amounts")}</p>
                                        <p className="value">Balance: {BigNumber(sftBalance).toFixed(3)}</p>
                                    </div>
                                    <Form.Item
                                        name="claimAmount"
                                        validateTrigger="onBlur"
                                        validateFirst={true}
                                    >
                                        <div className="input-box">
                                            <Input
                                            />
                                        </div>
                                    </Form.Item>
                                    <Button style={{marginTop: "10px"}} onClick={() => {
                                        claimSFT()
                                    }} type="primary" className="lock-btn">
                                        Claim
                                    </Button>

                                </div>
                            </Form>
                        </div>

                        <div className="panel-container">
                            <h1 className="panel-title">
                                {t("Claim Records")}
                            </h1>

                            <div className="list-nav-box">
                                <div className={"list-nav-item " + (claimActiveNav == 0 ? "active" : "")}
                                     style={{width:"auto",whiteSpace:"nowrap",padding:"0 10px"}}

                                     onClick={() => {
                                         setClaimActiveNav(0)
                                     }}>
                                    All Records
                                </div>
                                <div className={"list-nav-item " + (claimActiveNav == 1 ? "active" : "")}
                                     style={{width:"auto",whiteSpace:"nowrap",padding:"0 10px",marginLeft:"10px"}}

                                     onClick={() => {
                                         setClaimActiveNav(1)
                                     }}>
                                    My Records
                                </div>
                            </div>
                            {
                                claimActiveNav == 0 && <div>
                                    <div className="superdao-list-box withdraw-list">
                                        <div className='list-headert2'>
                                            <div className="list-header withall-records-header">
                                                <div className="col">
                                                    {t("No.")}
                                                </div>

                                                <div className="col">
                                                    Address
                                                </div>

                                                <div className="col">
                                                    SRT Amounts
                                                </div>

                                                <div className="col">
                                                     {t("Time(UTC)")}
                                                </div>

                                            </div>

                                            {srtRecords.length == 0 ? <Empty
                                                image={Empty.PRESENTED_IMAGE_SIMPLE}/> : srtRecords.map((item, index) => {
                                                return (<div key={index} className="list-item withall-records-item">
                                                    <div className="col no">
                                                        {index + 1}
                                                    </div>
                                                    <div className="col address">
                                                        <a>
                                                            {formatAddress(item.user)}
                                                        </a>
                                                    </div>
                                                    <div className="col">
                                                        {BigNumber(item.srtAmount).div(10 ** 18).toFixed()}
                                                    </div>

                                                    <div className="col">
                                                        {dealTime(item.blockTimestamp)}
                                                    </div>
                                                </div>)
                                            })}
                                        </div>

                                    </div>
                                    <div className="pagination">
                                        <Pagination current={curPage} showSizeChanger

                                                    onShowSizeChange={handleShowSizeChange}
                                                    onChange={onChangePage} total={total}

                                                    defaultPageSize={pageCount}/>
                                    </div>
                                </div>
                            }

                            {
                                claimActiveNav == 1 && <div>
                                    <div className="superdao-list-box withdraw-list">
                                        <div className='list-headert2'>
                                            <div className="list-header withall-records-header">
                                                <div className="col">
                                                    {t("No.")}
                                                </div>

                                                <div className="col">
                                                    Address
                                                </div>

                                                <div className="col">
                                                    SRT Amounts
                                                </div>

                                                <div className="col">
                                                     {t("Time(UTC)")}
                                                </div>

                                            </div>

                                            {myClaimRecords.length == 0 ? <Empty
                                                image={Empty.PRESENTED_IMAGE_SIMPLE}/> : myClaimRecords.map((item, index) => {
                                                return (<div key={index} className="list-item withall-records-item">
                                                    <div className="col no">
                                                        {index + 1}
                                                    </div>
                                                    <div className="col address">
                                                        <a>
                                                            {formatAddress(item.user)}
                                                        </a>
                                                    </div>
                                                    <div className="col">
                                                        {BigNumber(item.srtAmount).div(10 ** 18).toFixed()}
                                                    </div>

                                                    <div className="col">
                                                        {dealTime(item.blockTimestamp)}
                                                    </div>
                                                </div>)
                                            })}
                                        </div>

                                    </div>
                                    <div className="pagination">
                                        <Pagination current={curPage} showSizeChanger

                                                    onShowSizeChange={handleShowSizeChange}
                                                    onChange={onChangePage} total={total}

                                                    defaultPageSize={pageCount}/>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    }

                </div>


            </div>
        </SBDSFTLockStyle>
    )
}

export default SBDLock;
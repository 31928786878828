import styled from "styled-components";
export default styled.div`
.submit-btn{
      width: 100%;
      margin-top: 20px;
      height: 50px;
      background: linear-gradient(229deg, #DAB163 0%, #CC8A35 100%);
      border-radius: 10px 10px 10px 10px;
      font-size: 18px;
      font-family: Roboto-Bold, Roboto;
      font-weight: bold;
      color: #1A1414;
    }
  .header-box{
    width: 60%;
    display: flex;
    justify-content: space-between;
    margin: 10% auto 50px;
    .header-item{
      width: 48%;
      padding: 20px;
      background: #1C1C1C;
      box-shadow: 0px 5px 20px 5px rgba(0,0,0,0.2);
      border-radius: 20px 20px 20px 20px;
      opacity: 1;
      border: 1px solid rgba(255,255,255,0.15);
      .introduce{
        text-align:center;
        font-size: 20px;
font-family: Roboto-Bold, Roboto;
font-weight: bold;
color: #FFFFFF;
margin:1em 0em;
      }
   
    }
  }

  @media screen and (max-width: 1440px) {
    .header-box{
        width: 75%;
        margin: 12% auto 50px;
         .header-item {
        .introduce {
        font-size:18px;
    }
}
}

.submit-btn {
    font-size:16px;
  }
}


  @media screen and (max-width: 450px) {
    .header-box{
        width: 100%;
        display: block;
        margin: 2em auto;
         .header-item {
            margin: 1em 0;
            width: 100%;
        .introduce {
        font-size:18px;
    }
}
}
.submit-btn {
    font-size:16px;
  }
  }

`
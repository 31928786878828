import styled from "styled-components";
export default styled.div`
  width: 100%;
  overflow-x: auto;
  position: relative;
  z-index: 1;

  &::-webkit-scrollbar {
    width: 20px;
    height: 20px;
  }

  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-thumb {
    border-radius: 999px;
    border: 5px solid transparent;
  }

  &::-webkit-scrollbar-thumb {
    min-height: 20px;
    background-clip: content-box;
    box-shadow: 0 0 0 10px rgba(0, 0, 0, .8) inset;
  }

  &::-webkit-scrollbar-corner {
    background: transparent;
  }

  .footer {
    
   
  }

    .navBox{
      .ant-menu{
        background: linear-gradient(rgb(33,33,33) 50%, rgb(28,28,28) 100%);;

      }
    }
    .footer{
      width: 70%;
      margin:0 auto;
    padding: 2% 0;
    display: flex;
    justify-content: space-between;
      
      .lb{
      display:none;
    }
    }
    
    .logo {
    width: 150px;
  }
  .copyrightphone {
    
    display: none;
  }
  .copyright {
    color: #8A8080;
    margin-top:2em;
    font-size:16px;
  }

  .link-list {
    display: flex;
    flex-grow: 1;
    margin: 0em;
    
    width: 100%;

    .link-item {
      margin-right: 1em;
      margin-top: 2em;
      cursor: pointer;

      .icon {
        width: 35px;
      }
    }
  }


  .left {
    width: 25%;
  }

  .right {
    width: 40%;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-end;

    .link-list {
      display: flex;
      width: 100%;
      margin-top: 1em;
      justify-content: flex-end;

      .link {
        img{
             width: 30px;
        }
       
       margin-top:2em;
        margin-left: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .link-box {
    display: flex;
    justify-content: space-around;
    width: 100%;
    font-family: Helvetica-Bold, Helvetica, sans-serif;

    .link-col {
      opacity: 0.5;

      &:first-child {
        margin-left: 0%;
      }

    

      .link-row.title {
        font-size: 18px;
font-family: Roboto-SemiBold, Roboto;
font-weight: 600;
color: #8A8080;
line-height: 24px;
        white-space: pre-wrap;
      }
    }
  }

 
  @media screen and (max-width: 1440px) {
    .footer{
      width: 70%;
      margin:0 auto;
    padding: 2% 0;
    display: flex;
    justify-content: space-between;
      
      .lb{
      display:none;
    }
    }
    
    .logo {
    width: 150px;
  }
  .copyrightphone {
    
    display: none;
  }
  .copyright {
    color: #8A8080;
    margin-top:2em;
    font-size:14px;
  }

  .link-list {
    display: flex;
    flex-grow: 1;
    margin: 0em;
    
    width: 100%;

    .link-item {
      margin-right: 1em;
      margin-top: 2em;
      cursor: pointer;

      .icon {
        width: 35px;
      }
    }
  }


  .left {
    width: 25%;
  }

  .right {
    width: 50%;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-end;

    .link-list {
      display: flex;
      width: 100%;
      margin-top: 1em;
      justify-content: flex-end;

      .link {
        img{
             width: 30px;
        }
       
       margin-top:2em;
        margin-left: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .link-box {
    display: flex;
    justify-content: space-around;
    width: 100%;
    font-family: Helvetica-Bold, Helvetica, sans-serif;

    .link-col {
      opacity: 0.5;

      &:first-child {
        margin-left: 0%;
      }

    

      .link-row.title {
        font-size: 16px;
font-family: Roboto-SemiBold, Roboto;
font-weight: 600;
color: #8A8080;
line-height: 24px;
        white-space: pre-wrap;
      }
    }
  }
  }

  @media screen and (max-width: 450px) {
   
    .ant-menu-inline.ant-menu-root .ant-menu-submenu-title>.ant-menu-title-content {
        margin:0 auto;
    }
    .ant-menu-submenu-arrow{
        display: none;
    }
     .left{
        display: none;
    }
    .ant-menu-dark .ant-menu-submenu-open .ant-menu-sub.ant-menu-inline{
        .ant-menu-item {
            padding-left: 0px!important;

        }
    }
    .ant-menu-dark.ant-menu-inline .ant-menu-item{
        border-bottom: 1px solid rgba(255,255,255,0.15);
        text-align:left;
        width: 90%;
        height: 50px;
    margin: 0.8em auto;
    }
    .ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title{
        height:58px;
    }
    .ant-menu-inline.ant-menu-root .ant-menu-submenu-title{
        background:#1B1B1B;
    }
    .ant-menu-submenu-title {
    width: 100%;
    border-bottom: 1px solid rgba(255,255,255,0.15);
    padding: 0 auto !important;
    font-size: 15px;
font-family: Roboto-SemiBold, Roboto;
margin: 0px;
    
font-weight: 600;
color: #8A8080;
line-height:50px;
/* background:#1B1B1B; */
.ant-menu-title-content{
    color:rgba(180, 158, 158, 1);
    margin: 0px;
    line-height:50px;
    text-align:center;

}

}

.ant-menu .ant-menu-root .ant-menu-inline .ant-menu-dark menu{
    background:rgb(28,28,28) ;

}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected{
    background: linear-gradient(32deg, rgb(255,255,255,0.5) 10%, rgb(255,255,255,0.5) 10%);
    -webkit-background-clip: text;
    color: transparent;

}
.ant-menu-dark .ant-menu-submenu-active, .ant-menu-dark .ant-menu-submenu-open{
    .ant-menu-sub.ant-menu-inline{
      background:white;
    }
}
.ant-menu-dark.ant-menu-inline .ant-menu-item{
    font-size: 15px;
font-family: Roboto-Medium, Roboto;
font-weight: 500;
color: #8A8080;
text-align:center;

line-height: 20px;

}
    .footer {
display: block;
      overflow: hidden;
      padding: 0%;
      width: 100%;
      .lb{
        display: block;
        width: 100%;
        .bottom-box-box{
          width: 100%;
          font-size: 18px;
font-family: Roboto-SemiBold, Roboto;
font-weight: 600;
color: rgba(138, 128, 128, 1);
line-height: 20px;
        }
      }
    
    .right {
  
      display: block;
      width: 100%;
      .link-list{
        .link{
            margin: 1em 0.5em;
        }
      }
    }
    .left {
        display: none;
    text-align:center;
    margin: 0 20px;
    width: 100%;
  }

  
    .logo {
    width: 140px;
    margin-top: 2.5em;
  }
.link-list{
  justify-content: center;
}
  .copyrightphone {
    display: block;
    color: rgba(138, 128, 128, 1);
    font-size:14px;
    margin-bottom: 20px;
  }

  .link-list {
    display: flex;

    flex-grow: 1;
    flex-wrap: wrap;
    width: 100%;
    margin:0.5em auto 2em auto;
    padding: 0em 4.5em;
    .link-item {
      margin: 0.5em 0.5em;
      cursor: pointer;

      .icon {
        width: 42px;
      }
    }
  }


  
  

  .link-box {
    display: none;
    justify-content: space-around;
    width: 100%;
    font-family: Helvetica-Bold, Helvetica, sans-serif;

    .link-col {
      opacity: 0.5;

      &:first-child {
        margin-left: 0%;
      }

      .link-row {
        font-weight: bold;
        color: #544545;
        line-height: 26px;
        font-size: 12px;
        cursor: pointer;
        white-space: pre-wrap;
        flex-shrink: 0;
      }

      .link-row.title {
        font-size: 14px;
        line-height: 30px;
        font-weight: bold;
        color: #FFFFFF;
        white-space: pre-wrap;
      }
    }
  }
  }

}

  @media screen and (max-width: 400px) {
    .ant-menu-inline.ant-menu-root .ant-menu-submenu-title>.ant-menu-title-content {
        margin:0 auto;
    }
    
    .footer {
      overflow: hidden;
      padding: 0%;
      width: 100%;
      .lb{
        display: block;
        width: 100%;
     
        .bottom-box-box{
          width: 100%;
          font-size: 16px;
font-family: Roboto-SemiBold, Roboto;
font-weight: 600;
color: rgba(138, 128, 128, 1);
line-height: 20px;
        }
      }

    .left {
        display: none;
    text-align:center;
    margin: 0 20px;
    width: 100%;
  }
  .right{
        .link-box{
            display: none;
        }
      }
    
    .logo {
    width: 140px;
    margin-top: 2.5em;
  }
.link-list{
  justify-content: center;
}
  .copyrightphone {
    display: block;
    color: rgba(138, 128, 128, 1);
    font-size:14px;
    margin-bottom: 20px;
  }

  .link-list {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    width: 100%;
    margin:0.5em auto 2em auto;
    padding: 0em;
    .link-item {
      margin: 0.5em 0.5em;
      cursor: pointer;

      .icon {
        width: 42px;
      }
    }
  }


  

  

  .link-box {
    display: flex;
    justify-content: space-around;
    width: 100%;
    font-family: Helvetica-Bold, Helvetica, sans-serif;

    .link-col {
      opacity: 0.5;

      &:first-child {
        margin-left: 0%;
      }

      .link-row {
        font-weight: bold;
        color: #544545;
        line-height: 26px;
        font-size: 12px;
        cursor: pointer;
        white-space: pre-wrap;
        flex-shrink: 0;
      }

      .link-row.title {
        font-size: 14px;
        line-height: 30px;
        font-weight: bold;
        color: #FFFFFF;
        white-space: pre-wrap;
      }
    }
  }
  }

}

`


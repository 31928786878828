import styled from "styled-components";
export default styled.div`
  .ant-btn-primary::after {

    background: linear-gradient(229deg, #DAB163 0%, #CC8A35 100%);
  }
  .select-box{
    height: 40px;
    width: 100px!important;
    margin:  auto 10px;
    border-radius: 15px;
    overflow: hidden;
    .ant-select-selector{
      height: 106%;
      width: 104%;
      margin-top: -1%;
      padding-top: 2%;
      margin-left: -1%;
    }
  }
  .ant-btn-primary {
    background: linear-gradient(229deg, #DAB163 0%, #CC8A35 100%);

    img {
      margin-right: 5px;
    }
  }

  .Superbitdao-header {
    /* position: fixed; */
    top: 0;
    color: #FFAE4E;
    z-index: 3;
    width: calc(100%);
    padding: 2em 13% 0.5em 13%;
    display: flex;
    justify-content: space-between;
    &.bonus-bg{
      background: #1b1b1b;
    }
    .nav-list {
      display: flex;
      flex-grow: 1;
      justify-content: flex-end;
      padding: 0 4em;
      align-items: center;

      .nav-item {
        margin: 0 2em;
        cursor: pointer;
        font-size: 18px;
        font-family: Roboto-Medium, Roboto;
        font-weight: bold;

        line-height: 24px;

        a {
          color: #FFFFFF;
        }

        .active {
          font-weight: bold;
          color: #FFAE4E;
          /* background: linear-gradient(32deg, rgb(255, 174, 78) 0%, rgb(249, 212, 35) 100%); */
          /* box-shadow: 0px 3px 6px 0px rgba(128, 4, 149, 0.3); */

        }
      }

    }
  }

  .passport-icon {
    height: 35px;
    margin: 1em;
    padding: 4px 10px;
    background: linear-gradient(229deg, #DAB163 0%, #CC8A35 100%);
    box-shadow: none !important;

    img {
      width: 20px;
    }

  }

  .bsc {
    font-size: 16px;
    font-family: Roboto-Medium, Roboto;
    font-weight: bold;
    color: #1A1414;
    line-height: 19px;
  }

  .cwallet {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: rgba(216, 216, 216, 1);

    margin: auto 0 auto 40px;

    &.active {
      background-color: rgb(52, 255, 0);
    }
  }

  .select-chain {
    margin-right: 1em;

    .ant-select-selector {
      border-radius: 10px;
    }
  }

  .menu {
    width: 40px;
    display: none;

  }

  .select-chain {
    width: 140px;
  }

  .m-nav {
    background: rgba(1, 1, 1, 0.3);
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;

    .m-nav-content {
      position: fixed;
      width: 256px;
      right: 0;
      height: 100%;
      background: #201414;
      z-index: 100;

      .close {
        position: relative;
        left: 10px;
        top: 10px;
        z-index: 100;
        padding: 10px 10px;
      }

      .m-nav-box {
        padding-bottom: 30px;
        right: 0;
        top: 0;
      }
    }
  }


  /* mobile style */
  @media screen and (max-width: 1440px) {
    .Superbitdao-header {
      /* padding: 1em 0.5em 1em 0; */

      .nav-list {
        padding: 0em;

        .nav-item {
          padding: 0em;
          margin: 0em 1.5em;
          font-size: 16px;
        }
      }
    }

    .menu {
      display: none;
    }


    .select-chain {
      width: auto;
      max-width: 130px;
    }

  }

  @media screen and (max-width: 450px) {
    .Superbitdao-header {
      padding: 1em 4% 0em 4%;

      .logo {
        width: 65%;
        margin: 0.5em;
      }
    }

    .passport-icon {
      display: none;
    }

    .menu {
      display: block;
      margin: 1em 0px;
      flex-shrink: 0 !important;
    }

  }
  @media screen and (max-width: 400px) {
    .Superbitdao-header {
      padding: 1em 4% 0em 4%;

      .logo {
        width: 65%;
        margin: 0.5em;
      }
    }


  }
`


import styled from "styled-components";

const OGPoolStyle = styled.div`

  .ant-pagination .ant-pagination-item-link {
    border-radius: 5px;
  }

  .ant-pagination-disabled {
    border-radius: 5px;

  }

  .panel-container .search-container .search-box .ant-input-affix-wrapper {
    border: none;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 5px;
    background: rgb(39, 39, 39);
    border: 1px solid rgba(255, 255, 255, 0.15);
    color: #FFFFFF;
  }


  .ant-pagination-item-link {
    background: #1C1C1C !important;

    border: 1px solid rgba(255, 255, 255, 0.15);
    color: #FFFFFF;
  }

  .ant-pagination-item {
    background: #1C1C1C !important;
    color: #FFFFFF;
    border: 1px solid rgba(255, 255, 255, 0.15);
  }

  .anticon-right {
    color: #FFFFFF !important;

  }

  .anticon-left {
    color: #FFFFFF !important;

  }

  .addsbt {
    text-align: center;
    width: 13%;
    padding: 6px;
    font-size: 18px;
    margin: 1.5em auto;
    color: #FFAE4E;
    border: 1px solid;

    border-radius: 8px;
  }


  .part1 {

    padding: 0 4%;

    .panel-box {

      .panel-container {
        width: 100%;
        background: #1C1C1C;
        box-shadow: 0px 4px 15px 4px rgba(0, 0, 0, 0.2);
        border-radius: 15px 15px 15px 15px;
        padding: 50px 50% 25px 100px;
        border: 1px solid rgba(255, 255, 255, 0.15);
        margin: 1em 0;
      }
    }
  }

  .panel-box1 {
    padding: 0px 4%;

    .panel-container {
      background: rgb(28, 28, 28);;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 15px 4px;
      border-radius: 15px;
      padding: 50px 50% 25px 100px;
      border: 1px solid rgba(255, 255, 255, 0.15);
      margin: 1em 0px;
    }
  }

  .panel-title {
    margin-bottom: 0.5em;
    font-size: 30px;
    font-family: Helvetica-Bold, Helvetica, sans-serif;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 1.6;
  }

  .panel-title1 {
    width: 100%;
    display: flex;
    justify-content: space-between;

    p {
      font-size: 20px;
      font-family: Roboto-Medium, Roboto;
      font-weight: 500;
      color: #8A8080;
      line-height: 26px;
    }

    span {
      font-size: 20px;
      font-family: Roboto-Medium, Roboto;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 26px;
    }
  }


  .header-nav3 {
    padding: 10px 4%;
    display: flex;

    .superdao-nav-list {
      height: 45px;
      width: auto;
      margin: 0em;
      padding: 0em;

      .nav-item {
        min-width: 120px;
        width: auto;
        padding: 0 20px;
        font-size: 18px;
      }
    }
  }

  .listheadert1 {
    width: 1000px;

  }

  .header-nav2 {
    width: 100%;
    margin-left: 0;
    display: flex;
    padding: 0 4%;


    .superdao-nav-list1 {
      margin: 0;

      width: 100%;

      .nav-item {
        font-size: 18px;
        line-height: 40px;
        color: #FFFFFF;
        font-family: Roboto-Bold, Roboto;
        font-weight: bold;


      }
    }
  }

  .ant-form-item-label > label {
    font-size: 18px;
    color: #FFFFFF;
    font-family: Roboto-Medium, Roboto;
    font-weight: 500;
    line-height: 24px;
    line-height: 26px;
    margin: 0.6em 0;
  }

  .ant-form-item-control-input {
    background: rgb(39, 39, 39);
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    height: 45px;
  }

  .ant-form-item-control-input-content {
    span {
      padding-left: 20px;
      font-size: 18px;
    }
  }

  .ant-input {
    font-size: 18px;
    background: rgb(39, 39, 39);
    border: none;
    height: 45px;
  }

  .ant-btn-primary:focus {
    background: linear-gradient(229deg, #DAB163 0%, #CC8A35 100%);
  
    border: none;
  }

  .ant-btn-primary:active {
    background: linear-gradient(229deg, #DAB163 0%, #CC8A35 100%);
  !important;
    border: none;
  }

  .ant-btn-primary:hover {
    background: linear-gradient(229deg, #DAB163 0%, #CC8A35 100%);
  !important;
    border: none;
  }

  .ant-btn {

    background: linear-gradient(229deg, #DAB163 0%, #CC8A35 100%);
    font-size: 18px;
    font-family: Roboto-Bold, Roboto;
    font-weight: bold;
    height: 50px;
    line-height: 40px;
    color: rgb(26, 20, 20);
    width: 40%;
  }

  .header-nav1 {
    padding: 0%;
    width: 100%;
    margin-bottom: 1.5em;

    .superdao-nav-list {
      width: 30%;

      .nav-item {
        background: #272727;
        border-radius: 10px 10px 10px 10px;
        border: 1px solid rgba(255, 255, 255, 0.1);
        font-size: 18px;
        font-family: Roboto-Bold, Roboto;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 21px;

        &:focus {
          color: #1A1414;
          background: linear-gradient(229deg, #DAB163 0%, #CC8A35 100%);
          border-radius: 10px 10px 10px 10px;
        }
      }
    }
  }

  .btn-box {
    display: flex;
    justify-content: center;

    .addsbt {
      text-align: center;
      width: 13%;
      font-size: 18px;
      margin: 1.5em;
      padding: 0;
      height: 45px;;
      color: #FFAE4E;
      border: 1px solid;
      line-height: 45px;
      border-radius: 8px;
    }

    .ant-sbt {
      background: linear-gradient(229deg, #DAB163 0%, #CC8A35 100%);
      font-size: 18px;
      text-align: center;
      margin: 1.5em 0;
      width: 13%;
      border-radius: 8px;
      font-family: Roboto-Bold, Roboto;
      font-weight: bold;
      height: 45px;
      line-height: 45px;
      color: rgb(26, 20, 20);
    }
  }

  .setlv {
    margin: 1em 0em;
    background: rgba(28, 28, 28, 1);

    .list-header {
      display: flex;
      justify-content: flex-start;
      padding: 20px 0.6em;
      border-bottom: 1px solid rgba(234, 234, 234, 0.10);
    }

    .list-item, .list-header {
      justify-content: flex-start;

      .col {
        text-align: left;
        font-size: 16px;

        &:nth-child(1) {
          margin-left: 100px;
          width: 30%;
        }

        &:nth-child(2) {
          width: 50%;
          margin-left: 10px;
        }

        &:nth-child(3) {
          width: 10%;
          margin-left: 10px;

        }

      }
    }

    .list-item:last-child {
      border-bottom: none;
    }

    .list-item {
      color: #FFFFFF;
      display: flex;
      justify-content: flex-start;
      padding: 1em 0.5em;
      width: 100%;
      margin: 0 auto;
      border-radius: 0px;
      border-bottom: 1px solid rgba(234, 234, 234, 0.10);

      &:nth-child(even) {
        background: rgba(62, 62, 62, 1);
      }

      .col {
        overflow: hidden;
        padding-left: 0%;

      }

      .address {
        a {
          color: #CD9E57;
        }
      }
    }
  }

  .records-list {
    margin: 1em 0em;
    background: rgba(28, 28, 28, 1);;

    .rowheader {
      display: flex;
      padding: 20px 0.5em;
      border-bottom: 1px solid rgb(234, 234, 234, 0.1);
    }


    .row1, .rowheader {
      justify-content: flex-start;

      .col {
        text-align: left;
        font-size: 16px;

        width: 100px;
      }
    }

    .row1:last-child {
      border-bottom: none;
    }

    .row1 {
      display: flex;
      justify-content: flex-start;
      padding: 1em 0.5em;
      width: 100%;
      margin: 0 auto;
      border-radius: 0px;
      border-bottom: 1px solid rgba(234, 234, 234, 0.10);

      &:nth-child(even) {
        background: rgba(62, 62, 62, 1);
      }

      .col {

        overflow: hidden;
        padding-left: 0%;

      }

      .address {
        a {
          color: #CD9E57;
        }
      }
    }
  }


  .sign-list {
    margin: 1em 0em;
    background: rgba(28, 28, 28, 1);

    .signheader {
      display: flex;
      padding: 20px 0.5em;
      border-bottom: 1px solid rgba(234, 234, 234, 0.10);
    }

    .signitem, .signheader {
      justify-content: flex-start;

      .col {
        text-align: left;
        font-size: 16px;

        &:nth-child(1) {
          margin-left: 80px;
          width: 13%;
        }

        &:nth-child(2) {
          width: 18%;
          margin-left: 10px;
        }

        &:nth-child(3) {
          width: 19%;
          margin-left: 10px;
        }

        &:nth-child(4) {
          width: 19%;
          margin-left: 10px;
        }

        &:nth-child(5) {
          width: 18%;
          margin-left: 10px;
        }
      }
    }

    .signitem:last-child {
      border-bottom: none;
    }

    .signitem {
      display: flex;
      justify-content: flex-start;
      padding: 1em 0.5em;
      width: 100%;
      margin: 0 auto;
      border-radius: 0px;
      border-bottom: 1px solid rgba(234, 234, 234, 0.10);

      &:nth-child(even) {

        background: rgba(62, 62, 62, 1);
      }

      .col {

        overflow: hidden;
        padding-left: 0%;

      }

      .address {
        a {
          color: #CD9E57;
        }
      }
    }
  }


  .funding-list {
    margin: 1em 0;
    background: rgba(28, 28, 28, 1);
    overflow-x: scroll;

    .listheadert3 {
      width: 1905px;
    }

    .donheader {
      display: flex;
      padding: 20px 1.6em;
      border-bottom: 1px solid rgba(234, 234, 234, 0.10);
    }

    .donitem, .donheader {
      justify-content: flex-start;

      .col {
        text-align: left;
        font-size: 16px;

        &:nth-child(1) {
          margin-left: 5px;
          width: 140px;
        }

        &:nth-child(2) {
          width: 140px;
        }

        &:nth-child(3) {
          width: 140px;
        }

        &:nth-child(4) {
          width: 160px;
        }

        &:nth-child(5) {
          width: 160px;
        }

        &:nth-child(6) {
          width: 200px;
        }

        &:nth-child(7) {
          width: 200px;
        }

        &:nth-child(8) {
          width: 160px;
        }

        &:nth-child(9) {
          width: 160px;
        }

        &:nth-child(10) {
          width: 200px;
        }

        &:nth-child(11) {
          width: 180px;
        }

      }
    }

    .donitem:last-child {
      border-bottom: none;
    }

    .donitem {
      color: #FFFFFF;
      padding: 1em 2em;
      width: 100%;
      margin: 0 auto;
      border-radius: 0px;
      border-bottom: 1px solid rgba(234, 234, 234, 0.10);

      &:nth-child(even) {
        background: rgba(62, 62, 62, 1);
      }

      .col {
        overflow: hidden;
        padding-left: 0%;

        .item {
          font-size: 16px;
        }
      }

      .address {
        a {
          color: #CD9E57;
        }
      }
    }
  }


  .pagination {
    display: block !important;
    text-align: center;
  }


  .level-name {
    font-size: 18px;
    font-family: Roboto-SemiBold, Roboto;
    font-weight: 600;
    line-height: 45px;
    color: #FFFFFF;
    line-height: 20px;
    padding: 20px;
    margin-right: 50px;
  }

  .setInvite-title {
    font-size: 18px;
    font-family: Roboto-SemiBold, Roboto;
    font-weight: 600;
    color: rgb(138, 128, 128);
    line-height: 24px;
    margin-top: 0.5em;
  }


  .fund-list {
    .list-header1, .list-item1 {
      margin: 1em 1.5em;
      display: flex;

      .col1 {
        width: 100%;
        font-size: 18px;

        font-family: Roboto-SemiBold, Roboto;
        font-weight: 600;
        color: rgb(138, 128, 128);
        line-height: 24px;

        .lev1 {
          background: rgb(39, 39, 39);
          border-radius: 8px;
          border: 1px solid rgba(255, 255, 255, 0.1);
          display: flex;
          margin: 1.5em 0px;
          width: 100%;

          .ant-form-item {
            margin-bottom: 0px;
          }

          .list-lev {
            margin: 10px 0;
            width: 40%;
            background: rgb(39, 39, 39);
            border-radius: 8px;
            height: 45px;

            .ant-row .ant-col .ant-form-item-control-input {
              border: none;
              background: #1C1C1C;

              .ant-form-item-control-input-content .ant-input {
                color: #FFFFFF;
                background: #1C1C1C;

              }
            }

          }
        }

        .input-box {
          width: 100%;

          span {
            line-height: 45px;

            padding-left: 10px;
            padding-right: 10px;

          }
        }
      }
    }
  }

  .team-lev {
    overflow-y: scroll;

    .form {
      height: 580px;
    }
  }

  .content-item {
    width: 100%;

    .lev1 {
      display: flex;
    }

  }

  .input-box {
    width: 100%;

    span {
      line-height: 45px;

      padding-left: 10px;
      padding-right: 10px;

    }
  }

  .lev2 {

    .ant-form-item-label > label {
      font-size: 18px;
      font-family: Roboto-SemiBold, Roboto;
      font-weight: 600;
      color: rgb(255, 255, 255);
      line-height: 20px;
      padding: 20px;
      margin-right: 50px;
    }

    .ant-form-item-control {
      margin: 10px 0px;
      width: 30%;

      .ant-form-item-control-input {
        border: none;
        background: rgb(28, 28, 28);

        .input-box {
          background: rgb(28, 28, 28);
          width: 75%;
          border: none;
          border-radius: 8px;
          height: 45px;

          .ant-input {
            background: rgb(28, 28, 28);
          }
        }
      }
    }

    .ant-form-item {
      background: rgb(39, 39, 39);
      border-radius: 8px;
      border: 1px solid rgba(255, 255, 255, 0.1);

      margin: 1.3em 0px;

      .ant-form-item-row {
        display: flex !important;;

      }
    }
  }

  .form {

    width: 100%;

    .list-lev {
      width: 30%;
      display: flex;
    }

    .input-box {
      display: flex;
      width: 100%;
    }
  }


  .part3 {
    padding: 0px 2%;

    .panel-box {
      padding: 5px 2%;

      .panel-container {
        padding: 0;

        .donate-box {
          display: flex;
          justify-content: space-between;

          .donate-info {
            width: 31%;
            padding: 20px;
            background: rgb(28, 28, 28);
            box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 15px 4px;
            border-radius: 15px;

            border: 1px solid rgba(255, 255, 255, 0.15);
            margin: 0px;

            .ant-btn {
              width: 100%;
            }

            .flex-box1 {

              .info-item {
                display: flex;
                justify-content: space-between;

                .name {
                  font-size: 18px;
                  font-family: Roboto-Medium, Roboto;
                  font-weight: bold;
                  color: #8A8080;
                  line-height: 26px;
                  margin: 0.6em 0;
                }

                .value {
                  margin: 0.6em 0;
                  font-size: 18px;
                  font-family: Roboto-Bold, Roboto;
                  font-weight: bold;
                  color: #FFFFFF;
                  line-height: 26px;
                }
              }

            }

            .code {
              margin: 0.5em 0;
              font-size: 18px;
              font-family: Roboto-SemiBold, Roboto;
              font-weight: 600;
              color: #CD9E57;
              line-height: 26px;
            }

            .info-item1 {
              display: flex;
              justify-content: space-between;

              .name {
                font-size: 18px;
                font-family: Roboto-Medium, Roboto;
                font-weight: bold;
                color: #8A8080;
                line-height: 26px;
                margin: 0.6em 0;
              }

              .value {
                margin: 0.6em 0;
                font-size: 18px;
                font-family: Roboto-Bold, Roboto;
                font-weight: bold;
                color: #FFFFFF;
                line-height: 26px;
              }
            }

            .info-item {
              display: flex;
              justify-content: space-between;

              .name {
                font-size: 18px;
                font-family: Roboto-Medium, Roboto;
                font-weight: bold;
                color: #8A8080;
                line-height: 26px;
                margin: 0.6em 0;
              }

              .value {
                margin: 0.6em 0;
                font-size: 18px;
                font-family: Roboto-Bold, Roboto;
                font-weight: bold;
                color: #FFFFFF;
                line-height: 26px;
              }
            }

            .current {
              display: flex;
              justify-content: space-between;

              .name {
                font-size: 18px;
                font-family: Roboto-Medium, Roboto;
                font-weight: bold;
                color: #8A8080;
                line-height: 26px;
                margin: 0.6em 0;
              }

              .value {
                margin: 0.6em 0;
                font-size: 18px;
                font-family: Roboto-Bold, Roboto;
                font-weight: bold;
                color: #FFFFFF;
                line-height: 26px;
              }
            }

            .fund-balan {
              font-size: 16px;
              font-family: Roboto-Bold, Roboto;
              font-weight: bold;
              color: #FFFFFF;
              line-height: 19px;
              display: flex;
              justify-content: space-between;
              margin: 1.5em 0em;
            }


          }
        }
      }
    }
  }

  .panel-box2 {
    padding: 0px 4%;

    .fun {
      display: flex;
      justify-content: space-between;
      flex-flow: wrap;
      padding: 0em;
      margin: 0;
      box-shadow: none;

    }
  }

  .sc {
    width: 25px;
  }

  .fund-list {
    padding: 25px;
    background: rgb(28, 28, 28);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 15px 4px;
    border-radius: 15px;
    width: 31%;
    border: 1px solid rgba(255, 255, 255, 0.15);
    margin: 1em 0px;

    .list-title {
      font-size: 24px;
      font-family: Roboto-Medium, Roboto;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 32px;
    }

    .list-header {
      display: flex;
      margin: 1.5em 2em;
      justify-content: start;

      .col {
        width: 42%;
        font-size: 18px;
        font-family: Roboto-SemiBold, Roboto;
        font-weight: 600;
        color: #8A8080;
        line-height: 24px;
      }
    }

    .list-item {
      background: rgb(39, 39, 39);
      border-radius: 8px;
      border: 1px solid rgba(255, 255, 255, 0.1);
      display: flex;
      margin: 1.5em 0px;
      padding: 10px;
      justify-content: start;

      .col {

        border-radius: 10px;
        height: 45px;
        line-height: 40px;
        font-size: 16px;
        font-family: Roboto-SemiBold, Roboto;
        font-weight: 600;

        &:nth-child(1) {
          margin-left: 15px;
          width: 40%;
        }

        &:nth-child(2) {
          width: 38%;
          text-align: center;
          background: #1C1C1C;
          border-radius: 10px 10px 10px 10px;
          border: 1px solid rgba(255, 255, 255, 0.05);
        }

        &:nth-child(3) {
          width: 10%;
          margin-left: 5px;
        }


      }

      .address {
        text-align: left;
        border: none;

        a {
          color: #CD9E57;
        }
      }
    }
  }

  .ant-form-item-control-input-content {
    display: flex;
  }

  .max {

    .max-btn {
      font-size: 18px;
      font-family: Roboto-Bold, Roboto;
      font-weight: bold;
      color: rgb(26, 20, 20);
      width: 20%;
      text-align: center;
      line-height: 45px;
      background: linear-gradient(229deg, rgb(218, 177, 99) 0%, rgb(204, 138, 53) 100%);
      border-radius: 16px;
    }
  }


  .other-reward {
    display: flex;
    justify-content: space-between;
  }

  .address {
    a {
      color: rgba(205, 158, 87, 1);
    }

  }

  .go-btn {
    background: linear-gradient(229deg, #DAB163 0%, #CC8A35 100%);
    font-size: 18px;
    font-family: Roboto-Bold, Roboto;
    font-weight: bold;
    height: 50px;
    color: #1A1414;
    width: 40%;
    margin-top: 1em;
  }

  .switchh {
    width: 40%;
    line-height: 35px;

    p {
      font-size: 18px;
      display: flex;
      font-weight: 500;
      justify-content: space-between;
      color: #FFFFFF;
    }
  }


  .add-btn {
    border: 1px solid #FFAE4E;
    font-size: 18px;
    font-family: Roboto-Bold, Roboto;
    font-weight: bold;
    border-radius: 8px;
    height: 50px;
    text-align: center;

    line-height: 45px;
    color: rgb(255, 174, 78);
    width: 100%;

  }

  .tj-btn {
    height: 50px;
    text-align: center;
    line-height: 50px;
    margin-top: 1em;
    color: #1A1414;
    font-weight: bold;
    width: 100%;
    font-size: 18px;
    background: linear-gradient(229deg, #DAB163 0%, #CC8A35 100%);
    border-radius: 10px 10px 10px 10px;
  }

  .tj1-btn {
    height: 50px;
    text-align: center;
    line-height: 50px;
    margin-top: 1em;
    color: #1A1414;
    font-weight: bold;
    width: 40%;
    font-size: 18px;
    background: linear-gradient(229deg, #DAB163 0%, #CC8A35 100%);
    border-radius: 10px 10px 10px 10px;
  }

  .ant-switch-checked {
    background: linear-gradient(229deg, #DAB163 0%, #CC8A35 100%);

  }


  .ant-switch-handle {
    width: 24px;
    height: 24px;
    top: 0px;
  }

  .ant-switch {
    margin-left: 55px;
    min-width: 45px;
    height: 25px;
    border-radius: 10px 10px 10px 10px;
    margin-top: 6px;
  }

  .tip-box {
    padding: 0em 0 1.6em 0;
    font-size: 16px;
    font-family: Roboto-Medium, Roboto;
    font-weight: 500;
    color: #8A8080;
    line-height: 21px;
  }

  .panel-container1 {
    background: #1C1C1C;
    box-shadow: 0px 5px 20px 5px rgba(0, 0, 0, 0.2);
    border-radius: 20px 20px 20px 20px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    padding: 50px 50% 25px 100px;
    margin: 1.5em 0;
    .content-item
  }

  .ant-form {
    margin-bottom: 1.8em;

    .ant-form-item {
      margin-bottom: 20px;
      font-size: 16px;
      font-family: Roboto-Medium, Roboto;
      font-weight: 500;
    }
  }

  .flex-box {
    display: block;
  }

  .hh1 {
    margin: 1em 0em;
    background: rgba(28, 28, 28, 1);

    .flex-box1 {
      display: flex;
      justify-content: flex-start;
      padding: 20px 0.6em;
      border-bottom: 1px solid rgba(234, 234, 234, 0.10);
    }

    .hhi, .flex-box1 {
      justify-content: flex-start;

      .col {
        text-align: left;
        font-size: 16px;

        &:nth-child(1) {
          margin-left: 60px;
          width: 20%;
        }

        &:nth-child(2) {
          width: 50%;
          margin-left: 10px;
        }

        &:nth-child(3) {
          width: 10%;
          margin-left: 10px;

        }

        &:nth-child(4) {
          width: 10%;
          margin-left: 10px;

        }
      }
    }

    .hhi:last-child {
      border-bottom: none;
    }

    .hhi {
      color: #FFFFFF;
      display: flex;
      justify-content: flex-start;
      padding: 1em 0.5em;
      width: 100%;
      margin: 0 auto;
      border-radius: 0px;
      border-bottom: 1px solid rgba(234, 234, 234, 0.10);

      &:nth-child(even) {
        background: rgba(62, 62, 62, 1);
      }

      .col {
        overflow: hidden;
        padding-left: 0%;

      }

      .address {
        a {
          color: #CD9E57;
        }
      }
    }
  }

  @media screen and (max-width: 1440px ) {

    .max {

      .max-btn {
        font-size: 16px;
        width: 30%;
        border-radius: 8px;
      }
    }

    .part1 .panel-box .panel-container {
      padding: 40px 50% 25px 60px;
    }

    .panel-title {
      font-size: 21px;
      margin: 0px;
    }

    .superdao-nav-list {
      margin: 0px;
    }

    .ant-input {
      font-size: 15px;
    }

    .ant-form-item-label > label {
      font-size: 16px;
    }

    .ant-form-item-control-input-content span {
      font-size: 15px;
    }

    .header-nav3 {
      padding: 10px 4%;
      display: flex;

      .superdao-nav-list {
        height: 45px;
        width: auto;

        .nav-item {
          width: auto;
          padding: 0 20px;
          font-size: 16px;
        }
      }
    }


    .header-nav1 {
      padding: 0%;
      width: 100%;

      .superdao-nav-list {
        width: 43%;

        .nav-item {

          font-size: 16px;
        }
      }
    }

    .addsbt {
      text-align: center;
      width: 13%;
      padding: 5px;
      font-size: 16px;
      margin: 1.5em auto;
      color: #FFAE4E;
      border: 1px solid;

      border-radius: 8px;
    }

    .header-nav2 {
      width: 100%;

      .superdao-nav-list1 {
        width: 90%;

        .nav-item {
          font-size: 16px;
        }
      }
    }

    .level-name {
      font-size: 16px;
    }

    .fund-list {
      .list-item1 {
        margin: 0.5em 0;
      }

      .list-header1 {
        margin: 0.5em 1.5em;
      }

      .list-title {
        font-size: 18px;
      }

      .list-header {
        margin: 0.5em 0em;

      }

      .list-item {
        margin: 1em 0em;
      }

      .list-item .col {
        margin-left: 0em;

        &:nth-child(1) {
          width: 48%;
          margin-left: 0em;
        }
      }

      .list-header .col {
        margin: 0.5em 0.5em;

        font-size: 16px;
      }

      .list-header1 .col1, .list-item1 .col1 {
        font-size: 16px;
      }

      .list-item1 .content-item .setInvite-title {
        font-size: 16px;
      }
    }

    .panel-box1 {
      .panel-container {
        padding: 50px 50% 25px 60px;
      }
    }

    .setlv {
      .list-header {
        font-size: 15px;
      }

      .list-header, .list-item {
        .col {
          font-size: 15px;

          &:nth-child(1) {
            margin-left: 60px;
          }

        }
      }


    }

    .current-status {
      font-size: 16px;
      white-space: nowrap;
      margin-right: 10px;
    }

    .page-title {
      margin-left: 5%;
      padding: 0 8%;
      font-size: 30px;
      font-family: Roboto-Bold, Roboto;
      font-weight: bold;
      margin: 1em 0em;
    }

    .panel-title1 {
      p, span {
        font-size: 18px;
      }
    }

    .panel-container {

      .operate-box {
        margin-top: 20px;
        text-align: center;

        .add {
          width: 160px;
        }
      }

      .info {
        font-size: 14px;
        font-family: Roboto-Medium, Roboto;
        font-weight: 500;
        line-height: 18px;
        max-width: 420px;
      }
    }

    .panel-container1 {
      padding: 50px 50% 25px 60px;
    }


    .form {
      width: 100%;
    }

    .go-btn {
      font-weight: bold;
      color: rgb(26, 20, 20);
      font-size: 16px;
      font-family: Roboto-Bold, Roboto;
      height: 45px;
    }

    .add-btn {

      font-size: 16px;
      height: 45px;
    }

    .input-box {
      width: 100%;

      span {
        line-height: 45px;

        padding-left: 10px;
        padding-right: 10px;

      }
    }

    .lev2 {

      .ant-form-item-label > label {
        font-size: 16px;
        padding: 20px;
        margin-right: 50px;
      }

      .ant-form-item-control {
        margin: 10px 20px 10px 0px;

        width: 30%;

        .ant-form-item-control-input {
          .input-box {
            width: 100%;
          }
        }
      }

      .ant-form-item {
        background: rgb(39, 39, 39);
        border-radius: 8px;
        border: 1px solid rgba(255, 255, 255, 0.1);

        margin: 1.3em 0px;

        .ant-form-item-row {
          display: flex !important;;

        }
      }
    }


    .switchh {
      width: 40%;
      line-height: 35px;

      p {
        font-size: 16px;
      }
    }

    .records-list {

      .rowheader {
        display: flex;
        padding: 20px 0.5em;
      }

      .row1, .rowheader {
        justify-content: flex-start;

        .col {
          text-align: left;
          font-size: 15px;
          width: 130px;
        }
      }

      .row1 {
        display: flex;
        justify-content: flex-start;
        padding: 1em 0.5em;

      }
    }

    .sign-list {
      .signheader {
        display: flex;
      }

      .signitem, .signheader {
        justify-content: flex-start;

        .col {
          text-align: left;
          font-size: 15px;

          &:nth-child(1) {
            margin-left: 20px;
            width: 15%;
          }

          &:nth-child(2) {
            width: 39%;
            margin-left: 10px;
          }

          &:nth-child(3) {
            width: 35%;
            margin-left: 10px;
          }


        }
      }

      .signitem:last-child {
        border-bottom: none;
      }

    }


    .funding-list {
      overflow-x: scroll;

      .listheadert3 {
        width: 1840px;
      }

      .donheader {
        font-size: 15px;
      }

      .donitem {
        width: 100%;
        padding: 1em 1.8em;
      }

      .donheader, .donitem {
        .col {
          font-size: 15px;

          &:nth-child(1) {
            margin-left: 0px;
            width: 140px;
          }

          &:nth-child(6) {
            margin-left: 0px;
            width: 190px;
          }

          &:nth-child(7) {
            margin-left: 0px;
            width: 190px;
          }

          &:nth-child(10) {
            margin-left: 0px;
            width: 190px;
          }

          &:nth-child(11) {
            margin-left: 0px;
            width: 170px;
          }
        }

        .col {
          .item {
            font-size: 15px;
          }

          .address {
            text-align: left;
          }
        }
      }

    }

    .fund-list {
      .list-title {
        font-size: 18px;
      }

      .list-header, .list-item {
        .col {
          font-size: 15px;
        }
      }
    }

    .part2 {
      .header-box {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        position: relative;

        .fresh-icon {
          position: absolute;
          left: 260px;
          top: 40px;
          width: 26px;
          height: 26px;
          cursor: pointer;
          transition: 0.5s;

          &:hover {
            transform: rotate(180deg);
          }
        }

        .nav-list-box {
          margin: 0em 0em 2em 0;
          display: flex;
          width: 90%;

          .superdao-nav-list {
            height: 45px;
            width: 30%;
            margin: 0;

            .nav-item {
              font-size: 16px;
            }
          }
        }
      }

      .superdao-list-box {
        .list-header {
          padding: 20px 1em;
        }

        .list-item, .list-header {
          justify-content: flex-start;
          /* padding: 0.5em 2.5em 0.5em 2em; */

        }

        .col {
          text-align: left;
          padding-left: 0.5%;

          &:nth-child(1) {
            width: 7%;
            margin-left: 10px;
          }

          &:nth-child(2) {
            width: 10%;
          }

          &:nth-child(3) {
            width: 13%;
          }

          &:nth-child(4) {
            width: 17.5%;
          }

          &:nth-child(5) {
            width: 8%;
            /* padding-right: 5px; */
          }

          &:nth-child(6) {
            width: 9%;
          }

          &:nth-child(7) {
            width: 8%;
          }

          &:nth-child(8) {
            width: 11%;
          }

          &:nth-child(9) {
            width: 16%;

          }

        }


        .list-item {
          padding: 0.5em 1em;

          .col {
            overflow: hidden;

          }

          .no {
            color: #FE6D46;
            width: 60px;
          }


          .address {
            a {
              color: rgba(205, 158, 87, 1);
            }

          }
        }
      }

    }

    .ant-btn {
      font-size: 16px;
      height: 45px;
    }

    .tj-btn {
      font-size: 16px;
      height: 45px;
      width: 100%;
    }

    .tj1-btn {
      font-size: 16px;
      height: 45px;
    }

    .part3 {
      padding: 0px 2%;

      .panel-box {
        padding: 5px 2%;

        .panel-container {
          padding: 0;

          .donate-box {
            display: flex;
            justify-content: space-between;

            .donate-info {
              width: 31%;
              margin: 10px 0;

              .flex-box1 {

                .info-item {
                  display: flex;
                  justify-content: space-between;

                  .name {
                    font-size: 15px;

                  }

                  .value {
                    margin: 0.6em 0;
                    font-size: 15px;

                  }
                }

              }

              .code {
                margin: 0.5em 0;
                font-size: 15px;
                font-family: Roboto-SemiBold, Roboto;
                font-weight: 600;
                color: #CD9E57;
                line-height: 26px;
              }

              .info-item1 {
                display: block;

                .name {
                  font-size: 15px;

                }

                .value {
                  margin: 0.6em 0;
                  font-size: 15px;

                }
              }

              .info-item {
                display: flex;
                justify-content: space-between;

                .name {
                  font-size: 15px;

                }

                .value {
                  margin: 0.6em 0;
                  font-size: 15px;

                }
              }

              .current {
                display: flex;
                justify-content: space-between;

                .name {
                  font-size: 15px;
                  font-family: Roboto-Medium, Roboto;
                  font-weight: bold;
                  color: #8A8080;
                  line-height: 26px;
                  margin: 0.6em 0;
                }

                .value {
                  margin: 0.6em 0;
                  font-size: 15px;
                  font-family: Roboto-Bold, Roboto;
                  font-weight: bold;
                  color: #FFFFFF;
                  line-height: 26px;
                }
              }

              .fund-balan {
                display: block;
                font-size: 14px;
                margin: 1.5em 0em;
              }

            }
          }
        }
      }
    }

    .part21 {
      .superdao-list-box {

        .list-item, .list-header {
          justify-content: flex-start;

        }

        .col {
          text-align: left;
          font-size: 16px;

          &:nth-child(1) {
            width: 15%;

            margin-left: 30px;

          }

          &:nth-child(2) {
            width: 16%;
            margin-left: 10px;

          }

          &:nth-child(3) {
            width: 20%;
            margin-left: 15px;
          }

          &:nth-child(4) {
            width: 28%;
            margin-left: 10px;
          }

          &:nth-child(5) {
            width: 15%;
            margin-left: 15px;
          }
        }

        .list-item {
          padding: 0.5em 2.5em;

          .col {
            overflow: hidden;

            //text-overflow: ellipsis;

          }

          .no {
            color: rgba(228, 134, 134, 1);
          }


          .sc {
            width: 25px;
          }
        }
      }

      .hh1 {

        .hhi, .flex-box1 {
          justify-content: flex-start;

        }

        .col {
          text-align: left;
          font-size: 16px;

          &:nth-child(1) {
            width: 13%;

            margin-left: 30px;

          }

          &:nth-child(2) {
            width: 18%;
            margin-left: 10px;

          }

          &:nth-child(3) {
            width: 28%;
            margin-left: 10px;
          }

          &:nth-child(4) {
            width: 15%;
            margin-left: 15px;
          }

          &:nth-child(5) {
            width: 15%;
            margin-left: 15px;
          }
        }

        .hhi {
          padding: 0.5em 2.5em;

          .col {
            overflow: hidden;

            //text-overflow: ellipsis;

          }

          .no {
            color: rgba(228, 134, 134, 1);
          }


          .sc {
            width: 25px;
          }
        }
      }

      .hh {
        display: flex;
        width: 60%;

        .btns {
          margin-top: 1.7em;
          float: left;
          font-size: 18px;
        }

      }

    }

    .part3 {
      .operate-btns {
        width: 100%;

        Button {
          width: 100%;
          height: 40px;
          margin-top: 1em;
        }
      }


      .rate1 {
        margin-top: 0.5em;

        .info-item {
          .name {
            font-size: 16px;
            font-family: Roboto-Bold, Roboto;
            font-weight: bold;
            color: #8A8080;
            line-height: 30px;
          }

          .value {
            font-size: 24px;
            font-family: Roboto-Bold, Roboto;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 50px;
            background: white;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }

      .donate-pid {
        .flex-box {
          margin: 1.5em 0;
          justify-content: space-between;
          align-items: center;


          .value {
            font-size: 18px;
            font-family: Roboto-Bold, Roboto;
            font-weight: bold;
            color: #796B6B;
            line-height: 28px;
          }
        }
      }

      .input-box {
        position: relative;

        .exchangeAmount {
          height: 50px;
          display: flex;
          align-items: center;
          font-size: 18px;
          padding: 0 20px;
        }

        .ant-input-number, .ant-select {
          height: 50px;
          padding: 0 66px 0 40px;
          width: 100% !important;

          .ant-input-number-input-wrap, .ant-select-selector, input {
            height: 100%;
            width: 100% !important;

            &:focus-visible {
              outline: none;
            }
          }

          .ant-select-clear {
            margin-right: 66px;
          }
        }

        .max-btn {
          width: 100%;
          background: linear-gradient(32deg, #FFAE4E 0%, #F9D423 100%);
          border-radius: 10px 10px 10px 10px;
        }

        .coin-icon {
          position: absolute;
          top: 10px;
          left: 10px;
          width: 30px;
          height: 30px;
        }

        .coin-name {
          position: absolute;
          right: 10px;
          top: 10px;
          font-size: 20px;
        }
      }

      .balance-box {
        display: flex;
        justify-content: space-between;
        font-size: 20px;
        font-family: Roboto-Bold, Roboto;

        .name {
          font-weight: bold;
          color: #796B6B;
          line-height: 30px;
        }

        .value {
          font-weight: bold;
          color: #FFFFFF;
          line-height: 30px;
        }
      }

      .down-icon {
        width: 40px;
        height: 40px;
        margin: 0.5em 0 1em calc(50% - 20px);

      }

      .donate {
        margin-top: 1em;
        width: 100%;
        height: 50px;
        font-size: 20px;
        font-family: Helvetica-Bold, Helvetica;
        font-weight: bold;
      }

      .tip {
        margin-top: 2em;
        font-size: 16px;
        font-family: Roboto-Bold, Roboto;
        font-weight: bold;
        color: #FFFFFF;
      }
    }

    .header-nav {

      .superdao-nav-list {
        .nav-item {
          width: 150px;
          height: 55px;
          color: rgba(138, 128, 128, 1);
          line-height: 20px;
          /* background: rgba(255, 255, 255, 0.10); */
        }

        .nav-item.active {
          color: rgb(255, 174, 78);
        }
      }
    }

    .part4 {
      .sum-list {
        .sum-item-box {
          margin-top: 20px;
          padding: 10px 10px;
          box-shadow: #fff1f0 0 0 10px;
          text-align: center;

          .col {
            &:nth-child(1) {
              width: 60px;
            }

            &:nth-child(2) {
              width: 60px;
            }

            &:nth-child(3) {
              width: 60px;
            }

            &:nth-child(4) {
              width: 250px;
            }

            &:nth-child(5) {
              width: 50px;
            }

            &:nth-child(6) {
              width: 30px;
            }

            &:nth-child(7) {
              width: 100px;
            }

          }

          .sum-list-header {
            display: flex;

            .index {
              margin-right: 10px;
              font-weight: bold;
            }
          }

          .sum-item-header {
            border-bottom: 1px solid #eee;
            margin-top: 10px;
            display: flex;
            justify-content: space-between;
            padding: 10px 0;

            .col:nth-child(4) {
              width: 160px;
              overflow: hidden;
            }
          }

          .sum-list-footer {
            border-top: 1px solid #eee;
            display: flex;
            padding-top: 10px;

            .col {
              margin-right: 10px;

              &:nth-child(1) {
                width: 150px !important;
              }

              &:nth-child(2) {
                width: 120px !important;
              }

              &:nth-child(3) {
                width: 120px !important;
              }
            }
          }

          .sum-item {
            .col:nth-child(4) {
              width: 160px;
              overflow: hidden;
            }

            padding: 5px 0px;
            display: flex;

            justify-content: space-between;
          }
        }

      }
    }

  }

  .pagination {
    text-align: center;
  }

  @media screen and (max-width: 450px) {
    .fund-balan {
      display: block;
      font-size: 14px;
      margin: 1.5em 0em;
    }

    .ant-table-wrapper {
      margin: 1em 0;
    }

    .ant-form-item-label > label {
      font-size: 14px;
      margin: 5px;
    }

    .ant-form .ant-form-item {
      margin-bottom: 10px;
    }

    .ant-input {
      font-size: 14px;
    }

    .ant-form-item-control-input-content span {
      font-size: 14px;
    }

    .ant-form-item-control-input {
      height: 40px;
    }

    .ant-btn {
      width: 100%;
      font-size: 16px;
      line-height: 35px;
      height: 40px;
    }

    .tj-btn {
      line-height: 45px;
      height: 40px;
      width: 100%;
    }

    .tj1-btn {
      line-height: 45px;
      height: 40px;
      width: 100%;
    }

    .btn-box {
      width: 100%;

      .addsbt {
        width: 40%;
        font-size: 16px;
        padding: 0;
        line-height: 45px;
      }

      .ant-sbt {
        width: 40%;
        font-size: 16px;
        line-height: 45px;
      }
    }

    .add-btn {
      width: 100%;
      font-size: 16px;
      line-height: 35px;
      height: 40px;
    }

    .input-box {
      width: 100%;

      span {
        line-height: 45px;

        padding-left: 10px;
        padding-right: 10px;

      }
    }

    .lev2 {

      .ant-form-item-label > label {
        font-size: 15px;
        padding: 20px;
        margin-right: 20px;
      }

      .ant-form-item-control {
        margin: 10px 20px 10px 0px;

        width: 100%;

        .ant-form-item-control-input {
          .input-box {
            width: 100%;
          }
        }
      }

      .ant-form-item {
        background: rgb(39, 39, 39);
        border-radius: 8px;
        border: 1px solid rgba(255, 255, 255, 0.1);
        margin: 1.3em 0px;

        .ant-row {
          display: block !important;
          width: 100%;

          .ant-form-item-label {
            display: flex;
            width: 50%;
          }

          .ant-form-item-control {
            float: right;
            width: 40%;
            margin: -50px 25px;

            .ant-form-item-control-input {
              width: 100%;

              .ant-form-item-control-input-content {
                .input-box {
                  width: 100%;
                }
              }
            }
          }
        }

      }
    }


    .switchh {
      width: 100%;
      margin-top: 10px;

      p {
        font-size: 14px;
      }
    }

    .level-name {
      font-size: 15px;
    }

    .panel-container1 {
      padding: 25px;
    }

    .panel-container .info {
      font-size: 13px;
    }

    .panel-box1 .panel-container {
      padding: 20px;;

      .panel-title1 {
        p {
          font-size: 16px;
        }

        span {
          font-size: 16px;
        }
      }
    }

    .panel-title {
      font-size: 18px;
      margin: 0px;
    }

    .part1 .panel-box .panel-container {
      padding: 20px;
    }

    .panel-box {
      padding: 5px 0%;
    }

    .listheadert1 {
      width: 880px;;
    }

    .header-nav3 {
      padding: 5px 2%;
      display: flex;
      width: 100%;
      overflow-x: scroll;

      .headerlist {
        width: 800px;
      }

      .superdao-nav-list {
        margin: 0px;
        display: flex;

        .nav-item {
          width: 150px;
          font-size: 14px;
          padding: 0 5px;
        }
      }
    }


    .header-nav1 .superdao-nav-list {
      width: 80%;

      .nav-item {
        font-size: 14px;
      }
    }

    .header-nav2 {
      width: 100%;

      .superdao-nav-list1 {
        width: 100%;

        .nav-item {
          font-size: 14px;
        }
      }
    }

    .panel-box2 {
      .fun {
        display: block;

        .fund-list {
          width: 100%;

          .list-title {
            font-size: 16px;
          }

          .list-header1 .col1 {
            font-size: 15px;
          }

          .list-header {
            margin: 0.8em 0em;

            .col {
              margin: 0.5em 0.5em;

            }
          }

          .list-item {
            margin: 0.8em 0em;
          }

          .list-item .col {
            &:nth-child(1) {
              margin-left: 0px;
              width: 48%;
            }

          }

        }
      }
    }

    .setlv {

      .list-header {
        font-size: 14px;
        padding: 20px 0.5em;
      }

      .list-item {
        padding: 10px 0.5em;
      }

      .list-header, .list-item {
        .col {
          font-size: 14px;

          &:nth-child(1) {
            margin-left: 5px;
            width: 30%;
          }

          &:nth-child(2) {
            margin-left: 5px;
            width: 45%;
          }

          &:nth-child(3) {
            margin-left: 5px;
            width: 15%;
          }

        }
      }


    }


    .max {

      .max-btn {
        font-size: 15px;
        width: 25%;
        border-radius: 8px;
      }
    }

    .part3 .panel-box .panel-container .donate-box {
      display: block;

      .donate-info {
        width: 100%;

        .info-item {
          .name {
            font-size: 14px;
          }

          .value {
            font-size: 14px;

          }
        }

        .flex-box1 .info-item {
          .name {
            font-size: 14px;
          }

          .value {
            font-size: 14px;
          }
        }

        .code {
          font-size: 14px;
        }

        .info-item1 .value {
          font-size: 14px;
        }

        .current {
          .name {
            font-size: 14px;
          }

          .value {
            font-size: 14px;
          }
        }
      }
    }

    .addsbt {
      text-align: center;
      width: 40%;
      padding: 5px;
      font-size: 14px;
      margin: 1.5em auto;
      color: #FFAE4E;
      border: 1px solid;

      border-radius: 8px;
    }

    .records-list {
      overflow-x: scroll;

      .listheadert {
        width: 900px;
      }

      .rowheader {
        font-size: 14px;
        padding: 20px 0.5em;
      }

      .row1 {
        padding: 10px 0.5em;
      }

      .rowheader, .row1 {
        .col {
          font-size: 14px;
          width: 100px;

        }
      }

    }

    .sign-list {
      overflow-x: scroll;

      .listheader4 {

        width: 1000px;

        .signheader {
          display: flex;
        }

        .signitem, .signheader {
          justify-content: flex-start;

          .col {
            text-align: left;
            font-size: 14px;

            &:nth-child(1) {
              margin-left: 10px;
              width: 15%;
            }

            &:nth-child(2) {
              width: 18%;
              margin-left: 10px;
            }

            &:nth-child(3) {
              width: 22%;
              margin-left: 10px;
            }

            &:nth-child(4) {
              width: 22%;
              margin-left: 10px;
            }

            &:nth-child(5) {
              width: 17%;
              margin-left: 10px;
            }

          }
        }

      }
    }


    .funding-list {
      overflow-x: scroll;

      .listheadert3 {
        width: 1805px;
      }

      .donheader {
        font-size: 14px;
        padding: 20px 1.8em;
      }

      .donitem {
        padding: 10px 1.8em;
      }

      .donheader, .donitem {
        .col {
          font-size: 14px;

          &:nth-child(5) {
            width: 130px;
            margin-left: 0px;

          }

          &:nth-child(11) {
            width: 150px;
            margin-left: 0px;

          }

          .item {
            font-size: 14px;
          }
        }
      }

    }

    .admin3-list {
      overflow-x: scroll;

      .list-header {
        font-size: 14px;
        padding: 20px 0.5em;
      }

      .list-item {
        padding: 10px 0.5em;
      }

      .list-header, .list-item {
        .col {
          font-size: 14px;

          &:nth-child(1) {
            margin-left: 5px;
            width: 30%;
          }

          &:nth-child(2) {
            margin-left: 5px;
            width: 45%;
          }

          &:nth-child(3) {
            margin-left: 5px;
            width: 15%;
          }

        }
      }


    }
  }

`;
export default OGPoolStyle;

import React, {useEffect, useRef, useState} from 'react';
import {useConnect} from "../../../../api/contracts";
import {Table, Button, Select, Descriptions, message, Form, List, Input, notification, Card, Switch} from 'antd';
import {getContractByName, getContractByContract} from "../../../../api/connectContract";
import {dealMethod, viewMethod} from "../../../../utils/contractUtil"
import {useNavigate, useLocation} from "react-router-dom";
import FireLockStyle from "./style";
import judgeStatus from "../../../../utils/judgeStatus";
import AddWhiteListAddr from "./component/AddWhiteListAddr";
import RemoveWhiteListAddr from "./component/RemoveWhiteListAddr";
import add from "../../../../imgs/add.png";
import cut from "../../../../imgs/remove.png";
import {SVTDecimals} from "../../../../config/constants";
import BigNumber from "bignumber.js";

const FireLock = (props) => {
    let {state, dispatch} = useConnect();
    const [whitelist, setWhitelistArr] = useState([])

    const [isInWhiteList, setIsWhitelist] = useState()

    const [isChecked, setIsChecked] = useState(false)
    const [powerContracts, setpowerContracts] = useState()

    const [isTrue, setIsTrue] = useState(false)


    const [curNav, setCurNav] = useState(1)
    const [ownerAddr, setOwner] = useState("")

    const [launched, setLaunched] = useState()

    const [isShowAddW, setIsShowAddW] = useState(false)


    const [isShowAdd, setShowAdd] = useState(false)
    const [isShowRemove, setShowRemove] = useState(false)

    const [feeReceiver, setReceiver] = useState("")
    const [isInFeeWhitelist, setIsInFeeWhitelist] = useState(false)
    const [addWhiteArr, setAddWArr] = useState([{}])
    const [form] = Form.useForm();

    const location = useLocation()

    const openNotification = (message) => {
        notification.error({
            message: message,
            description:
                "",
            onClick: () => {
            },
        });
    };
    const handleDealMethod = async (name, params) => {
        let contractTemp = await getContractByName("SFT", state.api,)
        if (!contractTemp) {
            openNotification("Please connect")
        }
        return dealMethod(contractTemp, state.account, name, params)
    }

    const addOneWhite = async () => {
        let addWhiteArrT = JSON.parse(JSON.stringify(addWhiteArr))
        addWhiteArrT.push({})
        setAddWArr(addWhiteArrT)
    }
    const removeOneWhite = async () => {
        let addWhiteArrT = JSON.parse(JSON.stringify(addWhiteArr))
        addWhiteArrT.shift()
        setAddWArr(addWhiteArrT)
    }
    const handleViewMethod = async (name, params) => {
        let contractTemp = await getContractByName("SFT", state.api,)
        if (!contractTemp) {
            openNotification("Please connect")
        }
        return await viewMethod(contractTemp, state.account, name, params)
    }


    const getData = async (page) => {
        getOwner()
        getLaunched()
    }


    const getOwner = async () => {
        const ownerAddr = await handleViewMethod("owner", [])
        setOwner(ownerAddr)
    }
    const getLaunched = async () => {
        const res = await handleViewMethod("launched", [])
        setLaunched(res)
    }

    const getWhiteLists= async () => {
        const res = await handleViewMethod("whiteList", [form.getFieldValue().checkAddr])
        setIsWhitelist(res)
    }
    const  setWhiteList = async () => {
        await handleDealMethod("setWhiteList", [form.getFieldValue().whitelistAddr,true])
    }

    const  removeWhiteList = async () => {
        await handleDealMethod("setWhiteList", [form.getFieldValue().whitelistAddr,false])
    }

    const transferOwnership = async () => {
        await handleDealMethod("transferOwnership", [form.getFieldValue().owner])
        getOwner()
    }



    const launch= async () => {
        await handleDealMethod("launch", [])
        getLaunched()
    }
    useEffect(async () => {
        let judgeRes = await judgeStatus(state)
        if (!judgeRes) {
            return
        }
        await getData()
    }, [state.account]);
    const whitelistColumns = [
        {
            title: 'Address',
            dataIndex: '',
            key: '',
        },

    ]
    return (
        <FireLockStyle>
            {isShowAddW && <AddWhiteListAddr updateData={() => {
                getWhiteLists()
            }} closeDialog={() => {
                setIsShowAddW(false)
            }}/>}
            {isShowRemove && <RemoveWhiteListAddr updateData={() => {
            }} closeDialog={() => {
                setShowRemove(false)
            }}/>}
            <h1 className="title">
                SVT Admin
            </h1>
            <div className="panel-box">
                <div className="panel-container">
                    <div className="nav-list">
                        <div className={"nav-item " + (curNav == 1 ? "active" : "")} onClick={() => {
                            setCurNav(1)
                        }}>
                            Owner
                        </div>

                    </div>
                    {curNav == 1 && <div className="part1">

                        <div className="content-item">
                            <h3>Owner Address</h3>
                            <Form form={form} name="control-hooks">
                                <div className="current">
                                    <div className="name">
                                        Current:
                                    </div>
                                    <div className="value">
                                        {ownerAddr}
                                    </div>
                                </div>
                                <Form.Item
                                    name="owner"
                                    label="owner address"
                                    validateTrigger="onBlur"
                                    validateFirst={true}
                                    rules={[
                                        {required: true, message: 'Please input owner Address!'},
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                            </Form>
                            <Button type="primary" className="max-btn" onClick={() => {
                                transferOwnership()
                            }}>
                                Submit
                            </Button>
                        </div>




                        <div className="content-item">
                            <h3> launch</h3>
                            <Form form={form} name="control-hooks">
                                <Switch  style={{marginBottom:"30px"}} checked={launched} onChange={()=>{launch()}}/>
                            </Form>
                        </div>

                        <div className="content-item">
                            <h3>  set whitelist address</h3>
                            <Form form={form} name="control-hooks">
                                <div className="current">
                                    <div className="name">
                                        {/*setPowerContract:*/}
                                    </div>
                                    <div className="value">
                                        {/*{powerContracts}*/}
                                    </div>
                                </div>
                                <Form.Item
                                    name="whitelistAddr"
                                    label="whitelistAddr"
                                    validateTrigger="onBlur"
                                    validateFirst={true}
                                >
                                    <Input/>
                                </Form.Item>
                            </Form>
                            <Button type="primary" className="max-btn" onClick={() => {
                                setWhiteList()
                            }}>
                                Submit
                            </Button>
                        </div>
                        <div className="content-item">
                            <h3>  remove whitelist address</h3>
                            <Form form={form} name="control-hooks">

                                <Form.Item
                                    name="whitelistAddr"
                                    label="whitelistAddr"
                                    validateTrigger="onBlur"
                                    validateFirst={true}
                                >
                                    <Input/>
                                </Form.Item>
                            </Form>
                            <Button type="primary" className="max-btn" onClick={() => {
                                removeWhiteList()
                            }}>
                                Submit
                            </Button>
                        </div>
                        <div className="content-item">
                            <h3>  get is in whitelist</h3>
                            <Form form={form} name="control-hooks">
                                <div className="current">
                                    <div className="name">
                                        {/*setPowerContract:*/}
                                    </div>
                                    <div className="value">
                                        {/*{powerContracts}*/}
                                    </div>
                                </div>
                                <Form.Item
                                    name="checkAddr"
                                    label="checkAddr"
                                    validateTrigger="onBlur"
                                    validateFirst={true}
                                >
                                    <Input/>
                                </Form.Item>
                                <div>

                                </div>
                                <Form.Item
                                    label="isTrue"
                                    validateTrigger="onBlur"
                                    validateFirst={true}
                                >
                                    {isInWhiteList?"TRUE":"FALSE"}

                                </Form.Item>
                            </Form>
                            <Button type="primary" className="max-btn" onClick={() => {
                                getWhiteLists()
                            }}>
                                Submit
                            </Button>
                        </div>


                    </div>}


                </div>

            </div>
        </FireLockStyle>
    )
}
export default FireLock

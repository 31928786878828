import React from "react";

function getItem(label, key, type) {
    return {
        key,
        label,
        type,
    };
}

export default [
    getItem('Funding', 'SBDPurchase',),
    getItem('SBDLock', 'SBDLock',),
    getItem('NFT', 'NFTView',),
    getItem('Swap', 'SwapView',),
    getItem('Bonus', 'LeaderboardBonus',),
]
let ethScan = "https://bscscan.com"
let chainId = "56"
let chainId16 = "0x38"
let graphUrlBase = "https://api.thegraph.com/subgraphs/name/"
if (true) {
    chainId= "97"
    chainId16 = "0x61"
    graphUrlBase = "https://api.thegraph.com/subgraphs/name/"
    ethScan = "https://testnet.bscscan.com"
}
export default {
    Name:"Ethereum",
    ENV: "dev",
    chainId,
    chainId16,
    github: "https://github.com/Superbitdaolab",
    graphUrlBase,
    ethScan,

}
